import { makeStyles } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'

export default makeStyles(() => ({
	root: ({ disabled }: { disabled?: boolean }) => ({
		'&:hover': {
			cursor: disabled ? 'default' : 'pointer',
		},
		opacity: disabled ? 0.5 : 1,
		fontFamily: `"Open Sans", sans-serif`,
		color: 'white',
		fontSize: '0.875rem',
		fontWeight: 500,
		padding: '6px 16px',
		boxSizing: 'border-box',
		borderRadius: '4px',
		border: 'none',
		lineHeight: 1.1,
		transition:
			'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		boxShadow:
			'0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
		verticalAlign: 'middle',
	}),
	primary: {
		background: themeValues().colors.primary,
	},
	success: {
		background: themeValues().colors.success,
	},
	error: {
		background: themeValues().colors.error,
	},
}))
