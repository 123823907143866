import { ERRORS_WITH_DIALOG } from 'app/errors/codes'
import useUpload from 'app/hooks/useUpload'
import { resolveRoute } from 'app/utils/route'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useEnvironmentVariables } from 'app/hooks/index'
import routesApi from 'app/routing/routes/routesApi'
import ConfirmationDialog from 'component/ConfirmationDialog/ConfirmationDialog'
import PrimaryButton from 'component/PrimaryButton/PrimaryButton'
import useTracking from 'app/services/datawarehouse/useTracking'
import { UrlParams } from 'app/types/UrlParams'
import ErrorDialog from 'component/ErrorDialog/ErrorDialog'
import { UploadButtonProps } from 'domain/general/boq/components/body/upload/UploadButton/UploadButtonProps'

const UploadButton: FC<UploadButtonProps> = ({ children, disabled }) => {
	const [isDialogOpen, setDialogOpen] = useState(false)
	const [isErrorDialogOpen, setErrorDialogOpen] = useState(false)

	const { t } = useTranslation()
	const { track } = useTracking()
	const ENV = useEnvironmentVariables()
	const { projectUuid, lotUuid } = useParams<UrlParams>()

	const {
		renderFileInput,
		openUploadDialog,
		error,
		loading,
		initializeUpload,
	} = useUpload()

	useEffect(() => {
		if (projectUuid && lotUuid) {
			initializeUpload(
				`${ENV.REACT_APP_API_URL}/${resolveRoute(
					routesApi.uploadGaebCreateRevision,
					{
						projectUuid,
						lotUuid,
					}
				)}`
			)
		}
	}, [projectUuid, lotUuid])

	useEffect(() => {
		if (error && error.code) {
			if (ERRORS_WITH_DIALOG.indexOf(error.code) > -1) {
				setErrorDialogOpen(true)
			} else {
				setDialogOpen(true)
			}
		}
	}, [error])

	const onAccept = (): void => {
		if (!loading) {
			track('GAEB_SUPPORT')
			setDialogOpen(false)
		}
	}

	const onDecline = (): void => {
		setDialogOpen(false)
	}
	return (
		<>
			{renderFileInput()}
			<PrimaryButton
				onClick={() => openUploadDialog()}
				disabled={disabled}
				loading={loading}
			>
				{children || 'Upload'}
			</PrimaryButton>
			<ConfirmationDialog
				open={isDialogOpen}
				contentText={t('domain:boq.upload.confirmation.content')}
				onAccept={onAccept}
				onAcceptLabel={t('domain:boq.upload.confirmation.accept')}
				onDecline={onDecline}
				onDeclineLabel={t('domain:boq.upload.confirmation.decline')}
			/>
			{error && (
				<ErrorDialog
					open={isErrorDialogOpen}
					error={error}
					onAccept={() => setErrorDialogOpen(false)}
				/>
			)}
		</>
	)
}

export default UploadButton
