import { SvgIconProps } from '@material-ui/core'
import GavelIcon from '@material-ui/icons/Gavel'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import EuroIcon from '@material-ui/icons/Euro'
import DescriptionIcon from '@material-ui/icons/Description'
import routes from 'app/routing/routes/routesApp'
import { CombinationOperator } from 'app/types/CombinationOperator'
import { REVISION_STATUS } from 'app/types/REVISION_STATUS'
import { ValidCombination } from 'app/types/ValidCombinations'
import AwardingPageContent from 'domain/award/awarding/components/base/AwardingPageContent/AwardingPageContent'
import ComparisonPageContent from 'domain/award/comparison/components/base/ComparisonPageContent/ComparisonPageContent'
import BoqPageContent from 'domain/general/boq/components/base/BoqPageContent/BoqPageContent'
import { LOT_STATUS } from 'domain/lot/interfaces/LOT_STATUS'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'
import AttachmentPageContent from 'domain/general/attachments/components/base/AttachmentPageContent/AttachmentPageContent'
import TenderPageContent from 'domain/tender/bidders/components/base/TenderPageContent/TenderPageContent'
import PeriodPageContent from 'domain/tender/period/components/base/PeriodPageContent/PeriodPageContent'
import MembersPageContent from 'domain/general/members/components/base/MembersPageContent/MembersPageContent'
import RegulationsPageContent from 'domain/general/regulations/components/base/RegulationsPageContent/RegulationsPageContent'
import DrawerMenuItemHeadline from 'domain/drawer/components/DrawerMenuItemHeadline/DrawerMenuItemHeadline'
import InformationsPageContent from 'domain/general/informations/components/base/InformationsPageContent/InformationsPageContent'
import QuantityPageContent from 'domain/billing/quantity/components/base/QuantityPageContent/QuantityPageContent'
import { ENVIRONMENTS } from 'app/constants'
import AnnotationsPageContent from 'domain/award/annotations/components/base/AnnotationsPageContent/AnnotationsPageContent'

export enum MENU_ITEM_TYPES {
	COLLAPSIBLE = 'Collapsible',
	LINK = 'Link',
	HEADLINE = 'Headline',
}

export enum MENU_ITEM_INVALID_BEHAVIOR {
	HIDDEN = 'HIDDEN',
	DISABLED = 'DISABLED',
}

export interface BaseMenuItem<T> {
	key: string
	type: MENU_ITEM_TYPES
	validCombinations?: ValidCombination[]
	invalidCombinations?: ValidCombination[]
	invalidBehavior?: MENU_ITEM_INVALID_BEHAVIOR
	children?: T[]
	icon?: (props: SvgIconProps) => JSX.Element
	combinationOperator?: CombinationOperator
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	pageContent?: any
}

export interface MenuItemCollapsible<T> extends BaseMenuItem<T> {
	type: MENU_ITEM_TYPES.COLLAPSIBLE
	title: string
	open?: boolean
	expandIcon?: (props: SvgIconProps) => JSX.Element
}

export interface MenuItemLink<T> extends BaseMenuItem<T> {
	type: MENU_ITEM_TYPES.LINK
	title: string
	urlPattern: string
}

export interface MenuItemHeadline<T> extends BaseMenuItem<T> {
	type: MENU_ITEM_TYPES.HEADLINE
	title: string
}

export type DrawerMenuItem =
	| MenuItemCollapsible<DrawerMenuItem>
	| MenuItemLink<DrawerMenuItem>
	| MenuItemHeadline<DrawerMenuItem>

export type DrawerMenu = DrawerMenuItem[]

// @TODO move to domain when we are starting to implement the props
export interface DrawerMenuItemProps {
	configuration: DrawerMenuItem
	parentConfiguration: DrawerMenuItem
	active?: boolean
	loading?: boolean
	disabled?: boolean
}

export default [
	{
		type: MENU_ITEM_TYPES.HEADLINE,
		key: 'headline',
		title: 'domain:drawer.section.headline.title',
		PageContent: DrawerMenuItemHeadline,
	},
	{
		type: MENU_ITEM_TYPES.COLLAPSIBLE,
		key: 'general',
		title: 'domain:drawer.section.general.title',
		children: [
			{
				key: 'boq',
				type: MENU_ITEM_TYPES.LINK,
				urlPattern: routes.section,
				title: 'domain:drawer.section.general.subSections.boq',
				pageContent: BoqPageContent,
			},
			{
				key: 'attachments',
				type: MENU_ITEM_TYPES.LINK,
				urlPattern: routes.section,
				title: 'domain:drawer.section.general.subSections.attachments',
				pageContent: AttachmentPageContent,
				invalidBehavior: MENU_ITEM_INVALID_BEHAVIOR.DISABLED,
				combinationOperator: CombinationOperator.OR,
				validCombinations: [
					{ projectRole: ROLES_PROJECT.LEAD },
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.TENDERING,
						revisionStatus: REVISION_STATUS.BIDDING,
					},
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.TENDERING,
						revisionStatus: REVISION_STATUS.SUBMITTED,
					},
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.AWARDING,
						revisionStatus: REVISION_STATUS.SUBMITTED,
					},
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.AWARDED,
						revisionStatus: REVISION_STATUS.ACCEPTED,
					},
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.CONFIRMED,
						revisionStatus: REVISION_STATUS.ACCEPTED,
					},
				],
			},
			{
				key: 'regulations',
				type: MENU_ITEM_TYPES.LINK,
				urlPattern: routes.section,
				title: 'domain:drawer.section.general.subSections.regulations',
				pageContent: RegulationsPageContent,
				invalidBehavior: MENU_ITEM_INVALID_BEHAVIOR.DISABLED,
				combinationOperator: CombinationOperator.OR,
				validCombinations: [
					{ projectRole: ROLES_PROJECT.LEAD },
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.TENDERING,
						revisionStatus: REVISION_STATUS.BIDDING,
					},
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.TENDERING,
						revisionStatus: REVISION_STATUS.SUBMITTED,
					},
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.AWARDING,
						revisionStatus: REVISION_STATUS.SUBMITTED,
					},
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.AWARDED,
						revisionStatus: REVISION_STATUS.ACCEPTED,
					},
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.CONFIRMED,
						revisionStatus: REVISION_STATUS.ACCEPTED,
					},
				],
			},
			{
				key: 'members',
				type: MENU_ITEM_TYPES.LINK,
				urlPattern: routes.section,
				title: 'domain:drawer.section.general.subSections.members',
				pageContent: MembersPageContent,
				invalidBehavior: MENU_ITEM_INVALID_BEHAVIOR.DISABLED,
				combinationOperator: CombinationOperator.OR,
				validCombinations: [
					{ projectRole: ROLES_PROJECT.LEAD },
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.TENDERING,
						revisionStatus: REVISION_STATUS.BIDDING,
					},
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.TENDERING,
						revisionStatus: REVISION_STATUS.SUBMITTED,
					},
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.AWARDING,
						revisionStatus: REVISION_STATUS.SUBMITTED,
					},
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.AWARDED,
						revisionStatus: REVISION_STATUS.ACCEPTED,
					},
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.CONFIRMED,
						revisionStatus: REVISION_STATUS.ACCEPTED,
					},
				],
			},
			{
				key: 'informations',
				type: MENU_ITEM_TYPES.LINK,
				urlPattern: routes.section,
				title: 'domain:drawer.section.general.subSections.informations',
				pageContent: InformationsPageContent,
				invalidBehavior: MENU_ITEM_INVALID_BEHAVIOR.DISABLED,
				combinationOperator: CombinationOperator.OR,
				validCombinations: [
					{ projectRole: ROLES_PROJECT.LEAD },
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.TENDERING,
						revisionStatus: REVISION_STATUS.BIDDING,
					},
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.TENDERING,
						revisionStatus: REVISION_STATUS.SUBMITTED,
					},
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.AWARDING,
						revisionStatus: REVISION_STATUS.SUBMITTED,
					},
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.AWARDED,
						revisionStatus: REVISION_STATUS.ACCEPTED,
					},
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.CONFIRMED,
						revisionStatus: REVISION_STATUS.ACCEPTED,
					},
				],
			},
		] as DrawerMenuItem[],
		icon: DescriptionIcon,
	},
	{
		type: MENU_ITEM_TYPES.COLLAPSIBLE,
		key: 'tender',
		title: 'domain:drawer.section.tender.title',
		validCombinations: [{ projectRole: ROLES_PROJECT.LEAD }],
		invalidBehavior: MENU_ITEM_INVALID_BEHAVIOR.HIDDEN,
		children: [
			{
				key: 'bidders',
				type: MENU_ITEM_TYPES.LINK,
				urlPattern: routes.section,
				title: 'domain:drawer.section.tender.subSections.bidders',
				pageContent: TenderPageContent,
			},
			{
				key: 'period',
				type: MENU_ITEM_TYPES.LINK,
				urlPattern: routes.section,
				title: 'domain:drawer.section.tender.subSections.period',
				pageContent: PeriodPageContent,
				invalidBehavior: MENU_ITEM_INVALID_BEHAVIOR.HIDDEN,
				combinationOperator: CombinationOperator.AND,
				validCombinations: [{ projectRole: ROLES_PROJECT.LEAD }],
			},
		] as DrawerMenuItem[],
		icon: GroupAddIcon,
	},
	{
		type: MENU_ITEM_TYPES.COLLAPSIBLE,
		key: 'award',
		title: 'domain:drawer.section.award.title',
		validCombinations: [{ projectRole: ROLES_PROJECT.LEAD }],
		invalidBehavior: MENU_ITEM_INVALID_BEHAVIOR.HIDDEN,
		children: [
			{
				key: 'comparison',
				type: MENU_ITEM_TYPES.LINK,
				urlPattern: routes.section,
				title: 'domain:drawer.section.award.subSections.comparison',
				pageContent: ComparisonPageContent,
			},
			{
				key: 'annotations',
				type: MENU_ITEM_TYPES.LINK,
				urlPattern: routes.section,
				title: 'domain:drawer.section.award.subSections.annotations',
				pageContent: AnnotationsPageContent,
			},
			{
				key: 'awarding',
				type: MENU_ITEM_TYPES.LINK,
				urlPattern: routes.section,
				title: 'domain:drawer.section.award.subSections.awarding',
				pageContent: AwardingPageContent,
			},
		] as DrawerMenuItem[],
		icon: GavelIcon,
	},
	{
		type: MENU_ITEM_TYPES.COLLAPSIBLE,
		key: 'billing',
		title: 'domain:drawer.section.billing.title',
		combinationOperator: CombinationOperator.OR,
		validCombinations: [
			{ projectRole: ROLES_PROJECT.LEAD },
			{ projectRole: ROLES_PROJECT.BIDDER },
		],
		invalidCombinations: [{ environment: ENVIRONMENTS.DEMO }],
		invalidBehavior: MENU_ITEM_INVALID_BEHAVIOR.HIDDEN,
		children: [
			{
				key: 'quantity',
				type: MENU_ITEM_TYPES.LINK,
				urlPattern: routes.section,
				title: 'domain:drawer.section.billing.subSections.quantity',
				pageContent: QuantityPageContent,
				invalidBehavior: MENU_ITEM_INVALID_BEHAVIOR.DISABLED,
				combinationOperator: CombinationOperator.OR,
				validCombinations: [
					{ projectRole: ROLES_PROJECT.LEAD },
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.TENDERING,
						revisionStatus: REVISION_STATUS.BIDDING,
					},
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.TENDERING,
						revisionStatus: REVISION_STATUS.SUBMITTED,
					},
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.AWARDING,
						revisionStatus: REVISION_STATUS.SUBMITTED,
					},
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.AWARDED,
						revisionStatus: REVISION_STATUS.ACCEPTED,
					},
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.CONFIRMED,
						revisionStatus: REVISION_STATUS.ACCEPTED,
					},
				],
			},
		] as DrawerMenuItem[],
		icon: EuroIcon,
	},
] as DrawerMenu
