/* eslint-disable @typescript-eslint/no-explicit-any */
import TabulatorHelper from 'app/services/tabulator/TabulatorHelper'
import { BoqNode } from 'app/types/graphql/BoqNode'
import { POSITION_TYPES } from 'app/types/POSITION_TYPES'
import {
	getFirstChildFromCell,
	getParentFromRow,
	getParentFromCell,
	getQuantityDeterminationNodesFromCell,
	getBoqNodesFromCell,
	isFulfillmentType,
} from 'app/utils/tabulator'
import { hasEditableCellValidCombination } from 'app/utils/validator'
import { get } from 'lodash'
import {
	QuantityDeterminationNode,
	QUANTITY_DETERMINATION_STATUS,
} from 'app/config/quantityDetermination'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'

export const isLineItem = (cell: any): boolean =>
	!Array.isArray(getBoqNodesFromCell(cell)) ||
	!Array.isArray(getQuantityDeterminationNodesFromCell(cell)) ||
	isFulfillmentType(cell)

export const isLineItemRow = (row: any): boolean => {
	const boqNodesData = get(row.getData(), ['boqNodes'], null)
	const qdNodesData = get(row.getData(), ['quantityDeterminationNodes'], null)
	return row && !Array.isArray(boqNodesData || qdNodesData)
}

export const hasQuantityDeterminationNodes = (
	row: any
): QuantityDeterminationNode[] | null => {
	return get(row.getData(), ['quantityDeterminationNodes'], null)
}

export const handleCellUpEditable = (
	cell: any,
	isEditable: boolean
): boolean => {
	const boqNode = cell.getData() as BoqNode
	if (isLineItem(cell)) {
		switch (boqNode.type) {
			case POSITION_TYPES.NORMAL:
			case POSITION_TYPES.OPTIONAL:
			case POSITION_TYPES.ALTERNATIVE:
			case POSITION_TYPES.WAGE:
			case POSITION_TYPES.BASE:
			case POSITION_TYPES.BLANKET:
				return isEditable
			case POSITION_TYPES.SURCHARGE:
			case POSITION_TYPES.REMARK:
				return false
			default:
				return false
		}
	}

	return false
}

export const handleCellQtyEditable = (
	cell: any,
	isEditable: boolean
): boolean => {
	const boqNode = cell.getData() as BoqNode
	if (TabulatorHelper.isLineItem(cell) && isEditable) {
		if (boqNode.type === POSITION_TYPES.SURCHARGE) {
			return true
		}
	}

	return false
}

export const toggleTree = (e: any, cell: any): void => {
	const { type } = cell.getRow().getData()
	if (type === 'category') {
		cell.getRow().treeToggle()
	}
}

export const handleCellQDLabelEditable = (
	role: ROLES_PROJECT | null,
	cell: any,
	quantityDeterminationStatus: QUANTITY_DETERMINATION_STATUS | null
): boolean => {
	const { type, status: quantityDeterminationNodeStatus } = cell.getData()

	const childType = get(getFirstChildFromCell(cell), ['data', 'type'])

	if (
		hasEditableCellValidCombination({
			quantityDeterminationStatus,
			quantityDeterminationNodeStatus,
			type,
			role,
			childType,
		})
	) {
		return true
	}
	return false
}

export const handleCellQDFormulaEditable = (
	role: ROLES_PROJECT | null,
	cell: any,
	quantityDeterminationStatus: QUANTITY_DETERMINATION_STATUS | null
): boolean => {
	const { type, status: quantityDeterminationNodeStatus } = cell.getData()
	const parent = getParentFromCell(cell)
	const { type: parentType } = (parent && parent.getData()) || {}

	const childType = get(getFirstChildFromCell(cell), ['data', 'type'])

	const validParentTypes = [POSITION_TYPES.WAGE, POSITION_TYPES.BLANKET]

	const isGrandParentTypeBlanketOrWage = (): boolean => {
		if (parentType === POSITION_TYPES.QTY_DETERM) {
			const grandParent = getParentFromRow(parent)
			const { type: grandParentType } = grandParent.getData()
			return validParentTypes.includes(grandParentType)
		}
		return false
	}

	if (
		hasEditableCellValidCombination({
			quantityDeterminationStatus,
			quantityDeterminationNodeStatus,
			type,
			role,
			childType,
		})
	) {
		if (
			!validParentTypes.includes(parentType) &&
			!isGrandParentTypeBlanketOrWage()
		) {
			return true
		}
	}
	return false
}

export const handleCellQDQtyEditable = (
	role: ROLES_PROJECT | null,
	cell: any,
	quantityDeterminationStatus: QUANTITY_DETERMINATION_STATUS | null
): boolean => {
	const { type, status: quantityDeterminationNodeStatus } = cell.getData()
	const parent = getParentFromCell(cell)
	const { type: parentType } = (parent && parent.getData()) || {}

	const childType = get(getFirstChildFromCell(cell), ['data', 'type'])

	const validParentTypes = [POSITION_TYPES.WAGE, POSITION_TYPES.BLANKET]

	const isGrandParentTypeBlanketOrWage = (): boolean => {
		if (parentType === POSITION_TYPES.QTY_DETERM) {
			const grandParent = getParentFromRow(parent)
			const { type: grandParentType } = grandParent.getData()
			return validParentTypes.includes(grandParentType)
		}
		return false
	}

	if (
		hasEditableCellValidCombination({
			quantityDeterminationStatus,
			quantityDeterminationNodeStatus,
			type,
			role,
			childType,
		})
	) {
		if (
			validParentTypes.includes(parentType) ||
			isGrandParentTypeBlanketOrWage()
		) {
			return true
		}
	}

	return false
}
