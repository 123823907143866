import { makeStyles } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'

export default makeStyles({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		minHeight: '100vh',
		width: '100%',
	},
	pageContentTopContainer: {
		width: '100%',
	},
	pageContentBottomContainer: {
		width: '100%',
		display: 'flex',
	},
	pageContent: {
		borderRadius: 0,
		position: 'relative',
		flex: 1,
		minWidth: 900,
		minHeight: `calc(100vh - ${themeValues().sizes.TabHeader.height}px)`,
	},
})
