import { SubscriptionPlan } from 'domain/checkout/interfaces/SubscriptionPlan'

export enum STRIPE_PLAN {
	PLAN_MONTHLY_FULL = 'monthly-full',
	PLAN_MONTHLY_LIMITED = 'monthly-limited',
	PLAN_YEARLY_FULL = 'yearly-full',
}

export const subscriptionPlans: SubscriptionPlan[] = [
	{
		code: STRIPE_PLAN.PLAN_MONTHLY_LIMITED,
		title: 'Basic',
		subtitle: '',
		price: 49,
		info: [
			'Kostenloser Probemonat',
			'Mindestlaufzeit: 1 Monat',
			'Monatlich kündbar',
			'Unlimitierter Speicherplatz',
			'5 Vergabeeinheiten / Monat',
			'Wartung und Pflege inklusive',
		],
		prepaymentMonths: 1,
	},
	{
		code: STRIPE_PLAN.PLAN_MONTHLY_FULL,
		title: 'Standard',
		subtitle: '',
		price: 299,
		info: [
			'Kostenloser Probemonat',
			'Mindestlaufzeit: 1 Monat',
			'Monatlich kündbar',
			'Unlimitierter Speicherplatz',
			'Unlimitiert viele Vergabeeinheiten',
			'Wartung und Pflege inklusive',
		],
		prepaymentMonths: 1,
	},
	{
		code: STRIPE_PLAN.PLAN_YEARLY_FULL,
		title: 'Smart',
		subtitle: '',
		price: 249,
		info: [
			'Kostenloser Probemonat',
			'Mindestlaufzeit: 12 Monate',
			'Kündbar zum Laufzeitende',
			'Unlimitierter Speicherplatz',
			'Unlimitiert viele Vergabeeinheiten',
			'Wartung und Pflege inklusive',
			'* Vorauszahlung',
		],
		prepaymentMonths: 12,
	},
]
