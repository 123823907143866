import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import useCustomSnackbar from 'app/services/snackbar/useCustomSnackbar'
import { REVISION_STATUS } from 'app/types/REVISION_STATUS'
import { UrlParams } from 'app/types/UrlParams'
import {
	getStatusFromLot,
	getLotFromProject,
	getStatusFromRevision,
	getProjectFromResponse,
	getFirstRevisionFromLot,
} from 'app/utils/apollo'
import ConfirmationDialog from 'component/ConfirmationDialog/ConfirmationDialog'
import Loading from 'component/Loading/Loading'
import SecondaryButton from 'component/SecondaryButton/SecondaryButton'
import Validate from 'component/Validate/Validate'
import mutations from 'domain/general/boq/apollo/mutations'
import { LOT_STATUS } from 'domain/lot/interfaces/LOT_STATUS'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'
import queries from 'domain/tender/bidders/apollo/queries'
import React, { FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

const CreateTenderButton: FC = ({ children }) => {
	const { t } = useTranslation()
	const { sendSuccessMessage } = useCustomSnackbar()

	const { projectUuid, lotUuid, sectionId } = useParams<UrlParams>()

	const [fetchData, { data, loading }] = useGraphQLLazyQuery(
		queries.LotWithRevisions
	)

	const [createTender] = useGraphQLMutation(mutations.createTender, {
		onCompleted: () => sendSuccessMessage(t(`domain:boq.createTender.success`)),
	})

	const project = getProjectFromResponse(data)
	const lot = getLotFromProject(project)
	const lotStatus = getStatusFromLot(lot)

	const revision = getFirstRevisionFromLot(lot)
	const revisionStatus = getStatusFromRevision(revision)

	const [isDialogOpen, setDialogOpen] = useState(false)

	useEffect(() => {
		fetchData({
			variables: {
				projectUuid,
				lotUuid,
			},
		})
	}, [projectUuid, lotUuid, sectionId])

	const onClick = (): void => {
		if (revision && project) {
			createTender({
				variables: {
					projectUuid: project.uuid,
					lotUuid,
					revisionUuid: revision.uuid,
				},
			})
			setDialogOpen(false)
		}
	}

	// see possible combination in confluence https://tenderframe.atlassian.net/wiki/spaces/IT/pages/30605313/UI+-+Status+combinations
	return (
		<Loading loading={loading}>
			<Validate projectRoles={[ROLES_PROJECT.BIDDER]}>
				<Validate
					lotStatuses={[
						LOT_STATUS.TENDERING,
						LOT_STATUS.AWARDING,
						LOT_STATUS.AWARDED,
					]}
					revisionStatuses={[
						REVISION_STATUS.BIDDING,
						REVISION_STATUS.SUBMITTED,
						REVISION_STATUS.ACCEPTED,
						REVISION_STATUS.DECLINED,
					]}
				>
					<SecondaryButton
						onClick={() => setDialogOpen(true)}
						disabled={
							revisionStatus !== REVISION_STATUS.BIDDING ||
							lotStatus !== LOT_STATUS.TENDERING
						}
					>
						{children}
					</SecondaryButton>
					<ConfirmationDialog
						title={t('domain:boq.createTender.confirmation.title')}
						contentText={t('domain:boq.createTender.confirmation.content')}
						open={isDialogOpen}
						onDecline={() => setDialogOpen(false)}
						onAccept={onClick}
					/>
				</Validate>
			</Validate>
		</Loading>
	)
}

export default CreateTenderButton
