import {
	getLotFromProject,
	getProjectFromResponse,
	getFirstRevisionFromLot,
} from 'app/utils/apollo'
import React, { FC, useEffect } from 'react'
import PageContent from 'component/PageContent/PageContent'
import { UrlParams } from 'app/types/UrlParams'
import { useParams } from 'react-router-dom'

import queries from 'domain/general/members/apollo/queries'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import MembersPageContentHeader from '../MembersPageContentHeader/MembersPageContentHeader'
import MembersPageContentBody from '../MembersPageContentBody.tsx/MembersPageContentBody'

const MembersPageContent: FC = () => {
	const { projectUuid, lotUuid } = useParams<UrlParams>()
	const [fetchData, { data }] = useGraphQLLazyQuery(queries.LotWithLeadInfo)

	const project = getProjectFromResponse(data)
	const lot = getLotFromProject(project)
	const revision = getFirstRevisionFromLot(lot)

	useEffect(() => {
		if (projectUuid && lotUuid) {
			fetchData({ variables: { projectUuid, lotUuid } })
		}
	}, [projectUuid, lotUuid])

	return (
		<PageContent
			key={lotUuid}
			header={<MembersPageContentHeader />}
			body={<MembersPageContentBody revision={revision} />}
		/>
	)
}

export default MembersPageContent
