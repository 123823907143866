import { QueryHookOptions, useQuery } from '@apollo/react-hooks'
import { DocumentNode } from 'graphql'
import { OperationVariables, QueryResult } from '@apollo/react-common'
import useApolloErrorHandling from 'app/hooks/useApolloErrorHandling'
import { ApolloError } from 'apollo-client'

export interface UseQueryOptions<TVariables> extends QueryHookOptions {
	onError?: (error: ApolloError) => void
	variables?: TVariables
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default <TData = any, TVariables = OperationVariables>(
	query: DocumentNode,
	apolloOptions?: UseQueryOptions<TVariables>
): QueryResult<TData, TVariables> => {
	const [onErrorDefault] = useApolloErrorHandling()
	const { onError, variables, fetchPolicy } = apolloOptions || {}

	return useQuery<TData, TVariables>(query, {
		variables,
		fetchPolicy,
		onError: (error: ApolloError) => {
			onErrorDefault(error)
			if (onError) {
				onError(error)
			}
		},
	})
}
