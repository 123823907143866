import { makeStyles } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'

export type AttachmentDropzoneStyleKeys = 'root' | 'content' | 'icon'

export default makeStyles({
	root: ({ dragEntered }: { dragEntered: boolean }) => ({
		background: themeValues().colors.AttachmentDropzone.background,
		boxShadow: 'none',
		border: themeValues().sizes.AttachmentDropzone.borderWidth,
		borderColor: dragEntered
			? themeValues().colors.AttachmentDropzone.borderActive
			: themeValues().colors.AttachmentDropzone.border,
		borderStyle: 'dashed',
		'&:hover, &:hover .MuiSvgIcon-root': {
			cursor: 'pointer',
			borderColor: themeValues().colors.AttachmentDropzone.borderActive,
			color: themeValues().colors.AttachmentDropzone.borderActive,
		},
	}),
	content: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'relative',
		width: themeValues().sizes.AttachmentDropzone.width,
		height: '100%',
		minHeight: themeValues().sizes.AttachmentDropzone.height,
		minWidth: themeValues().sizes.AttachmentDropzone.width,
		alignSelf: 'stretch',
	},
	icon: ({ dragEntered }: { dragEntered: boolean }) => ({
		color: dragEntered ? '#30a7c9' : '#e3e3e3',
		fontSize: 60,
	}),
})
