import { gql } from 'apollo-boost'

const AccountToken = gql`
	query AccountToken($token: String!) {
		accountToken(token: $token) {
			email
		}
	}
`
export default {
	AccountToken,
}
