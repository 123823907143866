import { makeStyles, Theme } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'

interface StyleProps {
	active?: boolean
}

export default makeStyles<Theme, StyleProps>((theme: Theme) => ({
	root: {
		padding: 0,
		position: 'relative',
		height: `${themeValues().sizes.DrawerMenuItemLink.height}px`,
		minHeight: '100%',
		color: themeValues().colors.Drawer.color,
		borderBottom: themeValues().colors.DrawerMenuItemLink.borderBottom,
		backgroundColor: themeValues().colors.Drawer.background,
	},
	listItem: {
		height: 'inherit',
		borderBottom: themeValues().colors.DrawerMenuItemLink.borderBottom,
		'&.Mui-selected': {
			backgroundColor: 'transparent',
		},
	},
	listItemText: ({ active }) => ({
		fontWeight: active ? 'bolder' : 'inherit',
		marginLeft: theme.spacing(7),
	}),
}))
