import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
	table: {
		flex: 1,
		'& svg': {
			order: 2,
			color: '#f1f1f1',
		},
		'& svg:not(:nth-child(1))': {
			fill: 'rgba(0, 0, 0, 0.54)',
		},
		'& .caretDown': {
			marginBottom: '10%',
		},
	},
	annotationContainer: {
		marginTop: theme.spacing(7),
	},
	annotationsTable: {
		marginTop: theme.spacing(3),
	},
}))
