import { Theme, makeStyles } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'

export default makeStyles((theme: Theme) => ({
	root: ({ active }: { active?: boolean }) => {
		return {
			color: themeValues().colors.Drawer.color,
			height: themeValues().sizes.DrawerMenuItemLink.height,
			paddingLeft: theme.spacing(3),
			borderBottom: themeValues().colors.DrawerMenuItemLink.borderBottom,
			backgroundColor: themeValues().colors.DrawerMenuItem.background,
			'&.Mui-selected': {
				opacity: 'unset',
				backgroundColor: themeValues().colors.DrawerMenuItem.background,
				'&:hover': {
					backgroundColor: themeValues().colors.DrawerMenuItem.background,
				},
			},
			'&:hover': {
				backgroundColor: themeValues().colors.DrawerMenuItem.background,
				cursor: active ? 'default' : 'pointer',
			},
		}
	},
	navigation: {
		'&.MuiList-padding': {
			paddingTop: 0,
			paddingBottom: 0,
		},
	},
	listItemText: {
		paddingLeft: theme.spacing(3),
	},
}))
