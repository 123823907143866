import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { themeValues } from 'app/themes/themeValues'

interface StyleProps {
	selected: boolean
}

export default makeStyles<Theme, StyleProps>(theme => ({
	root: ({ selected }) => ({
		...themeValues().components.Tab,
		position: 'relative',
		background: selected
			? themeValues().components.Tab.backgroundSelected
			: themeValues().components.Tab.backgroundUnselected,
		color: selected ? theme.palette.grey.A700 : theme.palette.common.white,
		borderRadius: 0,
		borderTopLeftRadius: themeValues().sizes.LotTab.borderRadius,
		borderTopRightRadius: themeValues().sizes.LotTab.borderRadius,
		opacity: 1,
		paddingLeft: 0,
		paddingRight: 0,
		minHeight: themeValues().sizes.LotTab.height,
		height: themeValues().sizes.LotTab.height,
		'& h1': {
			fontSize: themeValues().sizes.LotTab.fontSize,
		},
	}),
}))
