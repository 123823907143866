import React, { FC } from 'react'
import useInterval from 'app/hooks/useInterval'
import useTracking from '../datawarehouse/useTracking'
import TrackingEvents from '../datawarehouse/TrackingEvents'

const PingProvider: FC = ({ children }) => {
	const { track } = useTracking()

	useInterval(() => {
		if (document.visibilityState === 'visible') {
			track('PING_FOREGROUND' as keyof typeof TrackingEvents)
		} else {
			track('PING_BACKGROUND' as keyof typeof TrackingEvents)
		}
	}, 10000)

	return <>{children}</>
}

export default PingProvider
