import useAuthentication from 'app/hooks/useAuthentication'
import TemplateBackground from 'component/TemplateBackground/TemplateBackground'
import React, { FC, FunctionComponent, useCallback } from 'react'
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'
import { REDIRECT_URL } from 'app/constants'

import useStyles from './TemplateAuthenticationStyles'

export interface TemplateAuthenticationProps extends RouteProps {
	component: FunctionComponent
}

const TemplateAuthentication: FC<TemplateAuthenticationProps> = ({
	component: Component,
	...rest
}) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
	const classes = useStyles()
	const { accessToken } = useAuthentication()
	const location = useLocation()

	const renderRedirectURL = useCallback(() => {
		return <Redirect to={REDIRECT_URL} />
	}, [])

	const renderContent = useCallback(() => {
		if (accessToken && !location.search) {
			return renderRedirectURL()
		}

		return (
			<TemplateBackground>
				<Component />
			</TemplateBackground>
		)
	}, [accessToken, location])

	return <Route {...rest} render={renderContent} />
}

export default TemplateAuthentication
