import { PageContentBodyProps } from 'component/PageContentBody/PageContentBodyProps'
import React, { FC } from 'react'

import useStyles from 'component/PageContentBody/PageContentBodyStyles'
import clsx from 'clsx'

const PageContentBody: FC<PageContentBodyProps> = ({
	children,
	notice,
	className,
}) => {
	const classes = useStyles()

	return (
		<div className={clsx(className, classes.root)}>
			{notice && <div className={classes.noticeContainer}>{notice}</div>}
			<div className={classes.container}>{children}</div>
		</div>
	)
}

export default PageContentBody
