import { gql } from 'apollo-boost'

const createQuantityDeterminationItemLead = gql`
	mutation createQuantityDeterminationItemLead(
		$projectUuid: String!
		$lotUuid: String!
		$qdUuid: String!
		$parentUuid: String!
		$name: String!
		$formula: String
		$qty: Float
	) {
		project(uuid: $projectUuid) {
			lot(uuid: $lotUuid) {
				quantityDetermination(uuid: $qdUuid) {
					createQuantityDeterminationItem(
						parentUuid: $parentUuid
						name: $name
						formula: $formula
						qty: $qty
					) {
						label
					}
				}
			}
		}
	}
`

export default {
	createQuantityDeterminationItemLead,
}
