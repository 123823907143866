import React, { ReactElement, FC } from 'react'

import clsx from 'clsx'
import useStyles from './TabulatorChipStyles'
import { TabulatorChipProps } from './TabulatorChipProps'

const TabulatorChip: FC<TabulatorChipProps> = ({
	children,
	variant,
}): ReactElement => {
	const classes = useStyles()

	return (
		<div className={clsx(classes.chip, classes[variant])}>
			<span>{children}</span>
		</div>
	)
}

export default TabulatorChip
