import { makeStyles } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'

export default makeStyles({
	root: {
		marginTop: '28px',
		borderBottom: themeValues().colors.DrawerMenuItemLink.borderBottom,
	},
	text: {
		textTransform: 'uppercase',
		color: themeValues().colors.DrawerMenuItemHeadline.color,
	},
})
