import {
	getLotFromProject,
	getQuantityDeterminationsFromLot,
	getProjectFromResponse,
} from 'app/utils/apollo'
import React, { FC, useEffect } from 'react'
import mutations from 'domain/billing/quantity/apollo/mutations'
import queries from 'domain/billing/quantity/apollo/queries'
import { useParams } from 'react-router-dom'
import { UrlParams } from 'app/types/UrlParams'
import Validate from 'component/Validate/Validate'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'
import { LOT_STATUS } from 'domain/lot/interfaces/LOT_STATUS'
import { REVISION_STATUS } from 'app/types/REVISION_STATUS'
import PageContentParagraph from 'component/PageContentParagraph/PageContentParagraph'
import PrimaryButton from 'component/PrimaryButton/PrimaryButton'
import PageContentBody from 'component/PageContentBody/PageContentBody'
import { useTranslation } from 'react-i18next'
import { CombinationOperator } from 'app/types/CombinationOperator'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import TableQuantityDetermination from '../../body/TableQuantityDeterminations/TableQuantityDeterminations'
import CreateQuantityDeterminationForm from '../../body/CreateQuantityDeterminationForm/CreateQuantityDeterminationForm'

const QuantityPageContentBody: FC = () => {
	const { projectUuid, lotUuid } = useParams<UrlParams>()
	const { t } = useTranslation()

	const [createConfirmation] = useGraphQLMutation(mutations.createConfirmation)

	const [quantityDeterminations, { data, loading }] = useGraphQLLazyQuery(
		queries.QuantityDeterminations,
		{
			fetchPolicy: 'network-only',
		}
	)

	const project = getProjectFromResponse(data)
	const lot = getLotFromProject(project)
	const quantityDeterminationsData = getQuantityDeterminationsFromLot(lot)

	const isQuantityDeterminationsTableEmpty = (): boolean => {
		if (quantityDeterminationsData) {
			return quantityDeterminationsData.length === 0
		}
		return true
	}

	useEffect(() => {
		quantityDeterminations({
			variables: {
				projectUuid,
				lotUuid,
			},
		})
	}, [projectUuid, lotUuid])

	const handleClick = (): void => {
		createConfirmation({
			variables: {
				projectUuid,
				lotUuid,
			},
		})
	}

	return (
		<PageContentBody>
			<Validate
				validCombinations={[
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.AWARDED,
						revisionStatus: REVISION_STATUS.ACCEPTED,
					},
				]}
			>
				<PageContentParagraph>
					{t('domain:billing.createConfirmation.content')}
				</PageContentParagraph>
				<PrimaryButton onClick={handleClick}>
					{t('domain:billing.createConfirmation.button.label')}
				</PrimaryButton>
			</Validate>
			<Validate
				combinationOperator={CombinationOperator.OR}
				validCombinations={[
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.CONFIRMED,
						revisionStatus: REVISION_STATUS.ACCEPTED,
					},
					{
						projectRole: ROLES_PROJECT.LEAD,
						lotStatus: LOT_STATUS.CONFIRMED,
					},
				]}
			>
				<TableQuantityDetermination data={data} loading={loading} />
			</Validate>

			<Validate
				validCombinations={[
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.CONFIRMED,
						revisionStatus: REVISION_STATUS.ACCEPTED,
					},
				]}
			>
				<CreateQuantityDeterminationForm
					isEmpty={isQuantityDeterminationsTableEmpty()}
				/>
			</Validate>
		</PageContentBody>
	)
}

export default QuantityPageContentBody
