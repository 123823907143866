import { DOMAIN_EVENTS } from 'domain/project/enums/DOMAIN_EVENTS'
import { DomainEvent } from 'domain/project/interfaces/DomainEvent'

export const getDomainEventByEventKey = (
	domainEvents: DomainEvent[] | null,
	key: DOMAIN_EVENTS
): DomainEvent | null => {
	if (domainEvents) {
		return (
			domainEvents.find(domainEvent => {
				return domainEvent.event === key
			}) || null
		)
	}

	return null
}
