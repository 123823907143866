import React, { ReactElement } from 'react'
import { CircularProgress, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles({
	root: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		height: 'inherit',
		backgroundColor: 'transparent',
	},
	spinner: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		textAlign: 'center',
	},
	title: {
		paddingTop: '5%',
	},
})

const useLoading = (
	render: () => ReactElement,
	isLoading: boolean,
	loader?: ReactElement,
	size?: number,
	disableLoader?: boolean,
	loadingText?: string
): ReactElement | null => {
	const classes = useStyles()
	if (isLoading) {
		if (disableLoader) {
			return null
		}
		return (
			loader || (
				<div className={classes.root}>
					<div className={classes.spinner}>
						<CircularProgress size={size} />
						{loadingText ? (
							<Typography variant="h4" component="h1" className={classes.title}>
								{loadingText}
							</Typography>
						) : null}
					</div>
				</div>
			)
		)
	}
	return render()
}

export default useLoading
