/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloError } from 'apollo-client'
import { ErrorResponse } from 'apollo-link-error'
import { BoqAnnotations, BoqAnnotation } from 'app/config/annotations'
import {
	QuantityDetermination,
	QuantityDeterminationNode,
	QuantityDeterminationPreflight,
	QUANTITY_DETERMINATION_STATUS,
} from 'app/config/quantityDetermination'
import { Bidder } from 'app/types/graphql/Bidder'
import { BoqNode } from 'app/types/graphql/BoqNode'
import { Collaboration } from 'app/types/graphql/Collaboration'
import { Contact } from 'app/types/graphql/Contact'
import { Contacts } from 'app/types/graphql/Contacts'
import { DataRevision } from 'app/types/graphql/DataRevision'
import { Lot } from 'app/types/graphql/Lot'
import { Project } from 'app/types/graphql/Project'
import { REVISION_STATUS } from 'app/types/REVISION_STATUS'
import { RoleAsParameter } from 'app/types/RoleAsParameter'
import { Account } from 'domain/account/interfaces/Account'
import { AccountQuery } from 'domain/account/interfaces/AccountQuery'
import { Attachment } from 'domain/general/attachments/interfaces/Attachment'
import { AttachmentAuthor } from 'domain/general/attachments/interfaces/AttachmentAuthor'
import { LOT_STATUS } from 'domain/lot/interfaces/LOT_STATUS'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'
import { ContactTag } from 'domain/tender/bidders/types/ContactTag'
import { get } from 'lodash'

export const getDataOrNull = <TData, TReturn>(
	data: TData,
	path: string[]
): TReturn | null => get(data, path, null)

export const getDataOrZero = <TData, TReturn>(
	data: TData,
	path: string[]
): TReturn | number => get(data, path, 0)

export const getRoleFromProject = (
	project: Project | null
): ROLES_PROJECT | null =>
	getDataOrNull<Project | null, ROLES_PROJECT>(project, ['role'])

export const getFirstRevisionFromProject = (
	project: Project | null
): DataRevision | null =>
	getDataOrNull<Project | null, DataRevision>(project, [
		'dataRevisions',
		'data',
		'0',
	])

export const getFirstRevisionFromLot = (
	lot: Lot | null
): DataRevision | null => {
	return getDataOrNull(lot, ['dataRevisions', 'data', '0'])
}

export const getBoqAnnotationsFromRevision = (
	revision: DataRevision | null
): BoqAnnotations | null =>
	getDataOrNull<DataRevision | null, BoqAnnotations>(revision, [
		'boqAnnotations',
	])

export const getDataFromBoqAnnotations = (
	boqAnnotations: BoqAnnotations | null
): BoqAnnotation[] | null =>
	getDataOrNull<BoqAnnotations | null, BoqAnnotation[]>(boqAnnotations, [
		'data',
	])

export const getLotsFromProject = (project: Project | null): Lot[] | null =>
	getDataOrNull<Project | null, Lot[]>(project, ['lots', 'data'])

export const getFirstLotFromProject = (project: Project | null): Lot | null =>
	getDataOrNull<Project | null, Lot>(project, ['lots', 'data', '0'])

export const getAccountFromResponse = (data: AccountQuery): Account | null =>
	getDataOrNull<any, Account>(data, ['account'])

export const getStripePaymentMethodsFromResponse = (
	data: AccountQuery
): Account | null =>
	getDataOrNull<any, Account>(data, ['account', 'stripePaymentMethods'])

export const getProjectFromResponse = (data: any): Project | null =>
	getDataOrNull<any, Project>(data, ['project'])

export const getProjectsFromResponse = (data: any): Project[] | null =>
	getDataOrNull<any, Project[]>(data, ['projects', 'data'])

export const getBiddersFromRevision = (
	revision: DataRevision | null
): Bidder[] | null =>
	getDataOrNull<DataRevision | null, Bidder[]>(revision, ['bidders'])

export const getQuantityDeterminationFromLot = (
	lot: Lot | null
): QuantityDetermination | null =>
	getDataOrNull<Lot | null, QuantityDetermination>(lot, [
		'quantityDetermination',
	])

export const getPreflightSubmissionFromQuantityDetermination = (
	quantityDetermination: QuantityDetermination | null
): QuantityDeterminationPreflight | null =>
	getDataOrNull<QuantityDetermination | null, QuantityDeterminationPreflight>(
		quantityDetermination,
		['preflightSubmission']
	)

export const getPreflightReviewFromQuantityDetermination = (
	quantityDetermination: QuantityDetermination | null
): QuantityDeterminationPreflight | null =>
	getDataOrNull<QuantityDetermination | null, QuantityDeterminationPreflight>(
		quantityDetermination,
		['preflightReview']
	)

export const getQuantityDeterminationsNodes = (
	quantityDetermination: QuantityDetermination | null
): QuantityDeterminationNode[] | null =>
	getDataOrNull<QuantityDetermination | null, QuantityDeterminationNode[]>(
		quantityDetermination,
		['quantityDeterminationNodes']
	)

export const getQuantityDeterminationsFromLot = (
	lot: Lot | null
): QuantityDetermination[] | null =>
	getDataOrNull<Lot | null, QuantityDetermination[]>(lot, [
		'quantityDeterminations',
		'data',
	])

export const getStatusFromQuantityDetermination = (
	quantityDetermination: QuantityDetermination | null
): QUANTITY_DETERMINATION_STATUS | null =>
	getDataOrNull<
		QuantityDetermination | null,
		QUANTITY_DETERMINATION_STATUS | null
	>(quantityDetermination, ['status'])

export const getBoqNodesFromRevision = (
	revision: DataRevision | null
): BoqNode[] | null => getDataOrNull(revision, ['boqNodes'])

export const getStatusFromRevision = (
	revision: DataRevision | null
): REVISION_STATUS | null => getDataOrNull(revision, ['status'])

export const getContactListFromProject = (
	project: Project | null
): Contacts | null => getDataOrNull(project, ['contacts'])

export const getRevisionsFromLot = (lot: Lot | null): DataRevision[] | null =>
	getDataOrNull<Lot | null, DataRevision[] | null>(lot, [
		'dataRevisions',
		'data',
	])

export const getLotFromProject = (project: Project | null): Lot | null =>
	getDataOrNull<Project | null, Lot | null>(project, ['lot'])

export const getStatusFromLot = (lot: Lot | null): LOT_STATUS | null =>
	getDataOrNull<Lot | null, LOT_STATUS | null>(lot, ['status'])

export const getTenderingEndDateFromLot = (lot: Lot | null): string | null =>
	getDataOrNull<Lot | null, string | null>(lot, ['tenderingEndDate'])

export const getCollaborationsFromLot = (
	lot: Lot | null
): Collaboration[] | null =>
	getDataOrNull<Lot | null, Collaboration[] | null>(lot, [
		'collaborations',
		'data',
	])

export const getTotalAccessCountFromLot = (lot: Lot | null): number =>
	getDataOrZero<Lot | null, number>(lot, ['collaborations', 'totalAccessCount'])

export const getAwardedBidderName = (
	bidders: Bidder[] | null
): string | null => {
	const awardedBidder = bidders && bidders.find(({ isAwarded }) => isAwarded)
	if (awardedBidder) {
		const { name } = awardedBidder
		return name
	}
	return null
}

export const isProjectLead = (role: RoleAsParameter): boolean =>
	role === ROLES_PROJECT.LEAD

export const isProjectBidder = (role: RoleAsParameter): boolean =>
	role === ROLES_PROJECT.BIDDER

export const getAttachmentsFromLot = (lot: Lot | null): Attachment[] | null => {
	return getDataOrNull<Lot | null, Attachment[]>(lot, ['attachments', 'data'])
}

export const getAttachmentAuthorsFromLot = (
	lot: Lot | null
): AttachmentAuthor[] | null => {
	return getDataOrNull<Lot | null, AttachmentAuthor[]>(lot, [
		'attachments',
		'authors',
	])
}

export const getAttachmentParentsFromLot = (
	lot: Lot | null
): Attachment[] | null => {
	return getDataOrNull<Lot | null, Attachment[]>(lot, [
		'attachments',
		'parents',
	])
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getContactsFromResponse = (data: any): Contact[] | null => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return getDataOrNull<any, Contact[]>(data, ['contacts', 'data'])
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTotalCountContactsFromResponse = (
	data: number
): number | null => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return getDataOrNull<any, number>(data, ['contacts', 'totalCount'])
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getContactFromResponse = (data: any): Contact | null => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return getDataOrNull<any, Contact>(data, ['contact'])
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTagsFromContacts = (data: any): ContactTag[] | null => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return getDataOrNull<any, ContactTag[]>(data, ['contacts', 'tags'])
}

export const createNestedQuery = (block: string, level = 1): string => {
	return (
		block.substr(0, block.lastIndexOf('}')).repeat(level) +
		block.substr(block.lastIndexOf('}')).repeat(level)
	)
}

export const getStatusCodeFromErrorLinkResponse = (
	error: ErrorResponse
): number | null => get(error, ['networkError', 'statusCode'], null)

export const getGraphqlErrorCodeFromErrorLinkResponse = (
	error?: ErrorResponse | ApolloError
): number | null =>
	get(error, ['graphQLErrors', 0, 'extensions', 'category'], null)
