/* eslint-disable @typescript-eslint/no-explicit-any */
import { addClasses } from 'app/utils/tabulator'
import { isNaN, toNumber } from 'lodash'
import { CURRENCY_SYMBOL_EURO } from 'app/config/locales'

export default (cell: any, bottomCalcParams: any): string => {
	let cellValue = cell.getValue()
	const cellElement = cell.getElement()

	const customClasses = []

	if (
		bottomCalcParams.isAwardProcessOver &&
		!bottomCalcParams.isBidderAwarded
	) {
		customClasses.push('declined-column')
		addClasses(cellElement, customClasses)
	}

	if (isNaN(cellValue)) {
		const cellValueAsNumber = toNumber(cellValue)
		cellValue = cellValueAsNumber.toFixed(3).replace(/0{0,1}$/, '')
		// @TODO handle by translation lib
		cellValue = cellValue.toString().replace('.', ',')
	}

	return `${cellValue} ${CURRENCY_SYMBOL_EURO} `
}
