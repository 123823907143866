import { gql } from 'apollo-boost'

const TenderEndDateForLot = gql`
	query TenderEndDateForLot($projectUuid: String!, $lotUuid: String!) {
		project(uuid: $projectUuid) {
			uuid
			lot(uuid: $lotUuid) {
				uuid
				tenderingEndDate
				status
			}
		}
	}
`

const ProjectWithTenderInformationAndContacts = gql`
	query ProjectWithTenderInformationAndContacts(
		$projectUuid: String!
		$lotUuid: String!
		$tagUuids: [String]
	) {
		project(uuid: $projectUuid) {
			uuid
			name
			role
			lot(uuid: $lotUuid) {
				uuid
				status
				collaborations {
					data {
						uuid
						hasAccess
						projectMemberUuid
					}
					totalAccessCount
				}
			}
		}
		contacts(tagUuids: $tagUuids) {
			data {
				uuid
				firstName
				lastName
				email
				company
				tags {
					uuid
					label
					code
				}
			}
			totalCount
		}
	}
`

const ProjectWithTotalAccessCount = gql`
	query ProjectWithTotalAccessCount($projectUuid: String!, $lotUuid: String!) {
		project(uuid: $projectUuid) {
			uuid
			name
			role
			lot(uuid: $lotUuid) {
				uuid
				collaborations {
					totalAccessCount
				}
			}
		}
	}
`

const ProjectWithTenderInformation = gql`
	query ProjectWithTenderInformation($projectUuid: String!, $lotUuid: String!) {
		project(uuid: $projectUuid) {
			uuid
			name
			role
			lot(uuid: $lotUuid) {
				uuid
				status
				collaborations {
					data {
						uuid
						hasAccess
						projectMemberUuid
					}
				}
			}
		}
	}
`

const LotWithRevisions = gql`
	query LotWithRevisions($projectUuid: String!, $lotUuid: String!) {
		project(uuid: $projectUuid) {
			uuid
			role
			lot(uuid: $lotUuid) {
				uuid
				status
				dataRevisions {
					data {
						accesses
						uuid
						status
					}
				}
			}
		}
	}
`

const ContactsUuid = gql`
	query ContactsUuid($tagUuids: [String]) {
		contacts(tagUuids: $tagUuids) {
			data {
				uuid
			}
			totalCount
		}
	}
`

const Contacts = gql`
	query Contacts {
		contacts {
			data {
				uuid
				firstName
				lastName
				email
				company
				tags {
					uuid
					label
					code
				}
			}
			totalCount
		}
	}
`

const ContactWithTags = gql`
	query ContactWithTags($contactUuid: String!) {
		contact(uuid: $contactUuid) {
			uuid
			firstName
			lastName
			email
			company
			tags {
				uuid
				label
				code
			}
		}
	}
`

const TagsByFilter = gql`
	query TagsByFilter(
		$findAppliedOnly: Boolean
		$limit: Int
		$pattern: String
		$tagUuids: [String]
	) {
		contacts(tagUuids: $tagUuids) {
			tags(
				findAppliedOnly: $findAppliedOnly
				limit: $limit
				pattern: $pattern
			) {
				uuid
				code
				label
			}
		}
	}
`

export default {
	Contacts,
	ContactsUuid,
	ProjectWithTenderInformation,
	ProjectWithTotalAccessCount,
	LotWithRevisions,
	ProjectWithTenderInformationAndContacts,
	ContactWithTags,
	TagsByFilter,
	TenderEndDateForLot,
}
