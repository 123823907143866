import Typography from '@material-ui/core/Typography'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { PAYMENT_SUCCESSFULL_REDIRECT_INTERVAL_IN_SECONDS } from 'app/config/checkout'
import useInterval from 'app/hooks/useInterval'
import routes from 'app/routing/routes/routesApp'
import clsx from 'clsx'
import { CardPaymentSuccessfulProps } from 'domain/checkout/components/CardPaymentSuccessful/CardPaymentSuccessfulProps'
import CheckoutCard from 'domain/checkout/components/CheckoutCard/CheckoutCard'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { useAuthentication } from 'app/hooks'
import useStyles from './CardPaymentSuccessfulStyles'

const CardPaymentSuccessful: FC<CardPaymentSuccessfulProps> = props => {
	const classes = useStyles()

	const { refreshStoredToken, refreshToken } = useAuthentication()

	const { t } = useTranslation()
	const { push } = useHistory()
	const { classes: parentClasses = {} } = props
	const [restTime, setRestTime] = useState(
		PAYMENT_SUCCESSFULL_REDIRECT_INTERVAL_IN_SECONDS
	)

	useInterval(() => {
		if (restTime > 0) {
			setRestTime(restTime - 1)
		}

		if (restTime === 0) {
			refreshStoredToken(refreshToken)
			push(routes.home)
		}
	}, 1000)

	return (
		<CheckoutCard
			{...props}
			classes={{
				...parentClasses,
				cardContent: clsx(
					classes.checkoutCardContent,
					parentClasses.cardContent
				),
			}}
		>
			<CheckCircleOutlineIcon
				classes={{
					root: classes.icon,
				}}
			/>
			<Typography
				variant="h5"
				classes={{
					root: classes.headline,
				}}
			>
				{t('domain:checkout.paymentSuccessful.title')}
			</Typography>
			<Typography>
				{t('domain:checkout.paymentSuccessful.info', {
					restTime,
				})}
			</Typography>
		</CheckoutCard>
	)
}

export default CardPaymentSuccessful
