import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: theme.spacing(4),
	},
	autocomplete: {
		width: 300,
	},
	tagRoot: {
		margin: theme.spacing(0.5),
	},
}))
