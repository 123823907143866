import {
	getLotFromProject,
	getStatusFromQuantityDetermination,
	getQuantityDeterminationFromLot,
	getProjectFromResponse,
	getRoleFromProject,
} from 'app/utils/apollo'
import React, { FC, ReactNode, useEffect } from 'react'
import { PageContent } from 'component/index'
import { useParams, useHistory } from 'react-router-dom'
import queries from 'domain/billing/quantity/apollo/queries'
import { UrlParams } from 'app/types/UrlParams'
import { get } from 'lodash'
import useUrlSearchParams from 'app/hooks/useUrlSearchParams'
import routes from 'app/routing/routes/routesApp'
import Loading from 'component/Loading/Loading'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import QuantityPageContentHeader from '../QuantityPageContentHeader/QuantityPageContentHeader'
import QuantityPageContentBody from '../QuantityPageContentBody/QuantityPageContentBody'
import QuantityDetailPageContentHeader from '../../../detail/components/base/QuantityDetailPageContentHeader/QuantityDetailPageContentHeader'
import QuantityDetailPageContentBody from '../../../detail/components/base/QuantityDetailPageContentBody/QuantityDetailPageContentBody'

const QuantityPageContent: FC = () => {
	const { projectUuid, lotUuid } = useParams<UrlParams>()
	const { push } = useHistory()

	const query = useUrlSearchParams()
	const queryUuid = query.get('qduuid')
	const [fetchData, { data, loading, error }] = useGraphQLLazyQuery(
		queries.LotWithQuantityDetermination
	)

	const project = getProjectFromResponse(data)
	const lot = getLotFromProject(project)
	const quantityDetermination = getQuantityDeterminationFromLot(lot)
	const qdUuid = get(quantityDetermination, 'uuid', null)
	const role = getRoleFromProject(project)
	const qDStatus = getStatusFromQuantityDetermination(quantityDetermination)

	useEffect(() => {
		if (projectUuid && lotUuid && queryUuid) {
			fetchData({
				variables: {
					projectUuid,
					lotUuid,
					qdUuid: queryUuid,
				},
			})
		}
	}, [projectUuid, lotUuid, queryUuid])

	const renderQuantityPageContent = (): ReactNode => {
		if (query.has('qduuid') && queryUuid && qdUuid === queryUuid) {
			return (
				<PageContent
					header={<QuantityDetailPageContentHeader />}
					body={<QuantityDetailPageContentBody role={role} status={qDStatus} />}
				/>
			)
		}
		return null
	}

	useEffect(() => {
		if (error) {
			push(routes['404'])
		}
	}, [error])

	return (
		<Loading loading={loading}>
			{renderQuantityPageContent() || (
				<PageContent
					header={<QuantityPageContentHeader />}
					body={<QuantityPageContentBody />}
				/>
			)}
		</Loading>
	)
}

export default QuantityPageContent
