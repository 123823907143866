import PageContentBody from 'component/PageContentBody/PageContentBody'
import React, { FC, ReactNode } from 'react'
import { Table, TableRow, TableCell, TableBody } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PageContentHeading2 from 'component/PageContentHeading2/PageContentHeading2'
import { InformationsPageContentBodyProps } from './InformationsPageContentBodyProps'

import useStyles from './InformationsPageContentBodyStyles'

const InformationsPageContentBody: FC<InformationsPageContentBodyProps> = ({
	revision,
}) => {
	const { t } = useTranslation()

	const classes = useStyles()

	const renderInfoHeadLine = (title: string): ReactNode => {
		return (
			<PageContentHeading2 key={title} className={classes.title}>
				{t(`domain:general.informations.${title}.title`)}
			</PageContentHeading2>
		)
	}

	const renderInfoValues = (value: string, keys: string[]): ReactNode => {
		return (
			<TableRow key={keys.join('.')}>
				<TableCell classes={{ root: classes.keyCell }}>
					{t(`domain:general.informations.${keys.join('.')}`)}:
				</TableCell>
				<TableCell classes={{ root: classes.valueCell }}>{value}</TableCell>
			</TableRow>
		)
	}

	const resolveInfos = (
		infos: {} | null = {},
		keys: string[] = []
	): ReactNode => {
		if (infos) {
			return Object.entries(infos).map(item => {
				if (item[1] && item[0].charAt(0) !== '_') {
					if (typeof item[1] === 'string') {
						return renderInfoValues(item[1], [...keys, item[0]])
					}

					if (Array.isArray(item[1]) && Array.length) {
						return item[1].map((itemObj: {}) => resolveInfos(itemObj))
					}

					if (typeof item[1] === 'object') {
						return [
							renderInfoHeadLine(item[0]),
							<Table
								key={item[0]}
								classes={{ root: classes.table }}
								size="small"
							>
								<TableBody>
									{resolveInfos(item[1], [...keys, item[0]])}
								</TableBody>
							</Table>,
						]
					}
				}
				return null
			})
		}
		return null
	}

	return (
		<PageContentBody className={classes.pageBottom}>
			{resolveInfos(revision)}
		</PageContentBody>
	)
}

export default InformationsPageContentBody
