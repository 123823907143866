/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	getLotFromProject,
	getQuantityDeterminationsNodes,
	getQuantityDeterminationFromLot,
	getProjectFromResponse,
	getRoleFromProject,
} from 'app/utils/apollo'
import { walkAdd, getParentFromCell } from 'app/utils/tabulator'
import React, { FC, useEffect, useState, useMemo } from 'react'
import queries from 'domain/billing/quantity/apollo/queries'
import Table from 'component/Table/Table'
import { useParams } from 'react-router-dom'
import { UrlParams } from 'app/types/UrlParams'
import useOptions from 'domain/billing/quantity/tabulator/useOptions'
import useUrlSearchParams from 'app/hooks/useUrlSearchParams'
import { useTranslation } from 'react-i18next'
import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import mutations from 'domain/billing/quantity/apollo/mutations'
import mutationsLead from 'domain/billing/quantity/detail/lead/apollo/mutations/mutation'
import {
	QuantityDeterminationNode,
	QUANTITY_DETERMINATION_NODE_STATUS,
} from 'app/config/quantityDetermination'
import { POSITION_TYPES } from 'app/types/POSITION_TYPES'

import useColumns from 'domain/billing/quantity/detail/lead/tabulator/useColumns'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import useStyles from './LeadQuantityDeterminationTableStyles'

const LeadQuantityDeterminationTable: FC = () => {
	const { projectUuid, lotUuid } = useParams<UrlParams>()

	const [tableData, setTableData] = useState<QuantityDeterminationNode[]>([])

	const { t } = useTranslation()

	const { options } = useOptions()
	const query = useUrlSearchParams()
	const queryUuid = query.get('qduuid')

	const classes = useStyles()

	const [lotWithQuantityDetermination, { data, loading }] = useGraphQLLazyQuery(
		queries.LotWithQuantityDetermination
	)

	const [acceptQuantityDeterminationItem] = useGraphQLMutation(
		mutations.acceptQuantityDeterminationItem
	)

	const [createQuantityDeterminationItemLead] = useGraphQLMutation(
		mutationsLead.createQuantityDeterminationItemLead
	)

	const [updateQuantityDeterminationItem] = useGraphQLMutation(
		mutations.updateQuantityDeterminationItem
	)

	const [declineQuantityDeterminationItem] = useGraphQLMutation(
		mutations.declineQuantityDeterminationItem
	)

	const [deleteQuantityDeterminationItem] = useGraphQLMutation(
		mutations.deleteQuantityDeterminationItem
	)

	useEffect(() => {
		lotWithQuantityDetermination({
			variables: {
				projectUuid,
				lotUuid,
				qdUuid: queryUuid,
			},
		})
	}, [projectUuid, lotUuid, queryUuid])

	const project = getProjectFromResponse(data)
	const role = getRoleFromProject(project)
	const lot = getLotFromProject(project)
	const quantityDetermination = getQuantityDeterminationFromLot(lot)
	const quantityDeterminationNodes = getQuantityDeterminationsNodes(
		quantityDetermination
	)

	useEffect(() => {
		if (quantityDeterminationNodes) {
			setTableData(quantityDeterminationNodes)
		}
	}, [quantityDeterminationNodes])

	const handleAcceptQuantityDetermination = (qdIUuid: any): void => {
		acceptQuantityDeterminationItem({
			variables: {
				projectUuid,
				lotUuid,
				qdUuid: queryUuid,
				qdIUuid,
			},
			refetchQueries: [
				{
					query: queries.LotWithQuantityDetermination,
					variables: {
						projectUuid,
						lotUuid,
						qdUuid: queryUuid,
					},
				},
			],
		})
	}

	const handleDeclineQuantityDetermination = (qdIUuid: any): void => {
		declineQuantityDeterminationItem({
			variables: {
				projectUuid,
				lotUuid,
				qdUuid: queryUuid,
				qdIUuid,
			},
			refetchQueries: [
				{
					query: queries.LotWithQuantityDetermination,
					variables: {
						projectUuid,
						lotUuid,
						qdUuid: queryUuid,
					},
				},
			],
		})
	}

	const createNewSuggestion = (item: any): QuantityDeterminationNode => {
		const newSuggestion = {
			type: POSITION_TYPES.SUGGESTION,
			status: QUANTITY_DETERMINATION_NODE_STATUS.TEMPORARY,
			label: `${t(`domain:billing.suggestion.item.label`)} ${item.label}`,
			qu: item.qu,
		}
		if (!item.quantityDeterminationNodes) {
			// eslint-disable-next-line no-param-reassign
			item.quantityDeterminationNodes = []
			item.quantityDeterminationNodes.push(newSuggestion)
		}

		if (item.quantityDeterminationNodes) {
			const delIndex = item.quantityDeterminationNodes.findIndex(
				(indexItem: any) => indexItem.type === POSITION_TYPES.SUGGESTION
			)
			if (delIndex !== -1) {
				return item
			}
			item.quantityDeterminationNodes.push(newSuggestion)
		}
		return item
	}

	const walkDelete = (qDNs: any, uuid: string): QuantityDeterminationNode[] => {
		const newQuantityDeterminationsNodes = []
		// eslint-disable-next-line no-restricted-syntax
		for (const item of qDNs) {
			if (item.quantityDeterminationNodes) {
				const delIndex = item.quantityDeterminationNodes.findIndex(
					(indexItem: any) => {
						return indexItem.uuid ? indexItem.uuid === uuid : null
					}
				)
				if (delIndex !== -1) {
					if (
						item.quantityDeterminationNodes[delIndex]
							.quantityDeterminationNodes[0].status !==
						QUANTITY_DETERMINATION_NODE_STATUS.TEMPORARY
					) {
						const suggestionUuid =
							item.quantityDeterminationNodes[delIndex]
								.quantityDeterminationNodes[0].uuid
						deleteQuantityDeterminationItem({
							variables: {
								projectUuid,
								lotUuid,
								qdUuid: queryUuid,
								qdNIUuid: suggestionUuid,
							},
							refetchQueries: [
								{
									query: queries.LotWithQuantityDetermination,
									variables: {
										projectUuid,
										lotUuid,
										qdUuid: queryUuid,
									},
								},
							],
						})
					}
					item.quantityDeterminationNodes[
						delIndex
					].quantityDeterminationNodes.splice(0, 1)
				} else
					item.quantityDeterminationNodes = walkDelete(
						item.quantityDeterminationNodes,
						uuid
					)
			}
			newQuantityDeterminationsNodes.push(item)
		}
		return newQuantityDeterminationsNodes
	}

	const modifyRow = (cell: any): any => {
		if (cell) {
			const row = cell.getRow()
			const quantityDeterminationNode = row.getData() as QuantityDeterminationNode
			const { type, status } = quantityDeterminationNode
			const { uuid: parentUuid } = getParentFromCell(cell).getData()
			if (status === QUANTITY_DETERMINATION_NODE_STATUS.DECLINED) {
				setTableData(
					walkAdd(
						quantityDeterminationNodes,
						quantityDeterminationNode,
						createNewSuggestion
					)
				)
			} else if (
				type === POSITION_TYPES.SUGGESTION &&
				(status === QUANTITY_DETERMINATION_NODE_STATUS.TEMPORARY ||
					status === QUANTITY_DETERMINATION_NODE_STATUS.PENDING)
			) {
				setTableData(walkDelete(tableData, parentUuid))
			}
		}
		return null
	}

	const handleClickAction = (cell: any, action: string): void => {
		const row = cell.getRow()
		const quantityDeterminationNode = row.getData() as QuantityDeterminationNode
		const { uuid } = quantityDeterminationNode
		if (action === 'accept') {
			handleAcceptQuantityDetermination(uuid)
		}

		if (action === 'decline') {
			handleDeclineQuantityDetermination(uuid)
		}
	}

	const handleEdited = (cell: any): void => {
		const { label, formula, qty, status, uuid } = cell.getRow().getData()
		const { uuid: parentUuid } = getParentFromCell(cell).getData()
		if (label && (formula || qty) && parentUuid) {
			if (status === QUANTITY_DETERMINATION_NODE_STATUS.TEMPORARY) {
				createQuantityDeterminationItemLead({
					variables: {
						projectUuid,
						lotUuid,
						qdUuid: queryUuid,
						parentUuid,
						name: label,
						formula: formula || null,
						qty: qty || null,
					},
					refetchQueries: [
						{
							query: queries.LotWithQuantityDetermination,
							variables: {
								projectUuid,
								lotUuid,
								qdUuid: queryUuid,
							},
						},
					],
				})
			} else {
				updateQuantityDeterminationItem({
					variables: {
						uuid,
						projectUuid,
						lotUuid,
						qdUuid: queryUuid,
						name: label,
						formula,
						qty,
					},
					refetchQueries: [
						{
							query: queries.LotWithQuantityDetermination,
							variables: {
								projectUuid,
								lotUuid,
								qdUuid: queryUuid,
							},
						},
					],
				})
			}
		}
	}

	const columns = useColumns(
		modifyRow,
		handleClickAction,
		quantityDetermination,
		handleEdited,
		role
	)

	return useMemo(
		() => (
			<Table
				className={classes.table}
				options={options}
				loading={loading}
				data={tableData || []}
				columns={columns}
			/>
		),
		// important! take care of the rerender conditions
		[tableData, columns]
	)
}

export default LeadQuantityDeterminationTable
