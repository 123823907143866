import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
	cardRoot: {
		minHeight: 320,
	},

	fixed: {
		marginBottom: theme.spacing(2),
	},
	cardLogosRoot: {
		height: '50px',
		display: 'flex',
		flexFlow: 'row nowrap',
		alignItems: 'center',
		justifyContent: 'center',
	},
	cardLogo: {
		display: 'block',
		height: '80%',
		padding: '0 30px',
	},
}))
