/* eslint-disable @typescript-eslint/no-explicit-any */

import UpBaseFormatter from 'app/services/tabulator/formatters/up/UpBaseFormatter'
import { POSITION_TYPES } from 'app/types/POSITION_TYPES'

class OptionalFormatter extends UpBaseFormatter {
	constructor(cell: any) {
		super(cell, POSITION_TYPES.OPTIONAL)
		this.markAsValid()
	}
}

export default OptionalFormatter
