import {
	getLotFromProject,
	getStatusFromQuantityDetermination,
	getQuantityDeterminationFromLot,
	getProjectFromResponse,
} from 'app/utils/apollo'
import PageContentHeader from 'component/PageContentHeader/PageContentHeader'
import React, { FC, useEffect } from 'react'
import CreateSubmissionButton from 'domain/billing/quantity/detail/bidder/components/header/CreateSubmissionButton/CreateSubmissionButton'
import Validate from 'component/Validate/Validate'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'
import { LOT_STATUS } from 'domain/lot/interfaces/LOT_STATUS'
import { REVISION_STATUS } from 'app/types/REVISION_STATUS'
import queries from 'domain/billing/quantity/apollo/queries'
import { useParams } from 'react-router-dom'
import { UrlParams } from 'app/types/UrlParams'
import useUrlSearchParams from 'app/hooks/useUrlSearchParams'
import { QUANTITY_DETERMINATION_STATUS } from 'app/config/quantityDetermination'

import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import useStyles from './QuantityDetailContentHeaderStyles'
import CreateReviewButton from '../../../lead/components/header/CreateReviewButton/CreateReviewButton'
import CreateCompletionButton from '../../../bidder/components/header/CreateCompletionButton/CreateCompletionButton'
import DeleteCompletionButton from '../../../bidder/components/header/DeleteCompletionButton/DeleteCompletionButton'

const QuantityDetailPageContentHeader: FC = () => {
	const { projectUuid, lotUuid } = useParams<UrlParams>()

	const classes = useStyles()

	const query = useUrlSearchParams()
	const queryUuid = query.get('qduuid')

	const [
		ProjectWithQuantityDeterminationStatus,
		{ data },
	] = useGraphQLLazyQuery(queries.ProjectWithQuantityDeterminationStatus)

	useEffect(() => {
		ProjectWithQuantityDeterminationStatus({
			variables: {
				projectUuid,
				lotUuid,
				qdUuid: queryUuid,
			},
		})
	}, [projectUuid, lotUuid, queryUuid])

	const project = getProjectFromResponse(data)
	const lot = getLotFromProject(project)
	const quantityDetermination = getQuantityDeterminationFromLot(lot)
	const quantityDeterminationStatus = getStatusFromQuantityDetermination(
		quantityDetermination
	)

	const bidderDisabled =
		quantityDeterminationStatus === QUANTITY_DETERMINATION_STATUS.INITIAL ||
		quantityDeterminationStatus === QUANTITY_DETERMINATION_STATUS.SUBMITTED ||
		quantityDeterminationStatus === QUANTITY_DETERMINATION_STATUS.ACCEPTED

	const leadDisabled =
		quantityDeterminationStatus === QUANTITY_DETERMINATION_STATUS.REVIEWED ||
		quantityDeterminationStatus === QUANTITY_DETERMINATION_STATUS.ACCEPTED ||
		quantityDeterminationStatus === QUANTITY_DETERMINATION_STATUS.COMPLETED

	return (
		<div className={classes.root}>
			<PageContentHeader />
			<Validate
				validCombinations={[
					{
						projectRole: ROLES_PROJECT.BIDDER,
						lotStatus: LOT_STATUS.CONFIRMED,
						revisionStatus: REVISION_STATUS.ACCEPTED,
					},
				]}
				invalidCombinations={[
					{
						quantityDeterminationStatus: QUANTITY_DETERMINATION_STATUS.ACCEPTED,
					},
					{
						quantityDeterminationStatus:
							QUANTITY_DETERMINATION_STATUS.COMPLETED,
					},
				]}
			>
				<CreateSubmissionButton qdUuid={queryUuid} disabled={bidderDisabled} />
			</Validate>
			<Validate
				validCombinations={[
					{
						revisionStatus: REVISION_STATUS.COMPLETED,
						projectRole: ROLES_PROJECT.LEAD,
						lotStatus: LOT_STATUS.CONFIRMED,
					},
				]}
			>
				<CreateReviewButton qdUuid={queryUuid} disabled={leadDisabled} />
			</Validate>
			<Validate
				validCombinations={[
					{
						lotStatus: LOT_STATUS.CONFIRMED,
						revisionStatus: REVISION_STATUS.ACCEPTED,
						projectRole: ROLES_PROJECT.BIDDER,
						quantityDeterminationStatus: QUANTITY_DETERMINATION_STATUS.ACCEPTED,
					},
				]}
			>
				<CreateCompletionButton qdUuid={queryUuid} />
			</Validate>
			<Validate
				validCombinations={[
					{
						lotStatus: LOT_STATUS.CONFIRMED,
						revisionStatus: REVISION_STATUS.ACCEPTED,
						projectRole: ROLES_PROJECT.BIDDER,
						quantityDeterminationStatus:
							QUANTITY_DETERMINATION_STATUS.COMPLETED,
					},
				]}
			>
				<DeleteCompletionButton qdUuid={queryUuid} />
			</Validate>
		</div>
	)
}

export default QuantityDetailPageContentHeader
