import AttachmentCard from 'domain/general/attachments/components/body/attachments/AttachmentCard/AttachmentCard'
import { FolderAttachmentCardProps } from 'domain/general/attachments/components/body/attachments/FolderAttachmentCard/FolderAttachmentCardProps'
import React, { FC } from 'react'

const FolderAttachmentCard: FC<FolderAttachmentCardProps> = ({
	attachment,
	onRefetch,
	classes: parentClasses = {},
}) => (
	<AttachmentCard
		attachment={attachment}
		classes={parentClasses}
		onImageClick={onRefetch}
	/>
)

export default FolderAttachmentCard
