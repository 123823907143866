import useSideSheet from 'app/hooks/useSideSheet'
import Loading from 'component/Loading/Loading'
import { TableProps } from 'component/Table/TableProps'
import React, { FC, useEffect, useState } from 'react'
import { ReactTabulator } from 'react-tabulator'
import useStyles from './TableStyles'

const Table: FC<TableProps> = ({
	columns,
	data,
	options,
	className,
	loading = false,
	filters,
}) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [initialTableData, setInitialTableData] = useState<any[]>([])
	const [tabulatorRef, setTabulatorRef] = useState<ReactTabulator | null>()
	const [minHeight, setMinHeight] = useState()
	const { animating } = useSideSheet()
	const { offsetHeight } = (tabulatorRef && tabulatorRef.ref) || {}
	const classes = useStyles({ minHeight })

	useEffect(() => {
		setInitialTableData(data)
	}, [])

	// for debugging
	// useEffect(() => {
	// 	if (tabulatorRef) {
	// tabulatorRef.table.setColumns(columns)
	// tabulatorRef.table.redraw()
	// }
	// }, [data])

	useEffect(() => {
		if (tabulatorRef) {
			tabulatorRef.table.setColumns(columns)
			tabulatorRef.table.redraw()
		}
	}, [tabulatorRef, columns])

	useEffect(() => {
		if (tabulatorRef) {
			// update silently if just values changed
			// tabulatorRef.table.updateData(data)
			tabulatorRef.table.replaceData(data)
			// force hard update if new row
			if (initialTableData.length !== data.length) {
				tabulatorRef.table.setData(data)
			}
		}
	}, [tabulatorRef, data])

	useEffect(() => {
		// sidesheet workaround
		setTimeout(() => {
			if (!animating && tabulatorRef && columns) {
				tabulatorRef.table.redraw()
			}
		}, 200)
	}, [animating])

	// set table filters
	useEffect(() => {
		if (tabulatorRef && Array.isArray(filters)) {
			filters.forEach(filter => {
				tabulatorRef.table.setFilter(filter.function, filter.data)
			})
		}
	}, [tabulatorRef, filters])

	const setMinHeightIfUpdated = (): void => {
		if (minHeight !== offsetHeight) {
			setMinHeight(offsetHeight)
		}
	}

	return (
		<div className={classes.root}>
			<Loading loading={loading}>
				<ReactTabulator
					resizableColumns={false}
					ref={ref => {
						setTabulatorRef(ref)
					}}
					columns={columns}
					options={{
						...{
							invalidOptionWarnings: false,
							autoResize: true,
						},
						...options,
					}}
					data={initialTableData}
					className={className}
					renderComplete={setMinHeightIfUpdated()}
				/>
			</Loading>
		</div>
	)
}

export default Table
