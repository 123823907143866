import Notice from 'component/Notice/Notice'
import PageContent from 'component/PageContent/PageContent'
import PageContentBody from 'component/PageContentBody/PageContentBody'
import { PageContentErrorProps } from 'component/PageContentError/PageContentErrorProps'
import React, { FC } from 'react'

const PageContentError: FC<PageContentErrorProps> = ({ message }) => {
	return (
		<PageContent
			ignoreNotAllowed
			body={
				<PageContentBody notice={<Notice type="error" message={message} />} />
			}
		/>
	)
}

export default PageContentError
