import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
	cardHeader: {
		backgroundColor: theme.palette.grey[200],
		textAlign: 'center',
		height: 120,
	},
	cardContent: {
		padding: `${theme.spacing(6)}px ${theme.spacing(6)}px 0`,
	},
	cardPricing: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'baseline',
		marginBottom: theme.spacing(2),
	},
	submitButton: {
		marginBottom: theme.spacing(2),
		justifyContent: 'center',
	},
	listItem: {
		paddingTop: theme.spacing(1),
	},
	cardRoot: {
		display: 'flex',
		flexDirection: 'column',
	},
	infoAndAction: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	quantityAndButton: {
		display: 'flex',
		flexDirection: 'column',
	},
}))
