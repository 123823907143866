/* eslint-disable @typescript-eslint/no-explicit-any */
import UpBaseFormatter from 'app/services/tabulator/formatters/up/UpBaseFormatter'
import { POSITION_TYPES } from 'app/types/POSITION_TYPES'

export default class RemarkFormatter extends UpBaseFormatter {
	constructor(cell: any) {
		super(cell, POSITION_TYPES.REMARK)
	}

	// eslint-disable-next-line class-methods-use-this
	format(): string {
		return ''
	}
}
