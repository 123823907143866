import { DrawerProps } from 'domain/drawer/components/Drawer/DrawerProps'
import DrawerMenu from 'domain/drawer/components/DrawerMenu/DrawerMenu'
import React, { FC } from 'react'
import useStyles from 'domain/drawer/components/Drawer/DrawerStyles'

const Drawer: FC<DrawerProps> = () => {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<DrawerMenu />
		</div>
	)
}

export default Drawer
