import PageContentBody from 'component/PageContentBody/PageContentBody'
import React, { FC, ReactNode } from 'react'
import { Table, TableRow, TableCell, TableBody } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PageContentHeading2 from 'component/PageContentHeading2/PageContentHeading2'
import { MembersPageContentBodyProps } from './MembersPageContentBodyProps'

import useStyles from './MemberPageContentBodyStyles'

const MembersPageContentBody: FC<MembersPageContentBodyProps> = ({
	revision,
}) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const { leadInfo } = revision || {}

	const renderLeadInfoValues = (value: string, keys: string[]): ReactNode => {
		return (
			<TableRow key={keys.join('.')}>
				<TableCell classes={{ root: classes.keyCell }}>
					{t(`domain:general.leadInfo.${keys.join('.')}`)}:
				</TableCell>
				<TableCell classes={{ root: classes.valueCell }}>{value}</TableCell>
			</TableRow>
		)
	}

	const resolveLeadInfos = (
		infos: {} | null = {},
		keys: string[] = []
	): ReactNode => {
		if (infos) {
			return Object.entries(infos).map(item => {
				if (item[1] && item[0].charAt(0) !== '_') {
					if (typeof item[1] === 'string') {
						return renderLeadInfoValues(item[1], [...keys, item[0]])
					}

					if (Array.isArray(item[1]) && Array.length) {
						return item[1].map((itemObj: {}) => resolveLeadInfos(itemObj))
					}

					if (typeof item[1] === 'object') {
						return resolveLeadInfos(item[1], [...keys, item[0]])
					}
				}
				return null
			})
		}
		return null
	}

	return (
		<PageContentBody>
			<PageContentHeading2 className={classes.title}>
				{t('domain:general.leadInfo.title')}
			</PageContentHeading2>
			<Table classes={{ root: classes.table }} size="small">
				<TableBody>{resolveLeadInfos(leadInfo)}</TableBody>
			</Table>
		</PageContentBody>
	)
}

export default MembersPageContentBody
