import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { INPUT_PASSWORD_LENGTH_MIN } from 'app/constants'
import { PasswordValidatorProps } from 'component/PasswordValidator/PasswordValidatorProps'
import React, { FC, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { TextValidator } from 'react-material-ui-form-validator'

import useStyles from './PasswordValidatorStyles'

const PasswordValidator: FC<PasswordValidatorProps> = ({
	value,
	onChange,
	label,
	type,
	name,
	validators,
	errorMessages,
	endAdornment = true,
	onEndAdornmentChange,
}) => {
	const { t } = useTranslation()
	const [isText, setIsText] = useState(false)

	const classes = useStyles()

	const renderEndAdornment = useCallback(() => {
		if (!endAdornment) {
			return null
		}

		return (
			<InputAdornment position="end">
				<IconButton
					aria-label={t('common:inputs.password.endAdornment.ariaLabel')}
					onClick={() => {
						if (onEndAdornmentChange) {
							onEndAdornmentChange(!isText)
						}
						setIsText(!isText)
					}}
				>
					{isText ? <Visibility /> : <VisibilityOff />}
				</IconButton>
			</InputAdornment>
		)
	}, [isText, endAdornment])

	return (
		<TextValidator
			className={classes.passwordValidator}
			type={type || (isText ? 'text' : 'password')}
			validators={
				validators || [
					'required',
					`minStringLength:${INPUT_PASSWORD_LENGTH_MIN}`,
					'containsLowerCase',
					'containsUpperCase',
					'containsNumber',
				]
			}
			errorMessages={
				errorMessages || [
					t('common:inputs.password.validatorViolations.required'),
					t('common:inputs.password.validatorViolations.minLength', {
						minLength: INPUT_PASSWORD_LENGTH_MIN,
					}),
					t('common:inputs.password.validatorViolations.containsLowerCase'),
					t('common:inputs.password.validatorViolations.containsUpperCase'),
					t('common:inputs.password.validatorViolations.containsNumber'),
				]
			}
			label={label || t('common:inputs.password.label')}
			name={name || 'password'}
			value={value}
			fullWidth
			onChange={onChange}
			InputProps={{
				endAdornment: renderEndAdornment(),
			}}
		/>
	)
}

export default PasswordValidator
