import { Theme, makeStyles } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'

export default makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		marginLeft: theme.spacing(-2),
		marginRight: theme.spacing(-2),
	},
	authorFilter: {
		width: 300,
	},
	pageContentHead: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: theme.spacing(2),
	},
	AttachmentCardRoot: {
		width: themeValues().sizes.AttachmentCard.width,
		maxWidth: themeValues().sizes.AttachmentCard.width,
		marginRight: theme.spacing(2),
		marginLeft: theme.spacing(2),
		marginBottom: theme.spacing(4),
	},
	AttachmentDropzone: {
		marginRight: theme.spacing(2),
		marginLeft: theme.spacing(2),
		marginBottom: theme.spacing(4),
	},
}))
