import { REGEX_URL_PARAMS } from 'app/constants'
import AvailableRouteParams from 'app/routing/routes/AvailableRouteParams'
import QueryParameter from 'app/routing/routes/QueryParameter'

export const resolveRoute = (
	route: string,
	params: AvailableRouteParams,
	queryParameter?: QueryParameter
): string => {
	const baseUrl = route.replace(
		REGEX_URL_PARAMS,
		(fullMatch: string, group: string) => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
			// @ts-ignore
			if (params[group]) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
				// @ts-ignore
				return params[group]
			}
			throw new Error(`Missing parameter: ${group}`)
		}
	)
	if (queryParameter) {
		const parameter = new URLSearchParams()
		Object.entries(queryParameter).forEach(string => {
			return parameter.append(string[0], string[1])
		})
		return `${baseUrl}?${parameter.toString()}`
	}
	return baseUrl
}

export const isNewProjectRoute = (params: AvailableRouteParams): boolean => {
	if (params.revisionUuid || params.lotUuid || params.sectionId) {
		return false
	}
	return true
}
