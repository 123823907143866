import { useTranslation } from 'react-i18next'
import { HeaderProps } from 'domain/header/components/Header/HeaderProps'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Logo from 'domain/header/components/Logo/Logo'
import ProfileMenu from 'domain/header/components/profileMenu/ProfileMenu/ProfileMenu'
import ProfileName from 'domain/header/components/profileMenu/ProfileName/ProfileName'
import LotTabs from 'domain/lot/components/LotTabs/LotTabs'
import ProjectDropdown from 'domain/project/components/header/ProjectDropdown/ProjectDropdown'
import React, { FC, MouseEvent, useState } from 'react'

// custom Styles MUST be imported last to ensure style heredity
import useStyles from 'domain/header/components/Header/HeaderStyles'
import Validate from 'component/Validate/Validate'
import { ENVIRONMENTS } from 'app/constants'
import ProfileResetDemo from '../profileMenu/ProfileResetDemo/ProfileResetDemot'

const Header: FC<HeaderProps> = ({ onDbReset }) => {
	const classes = useStyles()

	const { t } = useTranslation()
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const isMenuOpen = Boolean(anchorEl)

	const handleProfileMenuOpen = (event: MouseEvent<HTMLElement>): void => {
		setAnchorEl(event.currentTarget)
	}

	const handleMenuClose = (): void => {
		setAnchorEl(null)
	}

	return (
		<div className={classes.root}>
			<AppBar
				position="relative"
				classes={{
					root: classes.appBar,
				}}
			>
				<div className={classes.appBarTop}>
					<Logo
						classes={{
							root: classes.logoRoot,
							image: classes.logoImage,
						}}
					/>
					<Toolbar
						classes={{
							root: classes.toolbar,
						}}
					>
						<Validate
							invalidCombinations={[{ environment: ENVIRONMENTS.DEMO }]}
						>
							<ProfileName />
						</Validate>
						<Validate validCombinations={[{ environment: ENVIRONMENTS.DEMO }]}>
							<ProfileResetDemo />
						</Validate>

						<IconButton
							color="inherit"
							onClick={handleProfileMenuOpen}
							className={classes.iconButton}
						>
							<AccountCircle />
						</IconButton>

						<ProfileMenu
							open={isMenuOpen}
							anchorEl={anchorEl}
							onClose={handleMenuClose}
							onDbReset={onDbReset}
						/>
					</Toolbar>
				</div>
				<div className={classes.projectDropdownWrapper}>
					<ProjectDropdown
						label={t('domain:project.select.label')}
						create
						classes={{ root: classes.projectDropdownRoot }}
					/>
					<LotTabs />
				</div>
			</AppBar>
		</div>
	)
}

export default Header
