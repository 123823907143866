import { gql } from 'apollo-boost'
import { createNestedQuery } from 'app/utils/apollo'

export const quantityDeterminationNodes = `quantityDeterminationNodes {
	uuid
	lin
	lic
	type
	label	
	qty
	qu
	status
	level
	formula
}`

const QuantityDeterminations = gql`
	query QuantityDeterminations($projectUuid: String!, $lotUuid: String!) {
		project(uuid: $projectUuid) {
			uuid
			role
			lot(uuid: $lotUuid) {
				uuid
				quantityDeterminations {
					data {
						uuid
						name
						createdAt
						status
					}
				}
			}
		}
	}
`

const LotWithQuantityDetermination = gql`
	query LotWithQuantityDetermination(
		$projectUuid: String!
		$lotUuid: String!
		$qdUuid: String!
	) {
		project(uuid: $projectUuid) {
			uuid
			role
			lot(uuid: $lotUuid) {
				uuid
				quantityDetermination(uuid: $qdUuid) {
					name
					uuid
					status
					${createNestedQuery(quantityDeterminationNodes, 7)}
				}
			}
		}
	}
`

const ProjectWithQuantityDeterminationStatus = gql`
	query ProjectWithQuantityDeterminationStatus(
		$projectUuid: String!
		$lotUuid: String!
		$qdUuid: String!
	) {
		project(uuid: $projectUuid) {
			uuid
			lot(uuid: $lotUuid) {
				uuid
				quantityDetermination(uuid: $qdUuid) {
					uuid
					status
				}
			}
		}
	}
`

const PreflightSubmission = gql`
	query PreflightSubmission(
		$projectUuid: String!
		$lotUuid: String!
		$qdUuid: String!
	) {
		project(uuid: $projectUuid) {
			uuid
			lot(uuid: $lotUuid) {
				uuid
				quantityDetermination(uuid: $qdUuid) {
					uuid
					preflightSubmission {
						failures {
							lin
							name
						}
						success
					}
				}
			}
		}
	}
`

const PreflightReview = gql`
	query PreflightReview(
		$projectUuid: String!
		$lotUuid: String!
		$qdUuid: String!
	) {
		project(uuid: $projectUuid) {
			uuid
			lot(uuid: $lotUuid) {
				uuid
				quantityDetermination(uuid: $qdUuid) {
					uuid
					preflightReview {
						failures {
							lin
							name
						}
						success
					}
				}
			}
		}
	}
`

export default {
	QuantityDeterminations,
	LotWithQuantityDetermination,
	ProjectWithQuantityDeterminationStatus,
	PreflightSubmission,
	PreflightReview,
}
