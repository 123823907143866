import {
	QUANTITY_DETERMINATION_STATUS,
	NOTICE_CONDITIONS,
} from 'app/config/quantityDetermination'
import { Notice } from 'component'
import PageContentBody from 'component/PageContentBody/PageContentBody'
import Validate from 'component/Validate/Validate'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'
import React, { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import BidderQuantityDeterminationTable from '../../../bidder/components/body/BidderQuantityDeterminationTable/BidderQuantityDeterminationTable'
import LeadQuantityDeterminationTable from '../../../lead/components/body/LeadQuantityDeterminationTable/LeadQuantityDeterminationTable'
import QuantityDetailBreadCrumb from '../../body/QuantityDetailBreadCrumb/QuantityDetailBreadCrumb'
import { QuantityDetailPageContentBodyProps } from './QuantityDetailPageContentBodyProps'

import useStyles from './QuantityDetailPageContentBodyStyles'

const QuantityDetailPageContentBody: FC<QuantityDetailPageContentBodyProps> = ({
	role,
	status,
}) => {
	const { t } = useTranslation()

	const classes = useStyles()

	const renderNotice = (): ReactElement | null => {
		if (!role || !status) {
			return null
		}

		const validConditionsForRole = get(NOTICE_CONDITIONS, role, null)
		const isRendered = (get(
			validConditionsForRole,
			['validQuantityDeterminationStatus'],
			[]
		) as QUANTITY_DETERMINATION_STATUS[]).includes(status)

		if (isRendered) {
			return (
				<Notice
					classes={{
						root: classes.notice,
					}}
					message={t(`domain:billing.notice.${role}.${status}`)}
					type="info"
				/>
			)
		}

		return null
	}

	return (
		<PageContentBody>
			<Validate
				validCombinations={[
					{
						projectRole: ROLES_PROJECT.LEAD,
					},
				]}
			>
				<QuantityDetailBreadCrumb />
				{renderNotice()}
				<LeadQuantityDeterminationTable />
			</Validate>
			<Validate
				validCombinations={[
					{
						projectRole: ROLES_PROJECT.BIDDER,
					},
				]}
			>
				<QuantityDetailBreadCrumb />
				{renderNotice()}
				<BidderQuantityDeterminationTable />
			</Validate>
		</PageContentBody>
	)
}

export default QuantityDetailPageContentBody
