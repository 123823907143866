import { useSideSheet } from 'app/hooks'
import { UrlParams } from 'app/types/UrlParams'
import Loading from 'component/Loading/Loading'
import NotAllowed from 'domain/project/components/base/NotAllowed/NotAllowed'
import { PageContentProps } from 'component/PageContent/PageContentProps'

import useStyles from 'component/PageContent/PageContentStyles'
import PageContentHeader from 'component/PageContentHeader/PageContentHeader'
import useLotStatus from 'domain/lot/hooks/useLotStatus'
import React, { FC, ReactElement, useEffect } from 'react'
import { useParams } from 'react-router-dom'

const PageContent: FC<PageContentProps> = ({
	header,
	body,
	ignoreNotAllowed,
}) => {
	const { loading } = useLotStatus()
	const { lotUuid } = useParams<UrlParams>()

	const fakeHeader = <PageContentHeader />
	const { open, closeSideSheet } = useSideSheet()
	const classes = useStyles(open)

	useEffect(() => {
		if (lotUuid && open) {
			closeSideSheet()
		}
	}, [lotUuid])

	useEffect(() => {
		return function cleanup() {
			closeSideSheet()
		}
	}, [])

	const renderPageContent = (): ReactElement | null => {
		return (
			<>
				{header || fakeHeader}
				{body}
			</>
		)
	}

	const renderContentContainer = (
		pageContent: ReactElement | null,
		sideSheet = true
	): ReactElement => {
		return (
			<div className={classes.root}>
				<div className={classes.innerContainer}>{pageContent}</div>
				{sideSheet && (
					<div
						id="sidesheet-container"
						className={classes.sideSheetContainer}
					/>
				)}
			</div>
		)
	}

	return (
		<NotAllowed
			disabled={ignoreNotAllowed}
			fallback={textComponent => renderContentContainer(textComponent)}
		>
			<Loading loading={loading}>
				{renderContentContainer(renderPageContent())}
			</Loading>
		</NotAllowed>
	)
}

export default PageContent
