import {
	getLotFromProject,
	getRevisionsFromLot,
	getProjectFromResponse,
	getDataFromBoqAnnotations,
	getBoqAnnotationsFromRevision,
	getRoleFromProject,
} from 'app/utils/apollo'
import React, { FC, ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { UrlParams } from 'app/types/UrlParams'
import queries from 'domain/award/annotations/apollo/queries'
import PageContentBody from 'component/PageContentBody/PageContentBody'
import AnnotationsTable from 'domain/award/annotations/components/body/AnnotationsTable/AnnotationsTable'
import { DataRevision } from 'app/types'
import PageContentHeading2 from 'component/PageContentHeading2/PageContentHeading2'
import EmptyAnnotationsNotice from 'domain/award/annotations/components/body/status/EmptyAnnotationsNotice/EmptyAnnotationsNotice'
import useGraphQLQuery from 'app/services/apollo/hooks/useGraphQLQuery'

const AnnotationsPageContentBody: FC = () => {
	const { projectUuid, lotUuid } = useParams<UrlParams>()
	const { data, loading } = useGraphQLQuery(queries.BoqAnnotations, {
		variables: {
			projectUuid,
			lotUuid,
			resolveBidderDataRevisions: true,
		},
	})

	const project = getProjectFromResponse(data)
	const lot = getLotFromProject(project)
	const role = getRoleFromProject(project)
	const revisions = getRevisionsFromLot(lot)
	const boqAnnotations =
		revisions && getBoqAnnotationsFromRevision(revisions[0])
	const dataBoqAnnotations = getDataFromBoqAnnotations(boqAnnotations)

	const renderBoqAnnotations = (): ReactElement[] | null => {
		if (revisions) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return revisions.map((revision: DataRevision): any => {
				const boqAnnotationsData = revision.boqAnnotations?.data
				const bidderName = revision.boqAnnotations?.name

				if (boqAnnotationsData && boqAnnotationsData.length > 0) {
					return (
						<div>
							<PageContentHeading2>{bidderName}</PageContentHeading2>
							<AnnotationsTable
								boqAnnotationsData={boqAnnotationsData}
								lot={lot}
								role={role}
							/>
						</div>
					)
				}
				return null
			})
		}
		return null
	}

	if (loading) {
		return null
	}

	return (
		<PageContentBody>
			{dataBoqAnnotations && dataBoqAnnotations.length > 0 ? (
				renderBoqAnnotations()
			) : (
				<EmptyAnnotationsNotice />
			)}
		</PageContentBody>
	)
}

export default AnnotationsPageContentBody
