/* eslint-disable @typescript-eslint/no-explicit-any */
import { getStatusFromQuantityDetermination } from 'app/utils/apollo'
import {
	getLastChildFromCell,
	getFirstChildFromCell,
	isNodeSuggestionTypeAndHasStatusPending,
	isNodeQtyDetermType,
	hasQDValidStatusToBeModifiedByBidder,
	hasNodeValidTypeToAddChildren,
} from 'app/utils/tabulator'
import React, { ReactElement, FC } from 'react'
import TabulatorIconButton from 'component/TabulatorIconButton/TabulatorIconButton'
import {
	QuantityDeterminationNode,
	QUANTITY_DETERMINATION_NODE_STATUS,
} from 'app/config/quantityDetermination'
import { POSITION_TYPES } from 'app/types/POSITION_TYPES'
import { get } from 'lodash'
import TabulatorButton from 'component/TabulatorButton/TabulatorButton'
import useStyles from './QuantityDeterminationModifyRowFormatterStyles'
import QuantityDeterminationModifyRowFormatterProps from './QuantityDeterminationModifyRowFormatterProps'

const QuantityDeterminationModifyRowFormatter: FC<QuantityDeterminationModifyRowFormatterProps> = ({
	cell,
	onClick,
	quantityDetermination,
	handleAcceptSuggestion,
}): ReactElement | null => {
	const row = cell.getRow()
	const quantityDeterminationNode = row.getData() as QuantityDeterminationNode
	const { type, status, uuid } = quantityDeterminationNode

	const classes = useStyles()

	const firstChildType = get(getFirstChildFromCell(cell), ['data', 'type'])
	const lastChildStatus = get(getLastChildFromCell(cell), ['data', 'status'])

	const quantityDeterminationStatus = getStatusFromQuantityDetermination(
		quantityDetermination
	)
	const disabledAddRow =
		lastChildStatus === QUANTITY_DETERMINATION_NODE_STATUS.TEMPORARY

	const disabledDeleteRow =
		status === QUANTITY_DETERMINATION_NODE_STATUS.DECLINED &&
		firstChildType === POSITION_TYPES.SUGGESTION

	if (
		quantityDeterminationStatus &&
		hasQDValidStatusToBeModifiedByBidder(quantityDeterminationStatus)
	) {
		if (hasNodeValidTypeToAddChildren(type)) {
			return (
				<TabulatorIconButton
					onClick={() => onClick(cell)}
					disabled={disabledAddRow}
				>
					<svg
						className="MuiSvgIcon-root"
						focusable="false"
						viewBox="0 0 24 24"
						aria-hidden="true"
					>
						<path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
					</svg>
				</TabulatorIconButton>
			)
		}

		if (isNodeQtyDetermType(type)) {
			return (
				<TabulatorIconButton
					onClick={() => onClick(cell)}
					disabled={disabledDeleteRow}
				>
					<svg
						className="MuiSvgIcon-root"
						focusable="false"
						viewBox="0 0 24 24"
						aria-hidden="true"
					>
						<path d="M19 13H5v-2h14v2z" />
					</svg>
				</TabulatorIconButton>
			)
		}

		if (
			status &&
			isNodeSuggestionTypeAndHasStatusPending(type, status) &&
			handleAcceptSuggestion
		) {
			return (
				<div>
					<TabulatorButton
						variant="success"
						className={classes.button}
						onClick={() => handleAcceptSuggestion(uuid)}
					>
						<svg
							className="MuiSvgIcon-root"
							focusable="false"
							viewBox="0 0 24 24"
							aria-hidden="true"
						>
							<path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
						</svg>
					</TabulatorButton>
					<TabulatorButton
						variant="error"
						onClick={() => onClick(cell)}
						className={classes.button}
					>
						<svg
							className="MuiSvgIcon-root"
							focusable="false"
							viewBox="0 0 24 24"
							aria-hidden="true"
							name="reject"
						>
							<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
						</svg>
					</TabulatorButton>
				</div>
			)
		}
	}

	return null
}

export default QuantityDeterminationModifyRowFormatter
