import {
	ACTION_SIDESHEET_ANIMATION_END,
	ACTION_SIDESHEET_ANIMATION_START,
	ACTION_SIDESHEET_CLOSE,
	ACTION_SIDESHEET_OPEN,
} from 'app/constants'
import { SideSheetContextState } from 'component/SideSheetProvider/SideSheetContextState'

export default (
	state: SideSheetContextState,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	action: any
): SideSheetContextState => {
	switch (action.type) {
		case ACTION_SIDESHEET_OPEN:
			return {
				...state,
				open: true,
				content: action.payload.content || null,
				title: action.payload.title || null,
				closable: action.payload.closable || true,
			}
		case ACTION_SIDESHEET_CLOSE:
			return {
				...state,
				open: false,
			}
		case ACTION_SIDESHEET_ANIMATION_START:
			return {
				...state,
				animating: true,
			}
		case ACTION_SIDESHEET_ANIMATION_END:
			return {
				...state,
				animating: false,
			}
		default:
			return state
	}
}
