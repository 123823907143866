import { gql } from 'apollo-boost'

const createBlankAccount = gql`
	mutation createBlankAccount($email: String!) {
		createBlankAccount(email: $email) {
			uuid
		}
	}
`

const createAccountToken = gql`
	mutation createAccountToken($email: String!) {
		createAccountToken(email: $email) {
			uuid
		}
	}
`

const createSubscription = gql`
	mutation createSubscription(
		$paymentMethodId: String!
		$pricingPlanCode: String!
		$quantity: Int!
	) {
		createSubscription(
			paymentMethodId: $paymentMethodId
			pricingPlanCode: $pricingPlanCode
			quantity: $quantity
		) {
			stripeSubscriptionId
			clientSecret
			invoiceStatus
			paymentIntentStatus
			setupIntentStatus
			status
		}
	}
`

const updateAccount = gql`
	mutation updateAccount(
		$token: String!
		$city: String
		$company: String
		$firstName: String
		$lastName: String
		$phone: String
		$street: String
		$taxId: String
		$zipCode: String
		$password: String
	) {
		updateAccount(
			token: $token
			city: $city
			company: $company
			firstName: $firstName
			lastName: $lastName
			phone: $phone
			street: $street
			taxId: $taxId
			zipCode: $zipCode
			password: $password
		) {
			uuid
		}
	}
`

export default {
	createBlankAccount,
	updateAccount,
	createSubscription,
	createAccountToken,
}
