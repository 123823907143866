import {
	getLotFromProject,
	getPreflightReviewFromQuantityDetermination,
	getQuantityDeterminationFromLot,
	getProjectFromResponse,
} from 'app/utils/apollo'
import React, { FC, useEffect, useState, ReactElement } from 'react'
import PrimaryButton from 'component/PrimaryButton/PrimaryButton'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import queries from 'domain/billing/quantity/apollo/queries'
import mutations from 'domain/billing/quantity/apollo/mutations'
import { useParams } from 'react-router-dom'
import { UrlParams } from 'app/types/UrlParams'
import PageContentParagraph from 'component/PageContentParagraph/PageContentParagraph'
import { ListItem, List } from '@material-ui/core'
import ConfirmationDialog from 'component/ConfirmationDialog/ConfirmationDialog'
import { useTranslation } from 'react-i18next'
import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import CreateReviewButtonProps from './CreateReviewButtonProps'

const CreateReviewButton: FC<CreateReviewButtonProps> = ({
	disabled,
	qdUuid,
}) => {
	const { projectUuid, lotUuid } = useParams<UrlParams>()

	const [showErrorDialog, setShowErrorDialog] = useState(false)
	const [showSuccessDialog, setShowSuccessDialog] = useState(false)

	const { t } = useTranslation()

	const [preflightReview, { data, loading }] = useGraphQLLazyQuery(
		queries.PreflightReview,
		{
			fetchPolicy: 'network-only',
		}
	)

	const [createReview, { loading: loadingCreateReview }] = useGraphQLMutation(
		mutations.createReview,
		{
			onCompleted: () => setShowSuccessDialog(false),
		}
	)

	const project = getProjectFromResponse(data)
	const lot = getLotFromProject(project)
	const quantityDetermination = getQuantityDeterminationFromLot(lot)

	const preflightReviewFromQtyDetermination = getPreflightReviewFromQuantityDetermination(
		quantityDetermination
	)
	const { success, failures } = preflightReviewFromQtyDetermination || {}

	useEffect(() => {
		if (success) {
			setShowSuccessDialog(true)
		}

		if (success === false) {
			setShowErrorDialog(true)
		}
	}, [success])

	const handleClick = (): void => {
		if (projectUuid && lotUuid && qdUuid) {
			preflightReview({
				variables: {
					projectUuid,
					lotUuid,
					qdUuid,
				},
			})
		}
	}

	const renderFailureItems = (): ReactElement[] | undefined => {
		return (
			failures &&
			failures.map(({ lin, name }) => {
				return (
					<ListItem>
						{lin} - {name}
					</ListItem>
				)
			})
		)
	}

	return (
		<>
			<PrimaryButton
				loading={loading}
				disabled={disabled}
				onClick={handleClick}
			>
				{t('domain:billing.createReview.button.label')}
			</PrimaryButton>

			<ConfirmationDialog
				open={showSuccessDialog}
				loading={loadingCreateReview}
				contentText={t(
					'domain:billing.createReview.preflightReview.success.text'
				)}
				onAccept={() =>
					createReview({
						variables: {
							projectUuid,
							lotUuid,
							qdUuid,
						},
					})
				}
				onAcceptLabel={t(
					'domain:billing.createReview.preflightReview.success.button.label.accept'
				)}
				onDecline={() => setShowSuccessDialog(false)}
				onDeclineLabel={t(
					'domain:billing.createReview.preflightReview.success.button.label.decline'
				)}
			/>

			<ConfirmationDialog
				open={showErrorDialog}
				onAccept={() => setShowErrorDialog(false)}
				onAcceptLabel={t(
					'domain:billing.createReview.preflightReview.failure.button.label'
				)}
			>
				<div>
					<PageContentParagraph>
						{t('domain:billing.createReview.preflightReview.failure.text')}
					</PageContentParagraph>
					<List>
						{t('domain:billing.createReview.preflightReview.failure.listTitle')}
						{renderFailureItems()}
					</List>
				</div>
			</ConfirmationDialog>
		</>
	)
}

export default CreateReviewButton
