import { RestError } from 'app/types/RestError'
import { UseUpload } from 'app/types/UseUpload'
import axios from 'axios'
import React, {
	ChangeEvent,
	ReactElement,
	useEffect,
	useRef,
	useState,
} from 'react'

export default (): UseUpload => {
	const inputElement = useRef<HTMLInputElement>(null)
	const [selectedFile, setSelectedFile] = useState<File | null>(null)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<RestError | null>(null)
	const [apiUrl, setApiUrl] = useState<string | null>(null)

	const initializeUpload = (url: string): void => {
		setApiUrl(url)
	}

	useEffect(() => {
		if (selectedFile && apiUrl) {
			setLoading(true)

			const formData = new FormData()
			formData.append('file', selectedFile)

			axios
				.post(apiUrl, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then(() => {
					window.location.reload()
				})
				.catch(e => {
					setError(e.response.data)
				})
				.finally(() => {
					setLoading(false)
					setSelectedFile(null)
				})
		}
	}, [selectedFile, apiUrl])

	useEffect(() => {
		if (inputElement && inputElement.current && !selectedFile) {
			inputElement.current.value = ''
		}
	}, [selectedFile])

	const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
		if (event.target.files) {
			setSelectedFile(event.target.files[0])
		}
	}

	const openUploadDialog = (): void => {
		if (inputElement && inputElement.current) {
			inputElement.current.click()
		}
	}

	const renderFileInput = (): ReactElement => {
		return (
			<input
				ref={inputElement}
				onChange={onChange}
				type="file"
				style={{ display: 'none' }}
			/>
		)
	}

	return {
		initializeUpload,
		renderFileInput,
		openUploadDialog,
		inputElement,
		loading,
		error,
	}
}
