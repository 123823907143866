import { convertBytesToMb, convertByteToKiloByte } from 'app/utils/unit'

export const formatSize = (bytes: number): string => {
	const valInMb = convertBytesToMb(bytes)
	const valInKb = convertByteToKiloByte(bytes)

	if (valInMb < 0.5) {
		if (valInKb < 1) {
			return `${bytes} B`
		}

		return `${Math.ceil(valInKb)} KB`
	}

	return `${valInMb.toFixed(1)} MB`
}
