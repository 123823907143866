import { POSITION_TYPES } from 'app/types/POSITION_TYPES'
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	isLineItemRow,
	hasQuantityDeterminationNodes,
} from 'app/services/tabulator/Callbacks'
import { addClasses, isFulfillmentType } from 'app/utils/tabulator'

export default (row: any): any => {
	const { level } = row.getData()
	const updatedRow = row.getElement()
	const customClasses = []

	if (isLineItemRow(row)) {
		if ('boqNodes' in row.getData()) {
			customClasses.push('row-line-item')
		}

		if ('quantityDeterminationNodes' in row.getData()) {
			customClasses.push('row-line-qd-item')
		}
	} else {
		customClasses.push(`level-${level}-row`)
		if (isFulfillmentType(row)) {
			customClasses.push(`${POSITION_TYPES.FULFILLMENT}-item`)
		} else if (hasQuantityDeterminationNodes(row) && level !== 1) {
			customClasses.push('row-qd-parent')
		}
	}

	return addClasses(updatedRow, customClasses)
}
