import { isArray, isObject } from 'lodash'

interface IterateOptions<T> {
	onNode?: (data: T[] | T, resolver: (data?: T | T[]) => T | T[]) => T[] | T
	onResolveArray?: (data: T[], resolver: (arr: T[]) => T[]) => T[]
	onResolveObject?: (data: T, resolver: (obj: T) => T) => T
}

export const resolveTree = <T>(
	data: T[] | T,
	options: IterateOptions<T>
): T[] | T => {
	const { onNode } = options || {}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const resolveArray = (arr: T[]): any => {
		return arr.map(dataArr => {
			return resolveTree(dataArr, options)
		})
	}

	const resolveObject = <T>(obj: T): T => {
		const dataObj: T = { ...obj }

		Object.keys(obj).forEach((valueU, index, array) => {
			const key = array[index]
			// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
			// @ts-ignore
			const value = obj[key]
			// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
			// @ts-ignore
			dataObj[key] = resolveTree(value, options)
		})

		return dataObj
	}

	const resolver = (newData: T[] | T = data): T[] | T => {
		const { onResolveArray, onResolveObject } = options || {}

		if (isArray(newData)) {
			return onResolveArray
				? onResolveArray(newData, resolveArray)
				: resolveArray(newData)
		}

		if (isObject(newData)) {
			return onResolveObject
				? onResolveObject(newData, resolveObject)
				: resolveObject(newData)
		}

		return newData
	}

	return onNode ? onNode(data, resolver) : resolver()
}
