import { ListItemText } from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'
import { DrawerMenuItemProps } from 'app/config/drawerMenu'
import routes from 'app/routing/routes/routesApp'
import { UrlParams } from 'app/types/UrlParams'
import { resolveRoute } from 'app/utils/route'
import Loading from 'component/Loading/Loading'
import React, { FC, MouseEvent } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import useStyles from './DrawerMenuItemLinkStyles'

const DrawerMenuItemLink: FC<DrawerMenuItemProps> = ({
	configuration,
	parentConfiguration,
	disabled,
	loading,
	active,
}) => {
	const classes = useStyles({ active })
	const { t } = useTranslation()

	const { push } = useHistory()

	const { projectUuid, lotUuid } = useParams<UrlParams>()

	const generateLinkFromConfiguration = (): string => {
		const parentKey = parentConfiguration.key
		const currentKey = configuration.key

		const hasSubSection = parentKey !== currentKey

		let route = routes.subSection

		if (!hasSubSection) {
			route = routes.section
		}

		if (!projectUuid || !lotUuid) {
			route = routes.project
		}

		return resolveRoute(route, {
			projectUuid,
			lotUuid,
			sectionId: parentKey,
			subSectionId: currentKey,
		})
	}

	const handleClick = (e: MouseEvent<HTMLAnchorElement>): void => {
		e.preventDefault()
		push(generateLinkFromConfiguration())
	}

	return (
		<Loading loading={!!loading} size={24}>
			<ListItem
				disableGutters
				classes={{
					root: classes.root,
				}}
				component="div"
			>
				<ListItem
					disabled={disabled}
					button
					component="a"
					href={generateLinkFromConfiguration()}
					onClick={handleClick}
					selected={active}
					classes={{
						button: classes.listItem,
					}}
				>
					<ListItemText
						classes={{
							primary: classes.listItemText,
						}}
					>
						{t(configuration.title)}
					</ListItemText>
				</ListItem>
			</ListItem>
		</Loading>
	)
}

export default DrawerMenuItemLink
