import drawerMenu, { DrawerMenuItem } from 'app/config/drawerMenu'

/** ************************* */

export const getSectionItemById = (
	sectionId: string | undefined,
	subSectionId?: string
): null | DrawerMenuItem => {
	const sectionItem = drawerMenu.find(({ key }) => key === sectionId)
	if (sectionItem && sectionItem.children) {
		return (
			sectionItem.children.find(({ key }) => key === subSectionId) ||
			sectionItem
		)
	}
	return null
}

export const isSectionItemNavigatable = (
	sectionItem: DrawerMenuItem
): boolean => {
	return !!sectionItem.pageContent
}
