import { makeStyles } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'

export default makeStyles(
	{
		root: {
			position: 'relative',
			width: '100%',
		},
		headlineCrop: {
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
		},
		media: {
			height: themeValues().sizes.AttachmentCard.height,
		},
		description: {},
		footerActionsContainer: {},
	},
	{
		name: 'MediaCard',
	}
)
