import {
	getAwardedBidderName,
	getStatusFromLot,
	getLotFromProject,
	getBiddersFromRevision,
	getFirstRevisionFromLot,
} from 'app/utils/apollo'
import queries from 'domain/award/awarding/apollo/queries'
import React, { FC, useState } from 'react'
import { useParams } from 'react-router-dom'

import ConfirmationDialog from 'component/ConfirmationDialog/ConfirmationDialog'
import PageContentBody from 'component/PageContentBody/PageContentBody'
import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import { Bidder } from 'app/types'
import { useCustomSnackbar } from 'app/services/snackbar'
import AwardBidderButton from 'domain/award/awarding/components/body/AwardBidderButton/AwardBidderButton'
import mutations from 'domain/award/awarding/apollo/mutations'

import { useTranslation } from 'react-i18next'
import { LOT_STATUS } from 'domain/lot/interfaces/LOT_STATUS'
import { UrlParams } from 'app/types/UrlParams'
import { AwardingPageContentBodyProps } from 'domain/award/awarding/components/base/AwardingPageContentBody/AwardingPageContentBodyProps'
import CloseTenderingNotice from 'domain/award/awarding/components/body/status/CloseTenderingNotice/CloseTenderingNotice'
import PageContentHeading1 from 'component/PageContentHeading1/PageContentHeading1'
import PageContentParagraph from 'component/PageContentParagraph/PageContentParagraph'
import { Notice } from 'component'
import Validate from 'component/Validate/Validate'
import { CombinationOperator } from 'app/types/CombinationOperator'

const AwardingPageContentBody: FC<AwardingPageContentBodyProps> = ({
	project,
}) => {
	const { lotUuid } = useParams<UrlParams>()
	const { t } = useTranslation()
	const { sendSuccessMessage } = useCustomSnackbar()
	const [selectedBidder, setSelectedBidder] = useState<Bidder | null>(null)
	const [isDialogOpen, setDialogOpen] = useState<boolean>(false)
	const [createAward] = useGraphQLMutation(mutations.createAward, {
		onCompleted: () =>
			sendSuccessMessage(t(`domain:award.createAward.success`)),
	})
	const lot = getLotFromProject(project)
	const revision = getFirstRevisionFromLot(lot)
	const bidders = getBiddersFromRevision(revision)
	const lotStatus = getStatusFromLot(lot)
	const awardedBidderName = getAwardedBidderName(bidders)

	if (!revision) {
		return (
			<PageContentBody>
				<PageContentParagraph>
					{t(`common:validatorViolations.missingRevision`)}
				</PageContentParagraph>
			</PageContentBody>
		)
	}

	const onClickAwardButton = (bidder: Bidder): void => {
		setDialogOpen(true)
		setSelectedBidder(bidder)
	}

	const onClickCloseDialog = (): void => {
		setDialogOpen(false)
		setSelectedBidder(null)
	}

	const onConfirmSelection = (): void => {
		if (revision && project && lot && selectedBidder) {
			createAward({
				variables: {
					projectUuid: project.uuid,
					lotUuid: lot.uuid,
					bidderUuid: selectedBidder.uuid,
				},
				refetchQueries: [
					{
						query: queries.ProjectWithAwardBidders,
						variables: {
							projectUuid: project.uuid,
							lotUuid: lot.uuid,
						},
					},
				],
			})
			setDialogOpen(false)
		}
	}

	return (
		<PageContentBody key={lotUuid}>
			<ConfirmationDialog
				open={isDialogOpen}
				title={t('domain:award.createAward.confirmation.title')}
				contentText={t('domain:award.createAward.confirmation.content', {
					bidder: (selectedBidder && selectedBidder.name) || '',
				})}
				onAccept={onConfirmSelection}
				onDecline={onClickCloseDialog}
			/>
			{lotStatus === LOT_STATUS.TENDERING && (
				<>
					<PageContentHeading1>
						{t(`domain:award.notAllowed.linkTitle`)}
					</PageContentHeading1>
					<CloseTenderingNotice />
				</>
			)}
			{lotStatus === LOT_STATUS.AWARDING && (
				<>
					{bidders &&
						bidders.map(bidder => (
							<AwardBidderButton
								key={bidder.uuid}
								bidder={bidder}
								onClickAwardButton={onClickAwardButton}
							/>
						))}
				</>
			)}
			<Validate
				combinationOperator={CombinationOperator.OR}
				validCombinations={[
					{ lotStatus: LOT_STATUS.AWARDED },
					{ lotStatus: LOT_STATUS.CONFIRMED },
				]}
			>
				<Notice
					message={t('domain:award.awarded.notice.message', {
						awardedBidderName,
					})}
				/>
			</Validate>
		</PageContentBody>
	)
}

export default AwardingPageContentBody
