import { LAST_VISITED_URL_PARAMS } from 'app/constants'
import { useLocalStorage } from 'app/hooks/index'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, useHistory } from 'react-router-dom'
import routes from 'app/routing/routes/routesApp'
import AvailableRouteParams from 'app/routing/routes/AvailableRouteParams'
import PrimaryButton from 'component/PrimaryButton/PrimaryButton'

import useStyles from 'template/TemplatePageNotFound/TemplatePageNotFoundStyles'
import { TemplatePageNotFoundProps } from 'template/TemplatePageNotFound/TemplatePageNotFoundProps'
import PageContentHeading1 from 'component/PageContentHeading1/PageContentHeading1'
import PageContentParagraph from 'component/PageContentParagraph/PageContentParagraph'

const TemplatePageNotFound: FC<TemplatePageNotFoundProps> = ({ ...rest }) => {
	const classes = useStyles()

	const { t } = useTranslation()
	const { push } = useHistory()
	const [, setValue] = useLocalStorage<AvailableRouteParams | null>(
		LAST_VISITED_URL_PARAMS,
		null
	)

	useEffect(() => {
		setValue(null)
	}, [])

	return (
		<Route
			{...rest}
			render={() => {
				return (
					<div className={classes.root}>
						<div>
							<PageContentHeading1 className={classes.title}>
								{t('domain:template.404.headline')}
							</PageContentHeading1>
							<PageContentParagraph
								color="textPrimary"
								className={classes.subTitle}
							>
								{t('domain:template.404.description')}
							</PageContentParagraph>
							<PrimaryButton onClick={() => push(routes.home)}>
								{t('domain:template.404.back.label')}
							</PrimaryButton>
						</div>
					</div>
				)
			}}
		/>
	)
}

export default TemplatePageNotFound
