import { VariantType, useSnackbar } from 'notistack'
import { SNACKBAR_TIMEOUT } from 'app/constants'

export interface UseCustomSnackbar {
	sendSuccessMessage: (message: string) => void
	sendErrorMessage: (message: string) => void
	sendWarningMessage: (message: string) => void
	sendInfoMessage: (message: string) => void
}

export default (): UseCustomSnackbar => {
	const { enqueueSnackbar } = useSnackbar()

	const sendMessage = (message: string, variant: VariantType): void => {
		enqueueSnackbar(message, {
			variant,
			autoHideDuration: SNACKBAR_TIMEOUT,
		})
	}

	const sendSuccessMessage = (message: string): void => {
		sendMessage(message, 'success')
	}

	const sendErrorMessage = (message: string): void => {
		sendMessage(message, 'error')
	}

	const sendWarningMessage = (message: string): void => {
		sendMessage(message, 'warning')
	}

	const sendInfoMessage = (message: string): void => {
		sendMessage(message, 'info')
	}

	return {
		sendSuccessMessage,
		sendErrorMessage,
		sendWarningMessage,
		sendInfoMessage,
	}
}
