import { Theme } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

interface StyleProps {
	isEmpty: boolean
}

export default makeStyles<Theme, StyleProps>((theme: Theme) => ({
	root: ({ isEmpty }) => ({
		marginTop: isEmpty ? 0 : theme.spacing(4),
	}),
	buttonSubmit: {
		marginTop: theme.spacing(4),
	},
}))
