import { Typography } from '@material-ui/core'
import clsx from 'clsx'
import AttributeInfoItemHeadline from 'domain/general/boq/components/sidesheet/attributeInfos/AttributeInfoItemHeadline/AttributeInfoItemHeadline'
import { TypeInfoItemProps } from 'domain/general/boq/components/sidesheet/attributeInfos/TypeInfoItem/TypeInfoItemProps'
import React, { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

const TypeInfoItem: FC<TypeInfoItemProps> = ({
	type,
	subType,
	classes: parentClasses = {},
}) => {
	const { t } = useTranslation()

	const getTypeToRender = (): string => {
		if (subType) {
			return t(`domain:boq.lineItem.types.${subType}`)
		}

		return t(`domain:boq.lineItem.types.${type}`)
	}

	const renderHeadline = (): ReactElement => {
		return (
			<AttributeInfoItemHeadline
				classes={{ root: parentClasses.headline }}
				text={t(`domain:boq.node.type`)}
			/>
		)
	}

	const renderContent = (): ReactElement => {
		return (
			<Typography
				classes={{
					root: clsx(parentClasses.content),
				}}
				dangerouslySetInnerHTML={{
					__html: getTypeToRender(),
				}}
			/>
		)
	}

	return (
		<>
			{renderHeadline()}
			{renderContent()}
		</>
	)
}

export default TypeInfoItem
