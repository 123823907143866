import { makeStyles, Theme } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'

export default makeStyles((theme: Theme) => ({
	icon: {
		fontSize: 100,
		color: themeValues().colors.success,
	},
	headline: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	checkoutCardContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
	},
}))
