import { gql } from 'apollo-boost'

const createQuantityDeterminationItemBidder = gql`
	mutation createQuantityDeterminationItemBidder(
		$projectUuid: String!
		$lotUuid: String!
		$qdUuid: String!
		$lin: String!
		$name: String!
		$formula: String
		$qty: Float
	) {
		project(uuid: $projectUuid) {
			lot(uuid: $lotUuid) {
				quantityDetermination(uuid: $qdUuid) {
					createQuantityDeterminationItem(
						lin: $lin
						name: $name
						formula: $formula
						qty: $qty
					) {
						label
					}
				}
			}
		}
	}
`

export default {
	createQuantityDeterminationItemBidder,
}
