import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { BreadCrumbsProps } from 'component/BreadCrumbs/BreadCrumbsProps'
import React, { FC } from 'react'

const BreadCrumbs: FC<BreadCrumbsProps> = ({ children }) => {
	return (
		<Breadcrumbs
			aria-label="breadcrumb"
			separator={<ArrowForwardIosIcon fontSize="small" />}
		>
			{children}
		</Breadcrumbs>
	)
}

export default BreadCrumbs
