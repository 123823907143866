import { gql } from 'apollo-boost'

const DeleteBoqAnnotation = gql`
	mutation DeleteBoqAnnotation(
		$projectUuid: String!
		$lotUuid: String!
		$revisionUuid: String!
		$annotationUuid: String!
	) {
		project(uuid: $projectUuid) {
			lot(uuid: $lotUuid) {
				revision(uuid: $revisionUuid) {
					deleteBoqAnnotation(uuid: $annotationUuid)
				}
			}
		}
	}
`

const createBoqAnnotation = gql`
	mutation createBoqAnnotation(
		$projectUuid: String!
		$lotUuid: String!
		$revisionUuid: String!
		$lin: String!
		$referenceLabel: String!
		$shortText: String!
		$longText: String
		$unit: String
		$quantity: Float
		$unitPrice: Float
		$comment: String
	) {
		project(uuid: $projectUuid) {
			lot(uuid: $lotUuid) {
				revision(uuid: $revisionUuid) {
					createBoqAnnotation(
						lin: $lin
						referenceLabel: $referenceLabel
						shortText: $shortText
						longText: $longText
						unit: $unit
						quantity: $quantity
						unitPrice: $unitPrice
						comment: $comment
					) {
						uuid
					}
				}
			}
		}
	}
`

const updateBoqAnnotation = gql`
	mutation updateBoqAnnotation(
		$projectUuid: String!
		$lotUuid: String!
		$revisionUuid: String!
		$annotationUuid: String!
		$shortText: String!
		$longText: String
		$unit: String
		$quantity: Float
		$unitPrice: Float
		$comment: String
	) {
		project(uuid: $projectUuid) {
			lot(uuid: $lotUuid) {
				revision(uuid: $revisionUuid) {
					updateBoqAnnotation(
						uuid: $annotationUuid
						shortText: $shortText
						longText: $longText
						unit: $unit
						quantity: $quantity
						unitPrice: $unitPrice
						comment: $comment
					) {
						uuid
						shortText
						longText
						unit {
							code
							label
						}
						quantity
						unitPrice
						comment
					}
				}
			}
		}
	}
`

export default { createBoqAnnotation, updateBoqAnnotation, DeleteBoqAnnotation }
