import React, { FC, useCallback } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { get } from 'lodash'

import { useLocalStorage } from 'app/hooks'
import { AuthenticationTokens } from 'app/types'
import { AUTH_TOKEN_IDENTIFIER } from 'app/constants'
import { setAuthToken } from 'app/services/axios'

export type PrivateRouteProps = RouteProps

const PrivateRoute: FC<PrivateRouteProps> = ({
	component,
	render,
	...rest
}) => {
	const [authenticationTokens] = useLocalStorage<AuthenticationTokens | null>(
		AUTH_TOKEN_IDENTIFIER,
		null
	)

	const accessToken = get(authenticationTokens, ['access_token'], null)

	setAuthToken(accessToken)

	const fallbackRender = useCallback(() => {
		return <Redirect to="/" />
	}, [])

	return (
		<Route
			{...rest}
			component={accessToken ? component : undefined}
			render={accessToken ? render : fallbackRender}
		/>
	)
}

export default PrivateRoute
