import { gql } from 'apollo-boost'

const ProjectListWithSelectInformation = gql`
	query ProjectListWithSelectInformation {
		projects {
			data {
				uuid
				name
				__typename
				lots {
					data {
						uuid
						name
					}
				}
			}
		}
	}
`

const ProjectListForRedirect = gql`
	query ProjectListForRedirect {
		projects {
			data {
				uuid
				name
				lots {
					data {
						uuid
					}
				}
			}
		}
	}
`

export default {
	ProjectListWithSelectInformation,
	ProjectListForRedirect,
}
