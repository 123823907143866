import { makeStyles, Theme } from '@material-ui/core'

const UpdateProjectFormStyles = makeStyles((theme: Theme) => ({
	root: {},
	introduction: {
		marginBottom: theme.spacing(4),
	},
	buttonRoot: {
		marginTop: theme.spacing(2),
	},
}))

export default UpdateProjectFormStyles
