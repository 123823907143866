import { CHECKOUTDATA_KEYS } from 'app/config/checkout'
import React, { FC, ReactElement, useEffect } from 'react'
import { CheckoutStepProps } from 'domain/checkout/interfaces/CheckoutStepProps'
import PasswordCheckValidator from 'component/PasswordCheckValidator/PasswordCheckValidator'
import { get } from 'lodash'
import mutations from 'domain/checkout/apollo/mutations'
import { useMutation } from '@apollo/react-hooks'
import RenderErrorMessage from 'component/RenderErrorMessage/RenderErrorMessage'
import { useTranslation } from 'react-i18next'
import { useAuthentication } from 'app/hooks'
import CheckoutCard from '../CheckoutCard/CheckoutCard'

const CardPasswordEnter: FC<CheckoutStepProps> = ({
	handleInputChange,
	stepId,
	checkoutData,
	goToNextStep,
	classes,
	createFormDataFromObject,
}) => {
	const token = get(checkoutData, CHECKOUTDATA_KEYS.TOKEN, '')
	const password = get(checkoutData, 'password', '')
	const email = get(checkoutData, CHECKOUTDATA_KEYS.EMAIL, '')

	const { t } = useTranslation()

	const { login, accessToken } = useAuthentication()

	useEffect(() => {
		const isAuthenticated = get(checkoutData, 'isAuthenticated', false)

		if (isAuthenticated) {
			if (goToNextStep) {
				goToNextStep()
			}
		}
	}, [checkoutData])

	useEffect(() => {
		if (accessToken && createFormDataFromObject) {
			createFormDataFromObject({ ...checkoutData, isAuthenticated: true })
		}
	}, [accessToken])

	const [updateAccount, { loading, error }] = useMutation(
		mutations.updateAccount,
		{
			onCompleted: () => {
				if (email && password) {
					login(email, password)
				}
			},
		}
	)

	const onSubmit = (): void => {
		updateAccount({
			variables: {
				token,
				password,
			},
		})
	}

	const renderPasswordValidator = (): ReactElement | null => {
		if (checkoutData && handleInputChange) {
			return (
				<PasswordCheckValidator
					formData={checkoutData}
					onInputChange={handleInputChange}
				/>
			)
		}
		return null
	}

	const disabled = (): boolean => !password

	return (
		<CheckoutCard
			classes={classes}
			loading={loading}
			headline={t('common:inputs.password.label')}
			stepId={stepId}
			onSubmit={onSubmit}
			disabled={disabled()}
		>
			{renderPasswordValidator()}
			<RenderErrorMessage error={error} />
		</CheckoutCard>
	)
}

export default CardPasswordEnter
