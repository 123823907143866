import { addSupTag } from 'app/utils/tabulator'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AnnotationUnitFormatter = (cell: any): string => {
	const value = cell.getValue()
	if (value) {
		const { code } = value
		return addSupTag(code)
	}
	return ''
}

export default AnnotationUnitFormatter
