import { makeStyles, Theme } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'

export default makeStyles((theme: Theme) => ({
	root: {
		height: themeValues().sizes.TokenDigitValidator.height,
		border: `1px solid ${theme.palette.divider}`,
	},
	input: {
		fontSize: themeValues().sizes.TokenDigitValidator.fontSize,
		textAlign: 'center',
	},
}))
