import { useRef, useEffect } from 'react'

export default (callback: () => void, delay: number): void => {
	const savedCallback = useRef<() => void>()

	useEffect(() => {
		savedCallback.current = callback
	})

	useEffect(() => {
		const tick = (): void => {
			if (savedCallback.current) {
				savedCallback.current()
			}
		}

		const id = setInterval(tick, delay)
		return () => clearInterval(id)
	}, [delay])
}
