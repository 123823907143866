import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

interface StyleProps {
	selected: boolean
}

export default makeStyles<Theme, StyleProps>(theme => ({
	root: {
		visibility: 'visible',
	},
	labelContainer: {
		display: 'flex',
		alignItems: 'center',
		padding: `0 ${theme.spacing(6)}px 0 ${theme.spacing(6)}px`,
	},
	icon: {
		paddingLeft: '5px',
	},
	label: {
		paddingLeft: theme.spacing(1),
		paddingRight: '5px',
	},
	menuIcon: {
		visibility: 'hidden',
	},
	lotInput: {
		padding: `0 ${theme.spacing(3)}px 0 ${theme.spacing(3)}px`,
	},
}))
