import useLocalStorage from 'app/hooks/useLocalStorage'
import { FormInputData } from 'app/types/FormInputData'
import { UseForm } from 'app/types/UseForm'
import { UseFormConfig } from 'app/types/UseFormConfig'
import { ChangeEvent, useState, useEffect } from 'react'
import { CHECKOUT_DATA } from 'app/constants'
import { omit } from 'lodash'

export default (config?: UseFormConfig): UseForm => {
	const { defaultValues = {}, persist = false, dontPersist = [] } = config || {}
	const [formData, setFormData] = useState(defaultValues)

	const getValueFromChangeEvent = (
		e: ChangeEvent<HTMLInputElement>
	): string | boolean | null => {
		if (e.target.type === 'checkbox') {
			return e.target.checked
		}

		if (e.target.value) {
			return e.target.value
		}

		return null
	}

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
		setFormData({
			...formData,
			[e.target.name]: getValueFromChangeEvent(e),
		})
	}

	const reset = (): void => {
		setFormData(
			Object.keys(formData).reduce((prev, key) => {
				return { ...prev, [key]: '' }
			}, {})
		)
	}

	const createFormDataFromObject = (data: FormInputData): void => {
		setFormData(
			Object.keys(data).reduce((prev, key) => {
				return { ...prev, [key]: data[key] }
			}, {})
		)
	}

	const [storedData, setStoredData] = useLocalStorage<FormInputData | null>(
		CHECKOUT_DATA,
		null
	)

	useEffect(() => {
		if (Object.keys(formData).length > 0 && persist) {
			const notSensitiveData = omit(formData, dontPersist)
			setStoredData(notSensitiveData)
		}
	}, [formData])

	useEffect(() => {
		if (Object.keys(formData).length === 0) {
			createFormDataFromObject({
				...storedData,
			})
		}
	}, [])

	return {
		handleInputChange,
		formData,
		reset,
		createFormDataFromObject,
	}
}
