/* eslint-disable no-restricted-syntax */
import {
	getLotFromProject,
	getQuantityDeterminationsNodes,
	getQuantityDeterminationFromLot,
	getProjectFromResponse,
	getRoleFromProject,
} from 'app/utils/apollo'
import { walkAdd, getParentFromCell, getRowFromCell } from 'app/utils/tabulator'
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState, useMemo } from 'react'
import queries from 'domain/billing/quantity/apollo/queries'
import Table from 'component/Table/Table'
import { useParams } from 'react-router-dom'
import { UrlParams } from 'app/types/UrlParams'
import useOptions from 'domain/billing/quantity/tabulator/useOptions'
import { v4 as uuidv4 } from 'uuid'
import useUrlSearchParams from 'app/hooks/useUrlSearchParams'

import {
	QuantityDeterminationNode,
	QUANTITY_DETERMINATION_NODE_STATUS,
} from 'app/config/quantityDetermination'
import { POSITION_TYPES } from 'app/types/POSITION_TYPES'
import useColumns from 'domain/billing/quantity/detail/bidder/tabulator/useColumns'
import ConfirmationDialog from 'component/ConfirmationDialog/ConfirmationDialog'
import { useTranslation } from 'react-i18next'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import mutations from 'domain/billing/quantity/apollo/mutations'
import mutationsBidder from 'domain/billing/quantity/detail/bidder/apollo/mutations/mutation'

import useStyles from './BidderQuantityDeterminationTableStyles'

const BidderQuantityDeterminationTable: FC = () => {
	const { projectUuid, lotUuid } = useParams<UrlParams>()

	const [tableData, setTableData] = useState<QuantityDeterminationNode[]>([])
	const [open, setOpen] = useState(false)
	const [delUuid, setDelUuid] = useState('')

	const { t } = useTranslation()

	const { options } = useOptions()
	const query = useUrlSearchParams()
	const queryUuid = query.get('qduuid')

	const classes = useStyles()

	const [lotWithQuantityDetermination, { data, loading }] = useGraphQLLazyQuery(
		queries.LotWithQuantityDetermination
	)

	const [createQuantityDeterminationItemBidder] = useGraphQLMutation(
		mutationsBidder.createQuantityDeterminationItemBidder
	)

	const [updateQuantityDeterminationItem] = useGraphQLMutation(
		mutations.updateQuantityDeterminationItem
	)

	const [acceptQuantityDeterminationItem] = useGraphQLMutation(
		mutations.acceptQuantityDeterminationItem
	)

	const [deleteQuantityDeterminationItem] = useGraphQLMutation(
		mutations.deleteQuantityDeterminationItem
	)

	const [declineQuantityDeterminationItem] = useGraphQLMutation(
		mutations.declineQuantityDeterminationItem
	)

	useEffect(() => {
		lotWithQuantityDetermination({
			variables: {
				projectUuid,
				lotUuid,
				qdUuid: queryUuid,
			},
		})
	}, [projectUuid, lotUuid, queryUuid, data])

	const project = getProjectFromResponse(data)
	const role = getRoleFromProject(project)
	const lot = getLotFromProject(project)
	const quantityDetermination = getQuantityDeterminationFromLot(lot)
	const quantityDeterminationNodes = getQuantityDeterminationsNodes(
		quantityDetermination
	)

	useEffect(() => {
		if (quantityDeterminationNodes) {
			setTableData(quantityDeterminationNodes)
		}
	}, [quantityDeterminationNodes])

	const getLic = (item: QuantityDeterminationNode): string => {
		if (item.quantityDeterminationNodes) {
			const lic = item.quantityDeterminationNodes.length + 1
			return lic.toString()
		}
		return '1'
	}

	const createNewNode = (item: any): QuantityDeterminationNode => {
		const newQuantityDeterminationNode = {
			type: POSITION_TYPES.QTY_DETERM,
			status: QUANTITY_DETERMINATION_NODE_STATUS.TEMPORARY,
			uuid: uuidv4(),
			lic: getLic(item),
			lin: null,
			shortText: null,
			unit: null,
			formula: null,
			qty: null,
			qu: item.qu,
		}
		if (!item.quantityDeterminationNodes) {
			// eslint-disable-next-line no-param-reassign
			item.quantityDeterminationNodes = []
		}
		item.quantityDeterminationNodes.push(newQuantityDeterminationNode)
		return item
	}

	const walkDelete = (qDN: any, uuid: string): QuantityDeterminationNode[] => {
		const newQuantityDeterminationsNodes = []
		for (const item of qDN) {
			if (item.quantityDeterminationNodes) {
				const delIndex = item.quantityDeterminationNodes.findIndex(
					(indexItem: any) => {
						return indexItem.uuid ? indexItem.uuid === delUuid : null
					}
				)
				if (delIndex !== -1) {
					if (
						item.quantityDeterminationNodes[delIndex].type ===
							POSITION_TYPES.QTY_DETERM &&
						item.quantityDeterminationNodes[delIndex].status !==
							QUANTITY_DETERMINATION_NODE_STATUS.TEMPORARY
					) {
						deleteQuantityDeterminationItem({
							variables: {
								projectUuid,
								lotUuid,
								qdUuid: queryUuid,
								qdNIUuid: delUuid,
							},
							refetchQueries: [
								{
									query: queries.LotWithQuantityDetermination,
									variables: {
										projectUuid,
										lotUuid,
										qdUuid: queryUuid,
									},
								},
							],
						})
					}

					if (
						item.quantityDeterminationNodes[delIndex].type ===
							POSITION_TYPES.SUGGESTION &&
						item.quantityDeterminationNodes[delIndex].status !==
							QUANTITY_DETERMINATION_NODE_STATUS.TEMPORARY
					) {
						declineQuantityDeterminationItem({
							variables: {
								projectUuid,
								lotUuid,
								qdUuid: queryUuid,
								qdIUuid: delUuid,
							},
							refetchQueries: [
								{
									query: queries.LotWithQuantityDetermination,
									variables: {
										projectUuid,
										lotUuid,
										qdUuid: queryUuid,
									},
								},
							],
						})
					}
					item.quantityDeterminationNodes.splice(delIndex, 1)
					item.quantityDeterminationNodes.forEach(
						(indexItem: any, index: any = 1) => {
							// eslint-disable-next-line no-param-reassign
							indexItem.lic = index + 1
						}
					)
				} else
					item.quantityDeterminationNodes = walkDelete(
						item.quantityDeterminationNodes,
						uuid
					)
			}
			newQuantityDeterminationsNodes.push(item)
		}
		setOpen(!open)
		return newQuantityDeterminationsNodes
	}

	const modifyRow = (cell: any): any => {
		const row = getRowFromCell(cell)
		const quantityDeterminationNode = row.getData() as QuantityDeterminationNode
		const { type, uuid } = quantityDeterminationNode
		if (
			type === POSITION_TYPES.QTY_DETERM ||
			type === POSITION_TYPES.SUGGESTION
		) {
			setDelUuid(uuid)
			setOpen(!open)
			return delUuid
		}
		return setTableData(
			walkAdd(tableData, quantityDeterminationNode, createNewNode)
		)
	}

	const handleEdited = (cell: any): void => {
		const { label, formula, qty, status, uuid } = cell.getRow().getData()
		const { lin: parentLin } = getParentFromCell(cell).getData()
		if (label && (formula || qty) && parentLin) {
			if (status === QUANTITY_DETERMINATION_NODE_STATUS.TEMPORARY) {
				createQuantityDeterminationItemBidder({
					variables: {
						projectUuid,
						lotUuid,
						qdUuid: queryUuid,
						lin: parentLin,
						name: label,
						formula,
						qty,
					},
					refetchQueries: [
						{
							query: queries.LotWithQuantityDetermination,
							variables: {
								projectUuid,
								lotUuid,
								qdUuid: queryUuid,
							},
						},
					],
				})
			} else {
				updateQuantityDeterminationItem({
					variables: {
						uuid,
						projectUuid,
						lotUuid,
						qdUuid: queryUuid,
						name: label,
						formula,
						qty,
					},
					refetchQueries: [
						{
							query: queries.LotWithQuantityDetermination,
							variables: {
								projectUuid,
								lotUuid,
								qdUuid: queryUuid,
							},
						},
					],
				})
			}
		}
	}

	const handleAcceptSuggestion = (qdIUuid: string): void => {
		if (projectUuid && lotUuid && queryUuid) {
			acceptQuantityDeterminationItem({
				variables: {
					projectUuid,
					lotUuid,
					qdUuid: queryUuid,
					qdIUuid,
				},
				refetchQueries: [
					{
						query: queries.LotWithQuantityDetermination,
						variables: {
							projectUuid,
							lotUuid,
							qdUuid: queryUuid,
						},
					},
				],
			})
		}
	}

	const columns = useColumns(
		modifyRow,
		quantityDetermination,
		handleEdited,
		role,
		handleAcceptSuggestion
	)

	return useMemo(
		() => (
			<>
				<Table
					className={classes.table}
					options={options}
					loading={loading}
					data={tableData || []}
					columns={columns}
				/>
				<ConfirmationDialog
					contentText={t(
						`domain:billing.bidderQuantityDeterminationTable.edit.deleteRow.confirmationDialog.infoText`
					)}
					title={t(
						`domain:billing.bidderQuantityDeterminationTable.edit.deleteRow.confirmationDialog.title`
					)}
					open={open}
					onAccept={() => setTableData(walkDelete(tableData, delUuid))}
					onAcceptLabel={t(
						`domain:billing.bidderQuantityDeterminationTable.edit.deleteRow.confirmationDialog.button.label.accept`
					)}
					onDecline={() => setOpen(!open)}
					onDeclineLabel={t(
						`domain:billing.bidderQuantityDeterminationTable.edit.deleteRow.confirmationDialog.button.label.decline`
					)}
				/>
			</>
		),
		[tableData, open, columns]
	)
}

export default BidderQuantityDeterminationTable
