import { ById } from 'app/types'

export enum PRICING_PLANS {
	DEMO = 'demo',
	FREE = 'free',
	PREMIUM = 'premium',
}

export enum PRICING_PLANS_SCOPE {
	DEMO = 'demo_pricing_plan',
	FREE = 'free_pricing_plan',
	PREMIUM = 'premium_pricing_plan',
}

export const PRICING_PLANS_SCOPE_ORDER = [
	PRICING_PLANS.DEMO,
	PRICING_PLANS.FREE,
]

export const MAP_PRICING_PLAN_TO_SCOPE: ById<PRICING_PLANS_SCOPE> = {
	[PRICING_PLANS.PREMIUM]: PRICING_PLANS_SCOPE.PREMIUM,
	[PRICING_PLANS.FREE]: PRICING_PLANS_SCOPE.FREE,
	[PRICING_PLANS.DEMO]: PRICING_PLANS_SCOPE.DEMO,
}
