import React, { FC } from 'react'
import { Typography } from '@material-ui/core'
import { PageContentHeading1Props } from './PageContentHeading1Props'

const PageContentHeading1: FC<PageContentHeading1Props> = ({
	children,
	className,
}) => {
	return (
		<Typography className={className} variant="h4" component="h1">
			{children}
		</Typography>
	)
}

export default PageContentHeading1
