import { gql } from 'apollo-boost'

const createTenderingEndDate = gql`
	mutation createTenderingEndDate(
		$projectUuid: String!
		$lotUuid: String!
		$tenderingEndDate: String
	) {
		project(uuid: $projectUuid) {
			updateLot(uuid: $lotUuid, tenderingEndDate: $tenderingEndDate) {
				uuid
				tenderingEndDate
			}
		}
	}
`

const createContact = gql`
	mutation createContact(
		$firstName: String
		$lastName: String!
		$email: String!
		$company: String
	) {
		createContact(
			firstName: $firstName
			lastName: $lastName
			email: $email
			company: $company
		) {
			uuid
			firstName
			lastName
			email
			company
			__typename
		}
	}
`

const createCollaboration = gql`
	mutation createCollaboration(
		$projectUuid: String!
		$contactUuid: String!
		$lotUuid: String!
	) {
		project(uuid: $projectUuid) {
			lot(uuid: $lotUuid) {
				createCollaboration(contactUuid: $contactUuid) {
					uuid
					hasAccess
					projectMemberUuid
				}
				uuid
				status
				__typename
			}
		}
	}
`

const deleteCollaboration = gql`
	mutation deleteCollaboration(
		$projectUuid: String!
		$lotUuid: String!
		$contactUuid: String!
		$projectMemberUuid: String!
	) {
		project(uuid: $projectUuid) {
			lot(uuid: $lotUuid) {
				uuid
				status
				__typename
				deleteCollaboration(
					projectMemberUuid: $projectMemberUuid
					contactUuid: $contactUuid
				) {
					uuid
					hasAccess
					projectMemberUuid
				}
			}
		}
	}
`

const createAwarding = gql`
	mutation createAwarding($projectUuid: String!, $lotUuid: String!) {
		project(uuid: $projectUuid) {
			lot(uuid: $lotUuid) {
				uuid
				status
				__typename
				createAwarding {
					uuid
					status
					__typename
				}
			}
		}
	}
`

const createContactTag = gql`
	mutation createContactTag($contactUuid: String!, $label: String!) {
		contact(uuid: $contactUuid) {
			createTag(label: $label) {
				uuid
				code
				label
			}
		}
	}
`

const deleteContactTag = gql`
	mutation deleteContactTag($contactUuid: String!, $tagUuid: String!) {
		contact(uuid: $contactUuid) {
			deleteTag(uuid: $tagUuid) {
				uuid
				code
				label
			}
		}
	}
`

export default {
	createContact,
	createCollaboration,
	deleteCollaboration,
	createAwarding,
	createContactTag,
	deleteContactTag,
	createTenderingEndDate,
}
