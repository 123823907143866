/* eslint-disable @typescript-eslint/no-explicit-any */

import AlternativeFormatter from 'app/services/tabulator/formatters/up/AlternativeFormatter'
import BaseFormatter from 'app/services/tabulator/formatters/up/BaseFormatter'
import { isLineItem } from 'app/services/tabulator/Callbacks'
import BlanketFormatter from 'app/services/tabulator/formatters/up/BlanketFormatter'
import NormalFormatter from 'app/services/tabulator/formatters/up/NormalFormatter'
import OptionalFormatter from 'app/services/tabulator/formatters/up/OptionalFormatter'
import RemarkFormatter from 'app/services/tabulator/formatters/up/RemarkFormatter'
import WageFormatter from 'app/services/tabulator/formatters/up/WageFormatter'
import { BoqNode } from 'app/types/graphql/BoqNode'
import { POSITION_TYPES } from 'app/types/POSITION_TYPES'

export default (cell: any): string | number => {
	if (!cell || !isLineItem(cell)) {
		return ''
	}

	const boqNode = cell.getData() as BoqNode
	const { type, up } = boqNode

	switch (type) {
		case POSITION_TYPES.BLANKET:
			return new BlanketFormatter(cell).format()
		case POSITION_TYPES.WAGE:
			return new WageFormatter(cell).format()
		case POSITION_TYPES.NORMAL:
			return new NormalFormatter(cell).format()
		case POSITION_TYPES.BASE:
			return new BaseFormatter(cell).format()
		case POSITION_TYPES.REMARK:
			return new RemarkFormatter(cell).format()
		case POSITION_TYPES.OPTIONAL:
			return new OptionalFormatter(cell).format()
		case POSITION_TYPES.ALTERNATIVE:
			return new AlternativeFormatter(cell).format()
		default:
			return up || ''
	}
}
