import { gql } from 'apollo-boost'

const createConfirmation = gql`
	mutation createConfirmation($projectUuid: String!, $lotUuid: String!) {
		project(uuid: $projectUuid) {
			lot(uuid: $lotUuid) {
				createConfirmation {
					uuid
					status
				}
			}
		}
	}
`

const updateQuantityDeterminationItem = gql`
	mutation updateQuantityDeterminationItem(
		$projectUuid: String!
		$lotUuid: String!
		$qdUuid: String!
		$uuid: String!
		$name: String!
		$formula: String
		$qty: Float
	) {
		project(uuid: $projectUuid) {
			lot(uuid: $lotUuid) {
				quantityDetermination(uuid: $qdUuid) {
					updateQuantityDeterminationItem(
						uuid: $uuid
						name: $name
						formula: $formula
						qty: $qty
					) {
						label
					}
				}
			}
		}
	}
`

const deleteQuantityDeterminationItem = gql`
	mutation deleteQuantityDeterminationItem(
		$projectUuid: String!
		$lotUuid: String!
		$qdUuid: String!
		$qdNIUuid: String!
	) {
		project(uuid: $projectUuid) {
			lot(uuid: $lotUuid) {
				quantityDetermination(uuid: $qdUuid) {
					deleteQuantityDeterminationItem(uuid: $qdNIUuid) {
						success
					}
				}
			}
		}
	}
`

const createSubmission = gql`
	mutation createSubmission(
		$projectUuid: String!
		$lotUuid: String!
		$qdUuid: String!
	) {
		project(uuid: $projectUuid) {
			lot(uuid: $lotUuid) {
				quantityDetermination(uuid: $qdUuid) {
					createSubmission {
						status
						uuid
					}
				}
			}
		}
	}
`

const acceptQuantityDeterminationItem = gql`
	mutation acceptQuantityDeterminationItem(
		$projectUuid: String!
		$lotUuid: String!
		$qdUuid: String!
		$qdIUuid: String!
	) {
		project(uuid: $projectUuid) {
			lot(uuid: $lotUuid) {
				quantityDetermination(uuid: $qdUuid) {
					acceptQuantityDeterminationItem(uuid: $qdIUuid) {
						success
					}
				}
			}
		}
	}
`

const declineQuantityDeterminationItem = gql`
	mutation declineQuantityDeterminationItem(
		$projectUuid: String!
		$lotUuid: String!
		$qdUuid: String!
		$qdIUuid: String!
	) {
		project(uuid: $projectUuid) {
			lot(uuid: $lotUuid) {
				quantityDetermination(uuid: $qdUuid) {
					declineQuantityDeterminationItem(uuid: $qdIUuid) {
						success
					}
				}
			}
		}
	}
`

const createReview = gql`
	mutation createReview(
		$projectUuid: String!
		$lotUuid: String!
		$qdUuid: String!
	) {
		project(uuid: $projectUuid) {
			lot(uuid: $lotUuid) {
				quantityDetermination(uuid: $qdUuid) {
					createReview {
						status
						uuid
					}
				}
			}
		}
	}
`

const createCompletion = gql`
	mutation createCompletion(
		$projectUuid: String!
		$lotUuid: String!
		$qdUuid: String!
	) {
		project(uuid: $projectUuid) {
			lot(uuid: $lotUuid) {
				quantityDetermination(uuid: $qdUuid) {
					createCompletion {
						status
						uuid
					}
				}
			}
		}
	}
`

const deleteCompletion = gql`
	mutation deleteCompletion(
		$projectUuid: String!
		$lotUuid: String!
		$qdUuid: String!
	) {
		project(uuid: $projectUuid) {
			lot(uuid: $lotUuid) {
				quantityDetermination(uuid: $qdUuid) {
					deleteCompletion {
						status
						uuid
					}
				}
			}
		}
	}
`

export default {
	createConfirmation,
	createSubmission,
	updateQuantityDeterminationItem,
	deleteQuantityDeterminationItem,
	acceptQuantityDeterminationItem,
	declineQuantityDeterminationItem,
	createReview,
	createCompletion,
	deleteCompletion,
}
