import { gql } from 'apollo-boost'
import { createNestedQuery } from 'app/utils/apollo'

export const boqNodes = `boqNodes {
    bidders {
        uuid
        up
        isAwarded
    }
    path {
        root
        qty
        qu
        up
        it
    }
    lin
    label
    level
    count
    longText {
				html
				text
		}
		shortText {
				html
				text
		}
    qty
    qu
    up
    it
}`

const DataRevisions = gql`
    fragment DataRevisions on Project {
        lots {
            data {
								uuid
								name
								status
                dataRevisions {
                    data {
                        uuid
                        status
                        accesses
                        bidders {
                            uuid
                            name
                        }
                        version
                        ${createNestedQuery(boqNodes, 5)}
                    }
                }
            }
        }
    }
`

export default {
	DataRevisions,
}
