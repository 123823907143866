/* eslint-disable @typescript-eslint/no-explicit-any */

import NormalFormatter from 'app/services/tabulator/formatters/up/NormalFormatter'
import { POSITION_TYPES } from 'app/types/POSITION_TYPES'

class WageFormatter extends NormalFormatter {
	constructor(cell: any) {
		super(cell, POSITION_TYPES.WAGE)
	}
}

export default WageFormatter
