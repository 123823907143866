import { makeStyles, Theme } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'

export default makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		marginTop: theme.spacing(1),
		minHeight: themeValues().sizes.PageContentHeader.minHeight,
		'& button + button:not(.MuiIconButton-root)': {
			marginLeft: theme.spacing(2),
		},
	},
	topRow: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
	},
	titleContainer: {
		display: 'inline-flex',
	},
	title: {
		paddingRight: theme.spacing(2),
	},
}))
