import { CHECKOUTDATA_KEYS, STEPS_CHECKOUT } from 'app/config/checkout'
import React, { FC, useEffect, FocusEvent } from 'react'
import { TextValidator } from 'react-material-ui-form-validator'
import { get, invert } from 'lodash'
import { useTranslation } from 'react-i18next'
import { CheckoutStepProps } from 'domain/checkout/interfaces/CheckoutStepProps'
import { useMutation } from '@apollo/react-hooks'
import mutations from 'domain/checkout/apollo/mutations'
import RenderErrorMessage from 'component/RenderErrorMessage/RenderErrorMessage'
import useTracking from 'app/services/datawarehouse/useTracking'
import TrackingEvents from 'app/services/datawarehouse/TrackingEvents'
import CheckoutCard from '../CheckoutCard/CheckoutCard'

const CardCompanyDataEnter: FC<CheckoutStepProps> = ({
	goToNextStep,
	handleInputChange,
	checkoutData,
	createFormDataFromObject,
	classes,
	stepId,
}) => {
	const firstName = get(checkoutData, CHECKOUTDATA_KEYS.FIRSTNAME, '')
	const lastName = get(checkoutData, CHECKOUTDATA_KEYS.LASTNAME, '')
	const phone = get(checkoutData, CHECKOUTDATA_KEYS.PHONE, '')
	const company = get(checkoutData, CHECKOUTDATA_KEYS.COMPANY, '')
	const street = get(checkoutData, CHECKOUTDATA_KEYS.STREET, '')
	const zipCode = get(checkoutData, CHECKOUTDATA_KEYS.ZIP_CODE, '')
	const city = get(checkoutData, CHECKOUTDATA_KEYS.CITY, '')
	const taxId = get(checkoutData, 'taxId', null)
	const token = get(checkoutData, CHECKOUTDATA_KEYS.TOKEN, '')

	const { t } = useTranslation()
	const { track } = useTracking()

	const [updateAccount, { loading, error }] = useMutation(
		mutations.updateAccount,
		{
			onCompleted: () => {
				if (createFormDataFromObject) {
					createFormDataFromObject({
						...checkoutData,
						[CHECKOUTDATA_KEYS.STEP_COMPANY_DATA_ENTER_COMPLETED]: true,
					})
				}
			},
		}
	)

	useEffect(() => {
		const isStepCompleted = get(
			checkoutData,
			CHECKOUTDATA_KEYS.STEP_COMPANY_DATA_ENTER_COMPLETED,
			false
		)
		if (isStepCompleted && goToNextStep) {
			goToNextStep()
		}
	}, [checkoutData])

	const onSubmit = (): void => {
		updateAccount({
			variables: {
				token,
				firstName,
				lastName,
				phone,
				company,
				street,
				zipCode,
				city,
				taxId,
			},
		})
	}

	const handleTrackOnBlur = (event: FocusEvent): void => {
		const eventName = get(event, ['target', 'name'], '')
		const stepKey = stepId && invert(STEPS_CHECKOUT)[stepId]
		track(
			`${stepKey}_${eventName.toUpperCase()}ENTER` as keyof typeof TrackingEvents,
			checkoutData
		)
	}

	const disabled = (): boolean => !street && !zipCode && !city

	return (
		<CheckoutCard
			headline={t('domain:checkout.updateAccount.card.company.title')}
			onSubmit={onSubmit}
			disabled={disabled()}
			classes={classes}
			stepId={stepId}
			loading={loading}
		>
			<TextValidator
				validators={['required']}
				name="street"
				value={street}
				label={`${t('domain:checkout.updateAccount.form.street.label')} *`}
				placeholder={t('domain:checkout.updateAccount.form.street.placeholder')}
				errorMessages={[
					t(
						'domain:checkout.updateAccount.form.street.validatorViolations.required'
					),
				]}
				fullWidth
				margin="normal"
				onChange={handleInputChange}
				onBlur={handleTrackOnBlur}
			/>
			<TextValidator
				validators={['required', 'isNumber']}
				name="zipCode"
				value={zipCode}
				label={`${t('domain:checkout.updateAccount.form.zipCode.label')} *`}
				placeholder={t(
					'domain:checkout.updateAccount.form.zipCode.placeholder'
				)}
				errorMessages={[
					t(
						'domain:checkout.updateAccount.form.zipCode.validatorViolations.required'
					),
					t(
						'domain:checkout.updateAccount.form.zipCode.validatorViolations.isNumber'
					),
				]}
				fullWidth
				margin="normal"
				onChange={handleInputChange}
				onBlur={handleTrackOnBlur}
			/>
			<TextValidator
				// eslint-disable-next-line no-useless-escape
				validators={['required', `matchRegexp:^[a-zA-ZäÄöÖüÜß\s]*$`]}
				name="city"
				value={city}
				label={`${t('domain:checkout.updateAccount.form.city.label')} *`}
				placeholder={t('domain:checkout.updateAccount.form.city.placeholder')}
				errorMessages={[
					t(
						'domain:checkout.updateAccount.form.city.validatorViolations.required'
					),
					t(
						'domain:checkout.updateAccount.form.city.validatorViolations.matchRegexp'
					),
				]}
				fullWidth
				margin="normal"
				onChange={handleInputChange}
				onBlur={handleTrackOnBlur}
			/>
			<RenderErrorMessage error={error} />
		</CheckoutCard>
	)
}

export default CardCompanyDataEnter
