import isEmail from 'app/services/validator/isEmail'
import isUrl from 'app/services/validator/isUrl'
import { isString } from 'lodash'
import { LinkProps } from 'component/Link/LinkProps'
import React, { FC } from 'react'
import { Link as RrdLink } from 'react-router-dom'

import clsx from 'clsx'
import useStyles from 'component/Link/LinkStyles'

const Link: FC<LinkProps> = ({ children, to, color, className, ...rest }) => {
	const classes = useStyles({ color })

	if (isString(to) && isEmail(to)) {
		return (
			<a
				className={clsx(classes.root, className)}
				href={`mailto:${to}`}
				{...rest}
			>
				{children}
			</a>
		)
	}

	if (isString(to) && isUrl(to)) {
		return (
			<a className={clsx(classes.root, className)} href={to} {...rest}>
				{children}
			</a>
		)
	}

	return (
		<RrdLink {...rest} to={to} className={clsx(classes.root, className)}>
			{children}
		</RrdLink>
	)
}

export default Link
