import React, { FC, ReactNode } from 'react'
import PageContentHeader from 'component/PageContentHeader/PageContentHeader'
import { useTranslation } from 'react-i18next'
import { ProjectPageContentHeaderProps } from './ProjectPageContentHeaderProps'

const ProjectPageContentHeader: FC<ProjectPageContentHeaderProps> = ({
	lots,
}) => {
	const { t } = useTranslation()

	const renderHeader = (): ReactNode => {
		if (Array.isArray(lots) && lots.length) {
			return <PageContentHeader title={t('domain:project.title')} />
		}
		return (
			<PageContentHeader
				title={t('domain:project.introductions.lead.newProject')}
			/>
		)
	}

	return <>{renderHeader()}</>
}

export default ProjectPageContentHeader
