import { gql } from 'apollo-boost'

const BoqAnnotations = gql`
	query BoqAnnotations(
		$projectUuid: String!
		$lotUuid: String!
		$resolveBidderDataRevisions: Boolean
	) {
		project(uuid: $projectUuid) {
			uuid
			role
			lot(uuid: $lotUuid) {
				uuid
				status
				dataRevisions(resolveBidderDataRevisions: $resolveBidderDataRevisions) {
					data {
						uuid
						status
						boqAnnotations {
							uuid
							data {
								uuid
								unit {
									code
									label
								}
								comment
								lin
								longText
								quantity
								referenceLabel
								shortText
								unitPrice
							}
							name
							totalCount
						}
					}
				}
			}
		}
	}
`

const Units = gql`
	query Units {
		common {
			units {
				code
				label
			}
		}
	}
`

export default { BoqAnnotations, Units }
