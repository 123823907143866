import { resolveRoute } from 'app/utils/route'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'
import React, { FC, ReactElement, useCallback, useState } from 'react'
import axios, { AxiosError } from 'axios'
import { useDropzone } from 'react-dropzone'
import { useParams } from 'react-router-dom'
import clsx from 'clsx'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import { UPLOAD_ATTACHMENT_SIZE_MAX } from 'app/config/attachments'
import { UrlParams } from 'app/types/UrlParams'
import { CombinationOperator } from 'app/types/CombinationOperator'
import routesApi from 'app/routing/routes/routesApi'
import useCustomSnackbar from 'app/services/snackbar/useCustomSnackbar'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import Loading from 'component/Loading/Loading'
import Validate from 'component/Validate/Validate'
import queries from 'domain/general/attachments/apollo/queries'
import { AttachmentDropzoneProps } from 'domain/general/attachments/components/body/attachments/AttachmentDropzone/AttachmentDropzoneProps'

import useStyles from 'domain/general/attachments/components/body/attachments/AttachmentDropzone/AttachmentDropzoneStyles'
import ValidateDomainEvent from 'component/ValidateDomainEvent/ValidateDomainEvent'
import { DOMAIN_EVENTS } from 'domain/project/enums/DOMAIN_EVENTS'
import { DOMAIN_EVENT_TRIGGER } from 'domain/project/enums/DOMAIN_EVENT_TRIGGER'

const AttachmentDropzone: FC<AttachmentDropzoneProps> = ({
	classes: parentClasses = {},
	parentUuid,
}): ReactElement => {
	const [loading, setLoading] = useState(false)
	const [dragEntered, setDragEntered] = useState<boolean>(false)
	const { sendErrorMessage } = useCustomSnackbar()
	const { projectUuid, lotUuid } = useParams<UrlParams>()
	const [fetchData] = useGraphQLLazyQuery(queries.ProjectWithLotAttachments, {
		fetchPolicy: 'network-only',
	})

	const classes = useStyles({ dragEntered })

	const onDrop = useCallback(
		acceptedFiles => {
			setDragEntered(false)

			const apiRoute = !parentUuid
				? routesApi.uploadLotAttachment
				: routesApi.uploadLotAttachmentForParent

			if (Array.isArray(acceptedFiles) && acceptedFiles.length > 0) {
				const formData = new FormData()
				const apiUrl = `${process.env.REACT_APP_API_URL}/${resolveRoute(
					apiRoute,
					{
						projectUuid,
						lotUuid,
						parentUuid,
					}
				)}`

				acceptedFiles.forEach((file: File) => {
					formData.append('files[]', file)
				})

				setLoading(true)
				axios
					.post(apiUrl, formData, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					})
					.then(() => {
						if (projectUuid && lotUuid) {
							fetchData({
								variables: {
									projectUuid,
									lotUuid,
									parentUuid,
									authorUuid: null,
								},
							})
						}
					})
					.catch((err: AxiosError) => {
						sendErrorMessage(err.message)
					})
					.finally(() => setLoading(false))
			}
		},
		[projectUuid, lotUuid]
	)

	const { getRootProps, getInputProps } = useDropzone({
		onDragEnter: () => setDragEntered(true),
		onDragLeave: () => setDragEntered(false),
		onDrop,
		maxSize: UPLOAD_ATTACHMENT_SIZE_MAX,
		onDropRejected<T extends File>(files: T[]): void {
			if (files[0].size > UPLOAD_ATTACHMENT_SIZE_MAX) {
				sendErrorMessage(
					'Die von Ihnen hochgeladene Datei übersteigt das Limit von 50Mb.'
				)
			}
		},
	})

	return (
		<Validate
			combinationOperator={CombinationOperator.OR}
			validCombinations={[
				{ projectRole: ROLES_PROJECT.LEAD },
				{ projectRole: ROLES_PROJECT.BIDDER },
			]}
		>
			<ValidateDomainEvent
				event={DOMAIN_EVENTS.CREATE_ATTACHMENT}
				trigger={DOMAIN_EVENT_TRIGGER.ON_DROP}
			>
				<Card
					{...getRootProps()}
					className={clsx(classes.root, parentClasses.root)}
				>
					<CardContent className={classes.content}>
						<Loading loading={loading}>
							<AddCircleOutlineIcon className={classes.icon} />
							<input {...getInputProps()} />
						</Loading>
					</CardContent>
				</Card>
			</ValidateDomainEvent>
		</Validate>
	)
}

export default AttachmentDropzone
