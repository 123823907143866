import React, { FC, ReactElement } from 'react'
import { Grid } from '@material-ui/core'
import { subscriptionPlans } from 'app/config/subscriptionPlans'
import PricingPlan from 'component/PricingPlan/PricingPlan'
import { CardPricingTableProps } from './CardPricingTableProps'
import useStyles from './CardPricingTableStyles'

const CardPricingTable: FC<CardPricingTableProps> = ({
	onSelectPricingPlan,
	goToNextStep,
	checkoutData,
}) => {
	const classes = useStyles()

	const renderSubscriptionsPlans = (): ReactElement[] =>
		subscriptionPlans.map(plan => {
			return (
				<PricingPlan
					key={plan.code}
					classes={{ cardRoot: classes.pricingPlanCard }}
					pricingPlan={plan}
					onSelectPricingPlan={onSelectPricingPlan}
					goToNextStep={goToNextStep}
					checkoutData={checkoutData}
				/>
			)
		})

	return (
		<Grid container justify="center" alignItems="stretch">
			{renderSubscriptionsPlans()}
		</Grid>
	)
}

export default CardPricingTable
