import { Checkbox } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import RenderErrorMessage from 'component/RenderErrorMessage/RenderErrorMessage'
import React, { ReactElement } from 'react'
import { ValidatorComponent } from 'react-material-ui-form-validator'

class CheckboxValidator extends ValidatorComponent {
	render(): ReactElement {
		const { errorMessages, ...rest } = this.props

		let error = null
		const { isValid } = this.state as {
			isValid: boolean
			errorMessages: string[]
		}
		// @TODO for now it is ok, but we need to identify the error.
		// Currently we are just taking the first one which is defined
		if (!isValid) {
			error = new Error((errorMessages && errorMessages[0]) || 'ERROR')
		}

		return (
			<FormControl component="fieldset">
				<FormGroup>
					<FormControlLabel
						label={this.props.label}
						value={this.props.value}
						control={
							<Checkbox
								{...rest}
								onChange={this.props.onChange}
								ref={r => {
									// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
									// @ts-ignore
									this.input = r
								}}
							/>
						}
					/>
				</FormGroup>
				<RenderErrorMessage error={error} />
			</FormControl>
		)
	}
}

export default CheckboxValidator
