import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { STEPS_CHECKOUT } from 'app/config/checkout'
import useAuthentication from 'app/hooks/useAuthentication'
import useForm from 'app/hooks/useForm'
import routes from 'app/routing/routes/routesApp'
import { resolveRoute } from 'app/utils/route'
import Loading from 'component/Loading/Loading'
import { CheckoutFlowProps } from 'domain/checkout/components/CheckoutFlow/CheckoutFlowProps'
import CheckoutFlowValidator from 'domain/checkout/components/CheckoutFlowValidator/CheckoutFlowValidator'
import React, {
	FC,
	ReactElement,
	useCallback,
	useState,
	useEffect,
} from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { UrlParams } from 'app/types/UrlParams'

import useStyles from 'domain/checkout/components/CheckoutFlow/CheckoutFlowStyles'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string)

const CheckoutFlow: FC<CheckoutFlowProps> = ({ children }) => {
	const classes = useStyles()

	const { accessToken, loading } = useAuthentication()

	const {
		formData: checkoutData,
		handleInputChange,
		createFormDataFromObject,
	} = useForm({ persist: true, dontPersist: ['password', 'passwordCheck'] })
	const { stepId } = useParams<UrlParams>()
	const { push } = useHistory()

	const [token, setToken] = useState<string | undefined>()

	const onSelectPricingPlan = (chosenPlan: string, quantity: string): void => {
		createFormDataFromObject({
			...checkoutData,
			chosenPlan,
			quantity,
		})
	}

	useEffect(() => {
		if (token) {
			createFormDataFromObject({ ...checkoutData, token })
		}
	}, [token])

	const goToStep = (stepIndex: number): void => {
		push(
			resolveRoute(routes.checkoutSteps, {
				stepId: Object.values(STEPS_CHECKOUT)[stepIndex],
			})
		)
	}

	const goToPrevStep = useCallback(() => {
		const indexOfCurrentStep = Object.values(STEPS_CHECKOUT).indexOf(
			stepId as STEPS_CHECKOUT
		)
		const indexOfPrevStep = indexOfCurrentStep - 1

		if (indexOfPrevStep > -1) {
			goToStep(indexOfPrevStep)
		}
	}, [stepId, children])

	const getNextStepIndex = (): number => {
		const indexOfCurrentStep = Object.values(STEPS_CHECKOUT).indexOf(
			stepId as STEPS_CHECKOUT
		)
		return indexOfCurrentStep + 1
	}

	const goToNextStep = useCallback(() => {
		const indexOfNextStep = getNextStepIndex()

		if (indexOfNextStep < Object.keys(STEPS_CHECKOUT).length) {
			goToStep(indexOfNextStep)
		}
	}, [stepId, children])

	const renderCurrentStep = useCallback(() => {
		const childrenAsArray = React.Children.toArray(children)

		const currentChild = childrenAsArray.find(child => {
			const {
				props: { stepId: componentStepId },
			} = child as ReactElement

			return componentStepId === stepId
		}) as ReactElement | undefined

		if (currentChild) {
			return React.cloneElement(currentChild, {
				goToNextStep,
				goToPrevStep,
				checkoutData,
				handleInputChange,
				classes,
				onSelectPricingPlan,
				setToken,
				createFormDataFromObject,
			})
		}

		return null
	}, [stepId, children, checkoutData])

	if (!stepId) {
		return null
	}

	return (
		<Elements stripe={stripePromise}>
			<Loading loading={loading}>
				<CheckoutFlowValidator
					checkoutData={checkoutData}
					stepId={stepId as STEPS_CHECKOUT}
					accessToken={accessToken}
				>
					{renderCurrentStep()}
				</CheckoutFlowValidator>
			</Loading>
		</Elements>
	)
}

export default CheckoutFlow
