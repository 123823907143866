/* eslint-disable @typescript-eslint/no-explicit-any */
import { OperationVariables, QueryResult } from '@apollo/react-common'
import { useQuery } from '@apollo/react-hooks'
import { ApolloError } from 'apollo-client'
import { UseQueryOptions } from 'app/services/apollo/hooks/useGraphQLQuery'
import { DocumentNode } from 'graphql'

export default <TData = any, TVariables = OperationVariables>(
	query: DocumentNode,
	options?: UseQueryOptions<TVariables>
): QueryResult<TData, TVariables> => {
	const { variables, fetchPolicy } = options || {}

	return useQuery<TData, TVariables>(query, {
		variables,
		fetchPolicy,
		onError: (error: ApolloError) => {
			throw new Error(error.message)
		},
	})
}
