import { isHotjarEnabled, isGaEnabled } from 'app/utils/tracking'
import React, { FC, useEffect } from 'react'
import ReactGA from 'react-ga'
import { hotjar } from 'react-hotjar'
import { useHistory } from 'react-router-dom'
import { toNumber, get } from 'lodash'

import useEnvironmentVariables from 'app/hooks/useEnvironmentVariables'
import { useQuery } from '@apollo/react-hooks'
import { AccountQuery } from 'domain/account/interfaces/AccountQuery'
import queries from 'domain/account/apollo/queries'

const TrackingProvider: FC = ({ children }) => {
	const { location } = useHistory()
	const {
		REACT_APP_GA_ID,
		REACT_APP_HOTJAR_HJID,
		REACT_APP_HOTJAR_HJSV,
	} = useEnvironmentVariables()

	const gaEnabled = isGaEnabled()
	const hotjarEnabled = isHotjarEnabled()

	const { data } = useQuery<AccountQuery | null>(queries.AccountInformations)

	const userUuid = get(data, ['account', 'uuid'], null)

	useEffect(() => {
		if (gaEnabled) {
			ReactGA.initialize(REACT_APP_GA_ID)
			ReactGA.set({ anonymizeIp: true, userId: userUuid })
		}

		if (hotjarEnabled) {
			hotjar.initialize(
				toNumber(REACT_APP_HOTJAR_HJID),
				toNumber(REACT_APP_HOTJAR_HJSV)
			)
		}
	}, [])

	useEffect(() => {
		if (gaEnabled && userUuid) {
			ReactGA.set({ userId: userUuid })
		}
	}, [userUuid])

	useEffect(() => {
		if (location && gaEnabled) {
			ReactGA.pageview(`${location.pathname} ${location.search}`)
		}
	}, [location])

	return <>{children}</>
}

export default TrackingProvider
