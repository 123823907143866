import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
	cardRoot: {
		width: 500,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	cardHeader: {
		padding: theme.spacing(2),
	},
	cardContent: {
		padding: theme.spacing(2),
	},
	cardActions: {
		padding: theme.spacing(2),
	},
}))
