/* eslint-disable @typescript-eslint/no-explicit-any */

import BaseFormatter from 'app/services/tabulator/formatters/base/BaseFormatter'
import { POSITION_TYPES } from 'app/types/POSITION_TYPES'

class QtyBaseFormatter extends BaseFormatter {
	private _type: POSITION_TYPES

	get type(): POSITION_TYPES {
		return this._type
	}

	set type(value: POSITION_TYPES) {
		this._type = value
	}

	constructor(cell: any, type: POSITION_TYPES) {
		super(cell)

		this._type = type

		this.cssClasses.push('line-item')
		this.cssClasses.push(`line-item-${type}`)
	}
}

export default QtyBaseFormatter
