/* eslint-disable @typescript-eslint/no-explicit-any */
import { addClasses } from 'app/utils/tabulator'
import React, { FC, ReactElement } from 'react'
import { ContactTag } from 'domain/tender/bidders/types/ContactTag'
import IconButton from '@material-ui/core/IconButton'

export interface ContactTagsFormatterProps {
	cell?: any
	onClick?: (tag: ContactTag) => void
}

const IconFormatter: FC<ContactTagsFormatterProps> = ({
	cell,
	children,
}): ReactElement => {
	const row = cell.getRow()
	const rowElement = row.getElement()
	addClasses(rowElement, ['row-growable'])

	return <IconButton>{children}</IconButton>
}

export default IconFormatter
