/* eslint-disable @typescript-eslint/no-explicit-any */
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { CombinationOperator } from 'app/types/CombinationOperator'
import { ValidCombination } from 'app/types/ValidCombinations'
import ProfileMenuItemDatabaseReset from 'domain/header/components/profileMenu/ProfileMenuItemDatabaseReset/ProfileMenuItemDatabaseReset'
import ProfileMenuItemLogout from 'domain/header/components/profileMenu/ProfileMenuItemLogout/ProfileMenuItemLogout'
import { LOT_STATUS } from 'domain/lot/interfaces/LOT_STATUS'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'
import ProfileMenuRefreshToken from 'domain/header/components/profileMenu/ProfileMenuRefreshToken/ProfileMenuRefreshToken'

export interface NavigationItem {
	id: string
	parentId?: string
	title?: string
	url?: string
	icon?: (props: SvgIconProps) => JSX.Element
	menuIcon?: (props: SvgIconProps) => JSX.Element
	component?: any
	contentComponent?: any
	projectRoles?: ROLES_PROJECT[] | null
	status?: LOT_STATUS | null
	disabled?: boolean
	hideOnMissingLotUuid?: boolean
	validCombinations?: ValidCombination[]
	combinationOperator?: CombinationOperator
}

export const headerMenu: NavigationItem[] = [
	{
		id: 'dbreset',
		component: ProfileMenuItemDatabaseReset,
	},
	{
		id: 'refreshToken',
		component: ProfileMenuRefreshToken,
	},
	{
		id: 'logout',
		component: ProfileMenuItemLogout,
		title: 'Logout',
		icon: ExitToAppIcon,
	},
]
