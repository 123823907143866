import { variantFromRoleAndQuantityDeterminationStatus } from 'app/utils/tabulator'
import React, { ReactElement, FC } from 'react'
import TabulatorChip from 'component/TabulatorChip/TabulatorChip'
import { useTranslation } from 'react-i18next'
import QuantityDeterminationStatusFormatterProps from './QuantityDeterminationStatusFormatterProps'

const QuantityDeterminationStatusFormatter: FC<QuantityDeterminationStatusFormatterProps> = ({
	cell,
	role,
}): ReactElement => {
	const { t } = useTranslation()
	const quantityDeterminationStatus = cell.getValue()

	return (
		<TabulatorChip
			variant={variantFromRoleAndQuantityDeterminationStatus(
				role,
				quantityDeterminationStatus
			)}
		>
			{t(
				`domain:billing.tableQuantityDeterminations.status.${role}.${quantityDeterminationStatus}`
			)}
		</TabulatorChip>
	)
}
export default QuantityDeterminationStatusFormatter
