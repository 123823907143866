import { IconButton } from '@material-ui/core'
import { BreadCrumbButtonProps } from 'component/BreadCrumbButton/BreadCrumbButtonProps'
import AddIcon from '@material-ui/icons/Add'
import React, { FC } from 'react'
import useStyles from './BreadCrumbButtonStyles'

const BreadCrumbButton: FC<BreadCrumbButtonProps> = ({
	onClick,
	disabled,
	title,
}) => {
	const classes = useStyles()
	return (
		<IconButton
			className={classes.root}
			onClick={onClick}
			disabled={disabled}
			title={title}
		>
			<AddIcon classes={{ root: classes.icon }} />
		</IconButton>
	)
}

export default BreadCrumbButton
