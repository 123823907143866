import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles(() => ({
	root: ({ disabled }: { disabled?: boolean }) => ({
		padding: '4px',
		outline: 0,
		borderRadius: '50%',
		border: 0,
		cursor: 'pointer',
		verticalAlign: 'middle',
		background: 'transparent',
		transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		'& svg': {
			fill: 'rgba(0, 0, 0, 0.54)',
		},
		'&:hover': {
			background: disabled ? 'transparent' : 'rgba(0, 0, 0, 0.04)',
			cursor: disabled ? 'default' : 'pointer',
		},
		opacity: disabled ? 0.3 : 1,
	}),
	svgContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'inherit',
		justifyContent: 'inherit',
	},
}))
