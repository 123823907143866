import logout from 'app/hooks/useLogout'
import PrimaryButton from 'component/PrimaryButton/PrimaryButton'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

const LogoutButton: FC = ({ children }) => {
	const { t } = useTranslation()
	const onClick = (): void => {
		logout()
	}

	return (
		<PrimaryButton title={t('domain:logout.logout.button')} onClick={onClick}>
			{children || t('domain:logout.logout.button')}
		</PrimaryButton>
	)
}

export default LogoutButton
