import { gql } from 'apollo-boost'

const ProjectWithUpdateInformation = gql`
	query ProjectWithUpdateInformation($projectUuid: String!) {
		project(uuid: $projectUuid) {
			uuid
			name
			__typename
		}
	}
`

const ProjectForVerification = gql`
	query ProjectForVerification($projectUuid: String!) {
		project(uuid: $projectUuid) {
			uuid
			__typename
			role
			lots {
				data {
					uuid
					__typename
					status
					quantityDeterminations {
						data {
							uuid
							status
						}
					}
					dataRevisions {
						data {
							uuid
							__typename
							status
						}
					}
				}
			}
		}
	}
`

const NotAllowedCheck = gql`
	query NotAllowed($projectUuid: String!, $lotUuid: String!) {
		project(uuid: $projectUuid) {
			uuid
			role
			lot(uuid: $lotUuid) {
				uuid
				status
			}
		}
	}
`

export default {
	ProjectWithUpdateInformation,
	ProjectForVerification,
	NotAllowedCheck,
}
