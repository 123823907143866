import { gql } from 'apollo-boost'

const createLot = gql`
	mutation createLot($projectUuid: String!, $lotName: String!) {
		project(uuid: $projectUuid) {
			createLot(name: $lotName) {
				uuid
				name
				status
			}
		}
	}
`

const updateLot = gql`
	mutation updateLot(
		$projectUuid: String!
		$lotUuid: String!
		$lotName: String!
	) {
		project(uuid: $projectUuid) {
			updateLot(name: $lotName, uuid: $lotUuid) {
				uuid
				name
			}
		}
	}
`

const resetLot = gql`
	mutation resetLot($projectUuid: String!, $lotUuid: String!) {
		project(uuid: $projectUuid) {
			resetLot(uuid: $lotUuid) {
				uuid
			}
		}
	}
`

export default {
	createLot,
	updateLot,
	resetLot,
}
