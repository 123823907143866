import { makeStyles } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'

export default makeStyles({
	root: {
		color: themeValues().colors.text.color,
	},
	header: {
		color: themeValues().colors.text.color,
		margin: '20px 0',
	},
	input: {
		margin: '20px 0 0',
		'& .MuiFilledInput-root': {
			backgroundColor: 'white',
		},
		'& .MuiFormHelperText-root.Mui-error': {
			color: themeValues().colors.text.color,
			fontSize: '0.95em',
			margin: '0 14px 0 0',
		},
	},
	form: {
		textAlign: 'right',
	},
	button: {
		margin: themeValues().sizes.Actions.margin,
	},
})
