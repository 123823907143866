import { getGraphqlErrorCodeFromErrorLinkResponse } from 'app/utils/apollo'
import React, { FC, useEffect } from 'react'
import { FormGroup } from '@material-ui/core'
import PageContentParagraph from 'component/PageContentParagraph/PageContentParagraph'
import ValidatorForm from 'component/ValidatorForm/ValidatorForm'
import { TextValidator } from 'react-material-ui-form-validator'
import useForm from 'app/hooks/useForm'
import PrimaryButton from 'component/PrimaryButton/PrimaryButton'
import PageContentHeading1 from 'component/PageContentHeading1/PageContentHeading1'
import { useTranslation } from 'react-i18next'
import mutations from 'domain/register/apollo/mutations'
import { useAuthentication } from 'app/hooks'
import { get } from 'lodash'
import { useHistory } from 'react-router-dom'
import routes from 'app/routing/routes/routesApp'
import { AUTH_TOKEN_IDENTIFIER } from 'app/constants'
import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import { EMAIL_ALREADY_REGISTERED_CODE } from 'app/config/checkout'
import useStyles from './CreateDemoAccountFormStyles'

const CreateDemoAccountForm: FC = () => {
	const { formData, handleInputChange } = useForm({
		defaultValues: { email: '' },
	})

	const authTokenString = localStorage.getItem(AUTH_TOKEN_IDENTIFIER)

	const { push } = useHistory()
	const classes = useStyles()

	const { refreshStoredToken } = useAuthentication()

	const { t } = useTranslation()

	const [createDemoAccount, { data, loading }] = useGraphQLMutation(
		mutations.createDemoAccount,
		{
			onError: (err, handleError) => {
				const errorCode = getGraphqlErrorCodeFromErrorLinkResponse(err)
				if (errorCode === EMAIL_ALREADY_REGISTERED_CODE) {
					push(routes.login)
				} else {
					handleError(err)
				}
			},
		}
	)

	const handleSubmit = (): void => {
		const { email } = formData
		createDemoAccount({
			variables: {
				email,
			},
		})
	}

	useEffect(() => {
		if (data) {
			const refreshToken = get(data, ['createDemoAccount', 'refreshToken'], '')
			refreshStoredToken(refreshToken)
		}
	}, [data])

	useEffect(() => {
		if (authTokenString) {
			push(routes.projects)
		}
	}, [authTokenString])

	return (
		<div className={classes.root}>
			<PageContentHeading1 className={classes.header}>
				{t('domain:createDemoAccount.title')}
			</PageContentHeading1>
			<PageContentParagraph>
				{t('domain:createDemoAccount.content')}
			</PageContentParagraph>
			<ValidatorForm className={classes.form} onSubmit={handleSubmit}>
				<FormGroup>
					<TextValidator
						validators={['required', 'isEmail']}
						classes={{ root: classes.input }}
						name="email"
						label={t('domain:createDemoAccount.form.email.label')}
						variant="filled"
						type="email"
						value={formData.email}
						onChange={handleInputChange}
						fullWidth
						errorMessages={[
							t(
								'domain:createDemoAccount.form.email.validatorViolations.required'
							),
							t(
								'domain:createDemoAccount.form.email.validatorViolations.isEmail'
							),
						]}
					/>
				</FormGroup>
				<PrimaryButton
					loading={loading}
					type="submit"
					className={classes.button}
				>
					{t('domain:createDemoAccount.form.button.label')}
				</PrimaryButton>
			</ValidatorForm>
		</div>
	)
}

export default CreateDemoAccountForm
