import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { FORMAT_PRICE } from 'app/config/checkout'
import { InvoiceTotals } from 'app/types/InvoiceTotals'
import { getSubscriptionPlanByCode } from 'app/utils/checkout'
import clsx from 'clsx'
import { InvoiceTableProps } from 'component/InvoiceTable/InvoiceTableProps'
import { get } from 'lodash'
import React, { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import numeral from 'app/services/numeral'

import useStyles from './InvoiceItemStyles'

const InvoiceTable: FC<InvoiceTableProps> = ({ items }) => {
	const classes = useStyles()

	const { t } = useTranslation()

	const renderItemRows = (): ReactNode => {
		if (items.length < 1) {
			return null
		}

		return items.map(invoiceItem => {
			const { pricingPlanCode, quantity } = invoiceItem
			const subscriptionPlan = getSubscriptionPlanByCode(pricingPlanCode)
			const unityPrice =
				get(subscriptionPlan, 'price', 0) *
				get(subscriptionPlan, 'prepaymentMonths', 0)

			return (
				<TableRow>
					<TableCell>{get(subscriptionPlan, 'title', '')}</TableCell>
					<TableCell align="right">
						{numeral(unityPrice).format(FORMAT_PRICE)}
					</TableCell>
					<TableCell align="right">{quantity}</TableCell>
					<TableCell align="right">
						{numeral(unityPrice * quantity).format(FORMAT_PRICE)}
					</TableCell>
				</TableRow>
			)
		})
	}

	const generateTotalObject = (): InvoiceTotals | null => {
		const initialValue: InvoiceTotals = {
			withTaxes: 0,
			withoutTaxes: 0,
			taxes: 0,
		}

		return items.reduce((totals, invoiceItem) => {
			const { pricingPlanCode, quantity } = invoiceItem
			const subscriptionPlan = getSubscriptionPlanByCode(pricingPlanCode)
			const unityPrice =
				get(subscriptionPlan, 'price', 0) *
				get(subscriptionPlan, 'prepaymentMonths', 0)

			if (!subscriptionPlan) {
				return totals
			}

			const newTotalWithoutTaxes = totals.withoutTaxes + unityPrice * quantity
			const newTotalTaxes = (newTotalWithoutTaxes / 100) * 19

			return {
				...totals,
				withoutTaxes: newTotalWithoutTaxes,
				taxes: newTotalTaxes,
				withTaxes: newTotalWithoutTaxes + newTotalTaxes,
			}
		}, initialValue)
	}

	const renderEmptyState = (): ReactNode => {
		if (items.length < 1) {
			return (
				<TableRow>
					<TableCell colSpan={5}>{t('common:invoice.empty')}</TableCell>
				</TableRow>
			)
		}
		return null
	}

	const renderSummary = (): ReactNode => {
		if (items.length < 1) {
			return null
		}

		const totals = generateTotalObject()

		return (
			<>
				<TableRow>
					<TableCell
						align="right"
						classes={{
							root: clsx(classes.cellNoBorder, classes.cellCondensed),
						}}
						colSpan={3}
					>
						{t('common:invoice.summary.totalWithoutTaxes')}
					</TableCell>
					<TableCell
						align="right"
						classes={{
							root: clsx(classes.cellNoBorder, classes.cellCondensed),
						}}
						colSpan={2}
					>
						{numeral(get(totals, 'withoutTaxes', 0)).format(FORMAT_PRICE)}
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell
						align="right"
						classes={{
							root: classes.cellCondensed,
						}}
						colSpan={3}
					>
						{t('common:invoice.summary.totalTaxes')}
					</TableCell>
					<TableCell
						align="right"
						classes={{
							root: classes.cellCondensed,
						}}
						colSpan={3}
					>
						{numeral(get(totals, 'taxes', 0)).format(FORMAT_PRICE)}
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell
						align="right"
						classes={{
							root: clsx(
								classes.cellNoBorder,
								classes.cellCondensed,
								classes.cellBold
							),
						}}
						colSpan={3}
					>
						{t('common:invoice.summary.totalWithTaxes')}
					</TableCell>
					<TableCell
						align="right"
						classes={{
							root: clsx(
								classes.cellNoBorder,
								classes.cellCondensed,
								classes.cellBold
							),
						}}
						colSpan={3}
					>
						{numeral(get(totals, 'withTaxes', 0)).format(FORMAT_PRICE)}
					</TableCell>
				</TableRow>
			</>
		)
	}

	return (
		<Table
			classes={{
				root: classes.table,
			}}
		>
			<TableHead>
				<TableRow>
					<TableCell
						classes={{
							root: clsx(classes.cellCondensed, classes.cellBold),
						}}
					>
						{t('common:invoice.header.description')}
					</TableCell>
					<TableCell
						align="right"
						classes={{
							root: clsx(classes.cellCondensed, classes.cellBold),
						}}
					>
						{t('common:invoice.header.unitPrice')}
					</TableCell>
					<TableCell
						align="right"
						classes={{
							root: clsx(classes.cellCondensed, classes.cellBold),
						}}
					>
						{t('common:invoice.header.quantity')}
					</TableCell>
					<TableCell
						align="right"
						classes={{
							root: clsx(classes.cellCondensed, classes.cellBold),
						}}
					>
						{t('common:invoice.header.total')}
					</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{renderEmptyState()}
				{renderItemRows()}
				{renderSummary()}
			</TableBody>
		</Table>
	)
}

export default InvoiceTable
