/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useSideSheet } from 'app/hooks'
import AnnotationSideSheet from 'domain/award/annotations/sidesheet/AnnotationSideSheet/AnnotationSideSheet'
import { DataRevision } from 'app/types'
import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import { useParams } from 'react-router-dom'
import { UrlParams } from 'app/types/UrlParams'
import { useTranslation } from 'react-i18next'
import { BoqAnnotation } from 'app/config/annotations'
import { getRowFromCell } from 'app/services/tabulator'
import AnnotationDetailSideSheet from 'domain/award/annotations/sidesheet/AnnotationDetailSideSheet/AnnotationDetailSideSheet'
import mutations from '../apollo/mutations'
import queries from '../apollo/queries'

interface UseEvents {
	openAnnotationSideSheet: (e: any, cell: any) => void
	onDeleteBoqAnnotation: (e: any, cell: any) => void
	openAnnotationDetailSideSheet: (e: any, cell: any) => void
}

export default (revision: DataRevision | null): UseEvents => {
	const { openSideSheet, closeSideSheet } = useSideSheet()
	const { projectUuid, lotUuid } = useParams<UrlParams>()

	const [deleteBoqAnnotation] = useGraphQLMutation(
		mutations.DeleteBoqAnnotation
	)
	const { t } = useTranslation()

	const openAnnotationSideSheet = (e: any, cell: any): void => {
		const boqAnnotation = cell.getData() as BoqAnnotation
		const { lin, referenceLabel: label } = boqAnnotation
		const table = cell.getTable()
		const row = cell.getRow()

		table.deselectRow()
		row.toggleSelect()

		openSideSheet({
			title: t('domain:award.annotations.sideSheet.title', { lin, label }),
			content: (
				<AnnotationSideSheet
					revision={revision}
					boqAnnotation={boqAnnotation}
				/>
			),
		})
	}

	const onDeleteBoqAnnotation = (e: any, cell: any): void => {
		const { uuid: annotationUuid } = cell.getRow().getData()
		const revisionUuid = revision?.uuid

		if (projectUuid && lotUuid && revisionUuid && annotationUuid) {
			deleteBoqAnnotation({
				variables: { projectUuid, lotUuid, revisionUuid, annotationUuid },
				refetchQueries: [
					{
						query: queries.BoqAnnotations,
						variables: { projectUuid, lotUuid },
					},
				],
			})
			closeSideSheet()
		}
	}

	const openAnnotationDetailSideSheet = (e: any, cell: any): void => {
		const boqAnnotation = getRowFromCell(cell).getData() as BoqAnnotation
		const { lin, referenceLabel: label } = boqAnnotation
		const table = cell.getTable()
		const row = cell.getRow()

		table.deselectRow()
		row.toggleSelect()

		openSideSheet({
			title: t('domain:award.annotations.sideSheet.title', { lin, label }),
			content: <AnnotationDetailSideSheet boqAnnotation={boqAnnotation} />,
		})
	}

	return {
		openAnnotationSideSheet,
		onDeleteBoqAnnotation,
		openAnnotationDetailSideSheet,
	}
}
