import { gql } from 'apollo-boost'
import { createNestedQuery } from 'app/utils/apollo'

export const boqNodes = `boqNodes {
    bidders {
        uuid
        up
        isAwarded
    }
    path {
        root
        qty
        qu
        up
        it
    }
    longText {
				html
				text
		}
		shortText {
				html
				text
		}
		subQuantities {
        lin
        qty
    }
		isSurcharged
		label
    type
    subType
    lin
    lic
    level
    count
    qty
    qu
    up
    it
}`

const ProjectWithBoqInformation = gql`
    query ProjectWithBoqInformation($projectUuid: String!, $lotUuid: String!) {
      project(uuid: $projectUuid) {
          uuid
          name
          role
          lot(uuid: $lotUuid) {
              uuid
              name
              status
              dataRevisions {
                  data {
                      uuid
                      status
                      accesses
                      bidders {
                          uuid
                          name
                      }
                      version
                      ${createNestedQuery(boqNodes, 6)}
                      boqAnnotations {
                        uuid
                        data {
                            unit {
                                code
                                label
                            }
                            comment
                            lin
                            longText
                            quantity
                            referenceLabel
                            shortText
                            unitPrice
                            uuid
                        }
                        name
                        totalCount
                      }
                  }
              }
          }
      }
	}
`

export default { ProjectWithBoqInformation }
