import { makeStyles } from '@material-ui/core'

export default makeStyles({
	root: {
		display: 'flex',
		alignItems: 'center',
		height: '56px',
		marginBottom: '16px',
	},
})
