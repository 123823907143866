import { Card } from '@material-ui/core'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import PrimaryButton from 'component/PrimaryButton/PrimaryButton'
import ValidatorForm from 'component/ValidatorForm/ValidatorForm'
import { CheckoutCardProps } from 'domain/checkout/components/CheckoutCard/CheckoutCardProps'
import React, { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'
import useStyles from './CheckoutCardStyles'

const CheckoutCard: FC<CheckoutCardProps> = ({
	children,
	headline,
	onSubmit,
	classes: parentClasses = {},
	disabled,
	loading,
	instantValidate = false,
	buttonLabel,
	anotherAction,
}) => {
	const { t } = useTranslation()

	const classes = useStyles({ hasAnotherAction: !!anotherAction })

	const wrapWithFormIfNeeded = (card: ReactElement): ReactElement => {
		if (onSubmit) {
			return (
				<ValidatorForm instantValidate={instantValidate} onSubmit={onSubmit}>
					{card}
				</ValidatorForm>
			)
		}

		return card
	}

	const renderCardHeader = (): ReactElement | null => {
		if (headline) {
			return (
				<CardHeader
					classes={{
						root: parentClasses.cardHeader,
					}}
					title={headline}
				/>
			)
		}

		return null
	}

	const renderCardActions = (): ReactElement | null => {
		if (onSubmit) {
			return (
				<CardActions
					classes={{
						root: clsx(parentClasses.cardActions, classes.cardActions),
					}}
				>
					{anotherAction && anotherAction()}
					<PrimaryButton disabled={disabled} loading={loading} type="submit">
						{buttonLabel || t('domain:checkout.accountToken.form.submit.label')}
					</PrimaryButton>
				</CardActions>
			)
		}

		return null
	}

	return wrapWithFormIfNeeded(
		<Card
			classes={{
				root: parentClasses.cardRoot,
			}}
		>
			{renderCardHeader()}
			<CardContent
				classes={{
					root: parentClasses.cardContent,
				}}
			>
				{children}
			</CardContent>
			{renderCardActions()}
		</Card>
	)
}

export default CheckoutCard
