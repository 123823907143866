import useGraphQLQuery from 'app/services/apollo/hooks/useGraphQLQuery'
import { ProjectData, ProjectVars } from 'app/types/index'
import { UrlParams } from 'app/types/UrlParams'
import { getProjectFromResponse } from 'app/utils/apollo'
import PageContent from 'component/PageContent/PageContent'
import queries from 'domain/tender/bidders/apollo/queries'
import TenderPageContentBody from 'domain/tender/bidders/components/base/TenderPageContentBody/TenderPageContentBody'
import TenderPageContentHeader from 'domain/tender/bidders/components/base/TenderPageContentHeader/TenderPageContentHeader'
import React, { ReactElement, useEffect } from 'react'
import { useParams } from 'react-router-dom'

const TenderPageContent = (): ReactElement | null => {
	const { projectUuid, lotUuid } = useParams<UrlParams>()
	const { data, loading, refetch } = useGraphQLQuery<ProjectData, ProjectVars>(
		queries.ProjectWithTenderInformation,
		{
			variables: { projectUuid, lotUuid },
		}
	)

	const project = getProjectFromResponse(data)
	useEffect(() => {
		refetch()
	}, [projectUuid, lotUuid, refetch])

	return (
		<PageContent
			key={lotUuid}
			header={<TenderPageContentHeader project={project} />}
			body={<TenderPageContentBody loading={loading} project={project} />}
		/>
	)
}

export default TenderPageContent
