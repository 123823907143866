import { makeStyles } from '@material-ui/core'

export default makeStyles(() => ({
	root: {
		width: '100%',
	},
	image: {
		width: '100%',
	},
}))
