import React, { FC } from 'react'
import drawerMenu from 'app/config/drawerMenu'
import { List } from '@material-ui/core'
import DrawerMenuItem from '../DrawerMenuItem/DrawerMenuItem'

const DrawerMenu: FC = () => {
	return (
		<List>
			{drawerMenu.map(configuration => (
				<DrawerMenuItem
					key={configuration.key}
					configuration={configuration}
					parentConfiguration={configuration}
				/>
			))}
		</List>
	)
}

export default DrawerMenu
