import React, { FC } from 'react'
import PageContentBody from 'component/PageContentBody/PageContentBody'
import TenderingEndDate from '../../body/period/TenderingPeriod/TenderingPeriod'

const PeriodPageContentBody: FC = () => {
	return (
		<PageContentBody>
			<TenderingEndDate />
		</PageContentBody>
	)
}

export default PeriodPageContentBody
