import AddIcon from '@material-ui/icons/Add'
import routes from 'app/routing/routes/routesApp'
import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import { resolveRoute } from 'app/utils/route'
import SecondaryButton from 'component/SecondaryButton/SecondaryButton'
import mutations from 'domain/project/apollo/mutations'
import { get } from 'lodash'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import useStyles from 'domain/project/components/header/CreateProjectButton/CreateProjectButtonStyles'
import ValidateDomainEvent from 'component/ValidateDomainEvent/ValidateDomainEvent'
import { DOMAIN_EVENTS } from 'domain/project/enums/DOMAIN_EVENTS'
import { DOMAIN_EVENT_TRIGGER } from 'domain/project/enums/DOMAIN_EVENT_TRIGGER'

const CreateProjectButton: FC = () => {
	const classes = useStyles()

	const { t } = useTranslation()
	const [createProject] = useGraphQLMutation(mutations.createProject, {
		onCompleted: response => {
			const projectUuid = get(response, ['createProject', 'uuid'], null)

			if (projectUuid) {
				window.location.href = resolveRoute(routes.project, { projectUuid })
			}
		},
	})

	const onClick = (): void => {
		createProject({
			variables: {
				name: '',
			},
		})
	}

	return (
		<ValidateDomainEvent
			event={DOMAIN_EVENTS.CREATE_PROJECT}
			trigger={DOMAIN_EVENT_TRIGGER.ON_CLICK}
		>
			<SecondaryButton
				classes={{ root: classes.ButtonRoot }}
				onClick={onClick}
				title={t('domain:project.create.button.title')}
			>
				<AddIcon />
			</SecondaryButton>
		</ValidateDomainEvent>
	)
}

export default CreateProjectButton
