import { Typography } from '@material-ui/core'
import Validate from 'component/Validate/Validate'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'
import React, { FC, ReactNode } from 'react'
import PageContentBody from 'component/PageContentBody/PageContentBody'
import UpdateProjectForm from 'domain/project/components/body/update/UpdateProjectForm/UpdateProjectForm'
import { useTranslation } from 'react-i18next'
import ProjectPageContentBodyStyles from 'domain/project/components/base/ProjectPageContentBody/ProjectPageContentBodyStyles'
import CreateLotForm from 'domain/lot/components/CreateLotForm/CreateLotForm'
import { ProjectPageContentBodyProps } from './ProjectPageContentBodyProps'

const ProjectPageContentBody: FC<ProjectPageContentBodyProps> = ({ lots }) => {
	const classes = ProjectPageContentBodyStyles()
	const { t } = useTranslation()

	const renderLeadIntroduction = (): ReactNode => {
		if (Array.isArray(lots) && lots.length) {
			return (
				<>
					<Typography
						classes={{
							root: classes.textGap,
						}}
					>
						{t('domain:project.introductions.lead.updateProject')}
					</Typography>
					<Typography
						variant="h5"
						classes={{
							root: classes.textGap,
						}}
					>
						{t('domain:project.rename')}
					</Typography>
					<UpdateProjectForm />
				</>
			)
		}
		return <CreateLotForm />
	}

	return (
		<PageContentBody>
			<Validate
				validCombinations={[
					{
						projectRole: ROLES_PROJECT.BIDDER,
					},
				]}
			>
				<Typography
					classes={{
						root: classes.textGap,
					}}
				>
					{t('domain:project.introductions.bidder')}
				</Typography>
			</Validate>
			<Validate validCombinations={[{ projectRole: ROLES_PROJECT.LEAD }]}>
				{renderLeadIntroduction()}
			</Validate>
		</PageContentBody>
	)
}

export default ProjectPageContentBody
