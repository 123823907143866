import { convertApiErrorToErrorMessage } from 'app/errors/codes'
import ConfirmationDialog from 'component/ConfirmationDialog/ConfirmationDialog'
import { ErrorDialogProps } from 'component/ErrorDialog/ErrorDialogProps'
import React, { FC } from 'react'

const ErrorDialog: FC<ErrorDialogProps> = ({ open, error, onAccept }) => {
	if (!error) {
		return null
	}

	return (
		<ConfirmationDialog
			open={open}
			title="Ein Fehler ist aufgetreten!"
			contentText={convertApiErrorToErrorMessage(error)}
			onAcceptLabel="Ok"
			onAccept={onAccept}
		/>
	)
}

export default ErrorDialog
