/* eslint-disable @typescript-eslint/no-explicit-any */
import { getFirstRevisionFromLot } from 'app/utils/apollo'
import React, { useMemo } from 'react'
import { TABLE_FIELDS_BOQ_ANNOTATIONS } from 'app/constants'
import { useTranslation } from 'react-i18next'
import { reactFormatter } from 'react-tabulator'
import AnnotationLinFormatter from 'app/services/tabulator/formatters/lin/AnnotationLinFormatter'
import AnnotationUnitFormatter from 'app/services/tabulator/formatters/AnnotationUnitFormatter'
import AnnotationFormatter from 'app/services/tabulator/formatters/annotation/AnnotationFormatter'
import { Lot } from 'app/types'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'
import useEvents from './useEvents'

export default (lot: Lot | null, role: ROLES_PROJECT | null): any => {
	const revision = getFirstRevisionFromLot(lot)
	const { t } = useTranslation()
	const {
		openAnnotationSideSheet,
		onDeleteBoqAnnotation,
		openAnnotationDetailSideSheet,
	} = useEvents(revision)

	const data: any = [
		{
			field: TABLE_FIELDS_BOQ_ANNOTATIONS.LIN,
			title: t('common:tabulator.columns.reference'),
			width: 180,
			formatter: AnnotationLinFormatter,
		},
		{
			field: TABLE_FIELDS_BOQ_ANNOTATIONS.SHORT_TEXT,
			title: t('common:tabulator.columns.label'),
			widthGrow: 2,
		},
		{
			field: TABLE_FIELDS_BOQ_ANNOTATIONS.UNIT,
			title: t('common:tabulator.columns.qu'),
			formatter: AnnotationUnitFormatter,
		},
		{
			field: TABLE_FIELDS_BOQ_ANNOTATIONS.QUANTITY,
			title: t('common:tabulator.columns.qty'),
		},
		{
			field: TABLE_FIELDS_BOQ_ANNOTATIONS.UNIT_PRICE,
			title: t('common:tabulator.columns.up'),
			align: 'right',
		},
		{
			field: TABLE_FIELDS_BOQ_ANNOTATIONS.ANNOTATION,
			title: t('common:tabulator.columns.annotation'),
			formatter: reactFormatter(
				<AnnotationFormatter
					label={t('domain:award.annotations.updateAnnotation.button.label')}
					onClick={openAnnotationSideSheet}
					onDeleteClick={onDeleteBoqAnnotation}
					onClickDetails={openAnnotationDetailSideSheet}
					lot={lot}
					role={role}
				/>
			),
			width: 230,
		},
	]

	// Attention, useMemo just updates when parameter is provided
	return useMemo(() => data, [revision, lot])
}
