/* eslint-disable @typescript-eslint/no-explicit-any */

import { isLineItem } from 'app/services/tabulator/Callbacks'
import SurchargeFormatter from 'app/services/tabulator/formatters/qty/SurchargeFormatter'
import { BoqNode } from 'app/types/graphql/BoqNode'
import { POSITION_TYPES } from 'app/types/POSITION_TYPES'

export default (cell: any, isEditable?: boolean): string | number => {
	if (!cell || !isLineItem(cell)) {
		return ''
	}

	const boqNode = cell.getData() as BoqNode
	const { type, qty } = boqNode

	if (isEditable) {
		switch (type) {
			case POSITION_TYPES.SURCHARGE:
				return new SurchargeFormatter(cell).format()
			default:
				return qty || ''
		}
	}

	return qty || ''
}
