import DrawerMenuItemLink from 'domain/drawer/components/DrawerMenuItemLink/DrawerMenuItemLink'
import React, { FC, ReactElement } from 'react'
import {
	DrawerMenuItemProps,
	MENU_ITEM_TYPES,
	MENU_ITEM_INVALID_BEHAVIOR,
} from 'app/config/drawerMenu'
import { useParams } from 'react-router-dom'
import Validate from 'component/Validate/Validate'
import { UrlParams } from 'app/types/UrlParams'
import DrawerMenuItemCollapsible from '../DrawerMenuItemCollapsible/DrawerMenuItemCollapsible'
import DrawerMenuItemHeadline from '../DrawerMenuItemHeadline/DrawerMenuItemHeadline'

const DrawerMenuItem: FC<DrawerMenuItemProps> = ({
	configuration,
	parentConfiguration,
}) => {
	const { sectionId, subSectionId } = useParams<UrlParams>()

	const {
		type,
		key,
		validCombinations,
		combinationOperator,
		invalidCombinations,
		invalidBehavior,
	} = configuration

	const isActive = sectionId === configuration.key || subSectionId === key

	const renderTypeComponent = (disabled = false): ReactElement | null => {
		let Component = null
		switch (type) {
			case MENU_ITEM_TYPES.HEADLINE:
				Component = DrawerMenuItemHeadline
				break
			case MENU_ITEM_TYPES.LINK:
				Component = DrawerMenuItemLink
				break
			case MENU_ITEM_TYPES.COLLAPSIBLE:
				Component = DrawerMenuItemCollapsible
				break
			default:
				return null
		}
		return (
			<Component
				active={isActive}
				disabled={disabled}
				configuration={configuration}
				parentConfiguration={parentConfiguration}
			/>
		)
	}

	const handleFallback = (): ReactElement | null => {
		switch (invalidBehavior) {
			case MENU_ITEM_INVALID_BEHAVIOR.DISABLED:
				return renderTypeComponent(true)
			case MENU_ITEM_INVALID_BEHAVIOR.HIDDEN:
			default:
				return null
		}
	}

	return (
		<Validate
			validCombinations={validCombinations}
			combinationOperator={combinationOperator}
			invalidCombinations={invalidCombinations}
			disableLoader
			fallback={handleFallback}
		>
			{renderTypeComponent()}
		</Validate>
	)
}

export default DrawerMenuItem
