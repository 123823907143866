import axios from 'axios'
import { useEnvironmentVariables } from 'app/hooks'
import { useCustomSnackbar } from 'app/services/snackbar'
import { useState } from 'react'
import logout from 'app/hooks/useLogout'

export interface UseResetDb {
	loading: boolean
	sendRequest: () => void
}

export default (): UseResetDb => {
	const [isLoading, setLoading] = useState(false)
	const { REACT_APP_API_URL } = useEnvironmentVariables()
	const { sendErrorMessage, sendInfoMessage } = useCustomSnackbar()

	const sendRequest = (): void => {
		// eslint-disable-next-line no-alert
		if (window.confirm('Are you sure you want to reset the Database?')) {
			setLoading(true)

			axios
				.get(`${REACT_APP_API_URL}/api/v1/admin/database/reset`)
				.then(() => {
					sendInfoMessage('Die Datenbank wurde zurück gesetzt!')
					logout()
				})
				.catch(() => sendErrorMessage('DB Reset failed.'))
				.finally(() => setLoading(false))
		}
	}

	return { loading: isLoading, sendRequest }
}
