import makeStyles from '@material-ui/core/styles/makeStyles'
import { themeValues } from 'app/themes/themeValues'
import { TABULATOR_CHIP_VARIANTS } from 'app/config/tabulator'

export default makeStyles({
	chip: {
		display: 'flex',
		padding: '0 25px',
		height: '30px',
		fontSize: '16px',
		lineHeight: '50px',
		borderRadius: '25px',
		alignItems: 'center',
		width: '120px',
		justifyContent: 'center',
	},
	[TABULATOR_CHIP_VARIANTS.SUCCESS]: {
		backgroundColor: themeValues().colors.success,
		color: 'white',
	},
	[TABULATOR_CHIP_VARIANTS.ERROR]: {
		backgroundColor: themeValues().colors.error,
		color: 'white',
	},
	[TABULATOR_CHIP_VARIANTS.DEFAULT]: {
		backgroundColor: themeValues().colors.default,
	},
	[TABULATOR_CHIP_VARIANTS.WARNING]: {
		backgroundColor: themeValues().colors.warning,
	},
	[TABULATOR_CHIP_VARIANTS.PRIMARY]: {
		backgroundColor: themeValues().colors.primary,
		color: 'white',
	},
})
