import { AttributeInfoItemProps } from 'domain/general/boq/components/sidesheet/attributeInfos/AttributeInfoItem/AttributeInfoItemProps'
import DefaultInfoItem from 'domain/general/boq/components/sidesheet/attributeInfos/DefaultInfoItem/DefaultInfoItem'
import SubQuantityInfoItem from 'domain/general/boq/components/sidesheet/attributeInfos/SubQuantityInfoItem/SubQuantityInfoItem'
import TypeInfoItem from 'domain/general/boq/components/sidesheet/attributeInfos/TypeInfoItem/TypeInfoItem'
import React, { FC, ReactElement, ReactNode } from 'react'
import { SubQuantity } from 'app/types'
import { get } from 'lodash'
import { Unit } from 'app/config/annotations'
import UnitInfoItem from 'domain/general/boq/components/sidesheet/attributeInfos/UnitInfoItem/UnitInfoItem'
import { useTranslation } from 'react-i18next'
import useStyles from './AttributeInfoItemStyles'

const AttributeInfoItem: FC<AttributeInfoItemProps> = ({
	attributeKey,
	boqNode,
	boqAnnotation,
}) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const subQuantities = get(boqNode, 'subQuantities', []) as SubQuantity[]
	const { type, subType } = boqNode || {}
	const unit = get(boqAnnotation, 'unit', null) as Unit

	const translationKey = boqNode
		? t(`domain:boq.node.${attributeKey}`)
		: t(`domain:award.annotations.${attributeKey}`)

	const value = get({ ...boqNode, ...boqAnnotation }, attributeKey, null)

	const renderContent = (): ReactElement | ReactNode | null => {
		switch (attributeKey) {
			case 'subQuantities':
				return <SubQuantityInfoItem subQuantities={subQuantities} />
			case 'type':
				return (
					<TypeInfoItem
						classes={{ headline: classes.headline, content: classes.content }}
						type={type}
						subType={subType}
					/>
				)
			case 'unit':
				return (
					<UnitInfoItem
						classes={{ headline: classes.headline, content: classes.content }}
						unit={unit}
					/>
				)
			default:
				return (
					<DefaultInfoItem
						classes={{
							headline: classes.headline,
							content: classes.content,
						}}
						headline={translationKey}
						value={value}
					/>
				)
		}
	}

	return <div>{renderContent()}</div>
}

export default AttributeInfoItem
