import React, { FC } from 'react'
import PrimaryButton from 'component/PrimaryButton/PrimaryButton'
import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import mutations from 'domain/billing/quantity/apollo/mutations'
import queries from 'domain/billing/quantity/apollo/queries'
import { useParams } from 'react-router-dom'
import { UrlParams } from 'app/types/UrlParams'
import { useTranslation } from 'react-i18next'
import { DeleteCompletionButtonProps } from './DeleteCompletionButtonProps'

const DeleteCompletionButton: FC<DeleteCompletionButtonProps> = ({
	qdUuid,
}) => {
	const { projectUuid, lotUuid } = useParams<UrlParams>()

	const { t } = useTranslation()

	const [deleteCompletion, { loading }] = useGraphQLMutation(
		mutations.deleteCompletion
	)

	const handleClick = (): void => {
		if (projectUuid && lotUuid && qdUuid) {
			deleteCompletion({
				variables: {
					projectUuid,
					lotUuid,
					qdUuid,
				},
				refetchQueries: [
					{
						query: queries.LotWithQuantityDetermination,
						variables: {
							projectUuid,
							lotUuid,
							qdUuid,
						},
					},
				],
			})
		}
	}

	return (
		<PrimaryButton loading={loading} onClick={handleClick}>
			{t('domain:billing.deleteCompletion.button.label')}
		</PrimaryButton>
	)
}

export default DeleteCompletionButton
