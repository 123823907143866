/* eslint-disable @typescript-eslint/no-explicit-any */
import { TABLE_FIELDS_BOQ } from 'app/constants'
import { RowFormatterDefault } from 'app/services/tabulator'

export interface UseOptions {
	options: any
}

const caretDown =
	'<svg class="MuiSvgIcon-root jss416 caretDown" focusable="false" viewBox="0 0 24 16" preserveAspectRatio="xMidYMid slice" aria-hidden="true"><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"/></svg>'

const caretRight =
	'<svg class="MuiSvgIcon-root jss416" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z"/></svg>'

export default (): UseOptions => {
	const getOptions = (): any => ({
		dataTree: true,
		dataTreeChildField: 'boqNodes',
		dataTreeStartExpanded: true,
		dataTreeCollapseElement: caretDown,
		dataTreeExpandElement: caretRight,
		headerSort: false,
		layout: 'fitColumns',
		rowFormatter: RowFormatterDefault,
		tooltips: (cell: any) => {
			const { field } = cell.getColumn().getDefinition()
			return field === TABLE_FIELDS_BOQ.LABEL ? cell.getValue() : ''
		},
	})

	return { options: getOptions() }
}
