/* eslint-disable @typescript-eslint/no-explicit-any */

import UpBaseFormatter from 'app/services/tabulator/formatters/up/UpBaseFormatter'
import { POSITION_TYPES } from 'app/types/POSITION_TYPES'
import { toNumber } from 'lodash'

export default class NormalFormatter extends UpBaseFormatter {
	constructor(cell: any, type = POSITION_TYPES.NORMAL) {
		super(cell, type)
	}

	format(): string | number {
		const cellValue = this.cell.getValue()

		if (!cellValue || toNumber(cellValue) <= 0) {
			this.markAsInvalid()
		} else {
			this.markAsValid()
		}

		return super.format()
	}
}
