import MomentUtils from '@date-io/moment'
import Grid from '@material-ui/core/Grid'
import { Calendar, MuiPickersUtilsProvider } from '@material-ui/pickers'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import useCustomSnackbar from 'app/services/snackbar/useCustomSnackbar'
import {
	getTenderingEndDateFromLot,
	getStatusFromLot,
	getLotFromProject,
	getProjectFromResponse,
} from 'app/utils/apollo'
import { hasValidCombinations } from 'app/utils/validator'
import DatePicker from 'component/DatePicker/DatePicker'
import Loading from 'component/Loading/Loading'
import Notice from 'component/Notice/Notice'
import PrimaryButton from 'component/PrimaryButton/PrimaryButton'
import TimePicker from 'component/TimePicker/TimePicker'
import { Moment } from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { TENDERING_END_DATE_EXTRA_MINUTES } from 'app/config/locales'

import { LOT_STATUS } from 'domain/lot/interfaces/LOT_STATUS'
import { CombinationOperator } from 'app/types/CombinationOperator'
import queries from 'domain/tender/period/apollo/queries'
import mutations from 'domain/tender/period/apollo/mutations'
import { UrlParams } from 'app/types/UrlParams'
import useStyles from './TenderingPeriodStyles'

const TenderingPeriod: FC = () => {
	const { t } = useTranslation()
	const { sendSuccessMessage, sendErrorMessage } = useCustomSnackbar()
	const { projectUuid, lotUuid } = useParams<UrlParams>()
	const [selectedDate, setSelectedDate] = useState<Moment | null>(null)
	const [selectedTime, setSelectedTime] = useState<Moment | null>(null)
	const [noPredefinedDate, setNoPredefinedDate] = useState<boolean>(true)
	const [timeOfFirstRender, setTimeofFirstRender] = useState<Moment | null>(
		null
	)
	const classes = useStyles({ noPredefinedDate })

	const [
		fetchTenderEndDate,
		{ data, loading: queryLoading },
	] = useGraphQLLazyQuery(queries.TenderEndDateForLot)
	const [
		createTenderingEndDate,
		{ loading: mutationLoading },
	] = useGraphQLMutation(mutations.createTenderingEndDate, {
		onCompleted: () =>
			sendSuccessMessage(t('domain:tender.createTenderingPeriod.success')),
	})

	const now = new MomentUtils()
		.date()
		.add(TENDERING_END_DATE_EXTRA_MINUTES, 'm')
	const project = getProjectFromResponse(data)
	const lot = getLotFromProject(project)
	const endDate = getTenderingEndDateFromLot(lot)
	const status = getStatusFromLot(lot)

	useEffect(() => {
		if (projectUuid && lotUuid) {
			fetchTenderEndDate({
				variables: {
					projectUuid,
					lotUuid,
				},
			})
		}
	}, [projectUuid, lotUuid])

	useEffect(() => {
		if (endDate) {
			const endDateMoment = new MomentUtils().date(endDate)
			setSelectedDate(endDateMoment)
			setSelectedTime(endDateMoment)
		} else {
			setSelectedDate(null)
			setSelectedTime(null)
		}
	}, [endDate])

	const getSelectedTimeAndDate = (): Moment | null => {
		if (
			!selectedDate ||
			!selectedDate.isValid() ||
			!selectedTime ||
			!selectedTime.isValid()
		) {
			return null
		}
		return selectedDate.set({
			hour: selectedTime.get('hour'),
			minute: selectedTime.get('minute'),
			second: selectedTime.get('second'),
		})
	}

	const isDateInThePast = (): boolean => {
		const finalDate = getSelectedTimeAndDate()
		if (finalDate && timeOfFirstRender) {
			if (finalDate >= timeOfFirstRender) {
				return false
			}
			return true
		}
		return false
	}

	const isStatusCreatedOrTendering = (): boolean => {
		return hasValidCombinations(
			[
				{
					lotStatus: LOT_STATUS.CREATED,
				},
				{
					lotStatus: LOT_STATUS.TENDERING,
				},
			],
			{ lotStatus: status, combinationOperator: CombinationOperator.OR }
		)
	}

	const hasTenderingEnded = (): boolean => {
		return hasValidCombinations(
			[
				{
					lotStatus: LOT_STATUS.AWARDING,
				},
				{
					lotStatus: LOT_STATUS.AWARDED,
				},
				{
					lotStatus: LOT_STATUS.CONFIRMED,
				},
			],
			{ lotStatus: status, combinationOperator: CombinationOperator.OR }
		)
	}

	const isDateInThePastAndHasValidStatus = (): boolean =>
		isStatusCreatedOrTendering() && isDateInThePast()

	const isTenderingEndDateValid = (): boolean => {
		const finalDate = getSelectedTimeAndDate()

		if (!finalDate || !finalDate.isValid()) {
			return false
		}

		return !endDate || !finalDate.isSame(endDate)
	}

	const onUpdate = (): void => {
		const date = getSelectedTimeAndDate()

		if (date) {
			createTenderingEndDate({
				variables: {
					projectUuid,
					lotUuid,
					tenderingEndDate: date.toISOString(),
				},
			})
		} else {
			sendErrorMessage(
				t('domain:tender.createTenderingPeriod.validatorViolations.invalidData')
			)
		}
	}

	const disableButton = (): boolean => {
		if (isStatusCreatedOrTendering()) {
			if (!isTenderingEndDateValid() || isDateInThePastAndHasValidStatus()) {
				return true
			}
			return false
		}
		return true
	}

	const pickMessage = (): string => {
		if (hasTenderingEnded()) {
			return t('domain:tender.createTenderingPeriod.notice.ended.message')
		}

		if (!selectedDate) {
			return t('domain:tender.createTenderingPeriod.notice.chooseDate.message')
		}

		if (selectedDate && isDateInThePastAndHasValidStatus()) {
			return t(
				'domain:tender.createTenderingPeriod.validatorViolations.minDate'
			)
		}

		if (isStatusCreatedOrTendering()) {
			return t('domain:tender.createTenderingPeriod.notice.info.message')
		}
		return t('domain:tender.createTenderingPeriod.notice.ended.message')
	}

	// disable selected date style when no date has been selected
	useEffect(() => {
		if (!endDate) {
			if (selectedDate) {
				setNoPredefinedDate(false)
			}
		} else {
			setNoPredefinedDate(false)
		}
	}, [selectedDate])

	useEffect(() => {
		setTimeofFirstRender(new MomentUtils().date())
	}, [])

	/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
	const handleCalendarChange = (event: any): void => {
		setSelectedDate(event)
		if (!selectedTime) {
			setSelectedTime(event)
		}
	}

	return (
		<div className={classes.root}>
			<MuiPickersUtilsProvider utils={MomentUtils} locale="de">
				<Loading loading={queryLoading}>
					{isDateInThePastAndHasValidStatus() ? (
						<Notice
							classes={{
								root: classes.noticeRoot,
							}}
							message={t(
								'domain:tender.createTenderingPeriod.validatorViolations.minDate'
							)}
							type="error"
						/>
					) : (
						<Notice
							classes={{
								root: classes.noticeRoot,
							}}
							message={pickMessage()}
							type="info"
						/>
					)}
					<Grid className={classes.grid}>
						<Grid container spacing={1}>
							<Grid item xs={6}>
								<DatePicker
									value={selectedDate}
									onChange={handleCalendarChange}
									disabled={!isStatusCreatedOrTendering()}
								/>
							</Grid>
							<Grid item xs={6}>
								<TimePicker
									disabled={!isStatusCreatedOrTendering()}
									value={selectedTime}
									onChange={setSelectedTime}
									error={isDateInThePastAndHasValidStatus()}
								/>
							</Grid>
						</Grid>

						<Calendar
							allowKeyboardControl={false}
							disablePast
							disableFuture={!isStatusCreatedOrTendering()}
							shouldDisableDate={() => !isStatusCreatedOrTendering()}
							date={selectedDate || now}
							onChange={handleCalendarChange}
						/>

						<PrimaryButton
							disabled={disableButton()}
							onClick={onUpdate}
							loading={mutationLoading}
						>
							{t('domain:tender.createTenderingPeriod.form.submit.label')}
						</PrimaryButton>
					</Grid>
				</Loading>
			</MuiPickersUtilsProvider>
		</div>
	)
}

export default TenderingPeriod
