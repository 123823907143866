import { gql } from 'apollo-boost'

const createTenderingEndDate = gql`
	mutation createTenderingEndDate(
		$projectUuid: String!
		$lotUuid: String!
		$tenderingEndDate: String
	) {
		project(uuid: $projectUuid) {
			updateLot(uuid: $lotUuid, tenderingEndDate: $tenderingEndDate) {
				uuid
				tenderingEndDate
			}
		}
	}
`

const createAwarding = gql`
	mutation createAwarding($projectUuid: String!, $lotUuid: String!) {
		project(uuid: $projectUuid) {
			lot(uuid: $lotUuid) {
				uuid
				status
				__typename
				createAwarding {
					uuid
					status
					__typename
				}
			}
		}
	}
`

export default { createTenderingEndDate, createAwarding }
