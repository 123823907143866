import routes from 'app/routing/routes/routesApp'
import Link from 'component/Link/Link'
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import PageContentHeading1 from 'component/PageContentHeading1/PageContentHeading1'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import useAuthentication from 'app/hooks/useAuthentication'
import useForm from 'app/hooks/useForm'
import PrimaryButton from 'component/PrimaryButton/PrimaryButton'
import RenderErrorMessage from 'component/RenderErrorMessage/RenderErrorMessage'
import React, { FC, useState, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Validate from 'component/Validate/Validate'
import { ENVIRONMENTS } from 'app/constants'
import PageContentParagraph from 'component/PageContentParagraph/PageContentParagraph'

import useStyles from './LoginFormStyles'

const LoginForm: FC = () => {
	const classes = useStyles()

	const { t } = useTranslation()
	const { loading, login, error, resetError } = useAuthentication()
	const { formData, handleInputChange } = useForm({
		defaultValues: { username: '', password: '' },
	})
	const [isPasswordVisible, setPasswordVisibility] = useState(false)

	const customHandleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
		resetError()
		handleInputChange(e)
	}

	const onSubmit = (): void => {
		const { username, password } = formData

		if (username && password) {
			login(username as string, password as string)
		}
	}

	const handlePasswordVisibility = (): void => {
		setPasswordVisibility(!isPasswordVisible)
	}

	return (
		<div className={classes.root}>
			<PageContentHeading1 className={classes.header}>
				{t('domain:login.title')}
			</PageContentHeading1>
			<Validate validCombinations={[{ environment: ENVIRONMENTS.DEMO }]}>
				<PageContentParagraph>
					{t('domain:login.demoContent')}
				</PageContentParagraph>
			</Validate>
			<ValidatorForm onSubmit={onSubmit} instantValidate={false}>
				<FormGroup>
					<TextValidator
						classes={{ root: classes.input }}
						validators={['required', 'isEmail']}
						errorMessages={[
							t('domain:login.form.email.validatorViolations.required'),
							t('domain:login.form.email.validatorViolations.isEmail'),
						]}
						type="email"
						name="username"
						value={formData.username}
						onChange={customHandleInputChange}
						label={t('domain:login.form.email.label')}
						fullWidth
						variant="filled"
					/>
					<TextValidator
						classes={{ root: classes.input }}
						label={t('domain:login.form.password.label')}
						validators={['required']}
						errorMessages={[
							t('domain:login.form.password.validatorViolations.required'),
						]}
						type={isPasswordVisible ? 'text' : 'password'}
						name="password"
						onChange={customHandleInputChange}
						value={formData.password}
						fullWidth
						variant="filled"
						aria-describedby="component-error-text"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="Toggle password visibility"
										onClick={handlePasswordVisibility}
									>
										{isPasswordVisible ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<RenderErrorMessage error={error} />
					<div className={classes.actions}>
						<Validate
							invalidCombinations={[{ environment: ENVIRONMENTS.DEMO }]}
						>
							<Link className={classes.link} to={routes.checkoutFirstStep}>
								{t('domain:login.checkoutLink.linkTitle')}
							</Link>
						</Validate>
						<PrimaryButton type="submit" loading={loading}>
							{t('domain:login.form.submit')}
						</PrimaryButton>
					</div>
				</FormGroup>
			</ValidatorForm>
		</div>
	)
}

export default LoginForm
