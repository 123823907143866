/* eslint-disable @typescript-eslint/no-explicit-any */
import { QUANTITY_DETERMINATION_STATUS } from 'app/config/quantityDetermination'
import {
	removeClass,
	addClasses,
	getFirstChildFromCell,
	shouldClassBeRemoved,
} from 'app/utils/tabulator'
import { hasEditableCellValidCombination } from 'app/utils/validator'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'
import { get } from 'lodash'
import { POSITION_TYPES } from 'app/types/POSITION_TYPES'

export default (
	role: ROLES_PROJECT | null,
	cell: any,
	quantityDeterminationStatus: QUANTITY_DETERMINATION_STATUS | null
): string | number => {
	const cellElement = cell.getElement()
	const {
		type,
		status: quantityDeterminationNodeStatus,
	} = cell.getRow().getData()
	const value = cell.getValue()

	const childType = get(getFirstChildFromCell(cell), ['data', 'type'])

	if (
		hasEditableCellValidCombination({
			quantityDeterminationStatus,
			quantityDeterminationNodeStatus,
			type,
			role,
			childType,
		})
	) {
		if (type !== POSITION_TYPES.SUGGESTION) {
			if (value) {
				addClasses(cellElement, ['table-cell-valid'])
				removeClass(cellElement, 'table-cell-invalid')
			} else {
				addClasses(cellElement, ['table-cell-invalid'])
				removeClass(cellElement, 'table-cell-valid')
			}
		}
	}

	if (shouldClassBeRemoved(role, quantityDeterminationStatus)) {
		removeClass(cellElement, 'table-cell-valid')
	}

	if (type === POSITION_TYPES.SUGGESTION) {
		addClasses(cellElement, ['suggestion-label-cell'])
	}

	return value
}
