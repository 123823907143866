import { makeStyles, Theme } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'

export default makeStyles((theme: Theme) => ({
	root: {
		color: 'white',
	},
	message: {},
	info: {
		background: theme.palette.primary.light,
	},
	error: {
		background: theme.palette.error.main,
	},
	success: {
		background: themeValues().colors.success,
	},
}))
