import {
	STEPS_CHECKOUT,
	CHECKOUT_VALIDATION_RULES,
	CheckoutValidationRule,
	CHECKOUTDATA_KEYS,
} from 'app/config/checkout'
import routes from 'app/routing/routes/routesApp'
import { resolveRoute } from 'app/utils/route'
import { CheckoutFlowValidatorProps } from 'domain/checkout/components/CheckoutFlowValidator/CheckoutFlowValidatorProps'
import React, { FC, useEffect, useState, ReactElement } from 'react'
import { get, isInteger, invert, omit } from 'lodash'
import { useHistory } from 'react-router-dom'
import useTracking from 'app/services/datawarehouse/useTracking'
import TrackingEvents from 'app/services/datawarehouse/TrackingEvents'
import { useLocalStorage } from 'app/hooks'
import { FormInputData } from 'app/types/FormInputData'
import { CHECKOUT_DATA } from 'app/constants'

const CheckoutFlowValidator: FC<CheckoutFlowValidatorProps> = ({
	children,
	stepId,
	checkoutData,
	accessToken,
}) => {
	const { push } = useHistory()
	const [activeChild, setActiveChild] = useState<ReactElement | null>()

	const { track } = useTracking()

	const pricingPlan = get(checkoutData, CHECKOUTDATA_KEYS.PRICING_PLAN, null)
	const isAuthenticated = get(checkoutData, 'isAuthenticated', false)

	const [storedData] = useLocalStorage<FormInputData | null>(
		CHECKOUT_DATA,
		null
	)

	const goToStep = (step: STEPS_CHECKOUT | number): void => {
		let checkoutStepId: STEPS_CHECKOUT | number = 0

		if (isInteger(step)) {
			checkoutStepId = Object.values(STEPS_CHECKOUT)[step as number]
		} else {
			checkoutStepId = step as STEPS_CHECKOUT
		}

		push(
			resolveRoute(routes.checkoutSteps, {
				stepId: checkoutStepId,
			})
		)
	}

	const findRuleForStep = (
		step: STEPS_CHECKOUT
	): CheckoutValidationRule | undefined => {
		return CHECKOUT_VALIDATION_RULES.find(rule => {
			return step === rule.stepId
		})
	}

	const updateChild = (): void => {
		const childrenAsArray = React.Children.toArray(children)

		const currentChild = childrenAsArray.find(child => {
			const {
				props: { stepId: componentStepId },
			} = child as ReactElement

			return componentStepId === stepId
		}) as ReactElement | undefined

		if (currentChild) {
			setActiveChild(currentChild)
		} else {
			setActiveChild(null)
		}
	}

	const findSkipToIndex = (): number => {
		let nextStep = 0
		const indexOfCurrentStep = Object.values(STEPS_CHECKOUT).indexOf(
			stepId as STEPS_CHECKOUT
		)

		Object.keys(STEPS_CHECKOUT).forEach((value, index) => {
			if (index > indexOfCurrentStep) {
				const foundRule = CHECKOUT_VALIDATION_RULES.find(rule => {
					return Object.values(STEPS_CHECKOUT)[index] === rule.stepId
				})

				if (foundRule && !foundRule.skipIfAuthenticated) {
					if (!nextStep) {
						nextStep = index
					}
				}
			}
		})

		return nextStep
	}

	const isStepValid = (step: STEPS_CHECKOUT = stepId): boolean => {
		const checkoutDataKeys = Object.keys(checkoutData)
		const rule = findRuleForStep(step)

		if (rule) {
			if (rule.requiresAuthentication && !accessToken && !isAuthenticated) {
				return false
			}

			return rule.requiredDataKeys.every((el: string) => {
				return checkoutDataKeys.indexOf(el) > -1
			})
		}

		return false
	}

	const findLastValidStep = (): number => {
		let nextStep = 0
		const indexOfCurrentStep = Object.values(STEPS_CHECKOUT).indexOf(
			stepId as STEPS_CHECKOUT
		)

		Object.keys(STEPS_CHECKOUT).forEach((value, index) => {
			if (index < indexOfCurrentStep) {
				const foundRule = CHECKOUT_VALIDATION_RULES.find(rule => {
					return Object.values(STEPS_CHECKOUT)[index] === rule.stepId
				})

				if (foundRule) {
					if (isStepValid(foundRule.stepId)) {
						nextStep = index
					}
				}
			}
		})
		return nextStep
	}

	const isStepSkipable = (): boolean => {
		const foundRule = findRuleForStep(stepId)

		if (foundRule) {
			return (
				foundRule.skipIfAuthenticated && (!!accessToken || !!isAuthenticated)
			)
		}

		return false
	}

	const handleTracker = (): void => {
		const stepKey = invert(STEPS_CHECKOUT)[stepId]
		if (Object.keys(checkoutData).length !== 0) {
			return track(
				`${stepKey}_ENTER` as keyof typeof TrackingEvents,
				omit(checkoutData, ['password', 'passwordCheck'])
			)
		}

		if (storedData) {
			return track(
				`${stepKey}_ENTER` as keyof typeof TrackingEvents,
				storedData
			)
		}
		return track(`${stepKey}_ENTER` as keyof typeof TrackingEvents)
	}

	useEffect(() => {
		if (stepId) {
			handleTracker()
			updateChild()
		}
	}, [stepId])

	useEffect(() => {
		if (activeChild) {
			if (isStepSkipable()) {
				goToStep(findSkipToIndex())
				return
			}

			if (pricingPlan && isStepValid()) {
				return
			}

			if (pricingPlan && !isStepValid()) {
				goToStep(findLastValidStep())
				return
			}

			if (!pricingPlan && stepId !== STEPS_CHECKOUT.STEP_PRICING_PLAN) {
				push(routes.checkout)
			}
		}
	}, [activeChild])

	return <>{children}</>
}

export default CheckoutFlowValidator
