import React, { FC } from 'react'
import { Typography } from '@material-ui/core'
import { PageContentHeading2Props } from './PageContentHeading2Props'

const PageContentHeading2: FC<PageContentHeading2Props> = ({
	children,
	className,
	gutterBottom,
}) => {
	return (
		<Typography
			gutterBottom={gutterBottom}
			className={className}
			variant="h5"
			component="h2"
		>
			{children}
		</Typography>
	)
}

export default PageContentHeading2
