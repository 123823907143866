import { Typography } from '@material-ui/core'
import { BreadCrumbProps } from 'component/BreadCrumb/BreadCrumbProps'
import Link from 'component/Link/Link'
import React, { FC } from 'react'

import useStyles from 'component/BreadCrumb/BreadCrumbStyles'

const BreadCrumb: FC<BreadCrumbProps> = ({
	label,
	disabled,
	to,
	onClick,
	active,
}) => {
	const classes = useStyles()

	if (active) {
		return (
			<Typography
				classes={{
					root: classes.active,
				}}
			>
				{label}
			</Typography>
		)
	}

	if (disabled || !to) {
		return (
			<Typography
				classes={{
					root: classes.clickable,
				}}
				onClick={onClick}
			>
				{label}
			</Typography>
		)
	}

	return (
		<Link to={to}>
			<Typography component="p">{label}</Typography>
		</Link>
	)
}

export default BreadCrumb
