import { makeStyles, Theme } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'

export default makeStyles((theme: Theme) => ({
	root: {
		height: themeValues().sizes.TabHeader.height,
		background: themeValues().gradients.background,
		position: 'relative',
		color: 'white',
	},
	appBar: {
		height: 160,
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row',
	},
	appBarTop: {
		display: 'flex',
		width: '100%',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(2),
	},
	toolbar: { width: '100%', justifyContent: 'flex-end', alignItems: 'start' },
	iconButton: {
		padding: theme.spacing(1),
	},
	logoRoot: {
		maxWidth: 300,
		textAlign: 'center',
		paddingTop: theme.spacing(1),
	},
	logoImage: {
		maxWidth: 210,
	},
	projectDropdownWrapper: {
		display: 'flex',
		width: '100%',
		alignItems: 'flex-end',
	},
	projectDropdownRoot: {
		maxWidth: 300,
		minWidth: 300,
		padding: `${theme.spacing(1.25)}px ${theme.spacing(2)}px`,
		paddingTop: theme.spacing(2),
	},
}))
