import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import { ProfileMenuItemProps } from 'domain/header/components/profileMenu/ProfileMenuItem/ProfileMenuItemProps'
import React, { FC, ReactElement } from 'react'

const ProfileMenuItem: FC<ProfileMenuItemProps> = ({ onClick, item }) => {
	const { icon: Icon, title } = item
	const renderIcon = (): ReactElement | null => {
		if (Icon) {
			return (
				<ListItemIcon>
					<Icon />
				</ListItemIcon>
			)
		}

		return null
	}

	return (
		<MenuItem onClick={onClick} disabled={!onClick}>
			{renderIcon()}
			{title && <ListItemText primary={title}>{title}</ListItemText>}
		</MenuItem>
	)
}

export default ProfileMenuItem
