import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
	root: {
		color: theme.palette.error.main,
		'&:hover': {
			background: theme.palette.error.light,
			color: theme.palette.error.contrastText,
		},
	},
}))
