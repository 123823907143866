import { SnackbarContent } from '@material-ui/core'
import clsx from 'clsx'
import { NoticeProps } from 'component/Notice/NoticeProps'
import React, { FC } from 'react'

import useStyles from 'component/Notice/NoticeStyles'

const Notice: FC<NoticeProps> = ({
	message,
	type = 'info',
	classes: parentClasses = {},
}) => {
	const classes = useStyles({ type })

	return (
		<SnackbarContent
			square
			elevation={0}
			role="alertdialog"
			classes={{
				root: clsx(parentClasses.root, classes.root),
				message: clsx(parentClasses.message, classes.message),
			}}
			className={classes[type]}
			message={message}
		/>
	)
}

export default Notice
