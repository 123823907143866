/* eslint-disable @typescript-eslint/no-explicit-any */

import { removeClass, addClasses } from 'app/utils/tabulator'

class BaseFormatter {
	private _cell: any

	private _cssClasses: string[] = []

	get cell(): any {
		return this._cell
	}

	set cell(value: any) {
		this._cell = value
	}

	get cssClasses(): string[] {
		return this._cssClasses
	}

	set cssClasses(value: string[]) {
		this._cssClasses = value
	}

	addCssClass(value: string): BaseFormatter {
		this._cssClasses.push(value)
		return this
	}

	constructor(cell: any) {
		this._cell = cell
	}

	markAsValid(): void {
		this._cssClasses.push('table-cell-valid')
		removeClass(this.cell.getElement(), 'table-cell-invalid')
	}

	markAsInvalid(): void {
		this._cssClasses.push('table-cell-invalid')
		removeClass(this.cell.getElement(), 'table-cell-valid')
	}

	formatCellValue(): string | number {
		return this._cell.getValue()
	}

	format(): string | number {
		const value = this.formatCellValue()
		addClasses(this._cell.getElement(), this._cssClasses)
		return value
	}
}

export default BaseFormatter
