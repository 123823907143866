import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Link from 'component/Link/Link'
import useStyles from 'domain/register/components/InvalidLinkCardContent/InvalidLinkCardContentStyles'
import React, { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

const InvalidLinkCardContent: FC = () => {
	const { t } = useTranslation()
	const classes = useStyles()
	return (
		<CardContent className={classes.root}>
			<Typography variant="h5" component="h2" gutterBottom>
				{t('domain:register.complete.validatorViolations.invalidLink.title')}
			</Typography>
			<Typography component="p">
				<Trans
					defaults="domain:register.complete.validatorViolations.invalidLink.content"
					values={{
						linkTitle: t(
							`domain:register.complete.validatorViolations.invalidLink.linkTitle`
						),
					}}
					components={[<Link to="support@tenderframe.de">linkTitle</Link>]}
				/>
			</Typography>
		</CardContent>
	)
}

export default InvalidLinkCardContent
