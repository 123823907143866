import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import useCustomSnackbar from 'app/services/snackbar/useCustomSnackbar'
import { UrlParams } from 'app/types/UrlParams'
import {
	getLotFromProject,
	getProjectFromResponse,
	getAttachmentParentsFromLot,
	getAttachmentAuthorsFromLot,
	getAttachmentsFromLot,
} from 'app/utils/apollo'
import Loading from 'component/Loading/Loading'
import PageContentBody from 'component/PageContentBody/PageContentBody'
import Validate from 'component/Validate/Validate'
import mutations from 'domain/general/attachments/apollo/mutations'
import queries from 'domain/general/attachments/apollo/queries'
import { AttachmentPageContentBodyProps } from 'domain/general/attachments/components/base/AttachmentPageContentBody/AttachmentPageContentBodyProps'
import AttachmentDropzone from 'domain/general/attachments/components/body/attachments/AttachmentDropzone/AttachmentDropzone'
import FileAttachmentCard from 'domain/general/attachments/components/body/attachments/FileAttachmentCard/FileAttachmentCard'
import FolderAttachmentCard from 'domain/general/attachments/components/body/attachments/FolderAttachmentCard/FolderAttachmentCard'
import FolderCreateAttachmentCard from 'domain/general/attachments/components/body/attachments/FolderCreateAttachmentCard/FolderCreateAttachmentCard'
import AttachmentBreadCrumbs from 'domain/general/attachments/components/body/navigation/AttachmentBreadCrumbs/AttachmentBreadCrumbs'
import { ATTACHMENT_TYPES } from 'domain/general/attachments/interfaces/ATTACHMENT_TYPES'
import { AttachmentAuthor } from 'domain/general/attachments/interfaces/AttachmentAuthor'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import useStyles from 'domain/general/attachments/components/base/AttachmentPageContentBody/AttachmentPageContentBodyStyles'

const AttachmentPageContentBody: FC<AttachmentPageContentBodyProps> = () => {
	const classes = useStyles()

	const { sendErrorMessage } = useCustomSnackbar()
	const { t } = useTranslation()

	const [parentUuid, setParentUuid] = useState<string | null>(null)
	const [author, setAuthor] = useState<AttachmentAuthor | null>(null)
	const [hasFakeFolder, setHasFakeFolder] = useState(false)

	const { projectUuid, lotUuid } = useParams<UrlParams>()

	const [createFolder] = useGraphQLMutation(mutations.createFolder)

	const [fetchData, { data, loading }] = useGraphQLLazyQuery(
		queries.ProjectWithLotAttachments,
		{
			fetchPolicy: 'network-only',
		}
	)

	const project = getProjectFromResponse(data)
	const lot = getLotFromProject(project)
	const attachments = getAttachmentsFromLot(lot)
	const attachmentsParents = getAttachmentParentsFromLot(lot)
	const attachmentAuthors = getAttachmentAuthorsFromLot(lot) || []

	useEffect(() => {
		if (projectUuid && lotUuid) {
			fetchData({
				variables: {
					projectUuid,
					lotUuid,
					parentUuid,
					authorUuid: author ? author.uuid : null,
				},
			})
		}
	}, [parentUuid, lotUuid, author])

	useEffect(() => {
		setParentUuid(null)
	}, [projectUuid, lotUuid])

	useEffect(() => {
		setHasFakeFolder(false)
	}, [attachments])

	const renderAttachments = (): ReactNode | null => {
		if (Array.isArray(attachments) && attachments.length > 0) {
			return attachments.map(attachment => {
				let Component = FolderAttachmentCard

				if (ATTACHMENT_TYPES.FILE === attachment.type) {
					Component = FileAttachmentCard
				}

				return (
					<Component
						onRefetch={uuid => {
							setParentUuid(uuid)
						}}
						attachment={attachment}
						classes={{
							root: classes.AttachmentCardRoot,
						}}
					/>
				)
			})
		}

		return null
	}

	const onAccept = (folderName: string): undefined | void => {
		if (!folderName) {
			sendErrorMessage(
				t('domain:attachment.createFolder.validatorViolations.empty')
			)
			return
		}

		createFolder({
			variables: {
				projectUuid,
				lotUuid,
				parentUuid,
				folderName,
				authorUuid: null,
			},
			refetchQueries: [
				{
					query: queries.ProjectWithLotAttachments,
					variables: { projectUuid, lotUuid, parentUuid, authorUuid: null },
				},
			],
		})
	}

	return (
		<PageContentBody>
			<div className={classes.pageContentHead}>
				<AttachmentBreadCrumbs
					onNewFolder={setHasFakeFolder}
					onBreadCrumbClick={setParentUuid}
					parents={attachmentsParents || []}
					author={author}
				/>

				<Validate validCombinations={[{ projectRole: ROLES_PROJECT.LEAD }]}>
					<Autocomplete
						options={attachmentAuthors}
						noOptionsText={t('domain:attachment.filter.author.empty')}
						getOptionLabel={option => option.name}
						/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
						onChange={(event: any, selectedAuthor: AttachmentAuthor | null) => {
							setAuthor(selectedAuthor)
						}}
						classes={{
							root: classes.authorFilter,
						}}
						renderInput={params => (
							<TextField
								{...params}
								label={t('domain:attachment.filter.author.label')}
								variant="outlined"
								fullWidth
							/>
						)}
					/>
				</Validate>
			</div>
			<Loading loading={loading}>
				<div className={classes.root}>
					{hasFakeFolder && (
						<FolderCreateAttachmentCard
							onAccept={onAccept}
							onAbort={() => setHasFakeFolder(false)}
							classes={{
								root: classes.AttachmentCardRoot,
							}}
						/>
					)}
					{renderAttachments()}
					<AttachmentDropzone
						parentUuid={parentUuid}
						classes={{
							root: classes.AttachmentDropzone,
						}}
					/>
				</div>
			</Loading>
		</PageContentBody>
	)
}

export default AttachmentPageContentBody
