import { makeStyles, Theme } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'

export default makeStyles((theme: Theme) => ({
	'@global': {
		body: {
			minWidth: 0,
		},
	},
	root: {
		minHeight: '100vh',
		backgroundImage: `URL(${themeValues().images.login})`,
		backgroundPosition: 'center center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
	},
	sideBox: {
		backgroundColor: themeValues().colors.SideBoxLogin.color,
		maxWidth: '600px',
		'@media only screen and (max-width: 768px)': {
			maxWidth: '100%',
		},
		minHeight: '100vh',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'start',
		alignItems: 'center',
		paddingTop: theme.spacing(8),
	},
	logoImage: {
		width: 280,
	},
	logoRoot: {
		width: 300,
		textAlign: 'center',
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(7),
		padding: theme.spacing(6),
	},
}))
