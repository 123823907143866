import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
	root: {
		width: '100%',
		overflow: 'hidden',
	},
	tabs: {
		'& .MuiTab-root + .MuiTab-root': {
			marginLeft: theme.spacing(1.3),
		},
	},
	flexContainer: {
		alignItems: 'flex-end',
	},
	scrollButtonsDesktop: {},
	'@media (max-width: 599.95px)': {
		scrollButtonsDesktop: {
			display: 'block',
		},
	},
	indicator: {
		display: 'none',
	},
}))
