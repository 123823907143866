import VisibilityIcon from '@material-ui/icons/Visibility'
import useSideSheet from 'app/hooks/useSideSheet'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import useCustomSnackbar from 'app/services/snackbar/useCustomSnackbar'
import ContactTagsFormatter from 'app/services/tabulator/formatters/ContactTagsFormatter'
import IconFormatter from 'app/services/tabulator/formatters/IconFormatter'
import { Collaboration } from 'app/types/graphql/Collaboration'
import { Contact } from 'app/types/index'
import { UrlParams } from 'app/types/UrlParams'
import {
	getTotalAccessCountFromLot,
	getCollaborationsFromLot,
	getLotFromProject,
	getProjectFromResponse,
	getContactsFromResponse,
} from 'app/utils/apollo'
import ConfirmationDialog from 'component/ConfirmationDialog/ConfirmationDialog'
import Loading from 'component/Loading/Loading'
import Table from 'component/Table/Table'
import TableSwitch from 'component/TableSwitch/TableSwitch'
import useValidateDomainEvent from 'component/ValidateDomainEvent/useValidateDomainEvent'
import useLotStatus from 'domain/lot/hooks/useLotStatus'
import { LOT_STATUS } from 'domain/lot/interfaces/LOT_STATUS'
import { DOMAIN_EVENT_TRIGGER } from 'domain/project/enums/DOMAIN_EVENT_TRIGGER'
import { DOMAIN_EVENTS } from 'domain/project/enums/DOMAIN_EVENTS'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'
import mutations from 'domain/tender/bidders/apollo/mutations'
import optimisticResponses from 'domain/tender/bidders/apollo/optimisticResponses'
import queries from 'domain/tender/bidders/apollo/queries'
import { CollaborationTableProps } from 'domain/tender/bidders/components/body/collaborating/CollaborationTable/CollaborationTableProps'
import ContactDetailSideSheet from 'domain/tender/bidders/components/sidesheet/contacts/ContactDetailSideSheet/ContactDetailSideSheet'
import { ContactTag } from 'domain/tender/bidders/types/ContactTag'
import React, {
	ChangeEvent,
	FC,
	useEffect,
	useMemo,
	MouseEvent,
	useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { reactFormatter } from 'react-tabulator'

const CollaborationTable: FC<CollaborationTableProps> = ({
	className,
	role,
	filters,
	onTagClick,
}) => {
	const { t } = useTranslation()
	const { openSideSheet } = useSideSheet()
	const { projectUuid, lotUuid } = useParams<UrlParams>()
	const { status } = useLotStatus()
	const { sendErrorMessage, sendSuccessMessage } = useCustomSnackbar()
	const [newTag, setNewTag] = useState<ContactTag | null>()
	const [showInfoDialog, setShowInfoDialog] = useState(false)
	const [payloadCreateCollaboration, setPayloadCreateCollaboration] = useState<{
		value: unknown
		event: ChangeEvent<HTMLInputElement>
		contact: Contact
	} | null>(null)

	const [fetchContacts, { data, loading }] = useGraphQLLazyQuery(
		queries.ProjectWithTenderInformationAndContacts,
		{
			variables: {
				projectUuid,
				lotUuid,
			},
			fetchPolicy: 'network-only',
		}
	)

	const contacts = getContactsFromResponse(data)
	const project = getProjectFromResponse(data)
	const lot = getLotFromProject(project)
	const collaborations = getCollaborationsFromLot(lot)
	const totalAccessCount = getTotalAccessCountFromLot(lot)

	const {
		generateDialogMarkup,
		hasAccess: hasDomainEventAccess,
		openDialog,
	} = useValidateDomainEvent({
		trigger: DOMAIN_EVENT_TRIGGER.ON_CLICK,
		event: DOMAIN_EVENTS.CREATE_COLLABORATION,
		currentCount: totalAccessCount,
	})

	const [
		createCollaboration,
		{ loading: createMutationIsLoading },
	] = useGraphQLMutation(mutations.createCollaboration, {
		onCompleted: () =>
			sendSuccessMessage(t('domain:tender.createCollaboration.success')),
	})
	const [
		deleteCollaboration,
		{ loading: deleteMutationIsLoading },
	] = useGraphQLMutation(mutations.deleteCollaboration, {
		onCompleted: () =>
			sendSuccessMessage(t('domain:tender.deleteCollaboration.success')),
	})

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const prepareTableData = (): any[] => {
		if (
			!collaborations ||
			!contacts ||
			!Array.isArray(collaborations) ||
			!Array.isArray(contacts)
		) {
			return []
		}
		return contacts.map(contact => {
			const collaboration: Collaboration | undefined = collaborations.find(
				val => val.uuid === contact.uuid
			)

			if (!collaboration) {
				return null
			}

			return { ...contact, ...collaboration }
		})
	}

	const tableData = useMemo(() => prepareTableData(), [
		prepareTableData,
		collaborations,
		contacts,
	])

	const handleCollaborationCreate = (contact: Contact | null): void => {
		if (contact) {
			const { uuid: contactUuid } = contact
			createCollaboration({
				variables: {
					projectUuid,
					contactUuid,
					lotUuid,
				},
				// necessary to render a loading state
				refetchQueries: [
					{
						query: queries.ProjectWithTotalAccessCount,
						variables: {
							projectUuid,
							lotUuid,
						},
					},
				],
				optimisticResponse: optimisticResponses.createCollaborationResponse,
			})
		}
	}

	const handleCollaborationDelete = (contact: Contact | null): void => {
		if (contact) {
			const { uuid: contactUuid, projectMemberUuid } = contact
			deleteCollaboration({
				variables: {
					projectUuid,
					contactUuid,
					projectMemberUuid,
					lotUuid,
				},
				refetchQueries: [
					{
						query: queries.ProjectWithTotalAccessCount,
						variables: {
							projectUuid,
							lotUuid,
						},
					},
				],
				optimisticResponse: optimisticResponses.deleteCollaborationResponse,
			})
		}
	}

	const onChange = (
		event: ChangeEvent<HTMLInputElement>,
		contact: Contact
	): void => {
		const { hasAccess, projectMemberUuid } = contact

		if (hasAccess) {
			if (projectMemberUuid) {
				handleCollaborationDelete(contact)
			} else {
				sendErrorMessage(
					t(
						'domain:tender.deleteCollaboration.validatorViolations.missingMemberUuid'
					)
				)
			}
			return
		}

		handleCollaborationCreate(contact)
	}

	const openContactSidesheet = (
		e: MouseEvent<HTMLElement>,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		cell: any
	): void => {
		const contact = cell.getRow().getData() as Contact
		openSideSheet({
			title: t('domain:tender.viewContact.sideSheet.title'),
			content: <ContactDetailSideSheet contactUuid={contact.uuid} />,
		})
	}

	useEffect(() => {
		setNewTag(null)
	}, [filters])

	useEffect(() => {
		if (onTagClick && newTag) {
			onTagClick(newTag)
		}
	}, [newTag])

	useEffect(() => {
		if (projectUuid && lotUuid) {
			fetchContacts()
		}
	}, [projectUuid, lotUuid])

	useEffect(() => {
		if (
			payloadCreateCollaboration &&
			!createMutationIsLoading &&
			!deleteMutationIsLoading
		) {
			const { value: isCreation, event, contact } = payloadCreateCollaboration

			if (isCreation && totalAccessCount === 0) {
				setShowInfoDialog(true)
				return
			}

			if (!isCreation || (isCreation && hasDomainEventAccess())) {
				onChange(event, contact)
				return
			}

			if (!hasDomainEventAccess()) {
				openDialog()
			}
		}
	}, [payloadCreateCollaboration])

	return (
		<Loading loading={loading}>
			<Table
				filters={filters}
				className={className}
				data={tableData}
				options={{
					layout: 'fitColumns',
					pagination: 'local',
					index: 'uuid',
					paginationSize: 30,
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					tooltips: (cell: any) => {
						const { field } = cell.getColumn().getDefinition()
						if (role === ROLES_PROJECT.BIDDER && field === 'hasAccess') {
							return t(
								'domain:tender.createCollaboration.validatorViolations.tooltip'
							)
						}
						return ''
					},
				}}
				columns={useMemo(
					() => [
						{
							field: 'company',
							title: t('common:tabulator.columns.company'),
							cellClick: openContactSidesheet,
							cssClass: 'pointer',
						},
						{
							field: 'firstName',
							title: t('common:tabulator.columns.firstName'),
							cellClick: openContactSidesheet,
							cssClass: 'pointer',
						},
						{
							field: 'lastName',
							title: t('common:tabulator.columns.lastName'),
							cellClick: openContactSidesheet,
							cssClass: 'pointer',
						},
						{
							field: 'email',
							title: t('common:tabulator.columns.email'),
							cellClick: openContactSidesheet,
							cssClass: 'pointer',
						},
						{
							field: 'tags',
							title: t('common:tabulator.columns.tags'),
							headerSort: false,
							formatter: reactFormatter(
								<ContactTagsFormatter
									onClick={newTagInput => setNewTag(newTagInput)}
								/>
							),
						},
						{
							field: 'openDetail',
							title: '',
							headerSort: false,
							width: 60,
							formatter: reactFormatter(
								<IconFormatter>
									<VisibilityIcon />
								</IconFormatter>
							),
							cellClick: openContactSidesheet,
						},
						{
							field: 'hasAccess',
							title: 'Zugriff',
							headerSort: false,
							width: 80,
							formatter: reactFormatter(
								<TableSwitch
									onChange={(event, contact, value) => {
										setPayloadCreateCollaboration({
											event,
											contact,
											value,
										})
									}}
									projectUuid={projectUuid}
									disabled={
										LOT_STATUS.CREATED !== status &&
										LOT_STATUS.TENDERING !== status
									}
								/>
							),
						},
					],
					// take care of rerender conditions!!! we use the useMemo hook
					[projectUuid, lotUuid, status, totalAccessCount]
				)}
			/>
			<ConfirmationDialog
				open={showInfoDialog}
				title={t(
					'domain:tender.createCollaboration.firstCollaboration.confirmationDialog.title'
				)}
				contentText={t(
					'domain:tender.createCollaboration.firstCollaboration.confirmationDialog.contentText'
				)}
				onAcceptLabel={t(
					'domain:tender.createCollaboration.firstCollaboration.confirmationDialog.onAcceptLabel'
				)}
				onAccept={() => {
					if (payloadCreateCollaboration) {
						const { contact } = payloadCreateCollaboration
						handleCollaborationCreate(contact)
					}
					setShowInfoDialog(false)
				}}
				onDecline={() => setShowInfoDialog(false)}
			/>
			{generateDialogMarkup()}
		</Loading>
	)
}

export default CollaborationTable
