import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink, from } from 'apollo-link'
import routes from 'app/routing/routes/routesApp'
import {
	getGraphqlErrorCodeFromErrorLinkResponse,
	getStatusCodeFromErrorLinkResponse,
} from 'app/utils/apollo'
import { get } from 'lodash'
import {
	AUTH_TOKEN_IDENTIFIER,
	ERROR_CODE_INVALID_PROJECT_UUID,
	ERROR_CODE_INVALID_LOT_UUID,
	ERROR_CODE_NO_PERMISSION_TO_ACCESS_PROJECT,
} from 'app/constants'
import logout from 'app/hooks/useLogout'

const httpLink = new HttpLink({ uri: process.env.REACT_APP_API_GRAPHQL_URL })

/**
 * Apollo cache configuration.
 */
const cache = new InMemoryCache({
	dataIdFromObject: object =>
		// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
		// @ts-ignore
		object.__typename !== 'Bidder' ? object.uuid || null : null,
	addTypename: true,
})

/**
 * Middleware for apollo client, to set authentication headers.
 */
const authMiddleware = new ApolloLink((operation, forward) => {
	const authTokensString = localStorage.getItem(AUTH_TOKEN_IDENTIFIER)
	const authTokensObject = authTokensString ? JSON.parse(authTokensString) : {}
	const authToken = get(authTokensObject, ['access_token'], false)

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	operation.setContext(({ headers = {} }: { headers: any }) => ({
		headers: {
			...headers,
			authorization: authToken ? `Bearer ${authToken}` : '',
		},
	}))

	return forward(operation)
})

/**
 * Middleware to handle network errors or special API error, i. e. invalid authentication token.
 */
const onApolloError = onError(error => {
	const statusCode = getStatusCodeFromErrorLinkResponse(error)
	const gqlCategory = getGraphqlErrorCodeFromErrorLinkResponse(error)

	if (
		gqlCategory === ERROR_CODE_INVALID_PROJECT_UUID ||
		gqlCategory === ERROR_CODE_INVALID_LOT_UUID ||
		gqlCategory === ERROR_CODE_NO_PERMISSION_TO_ACCESS_PROJECT
	) {
		window.location.href = routes['404']
	}

	if (statusCode && statusCode === 401) {
		// @TODO try to refresh token
		logout()
	}
})

/**
 * apollo client instance as default export.
 */
export default new ApolloClient({
	link: from([authMiddleware, onApolloError, httpLink]),
	cache,
})
