import { makeStyles, Theme } from '@material-ui/core'

interface CheckoutCardStylesProps {
	hasAnotherAction?: boolean
}

export default makeStyles<Theme, CheckoutCardStylesProps>(() => {
	return {
		cardActions: ({ hasAnotherAction }) => {
			return {
				justifyContent: hasAnotherAction ? 'space-between' : 'flex-end',
			}
		},
	}
})
