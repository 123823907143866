import React, { ReactElement, FC } from 'react'
import clsx from 'clsx'
import { TabulatorTextLinkProps } from './TabulatorTextLinkProps'

import useStyles from './TabulatorTextLinkStyles'

const TabulatorTextLink: FC<TabulatorTextLinkProps> = ({
	children,
	onClick,
	className,
}): ReactElement => {
	const classes = useStyles()

	return (
		<button
			type="button"
			onClick={onClick}
			className={clsx(classes.root, className)}
		>
			{children}
		</button>
	)
}

export default TabulatorTextLink
