import React, { FC, useState, useEffect } from 'react'
import { DrawerMenuItemProps } from 'app/config/drawerMenu'
import { ListItem, ListItemText, List, Collapse } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { UrlParams } from 'app/types/UrlParams'
import useStyles from './DrawerMenuItemCollapsibleStyles'
import DrawerMenuItem from '../DrawerMenuItem/DrawerMenuItem'

const DrawerMenuItemCollapsible: FC<DrawerMenuItemProps> = ({
	configuration,
	parentConfiguration,
	active,
}) => {
	const { sectionId, subSectionId } = useParams<UrlParams>()

	const classes = useStyles({ active })
	const { t } = useTranslation()

	const { icon: Icon, children = [] } = configuration
	const [open, setOpen] = useState(active)

	const handleClick = (): void => {
		if (!active) {
			setOpen(!open)
		}
	}

	useEffect(() => {
		if (active) {
			setOpen(true)
		}
	}, [sectionId, subSectionId])

	return (
		<li>
			<List className={classes.navigation}>
				<ListItem
					key={subSectionId}
					classes={{
						root: classes.root,
					}}
					selected={active}
					onClick={handleClick}
				>
					{Icon && <Icon />}
					<ListItemText
						classes={{
							primary: classes.listItemText,
						}}
					>
						{t(configuration.title)}
					</ListItemText>
				</ListItem>
				{children.map(childConfiguration => {
					const { key } = childConfiguration
					return (
						<Collapse in={open} key={key} component="li">
							<DrawerMenuItem
								configuration={childConfiguration}
								parentConfiguration={parentConfiguration}
							/>
						</Collapse>
					)
				})}
			</List>
		</li>
	)
}

export default DrawerMenuItemCollapsible
