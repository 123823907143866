import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import routes from 'app/routing/routes/routesApp'
import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import useCustomSnackbar from 'app/services/snackbar/useCustomSnackbar'
import { CreateLotVars, ProjectData } from 'app/types/index'
import { SECTIONS } from 'app/types/SECTIONS'
import { UrlParams } from 'app/types/UrlParams'
import { resolveRoute } from 'app/utils/route'
import Tab from 'component/Tab/Tab'
import mutations from 'domain/lot/apollo/mutations'
import { CreateLotTabProps } from 'domain/lot/components/CreateLotTab/CreateLotTabProps'
import LotInput from 'domain/lot/components/LotInput/LotInput'
import { get } from 'lodash'
import React, { FC, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import useStyles from 'domain/lot/components/CreateLotTab/CreateLotTabStyles'
import { DOMAIN_EVENT_TRIGGER } from 'domain/project/enums/DOMAIN_EVENT_TRIGGER'
import { DOMAIN_EVENTS } from 'domain/project/enums/DOMAIN_EVENTS'
import ValidateDomainEvent from 'component/ValidateDomainEvent/ValidateDomainEvent'

const CreateLotTab: FC<CreateLotTabProps> = () => {
	const { t } = useTranslation()
	const { projectUuid } = useParams<UrlParams>()
	const { sendErrorMessage } = useCustomSnackbar()
	const [inEditMode, setEditMode] = useState(false)
	const classes = useStyles(inEditMode)

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onCompleted = (value: any): void => {
		const lotUuid: string = get(value, ['project', 'createLot', 'uuid'], null)

		window.location.href = resolveRoute(routes.section, {
			projectUuid,
			lotUuid,
			sectionId: SECTIONS.BOQ,
		})
	}

	const [createLot, { loading }] = useGraphQLMutation<
		ProjectData,
		CreateLotVars
	>(mutations.createLot, {
		onCompleted,
	})

	const isValidInput = (value: string | null): boolean => {
		return !!value
	}

	const onAcceptLot = (lotName: string | null): void => {
		if (isValidInput(lotName) && lotName) {
			createLot({
				variables: {
					projectUuid,
					lotName,
				},
			})
		} else {
			sendErrorMessage(t('domain:lot.createLot.validatorViolation'))
		}
	}

	const onDeclineLot = (): void => {
		setEditMode(false)
	}

	const renderEditableTabLabel = (): ReactElement => {
		return (
			<LotInput
				onAccept={onAcceptLot}
				onDecline={onDeclineLot}
				loading={loading}
				defaultValue={t('domain:lot.createLot.placeholder.inputTab')}
			/>
		)
	}

	const renderNonEditableTabLabel = (): ReactElement => {
		return (
			<ValidateDomainEvent
				event={DOMAIN_EVENTS.CREATE_LOT}
				trigger={DOMAIN_EVENT_TRIGGER.ON_CLICK}
			>
				<IconButton
					disableRipple
					className={classes.iconButton}
					onClick={() => setEditMode(true)}
					title={t('domain:lot.createLot.title')}
				>
					<AddIcon className={classes.addIcon} />
				</IconButton>
			</ValidateDomainEvent>
		)
	}

	return (
		<Tab
			className={classes.root}
			label={
				inEditMode ? renderEditableTabLabel() : renderNonEditableTabLabel()
			}
		/>
	)
}

export default CreateLotTab
