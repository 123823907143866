import { gql } from 'apollo-boost'

const createFolder = gql`
	mutation createFolder(
		$projectUuid: String!
		$lotUuid: String!
		$folderName: String!
		$parentUuid: String
		$authorUuid: String
	) {
		project(uuid: $projectUuid) {
			lot(uuid: $lotUuid) {
				attachments(parentUuid: $parentUuid, authorUuid: $authorUuid) {
					createFolder(name: $folderName) {
						uuid
						name
					}
				}
			}
		}
	}
`

export default {
	createFolder,
}
