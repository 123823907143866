import Typography from '@material-ui/core/Typography'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import { getContactFromResponse } from 'app/utils/apollo'
import Loading from 'component/Loading/Loading'
import queries from 'domain/tender/bidders/apollo/queries'
import CreateContactForm from 'domain/tender/bidders/components/sidesheet/contacts/CreateContactForm/CreateContactForm'
import { ContactDetailSideSheetProps } from 'domain/tender/bidders/components/sidesheet/contacts/ContactDetailSideSheet/ContactDetailSideSheetProps'
import ContactTags from 'domain/tender/bidders/components/sidesheet/contacts/ContactTags/ContactTags'
import React, { FC, useEffect } from 'react'

const ContactDetailSideSheet: FC<ContactDetailSideSheetProps> = ({
	contactUuid,
}) => {
	const [fetchContact, { data: contactData, loading }] = useGraphQLLazyQuery(
		queries.ContactWithTags
	)

	const contact = getContactFromResponse(contactData)

	useEffect(() => {
		if (contactUuid) {
			fetchContact({ variables: { contactUuid } })
		}
	}, [contactUuid])

	return (
		<Loading loading={loading}>
			{contact && (
				<CreateContactForm key={contactUuid} contact={contact} disabled />
			)}
			<Typography component="h3" variant="h6">
				Tags
			</Typography>
			{contact && <ContactTags contact={contact} />}
		</Loading>
	)
}

export default ContactDetailSideSheet
