import { ACTION_SIDESHEET_CLOSE, ACTION_SIDESHEET_OPEN } from 'app/constants'
import SideSheetContext from 'component/SideSheetProvider/SideSheetContext'
import { ReactElement, ReactNode, useContext } from 'react'

interface UseSideSheet {
	open: boolean
	animating: boolean
	getContent: () => ReactNode
	getTitle: () => string | ReactElement | null
	openSideSheet: (configuration: OpenSideSheetConfiguration) => void
	isClosable: () => boolean
	closeSideSheet: () => void
}

interface OpenSideSheetConfiguration {
	title?: string | ReactElement | null
	content?: ReactNode
	closable?: boolean
}

export default (): UseSideSheet => {
	const { state, dispatch } = useContext(SideSheetContext)
	const { open, content, animating, title, closable } = state

	const openSideSheet = (config: OpenSideSheetConfiguration): void => {
		if (dispatch) {
			document.body.classList.add('sidesheet-open')
			dispatch({
				type: ACTION_SIDESHEET_OPEN,
				payload: config,
			})
		}
	}

	const closeSideSheet = (): void => {
		if (dispatch) {
			document.body.classList.remove('sidesheet-open')
			dispatch({ type: ACTION_SIDESHEET_CLOSE })
		}
	}

	const getContent = (): ReactNode => content
	const getTitle = (): string | ReactElement | null => title
	const isClosable = (): boolean => closable

	return {
		open,
		animating,
		getContent,
		getTitle,
		isClosable,
		openSideSheet,
		closeSideSheet,
	}
}
