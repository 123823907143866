import containsLowerCase from 'app/services/validator/containsLowerCase'
import containsNumber from 'app/services/validator/containsNumber'
import containsUpperCase from 'app/services/validator/containsUpperCase'
import { ValidatorFormProps } from 'component/ValidatorForm/ValidatorFormProps'
import React, { FC, useEffect, useMemo } from 'react'
import { ValidatorForm as MuiValidatorForm } from 'react-material-ui-form-validator'

const ValidatorForm: FC<ValidatorFormProps> = ({
	children,
	customValidators = [],
	...rest
}) => {
	useEffect(() => {
		customValidators.forEach(validator => {
			MuiValidatorForm.addValidationRule(
				validator.name,
				validator.callback as () => boolean
			)
		})

		return () => {
			customValidators.forEach(validator => {
				MuiValidatorForm.removeValidationRule(validator.name)
			})
		}
	}, [customValidators])

	useEffect(() => {
		MuiValidatorForm.addValidationRule('containsLowerCase', (value: string) => {
			return containsLowerCase(value)
		})

		return () => {
			MuiValidatorForm.removeValidationRule('containsLowerCase')
		}
	}, [])

	useEffect(() => {
		MuiValidatorForm.addValidationRule('containsUpperCase', (value: string) => {
			return containsUpperCase(value)
		})

		return () => {
			MuiValidatorForm.removeValidationRule('containsUpperCase')
		}
	}, [])

	useEffect(() => {
		MuiValidatorForm.addValidationRule('containsNumber', (value: string) => {
			return containsNumber(value)
		})

		return () => {
			MuiValidatorForm.removeValidationRule('containsNumber')
		}
	}, [])

	useEffect(() => {
		MuiValidatorForm.addValidationRule('isTruthy', (value: boolean) => {
			return value
		})

		return () => {
			MuiValidatorForm.removeValidationRule('isTruthy')
		}
	}, [])

	return useMemo(() => {
		return (
			<MuiValidatorForm instantValidate={false} {...rest}>
				{children}
			</MuiValidatorForm>
		)
	}, [children])
}

export default ValidatorForm
