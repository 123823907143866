import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { MediaCardProps } from 'component/MediaCard/MediaCardProps'
import React, { FC } from 'react'
import { isString } from 'lodash'

import useStyles from 'component/MediaCard/MediaCardStyles'

const MediaCard: FC<MediaCardProps> = ({
	headline,
	title,
	subLine,
	src,
	description,
	footerActionButtons,
	onClick,
	hedlineSingleLine = false,
	classes: parentClasses = {},
}) => {
	const classes = useStyles()

	return (
		<Card className={clsx(classes.root, parentClasses.root)}>
			<CardActionArea onClick={onClick}>
				<CardMedia
					image={src}
					title={title}
					className={clsx(classes.media, parentClasses.media)}
				/>
			</CardActionArea>
			<CardContent>
				<div>
					{headline && isString(headline) && (
						<Typography
							gutterBottom
							variant="h6"
							component="h2"
							className={
								hedlineSingleLine
									? clsx(classes.headlineCrop, parentClasses.headlineCrop)
									: undefined
							}
							title={headline}
						>
							{headline}
						</Typography>
					)}
					{headline && !isString(headline) && headline}
					{subLine && (
						<Typography gutterBottom variant="body2" component="p">
							{subLine}
						</Typography>
					)}
				</div>
				{description && (
					<Typography
						variant="body1"
						color="textSecondary"
						component="p"
						className={clsx(classes.description, parentClasses.description)}
					>
						{description}
					</Typography>
				)}
			</CardContent>
			<div
				className={clsx(
					classes.footerActionsContainer,
					parentClasses.footerActionsContainer
				)}
			>
				{Array.isArray(footerActionButtons) &&
					footerActionButtons.length > 0 &&
					footerActionButtons}
			</div>
		</Card>
	)
}

export default MediaCard
