/* eslint-disable @typescript-eslint/no-explicit-any */
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import { ProjectData } from 'app/types/index'
import { UrlParams } from 'app/types/UrlParams'
import {
	getStatusFromLot,
	getStatusFromRevision,
	getStatusFromQuantityDetermination,
	getQuantityDeterminationsFromLot,
	getProjectFromResponse,
	getLotsFromProject,
	getFirstRevisionFromLot,
	getRoleFromProject,
} from 'app/utils/apollo'
import {
	hasValidCombinations,
	hasInvalidCombinations,
} from 'app/utils/validator'
import Loading from 'component/Loading/Loading'
import { ValidateProps } from 'component/Validate/ValidateProps'
import queries from 'domain/project/apollo/queries'
import { isUndefined } from 'lodash'
import React, { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useUrlSearchParams from 'app/hooks/useUrlSearchParams'
import { ENVIRONMENTS } from 'app/constants'

const Validate: FC<ValidateProps> = ({
	children,
	fallback,
	projectRoles,
	quantityDeterminationStatuses,
	lotStatuses,
	revisionStatuses,
	environments,
	validCombinations,
	invalidCombinations,
	combinationOperator,
	onCompleted,
	disableLoader,
}) => {
	const { projectUuid, lotUuid, sectionId } = useParams<UrlParams>()

	const query = useUrlSearchParams()
	const queryUuid = query.get('qduuid')

	const [fetchData, { data, loading }] = useGraphQLLazyQuery<ProjectData>(
		queries.ProjectForVerification,
		{
			onCompleted,
			variables: {
				projectUuid,
			},
		}
	)

	useEffect(() => {
		if (projectUuid) {
			fetchData({
				variables: {
					projectUuid,
				},
			})
		}
	}, [projectUuid, lotUuid, sectionId])

	const project = getProjectFromResponse(data)
	const role = getRoleFromProject(project)
	const lots = getLotsFromProject(project)

	const foundLot =
		Array.isArray(lots) &&
		lots.find(lotData => {
			return lotData.uuid === lotUuid
		})

	const lot = foundLot || null
	const lotStatus = getStatusFromLot(lot)
	const revision = getFirstRevisionFromLot(lot)
	const revisionStatus = getStatusFromRevision(revision)
	const quantityDeterminations = getQuantityDeterminationsFromLot(lot)

	const foundQD =
		Array.isArray(quantityDeterminations) &&
		quantityDeterminations.find(qd => qd.uuid === queryUuid)

	const quantityDetermination = foundQD || null

	const quantityDeterminationStatus = getStatusFromQuantityDetermination(
		quantityDetermination
	)

	const environment = process.env.REACT_APP_ENV as ENVIRONMENTS

	const isValid = (validData: any, currentData: any): boolean => {
		if (!Array.isArray(validData) || validData.length < 1) {
			return false
		}

		if (!currentData) {
			return false
		}

		return validData.includes(currentData)
	}

	// verify environment
	if (environments && !isValid(environments, environment)) {
		return fallback ? fallback() : null
	}

	// verify when query data is fetched
	if (!isUndefined(data) && !loading) {
		// verify Role
		if (projectRoles && !isValid(projectRoles, role)) {
			return fallback ? fallback() : null
		}

		// verify lot status
		if (lotStatuses && !isValid(lotStatuses, lotStatus)) {
			return fallback ? fallback() : null
		}

		// verify data revision status
		if (revisionStatuses && !isValid(revisionStatuses, revisionStatus)) {
			return fallback ? fallback() : null
		}

		// verify data quantity Determination status
		if (
			quantityDeterminationStatuses &&
			!isValid(quantityDeterminationStatuses, quantityDeterminationStatus)
		) {
			return fallback ? fallback() : null
		}

		if (
			validCombinations &&
			!hasValidCombinations(validCombinations, {
				role,
				revisionStatus,
				lotStatus,
				combinationOperator,
				quantityDeterminationStatus,
				environment,
			})
		) {
			return fallback ? fallback() : null
		}

		if (
			invalidCombinations &&
			!hasInvalidCombinations(invalidCombinations, {
				role,
				revisionStatus,
				lotStatus,
				combinationOperator,
				quantityDeterminationStatus,
				environment,
			})
		) {
			return fallback ? fallback() : null
		}
	}

	// verify whithout query data
	if (isUndefined(data) && !loading) {
		if (
			invalidCombinations &&
			!hasInvalidCombinations(invalidCombinations, {
				environment,
				combinationOperator,
			})
		) {
			return fallback ? fallback() : null
		}

		if (
			validCombinations &&
			!hasValidCombinations(validCombinations, {
				environment,
				combinationOperator,
			})
		) {
			return fallback ? fallback() : null
		}
	}

	return (
		<Loading loading={loading} disableLoader={disableLoader}>
			{children}
		</Loading>
	)
}

export default Validate
