import {
	getBoqNodesFromRevision,
	getFirstRevisionFromLot,
} from 'app/utils/apollo'
import Table from 'component/Table/Table'
import { BoqTableProps } from 'domain/general/boq/components/body/listing/BoqTable/BoqTableProps'
import useColumns from 'domain/general/boq/tabulator/useColumns'
import useOptions from 'domain/general/boq/tabulator/useOptions'
import React, { FC } from 'react'

const BoqTable: FC<BoqTableProps> = ({
	className,
	lot,
	isEditable = false,
	isUpVisible = false,
	role,
}) => {
	const { options } = useOptions()
	const revision = getFirstRevisionFromLot(lot)
	const columns = useColumns(isEditable, isUpVisible, role, lot)
	const boqNodes = getBoqNodesFromRevision(revision)

	return (
		<Table
			className={className}
			options={options}
			data={boqNodes || []}
			columns={columns}
		/>
	)
}

export default BoqTable
