/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, FC, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'
import TabulatorButton from 'component/TabulatorButton/TabulatorButton'

interface QuantityDeterminationQuantityFormatterProps {
	cell?: any
	onClick: (e: any, uuid: string) => void
	role: ROLES_PROJECT | null
}

const QuantityDeterminationQuantityFormatter: FC<QuantityDeterminationQuantityFormatterProps> = ({
	cell,
	onClick,
	role,
}): ReactElement => {
	const cellData = cell.getRow().getData()
	const { status, uuid } = cellData
	const { t } = useTranslation()
	return (
		<TabulatorButton onClick={(e: MouseEvent) => onClick(e, uuid)}>
			{t(
				`domain:billing.tableQuantityDeterminations.quantity.labelButton.${role}.${status}`
			)}
		</TabulatorButton>
	)
}

export default QuantityDeterminationQuantityFormatter
