import { gql } from 'apollo-boost'

const ProjectWithLotAttachments = gql`
	query ProjectWithLotAttachments(
		$projectUuid: String!
		$lotUuid: String!
		$parentUuid: String
		$authorUuid: String
	) {
		project(uuid: $projectUuid) {
			uuid
			lot(uuid: $lotUuid) {
				uuid
				attachments(parentUuid: $parentUuid, authorUuid: $authorUuid) {
					data {
						uuid
						author {
							uuid
							name
						}
						createdAt
						downloadUrl
						mimeType
						type
						openUrl
						name
						previewUrl
						size
						token
					}
					authors {
						uuid
						name
					}
					parents {
						uuid
						name
					}
					totalCount
				}
			}
		}
	}
`

export default {
	ProjectWithLotAttachments,
}
