/* eslint-disable @typescript-eslint/no-explicit-any */
import { TABLE_FIELDS_BOQ } from 'app/constants'
import {
	toggleTree,
	MeterUnitFormatter,
	handleCellQDLabelEditable,
	handleCellQDFormulaEditable,
	handleCellQDQtyEditable,
} from 'app/services/tabulator'
import LinFormatter from 'app/services/tabulator/formatters/lin/LinFormatter'
import { getStatusFromQuantityDetermination } from 'app/utils/apollo'
import { reactFormatter } from 'react-tabulator'
import QuantityDeterminationModifyRowFormatter from 'app/services/tabulator/formatters/QuantityDeterminationModifyRowFormatter/QuantityDeterminationModifyRowFormatter'
import { useTranslation } from 'react-i18next'
import React, { useMemo } from 'react'
import { QuantityDetermination } from 'app/config/quantityDetermination'
import { useCustomSnackbar } from 'app/services/snackbar'
import QuantityDeterminationFormulaFormatter from 'app/services/tabulator/formatters/QuantityDeterminationFormulaFormatter'
import QuantityDeterminationLabelFormatter from 'app/services/tabulator/formatters/QuantityDeterminationLabelFormatter'
import QuantityDeterminationNodesStatusFormatter from 'app/services/tabulator/formatters/QuantityDeterminationNodesStatusFormatter/QuantityDeterminationNodesStatusFormatter'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'
import QuantityDeterminationQtyFormatter from 'app/services/tabulator/formatters/QuantityDeterminationQtyFormatter'
import TabulatorEditor from 'app/services/tabulator/TabulatorEditor'
import IconTypesFormatter from 'app/services/tabulator/formatters/type/IconTypesFormatter'

export default (
	modifyRow: (cell: any) => void,
	quantityDetermination: QuantityDetermination | null,
	handleEdited: (cell: any) => void,
	role: ROLES_PROJECT | null,
	handleAcceptSuggestion: (qdIUuid: string) => void
): any => {
	const { t } = useTranslation()
	const { sendErrorMessage } = useCustomSnackbar()

	const quantityDeterminationStatus = getStatusFromQuantityDetermination(
		quantityDetermination
	)

	const columnsData: any = [
		{
			field: TABLE_FIELDS_BOQ.LIN,
			title: t('common:tabulator.columns.lin'),
			width: 120,
			cellClick: toggleTree,
			formatter: LinFormatter,
		},
		{
			field: TABLE_FIELDS_BOQ.TYPE,
			width: 50,
			title: '',
			formatter: reactFormatter(<IconTypesFormatter />),
		},
		{
			field: TABLE_FIELDS_BOQ.LABEL,
			title: t('common:tabulator.columns.label'),
			widthGrow: 3,
			formatter: (cell: any) =>
				QuantityDeterminationLabelFormatter(
					role,
					cell,
					quantityDeterminationStatus
				),
			editorParams: { sendErrorMessage },
			editable: (cell: any) =>
				handleCellQDLabelEditable(role, cell, quantityDeterminationStatus),
			editor: TabulatorEditor,
			cellEdited: handleEdited,
			// regex to avoid start and finish strings with whitespaces
			validator: ['required', 'regex:^[a-zA-Z0-9_].*[a-zA-Z0-9_]$'],
		},
		{
			field: TABLE_FIELDS_BOQ.QU,
			title: t('common:tabulator.columns.qu'),
			formatter: MeterUnitFormatter,
		},
		{
			field: 'formula',
			title: t(`domain:billing.bidderQuantityDeterminationTable.formula.title`),
			editor: TabulatorEditor,
			editorParams: { sendErrorMessage },
			cellEdited: handleEdited,
			editable: (cell: any) =>
				handleCellQDFormulaEditable(role, cell, quantityDeterminationStatus),
			formatter: (cell: any) =>
				QuantityDeterminationFormulaFormatter(
					role,
					cell,
					quantityDeterminationStatus
				),
		},
		{
			field: TABLE_FIELDS_BOQ.QTY,
			title: t('common:tabulator.columns.qty'),
			align: 'center',
			editorParams: { sendErrorMessage },
			editable: (cell: any) =>
				handleCellQDQtyEditable(role, cell, quantityDeterminationStatus),
			cellEdited: handleEdited,
			editor: TabulatorEditor,
			formatter: (cell: any) =>
				QuantityDeterminationQtyFormatter(
					role,
					cell,
					quantityDeterminationStatus
				),
		},
		{
			field: 'status',
			title: t(`domain:billing.bidderQuantityDeterminationTable.status.title`),
			formatter: reactFormatter(<QuantityDeterminationNodesStatusFormatter />),
			minWidth: 130,
		},
		{
			field: 'edit',
			title: t(`domain:billing.bidderQuantityDeterminationTable.edit.title`),
			cssClass: 'center-column-children',
			formatter: reactFormatter(
				<QuantityDeterminationModifyRowFormatter
					quantityDetermination={quantityDetermination}
					onClick={modifyRow}
					handleAcceptSuggestion={handleAcceptSuggestion}
				/>
			),
		},
	]

	return useMemo(() => columnsData, [modifyRow, quantityDetermination])
}
