import React, { FC } from 'react'
import { PageContent } from 'component'
import AnnotationsPageContentHeader from 'domain/award/annotations/components/base/AnnotationsPageContentHeader/AnnotationsPageContentHeader'
import AnnotationsPageContentBody from 'domain/award/annotations/components/base/AnnotationsPageContentBody/AnnotationsPageContentBody'

const AnnotationsPageContent: FC = () => {
	return (
		<PageContent
			header={<AnnotationsPageContentHeader />}
			body={<AnnotationsPageContentBody />}
		/>
	)
}

export default AnnotationsPageContent
