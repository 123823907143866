import React, { FC, useEffect } from 'react'
import TextLink from 'component/TextLink/TextLink'
import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import mutations from 'domain/header/apollo/mutations'
import { useApolloClient } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import routes from 'app/routing/routes/routesApp'
import { LAST_VISITED_URL_PARAMS } from 'app/constants'
import { useTranslation } from 'react-i18next'
import useStyles from './ProfileResetDemoStyles'

const ProfileResetDemo: FC = () => {
	const classes = useStyles()
	const client = useApolloClient()
	const { push } = useHistory()
	const { t } = useTranslation()

	const [resetDemoAccount, { data, error }] = useGraphQLMutation(
		mutations.resetDemoAccount
	)

	const handleClick = (): void => {
		localStorage.removeItem(LAST_VISITED_URL_PARAMS)
		resetDemoAccount()
		client.clearStore()
	}

	useEffect(() => {
		if (data && !error) {
			push(routes.projects)
		}
	}, [data, error])

	return (
		<TextLink className={classes.root} onClick={() => handleClick()}>
			{t('domain:header.resetDemo.title')}
		</TextLink>
	)
}

export default ProfileResetDemo
