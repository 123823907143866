import { Typography } from '@material-ui/core'
import { SubQuantity } from 'app/types/graphql/BoqNode'
import AttributeInfoItemHeadline from 'domain/general/boq/components/sidesheet/attributeInfos/AttributeInfoItemHeadline/AttributeInfoItemHeadline'
import { SubQuantityInfoItemProps } from 'domain/general/boq/components/sidesheet/attributeInfos/SubQuantityInfoItem/SubQuantityInfoItemProps'
import { get } from 'lodash'
import React, { FC, ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

const SubQuantityInfoItem: FC<SubQuantityInfoItemProps> = ({
	subQuantities,
	classes: parentClasses = {},
}) => {
	const { t } = useTranslation()

	const renderHeadline = (): ReactElement | null => {
		if (subQuantities) {
			return (
				<AttributeInfoItemHeadline
					classes={{ root: parentClasses.headline }}
					text={t(`domain:boq.node.subQuantities`)}
				/>
			)
		}

		return null
	}

	const renderContent = (): ReactNode => {
		if (subQuantities) {
			return Object.keys(subQuantities).map(key => {
				const subQuantity = get(subQuantities, key, null) as SubQuantity | null

				if (subQuantity) {
					return (
						<>
							<Typography classes={{ root: parentClasses.content }}>
								{subQuantity.lin}
							</Typography>
							<Typography classes={{ root: parentClasses.content }}>
								{subQuantity.qty}
							</Typography>
						</>
					)
				}

				return null
			})
		}

		return null
	}

	return (
		<>
			{renderHeadline()}
			{renderContent()}
		</>
	)
}

export default SubQuantityInfoItem
