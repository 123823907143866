import { AUTH_TOKEN_IDENTIFIER } from 'app/constants'
import routesApi from 'app/routing/routes/routesApi'
import { TrackingEvent } from 'app/services/datawarehouse/interfaces/TrackingEvent'
import Axios from 'axios'

export const getTrackingSettingsFromLocalStorage = (): {
	isGoogleAnalyticsEnabled: boolean
	isHotjarEnabled: boolean
} => {
	const authTokenString = localStorage.getItem(AUTH_TOKEN_IDENTIFIER)
	return (authTokenString && JSON.parse(authTokenString)) || {}
}

export const isGaEnabled = (): boolean => {
	const { isGoogleAnalyticsEnabled } = getTrackingSettingsFromLocalStorage()
	return process.env.REACT_APP_GA_ENABLED === 'true' && isGoogleAnalyticsEnabled
}

export const isHotjarEnabled = (): boolean => {
	const {
		isHotjarEnabled: isHotjarEnabledStorage,
	} = getTrackingSettingsFromLocalStorage()

	return (
		process.env.REACT_APP_HOTJAR_ENABLED === 'true' && isHotjarEnabledStorage
	)
}

export const postTrackingEvent = (data: TrackingEvent): void => {
	Axios.post(
		`${process.env.REACT_APP_ANALYTICS_URL}/${routesApi.tracking}`,
		data
	)
}
