import { Switch } from '@material-ui/core'
import { useLoading } from 'app/hooks/index'
import { TableSwitchProps } from 'component/TableSwitch/TableSwitchProps'
import { CollaborationTableRecord } from 'domain/tender/bidders/types/CollaborationTableRecord'
import React, { FC } from 'react'

import useStyles from 'component/TableSwitch/TableSwitchStyles'

const TableSwitch: FC<TableSwitchProps> = ({ cell, onChange, disabled }) => {
	const classes = useStyles()
	const contact: CollaborationTableRecord = cell.getRow().getData()

	return useLoading(
		() => (
			<Switch
				disabled={!!disabled}
				checked={!!contact.hasAccess}
				color="default"
				onChange={(event, checked) => {
					onChange(event, contact, checked)
				}}
				classes={{
					track: classes.track,
					checked: classes.checked,
				}}
			/>
		),
		contact.hasAccess === null
	)
}

export default TableSwitch
