import { makeStyles } from '@material-ui/core'

export default makeStyles({
	table: {
		flex: 1,
		'& svg': {
			order: 2,
			color: '#f1f1f1',
		},
		'& svg:not(:nth-child(1))': {
			fill: 'rgba(0, 0, 0, 0.54)',
		},
		'& .caretDown': {
			marginBottom: '10%',
		},
	},
})
