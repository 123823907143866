/* eslint-disable @typescript-eslint/no-explicit-any */

import SurchargeEditor from 'app/services/tabulator/editors/qty/SurchargeEditor'
import AlternativeEditor from 'app/services/tabulator/editors/up/AlternativeEditor'
import DefaultEditor from 'app/services/tabulator/editors/up/DefaultEditor'
import { BoqNode } from 'app/types/graphql/BoqNode'
import { POSITION_TYPES } from 'app/types/POSITION_TYPES'

export default (
	cell: any,
	onRendered: any,
	success: any,
	cancel: any,
	editorParams: any
): any => {
	const boqNode = cell.getData() as BoqNode
	const { type } = boqNode

	switch (type) {
		case POSITION_TYPES.ALTERNATIVE:
			return new AlternativeEditor(
				cell,
				onRendered,
				success,
				cancel,
				editorParams
			).input
		case POSITION_TYPES.SURCHARGE:
			return new SurchargeEditor(
				cell,
				onRendered,
				success,
				cancel,
				editorParams
			).input
		default:
			return new DefaultEditor(cell, onRendered, success, cancel, editorParams)
				.input
	}
}
