/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react'
import { TABLE_FIELDS_BOQ } from 'app/constants'
import { useCustomSnackbar } from 'app/services/snackbar'
import {
	CellFormatterPrice,
	MeterUnitFormatter,
	UpEditor,
	handleCellUpEditable,
	toggleTree,
	handleCellQtyEditable,
} from 'app/services/tabulator'
import LinFormatter from 'app/services/tabulator/formatters/lin/LinFormatter'
import QtyFormatter from 'app/services/tabulator/formatters/QtyFormatter'
import TabulatorEditor from 'app/services/tabulator/TabulatorEditor'
import { useTranslation } from 'react-i18next'
import { reactFormatter } from 'react-tabulator'
import IconTypesFormatter from 'app/services/tabulator/formatters/type/IconTypesFormatter'
import AnnotationFormatter from 'app/services/tabulator/formatters/annotation/AnnotationFormatter'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'
import { Lot } from 'app/types'
import useEvents from './useEvents'

export default (
	isEditable: boolean,
	isVisible: boolean,
	role: ROLES_PROJECT | null,
	lot: Lot | null
): any => {
	const { t } = useTranslation()
	const {
		onClickCellLabel,
		handleUpCellEdited,
		onClickCellType,
		openAnnotationSideSheet,
	} = useEvents(lot)

	const { sendErrorMessage } = useCustomSnackbar()

	let data: any = [
		{
			field: TABLE_FIELDS_BOQ.LIN,
			title: t('common:tabulator.columns.lin'),
			width: 120,
			cellClick: toggleTree,
			formatter: LinFormatter,
		},
		{
			field: TABLE_FIELDS_BOQ.TYPE,
			width: 60,
			title: '',
			formatter: reactFormatter(<IconTypesFormatter />),
			cellClick: onClickCellType,
		},
		{
			field: TABLE_FIELDS_BOQ.LABEL,
			title: t('common:tabulator.columns.label'),
			widthGrow: 2,
			cellClick: onClickCellLabel,
			formatter: MeterUnitFormatter,
		},
		{
			field: TABLE_FIELDS_BOQ.QU,
			title: t('common:tabulator.columns.qu'),
			formatter: MeterUnitFormatter,
		},
		{
			field: TABLE_FIELDS_BOQ.QTY,
			title: t('common:tabulator.columns.qty'),
			align: 'right',
			editorParams: { sendErrorMessage },
			editable: (cell: any) => handleCellQtyEditable(cell, isEditable),
			cellEdited: handleUpCellEdited,
			editor: TabulatorEditor,
			formatter: (cell: any) => QtyFormatter(cell, isEditable),
		},
	]

	if (isVisible) {
		data = [
			...data,
			{
				field: TABLE_FIELDS_BOQ.UP,
				title: t('common:tabulator.columns.up'),
				editor: UpEditor,
				editorParams: { sendErrorMessage },
				cellEdited: handleUpCellEdited,
				editable: (e: any) => handleCellUpEditable(e, isEditable),
				visible: isVisible,
				cssClass: 'up',
				formatter: CellFormatterPrice,
				align: 'right',
			},
			{
				field: TABLE_FIELDS_BOQ.ANNOTATION,
				title: t('common:tabulator.columns.annotation'),
				formatter: reactFormatter(
					<AnnotationFormatter
						label={t('domain:award.annotations.createAnnotation.button.label')}
						onClick={openAnnotationSideSheet}
						role={role}
						lot={lot}
					/>
				),
				visible: isVisible,
				width: 230,
			},
		]
	}

	return useMemo(() => data, [isVisible, isEditable, lot])
}
