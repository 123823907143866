import { CHECKOUTDATA_KEYS, STEPS_CHECKOUT } from 'app/config/checkout'
import React, { FC, useEffect, FocusEvent } from 'react'
import { TextValidator } from 'react-material-ui-form-validator'
import { get, invert } from 'lodash'
import { useTranslation } from 'react-i18next'
import { CheckoutStepProps } from 'domain/checkout/interfaces/CheckoutStepProps'
import useTracking from 'app/services/datawarehouse/useTracking'
import TrackingEvents from 'app/services/datawarehouse/TrackingEvents'
import CheckoutCard from '../CheckoutCard/CheckoutCard'

const CardUserDataEnter: FC<CheckoutStepProps> = ({
	goToNextStep,
	handleInputChange,
	checkoutData,
	createFormDataFromObject,
	classes,
	stepId,
}) => {
	const firstName = get(checkoutData, CHECKOUTDATA_KEYS.FIRSTNAME, '')
	const lastName = get(checkoutData, CHECKOUTDATA_KEYS.LASTNAME, '')
	const phone = get(checkoutData, CHECKOUTDATA_KEYS.PHONE, '')
	const company = get(checkoutData, CHECKOUTDATA_KEYS.COMPANY, '')

	const { t } = useTranslation()
	const { track } = useTracking()

	const disabled = (): boolean => !firstName && !lastName && !phone && !company

	useEffect(() => {
		const isStepCompleted = get(
			checkoutData,
			CHECKOUTDATA_KEYS.STEP_USER_DATA_ENTER_COMPLETED,
			false
		)
		if (isStepCompleted && goToNextStep) {
			goToNextStep()
		}
	}, [checkoutData])

	const handleTrackOnBlur = (event: FocusEvent): void => {
		const eventName = get(event, ['target', 'name'], '')
		const stepKey = stepId && invert(STEPS_CHECKOUT)[stepId]
		track(
			`${stepKey}_${eventName.toUpperCase()}ENTER` as keyof typeof TrackingEvents,
			checkoutData
		)
	}

	const onSubmit = (): void => {
		if (createFormDataFromObject) {
			createFormDataFromObject({
				...checkoutData,
				[CHECKOUTDATA_KEYS.STEP_USER_DATA_ENTER_COMPLETED]: true,
			})
		}
	}

	return (
		<CheckoutCard
			headline={t('domain:checkout.updateAccount.card.user.title')}
			onSubmit={onSubmit}
			disabled={disabled()}
			classes={classes}
			stepId={stepId}
		>
			<TextValidator
				validators={['required']}
				name="firstName"
				value={firstName}
				label={`${t('domain:checkout.updateAccount.form.firstName.label')} *`}
				placeholder={t(
					'domain:checkout.updateAccount.form.firstName.placeholder'
				)}
				errorMessages={[
					t(
						'domain:checkout.updateAccount.form.firstName.validatorViolations.required'
					),
				]}
				fullWidth
				margin="normal"
				onChange={handleInputChange}
				onBlur={handleTrackOnBlur}
			/>
			<TextValidator
				validators={['required']}
				name="lastName"
				value={lastName}
				label={`${t('domain:checkout.updateAccount.form.lastName.label')} *`}
				placeholder={t(
					'domain:checkout.updateAccount.form.lastName.placeholder'
				)}
				errorMessages={[
					t(
						'domain:checkout.updateAccount.form.lastName.validatorViolations.required'
					),
				]}
				fullWidth
				margin="normal"
				onChange={handleInputChange}
				onBlur={handleTrackOnBlur}
			/>
			<TextValidator
				validators={['required']}
				name="company"
				value={company}
				label={`${t('domain:checkout.updateAccount.form.company.label')} *`}
				placeholder={t(
					'domain:checkout.updateAccount.form.company.placeholder'
				)}
				errorMessages={[
					t(
						'domain:checkout.updateAccount.form.company.validatorViolations.required'
					),
				]}
				fullWidth
				margin="normal"
				onChange={handleInputChange}
				onBlur={handleTrackOnBlur}
			/>
			<TextValidator
				validators={['required', 'isNumber']}
				name="phone"
				value={phone}
				label={`${t('domain:checkout.updateAccount.form.phone.label')} *`}
				placeholder={t('domain:checkout.updateAccount.form.phone.placeholder')}
				errorMessages={[
					t(
						'domain:checkout.updateAccount.form.phone.validatorViolations.required'
					),
					t(
						'domain:checkout.updateAccount.form.phone.validatorViolations.isNumber'
					),
				]}
				fullWidth
				margin="normal"
				onChange={handleInputChange}
				onBlur={handleTrackOnBlur}
			/>
		</CheckoutCard>
	)
}

export default CardUserDataEnter
