import { makeStyles, Theme } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'
import {
	QUANTITY_DETERMINATION_NODE_STATUS,
	QUANTITY_DETERMINATION_STATUS,
} from 'app/config/quantityDetermination'
import { calculateOpacity } from 'app/utils/tabulator'

interface StyleProps {
	declineDisabled: boolean
	acceptDisabled: boolean
	quantityDeterminationNodeStatus: QUANTITY_DETERMINATION_NODE_STATUS | null
	quantityDeterminationStatus?: QUANTITY_DETERMINATION_STATUS | null
}

export default makeStyles<Theme, StyleProps>(() => ({
	cancelIcon: ({
		declineDisabled,
		quantityDeterminationNodeStatus,
		quantityDeterminationStatus,
	}) => {
		return {
			...themeValues().sizes.InputActionIcons,
			padding: '6px 0',
			'& .MuiSvgIcon-root': {
				color: 'white',
			},
			opacity: calculateOpacity(
				quantityDeterminationNodeStatus,
				declineDisabled,
				quantityDeterminationStatus
			),
		}
	},
	acceptIcon: ({
		acceptDisabled,
		quantityDeterminationNodeStatus,
		quantityDeterminationStatus,
	}) => ({
		...themeValues().sizes.InputActionIcons,
		padding: '6px 0',
		marginRight: '10px',
		'& .MuiSvgIcon-root': {
			color: 'white',
		},
		opacity: calculateOpacity(
			quantityDeterminationNodeStatus,
			acceptDisabled,
			quantityDeterminationStatus
		),
	}),
	suggestionLink: {
		backgroundColor: 'transparent',
		border: 'none',
		outline: 'none',
		color: themeValues().colors.error,
		'&:hover': {
			textDecoration: 'none',
			cursor: 'pointer',
		},
	},
}))
