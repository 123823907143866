import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import PageContentHeader from 'component/PageContentHeader/PageContentHeader'

const AnnotationsPageContentHeader: FC = () => {
	const { t } = useTranslation()

	return (
		<PageContentHeader>{t('domain:award.annotations.title')}</PageContentHeader>
	)
}

export default AnnotationsPageContentHeader
