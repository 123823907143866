import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ValidatorForm from 'component/ValidatorForm/ValidatorForm'
import { TextValidator } from 'react-material-ui-form-validator'
import useForm from 'app/hooks/useForm'
import PrimaryButton from 'component/PrimaryButton/PrimaryButton'
import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import boqQueries from 'domain/general/boq/apollo/queries'
import { useParams } from 'react-router-dom'
import { UrlParams } from 'app/types/UrlParams'
import { useCustomSnackbar } from 'app/services/snackbar'
import { useSideSheet } from 'app/hooks'
import RenderErrorMessage from 'component/RenderErrorMessage/RenderErrorMessage'
import { ById } from 'app/types'
import CreateAnnotationFormProps from './CreateAnnotationFormProps'
import useStyles from './CreateAnnotationFormStyles'
import SelectUnitDropdown from '../SelectUnitDropdown/SelectUnitDropdown'
import mutations from '../../apollo/mutations'
import queries from '../../apollo/queries'

const CreateAnnotationForm: FC<CreateAnnotationFormProps> = ({
	boqNode,
	boqAnnotation,
	revision,
}) => {
	const { projectUuid, lotUuid } = useParams<UrlParams>()

	const {
		uuid: annotationUuid,
		shortText: annotationShortText,
		longText: annotationLongText,
		unit: annotationUnit,
		quantity: annotationQuantity,
		unitPrice: annotationUnitPrice,
		comment: annotationComment,
	} = boqAnnotation ?? {}

	const {
		formData,
		handleInputChange,
		reset,
		createFormDataFromObject,
	} = useForm({
		defaultValues: {
			shortText: '',
			longText: '',
			unit: '',
			quantity: '',
			unitPrice: '',
			comment: '',
		},
	})

	useEffect(() => {
		if (boqAnnotation) {
			createFormDataFromObject({
				shortText: annotationShortText ?? '',
				longText: annotationLongText ?? '',
				unit: annotationUnit?.code ?? '',
				quantity: annotationQuantity ?? '',
				unitPrice: annotationUnitPrice ?? '',
				comment: annotationComment ?? '',
			})
		} else {
			reset()
		}
	}, [boqAnnotation, boqNode])

	const { sendSuccessMessage } = useCustomSnackbar()
	const { closeSideSheet } = useSideSheet()

	const classes = useStyles()
	const { t } = useTranslation()

	const sideSheetButtonLabel = boqAnnotation
		? t('domain:award.annotations.sideSheet.updateAnnotation.button.label')
		: t('domain:award.annotations.sideSheet.createAnnotation.button.label')

	const { lin, label: boqLabel } = boqNode ?? {}
	const revisionUuid = revision?.uuid

	const [createBoqAnnotation, { loading, error }] = useGraphQLMutation(
		mutations.createBoqAnnotation,
		{
			onCompleted: () => {
				sendSuccessMessage(
					t('domain:award.annotations.createAndUpdateBoqAnnotation.success')
				)
				closeSideSheet()
				reset()
			},
		}
	)

	const [
		updateBoqAnnotation,
		{ loading: updateLoading, error: updateError },
	] = useGraphQLMutation(mutations.updateBoqAnnotation, {
		onCompleted: () => {
			sendSuccessMessage(
				t('domain:award.annotations.createAndUpdateBoqAnnotation.success')
			)
			closeSideSheet()
		},
	})

	const handleSubmit = (): void => {
		const { shortText, longText, unit, quantity, unitPrice, comment } = formData

		const variables = {
			...formData,
			projectUuid,
			lotUuid,
			revisionUuid,
			shortText,
			longText: longText || null,
			unit: unit || null,
			quantity: quantity || null,
			unitPrice: unitPrice || null,
			comment: comment || null,
		} as ById<string>

		if (shortText && projectUuid && lotUuid && revisionUuid) {
			if (!boqAnnotation && lin) {
				createBoqAnnotation({
					variables: {
						...variables,
						lin,
						referenceLabel: boqLabel,
					},
					refetchQueries: [
						{
							query: boqQueries.ProjectWithBoqInformation,
							variables: { projectUuid, lotUuid },
						},
						{
							query: queries.BoqAnnotations,
							variables: { projectUuid, lotUuid },
						},
					],
				})
			}

			if (boqAnnotation && annotationUuid) {
				updateBoqAnnotation({
					variables: {
						...variables,
						annotationUuid,
					},
				})
			}
		}
	}

	return (
		<ValidatorForm className={classes.root} onSubmit={handleSubmit}>
			<TextValidator
				validators={['required', 'maxStringLength:255', 'trim']}
				name="shortText"
				label={t('domain:award.annotations.sideSheet.form.shortText.label')}
				placeholder={t(
					'domain:award.annotations.sideSheet.form.shortText.placeholder'
				)}
				value={formData.shortText}
				onChange={handleInputChange}
				fullWidth
				margin="normal"
				errorMessages={[
					t(
						'domain:award.annotations.sideSheet.form.shortText.validatorViolations.required'
					),
					t(
						'domain:award.annotations.sideSheet.form.shortText.validatorViolations.maxStringLength'
					),
					t(
						'domain:award.annotations.sideSheet.form.shortText.validatorViolations.trim'
					),
				]}
			/>
			<TextValidator
				name="longText"
				label={t('domain:award.annotations.sideSheet.form.longText.label')}
				placeholder={t(
					'domain:award.annotations.sideSheet.form.longText.placeholder'
				)}
				value={formData.longText}
				onChange={handleInputChange}
				fullWidth
				rows={4}
				multiline
				margin="normal"
				inputProps={{
					maxLength: 1000,
				}}
			/>

			<div className={classes.unit}>
				<SelectUnitDropdown
					handleInputChange={handleInputChange}
					formData={formData}
					name="unit"
				/>

				<TextValidator
					validators={['isFloat']}
					name="quantity"
					label={t('domain:award.annotations.sideSheet.form.quantity.label')}
					placeholder={t(
						'domain:award.annotations.sideSheet.form.quantity.placeholder'
					)}
					value={formData.quantity}
					onChange={handleInputChange}
					margin="normal"
					errorMessages={[
						t(
							'domain:award.annotations.sideSheet.form.quantity.validatorViolations.isNumber'
						),
					]}
				/>
			</div>
			<TextValidator
				validators={['isFloat']}
				name="unitPrice"
				label={t('domain:award.annotations.sideSheet.form.unitPrice.label')}
				placeholder={t(
					'domain:award.annotations.sideSheet.form.unitPrice.placeholder'
				)}
				value={formData.unitPrice}
				onChange={handleInputChange}
				margin="normal"
				errorMessages={[
					t(
						'domain:award.annotations.sideSheet.form.unitPrice.validatorViolations.isFloat'
					),
				]}
				fullWidth
			/>

			<TextValidator
				name="comment"
				label={t('domain:award.annotations.sideSheet.form.comment.label')}
				placeholder={t(
					'domain:award.annotations.sideSheet.form.comment.placeholder'
				)}
				value={formData.comment}
				onChange={handleInputChange}
				fullWidth
				rows={4}
				multiline
				margin="normal"
				inputProps={{
					maxLength: 1000,
				}}
			/>
			<div className={classes.action}>
				<PrimaryButton
					loading={loading || updateLoading}
					type="submit"
					fullWidth={false}
				>
					{sideSheetButtonLabel}
				</PrimaryButton>
			</div>
			<RenderErrorMessage error={error || updateError} />
		</ValidatorForm>
	)
}

export default CreateAnnotationForm
