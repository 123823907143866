import MuiTabs from '@material-ui/core/Tabs'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import routes from 'app/routing/routes/routesApp'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import { Lot, ProjectData, ProjectVars } from 'app/types/index'
import { SECTIONS } from 'app/types/SECTIONS'
import { UrlParams } from 'app/types/UrlParams'
import { getProjectFromResponse, getLotsFromProject } from 'app/utils/apollo'
import { resolveRoute } from 'app/utils/route'
import { MenuItem } from 'component/index'
import queries from 'domain/lot/apollo/queries'
import CreateLotTab from 'domain/lot/components/CreateLotTab/CreateLotTab'
import LotTab from 'domain/lot/components/LotTab/LotTab'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import useStyles from 'domain/lot/components/LotTabs/LotTabsStyles'

const LotTabs: FC = () => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [tabsRef, setTabsRef] = useState<any>()
	const { projectUuid, lotUuid, sectionId, subSectionId } = useParams<
		UrlParams
	>()
	const [fetchData, { data }] = useGraphQLLazyQuery<ProjectData, ProjectVars>(
		queries.ProjectWithLotInformation
	)

	useEffect(() => {
		if (projectUuid) {
			fetchData({
				variables: {
					projectUuid,
				},
			})
		}
	}, [projectUuid])

	const project = getProjectFromResponse(data)
	const lots = getLotsFromProject(project)

	const getRoute = (uuid: string): string => {
		if (subSectionId) {
			return resolveRoute(routes.subSection, {
				projectUuid,
				lotUuid: uuid,
				sectionId: sectionId || SECTIONS.BOQ,
				subSectionId,
			})
		}
		return resolveRoute(routes.section, {
			projectUuid,
			lotUuid: uuid,
			sectionId: sectionId || SECTIONS.BOQ,
		})
	}

	const renderTabs = (): ReactNode | null => {
		if (Array.isArray(lots) && lots.length > 0) {
			return lots.map(({ uuid, name }: Lot) => (
				<MenuItem
					onCompleted={tabsRef.updateScrollButtons}
					key={uuid}
					value={uuid}
					component={LotTab}
					item={{
						id: uuid,
						title: name,
						icon: FormatListBulletedIcon,
						menuIcon: MoreVertIcon,
						url: getRoute(uuid),
						projectRoles: [ROLES_PROJECT.BIDDER, ROLES_PROJECT.LEAD],
					}}
				/>
			))
		}
		return null
	}

	const classes = useStyles()

	return (
		<div className={classes.root}>
			<MuiTabs
				value={lotUuid}
				classes={{
					flexContainer: classes.flexContainer,
					scrollButtonsDesktop: classes.scrollButtonsDesktop,
					indicator: classes.indicator,
				}}
				action={setTabsRef}
				className={classes.tabs}
				variant="scrollable"
				scrollButtons="auto"
			>
				{renderTabs()}
				<MenuItem
					key="Create"
					value="Create"
					component={LotTab}
					item={{
						id: 'Create',
						icon: FormatListBulletedIcon,
						component: CreateLotTab,
						validCombinations: [
							{
								projectRole: ROLES_PROJECT.LEAD,
							},
						],
					}}
				/>
			</MuiTabs>
		</div>
	)
}

export default LotTabs
