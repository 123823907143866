import useCustomSnackbar from 'app/services/snackbar/useCustomSnackbar'
import axios from 'axios'
import AttachmentCard from 'domain/general/attachments/components/body/attachments/AttachmentCard/AttachmentCard'
import { FileAttachmentCardProps } from 'domain/general/attachments/components/body/attachments/FileAttachmentCard/FileAttachmentCardProps'
import FileSaver from 'file-saver'
import React, { FC } from 'react'

const FileAttachmentCard: FC<FileAttachmentCardProps> = ({
	attachment,
	classes,
}) => {
	const { openUrl, mimeType, name, downloadUrl } = attachment
	const { sendErrorMessage } = useCustomSnackbar()

	const onImageClick = (): void => {
		axios
			.get(openUrl, {
				responseType: 'blob',
			})
			.then(res => {
				const blob = new Blob([res.data], {
					type: mimeType,
				})
				const url = URL.createObjectURL(blob)

				window.open(url, '_blank')
			})
			.catch(() =>
				sendErrorMessage(`The file "${name}" could not be displayed.`)
			)
	}

	const onDownload = (): void => {
		axios
			.get(downloadUrl, {
				responseType: 'blob', // !!! do not remove, otherwise downloaded images are broken!
			})
			.then(res => {
				const blob = new Blob([res.data])
				FileSaver.saveAs(blob, name)
			})
			.catch(() =>
				sendErrorMessage(`The file "${name}" could not be downloaded.`)
			)
	}

	return (
		<AttachmentCard
			attachment={attachment}
			classes={classes}
			onDownload={onDownload}
			onImageClick={onImageClick}
		/>
	)
}

export default FileAttachmentCard
