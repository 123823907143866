import { themeValues } from 'app/themes/themeValues'
import { makeStyles, Theme } from '@material-ui/core'

interface StyleProps {
	noPredefinedDate: boolean
}

export default makeStyles<Theme, StyleProps>(theme => ({
	root: ({ noPredefinedDate }) => ({
		'& .MuiInputAdornment-root': {
			display: 'none !important',
		},
		'& .MuiPickersDay-daySelected.MuiPickersDay-dayDisabled': {
			color: themeValues().selectedDate.color,
			backgroundColor: themeValues().selectedDate.backgroundColor,
		},
		'& .MuiPickersDay-daySelected': {
			backgroundColor: noPredefinedDate
				? themeValues().selectedDate.color
				: themeValues().selectedDate.backgroundColor,
			color: noPredefinedDate
				? themeValues().selectedDate.textColor
				: themeValues().selectedDate.color,
		},
	}),
	grid: {
		maxWidth: themeValues().sizes.EndDateCalendar.maxWidth,
	},
	noticeRoot: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
	dateAndTime: {
		flexGrow: 1,
	},
}))
