/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	QUANTITY_DETERMINATION_NODE_STATUS,
	QuantityDeterminationNode,
	QUANTITY_DETERMINATION_STATUS,
} from 'app/config/quantityDetermination'
import {
	TABULATOR_CHIP_VARIANTS,
	QUANTITY_DETERMINATION_NODE_STATUS_CONFIG,
	QUANTITY_DETERMINATION_STATUS_CONFIG,
} from 'app/config/tabulator'
import { Bidder } from 'app/types/graphql/Bidder'
import { BoqNode } from 'app/types/graphql/BoqNode'
import { POSITION_TYPES } from 'app/types/POSITION_TYPES'
import { REVISION_STATUS } from 'app/types/REVISION_STATUS'
import { LOT_STATUS } from 'domain/lot/interfaces/LOT_STATUS'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'
import { get, isString, toNumber } from 'lodash'

export const isFulfillmentType = (cell: any): boolean =>
	cell.getData().type === POSITION_TYPES.FULFILLMENT

export const getRowFromCell = (cell: any): any | null =>
	get(cell.getRow(), ['_row'], null)

export const getBoqNodesFromCell = (cell: any): BoqNode[] | null =>
	get(getRowFromCell(cell), ['data', 'boqNodes'], null)

export const getQuantityDeterminationNodesFromCell = (
	cell: any
): QuantityDeterminationNode[] | null =>
	get(getRowFromCell(cell), ['data', 'quantityDeterminationNodes'], null)

export const getBiddersFromCell = (cell: any): Bidder[] | null =>
	get(getRowFromCell(cell), ['data', 'bidders'], null)

export const getPathForIdFromCell = (cell: any): any | null =>
	get(cell.getRow().getData(), ['path', cell.getField()], null)

export const getParentFromCell = (cell: any): any | null =>
	get(getRowFromCell(cell), ['modules', 'dataTree', 'parent'], null)

export const getParentFromRow = (row: any): any | null =>
	get(row, ['modules', 'dataTree', 'parent'], null)

export const getChildrenFromCell = (cell: any): any | null =>
	get(getRowFromCell(cell), ['modules', 'dataTree', 'children'], null)

export const getFirstChildFromCell = (cell: any): any | null =>
	get(getRowFromCell(cell), ['modules', 'dataTree', 'children', '0'], null)

export const getLastChildFromCell = (cell: any): any | null => {
	const children = getChildrenFromCell(cell)
	const lastChild = (children && children[children.length - 1]) || null
	return lastChild
}

export const addClasses = (element: any, classes: string[]): any => {
	const newElement = element
	if (newElement) {
		newElement.className = `${element.className} ${classes.join(' ')}`
	}
	return newElement
}

export const removeClass = (element: any, className: string): any => {
	const newElement = element
	newElement.className = newElement.className.replace(className, ' ')
	return newElement
}

export const roundToTwoDecimals = (number: number): number =>
	Math.round(number * 100) / 100

export const calculateDeviation = (
	targetValue: number,
	referenceValue: number
): number => (100 * Number(targetValue)) / referenceValue - 100

export const getNumberStringWithSign = (number: number): string =>
	number > 0
		? `+${number.toString().replace('.', ',')}%`
		: `${number.toString().replace('.', ',')}%`

export const calculateAverage = (numbers: number[]): number =>
	numbers.reduce((a, b) => {
		return a + b
	}) / numbers.length

export const isInvalidValue = (value: string): boolean => {
	if (!Number.isNaN(toNumber(value))) {
		const numberSegments = value.split('.')
		const decimals = numberSegments.length > 1 ? numberSegments[1] : null
		if (decimals) {
			return decimals.length > 3
		}
		return false
	}
	return true
}

export const addSupTag = (value: string | number): string => {
	if (!value) {
		return ''
	}

	if (isString(value)) {
		return value
			.replace(/m2/g, '<span>m<sup>2</sup></span>')
			.replace(/m3/g, '<span>m<sup>3</sup></span>')
	}
	return `${value}`
}

export const calculateDeviationFromLowest = (
	lowestPrice: number,
	price: number
): number => {
	if (lowestPrice === 0 && price === 0) {
		return 0
	}

	return Math.round(calculateDeviation(Number(price), lowestPrice))
}

export const walkAdd = (
	quantityDeterminationNodes: any,
	identifier: QuantityDeterminationNode,
	callback: (item: any) => QuantityDeterminationNode
): QuantityDeterminationNode[] => {
	const newQuantityDeterminationsNodes = []
	// eslint-disable-next-line no-restricted-syntax
	for (let item of quantityDeterminationNodes) {
		if (item.quantityDeterminationNodes) {
			item.quantityDeterminationNodes = walkAdd(
				item.quantityDeterminationNodes,
				identifier,
				callback
			)
		}

		if (
			(identifier.lin && item.lin === identifier.lin) ||
			(identifier.uuid && item.uuid === identifier.uuid)
		) {
			item = callback(item)
		}
		newQuantityDeterminationsNodes.push(item)
	}
	return newQuantityDeterminationsNodes
}

export const calculateOpacity = (
	quantityDeterminationNodestatus: QUANTITY_DETERMINATION_NODE_STATUS | null,
	disable: boolean,
	quantityDeterminationStatus?: QUANTITY_DETERMINATION_STATUS | null
): number => {
	let opacity = 0.2
	if (
		quantityDeterminationNodestatus ===
		QUANTITY_DETERMINATION_NODE_STATUS.PENDING
	) {
		opacity = 0.5
	}

	if (disable) {
		opacity = 1
	}

	if (quantityDeterminationStatus !== QUANTITY_DETERMINATION_STATUS.SUBMITTED) {
		opacity = 0.2
	}
	return opacity
}

export const variantFromNodeStatus = (
	quantityDeterminationNodeStatus: QUANTITY_DETERMINATION_NODE_STATUS
): TABULATOR_CHIP_VARIANTS => {
	return get(
		QUANTITY_DETERMINATION_NODE_STATUS_CONFIG,
		[quantityDeterminationNodeStatus],
		TABULATOR_CHIP_VARIANTS.DEFAULT
	)
}

export const variantFromRoleAndQuantityDeterminationStatus = (
	role: ROLES_PROJECT | null,
	quantityDeterminationStatus: QUANTITY_DETERMINATION_STATUS
): TABULATOR_CHIP_VARIANTS => {
	if (role) {
		return get(
			QUANTITY_DETERMINATION_STATUS_CONFIG,
			[role, quantityDeterminationStatus],
			TABULATOR_CHIP_VARIANTS.DEFAULT
		)
	}
	return TABULATOR_CHIP_VARIANTS.DEFAULT
}

export const hasAnnotationValidStatus = (
	lotStatusForValidation?: LOT_STATUS | null,
	revisionStatusForValidation?: REVISION_STATUS | null
): boolean =>
	lotStatusForValidation === LOT_STATUS.TENDERING &&
	revisionStatusForValidation === REVISION_STATUS.BIDDING

export const hasNodeValidTypeToAddChildren = (
	type: POSITION_TYPES
): boolean => {
	const validTypes = [
		POSITION_TYPES.NORMAL,
		POSITION_TYPES.OPTIONAL,
		POSITION_TYPES.BASE,
		POSITION_TYPES.ALTERNATIVE,
		POSITION_TYPES.REMARK,
		POSITION_TYPES.SURCHARGE,
		POSITION_TYPES.WAGE,
		POSITION_TYPES.BLANKET,
		POSITION_TYPES.FULFILLMENT,
		POSITION_TYPES.BLOCK,
	]

	return validTypes.includes(type)
}

export const hasQDValidStatusToBeModifiedByBidder = (
	quantityDeterminationStatus: QUANTITY_DETERMINATION_STATUS
): boolean => {
	const validQuantityDeterminationStatus = [
		QUANTITY_DETERMINATION_STATUS.INITIAL,
		QUANTITY_DETERMINATION_STATUS.EDITED,
		QUANTITY_DETERMINATION_STATUS.REVIEWED,
		QUANTITY_DETERMINATION_STATUS.ACCEPTED,
	]

	return validQuantityDeterminationStatus.includes(quantityDeterminationStatus)
}

export const isNodeQtyDetermType = (type: POSITION_TYPES): boolean => {
	return type === POSITION_TYPES.QTY_DETERM
}

export const isNodeSuggestionTypeAndHasStatusPending = (
	type: POSITION_TYPES,
	quantityDeterminationNodeStatus: QUANTITY_DETERMINATION_NODE_STATUS
): boolean => {
	return (
		type === POSITION_TYPES.SUGGESTION &&
		quantityDeterminationNodeStatus ===
			QUANTITY_DETERMINATION_NODE_STATUS.PENDING
	)
}
/** ************************* */

export const shouldClassBeRemoved = (
	role: ROLES_PROJECT | null,
	quantityDeterminationStatus: QUANTITY_DETERMINATION_STATUS | null
): boolean => {
	const conditions: any = {
		bidder: {
			validQuantityDeterminationStatus: [
				QUANTITY_DETERMINATION_STATUS.SUBMITTED,
			],
		},
		lead: {
			validQuantityDeterminationStatus: [
				QUANTITY_DETERMINATION_STATUS.INITIAL,
				QUANTITY_DETERMINATION_STATUS.EDITED,
				QUANTITY_DETERMINATION_STATUS.REVIEWED,
				QUANTITY_DETERMINATION_STATUS.ACCEPTED,
			],
		},
	}

	if (!role) {
		return false
	}

	const { validQuantityDeterminationStatus } = conditions[role]

	return !!validQuantityDeterminationStatus.includes(
		quantityDeterminationStatus
	)
}
