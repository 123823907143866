import { UrlParams } from 'app/types/UrlParams'
import PageContent from 'component/PageContent/PageContent'
import React, { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import PeriodPageContentBody from '../PeriodPageContentBody/PeriodPageContentBody'
import PeriodPageContentHeader from '../PeriodPageContentHeader/PeriodPageContentHeader'

const PeriodPageContent = (): ReactElement | null => {
	const { lotUuid } = useParams<UrlParams>()

	return (
		<PageContent
			key={lotUuid}
			header={<PeriodPageContentHeader />}
			body={<PeriodPageContentBody />}
		/>
	)
}

export default PeriodPageContent
