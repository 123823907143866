import { Typography } from '@material-ui/core'
import clsx from 'clsx'
import { AttributeInfoItemHeadlineProps } from 'domain/general/boq/components/sidesheet/attributeInfos/AttributeInfoItemHeadline/AttributeInfoItemHeadlineProps'
import React, { FC } from 'react'

import useStyles from './AttributeInfoItemHeadlineStyles'

const AttributeInfoItemHeadline: FC<AttributeInfoItemHeadlineProps> = ({
	text,
	classes: parentClasses = {},
}) => {
	const classes = useStyles()

	return (
		<Typography
			variant="h6"
			component="h2"
			classes={{
				root: clsx(classes.root, parentClasses.root),
			}}
		>
			{text}
		</Typography>
	)
}

export default AttributeInfoItemHeadline
