import { IMAGE_PLACEHOLDER_FOLDER } from 'app/config/attachments'
import useForm from 'app/hooks/useForm'
import ActionInput from 'component/ActionInput/ActionInput'
import MediaCard from 'component/MediaCard/MediaCard'
import { FolderCreateAttachmentCardProps } from 'domain/general/attachments/components/body/attachments/FolderCreateAttachmentCard/FolderCreateAttachmentCardProps'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

const FolderCreateAttachmentCard: FC<FolderCreateAttachmentCardProps> = ({
	classes,
	onAccept,
	onAbort,
}) => {
	const { t } = useTranslation()
	const { formData, handleInputChange } = useForm()

	return (
		<MediaCard
			src={IMAGE_PLACEHOLDER_FOLDER}
			title={t('domain:attachment.createFolder.card.title')}
			headline={
				<ActionInput
					name="folderName"
					focusAndSelect
					abortOnEscape
					acceptOnEnter
					value={formData.folderName as string}
					onChange={handleInputChange}
					onAccept={onAccept}
					onAbort={onAbort}
					defaultValue={t('domain:attachment.createFolder.input.defaultValue')}
				/>
			}
			classes={classes}
		/>
	)
}

export default FolderCreateAttachmentCard
