import { KeyboardTimePicker } from '@material-ui/pickers'
import { TIME_FORMAT_PICKER } from 'app/config/locales'
import { TimePickerProps } from 'component/TimePicker/TimePickerProps'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

const TimePicker: FC<TimePickerProps> = ({
	value,
	onChange,
	disabled,
	error,
}) => {
	const { t } = useTranslation()
	return (
		<KeyboardTimePicker
			emptyLabel="__:__"
			disabled={disabled}
			onChange={onChange}
			value={value}
			fullWidth
			format={TIME_FORMAT_PICKER}
			invalidDateMessage={t(
				'domain:tender.createTenderingPeriod.validatorViolations.invalidTime'
			)}
			error={error}
			minDateMessage={t(
				'domain:tender.createTenderingPeriod.validatorViolations.minDate'
			)}
		/>
	)
}

export default TimePicker
