import { ENVIRONMENTS } from 'app/constants'

/* eslint-disable @typescript-eslint/camelcase */
export default {
	GAEB_SUPPORT: {
		code: 'gaeb_support',
		value: 'Support requested',
	},
	TRANSLATION_NOT_FOUND: {
		code: 'translation_not_found',
	},
	STEP_PRICING_PLAN_ENTER: {
		code: 'step_pricing_plan',
		sub_type: 'enter',
	},
	STEP_PRICING_PLAN_SELECTQUANTITY: {
		code: 'step_pricing_plan',
		sub_type: 'selectQuantity',
	},
	STEP_EMAIL_ENTER_ENTER: {
		code: 'step_email_enter',
		sub_type: 'enter',
	},
	STEP_EMAIL_VERIFICATION_ENTER: {
		code: 'step_email_verification',
		sub_type: 'enter',
	},
	STEP_USER_DATA_ENTER_ENTER: {
		code: 'step_user_data_enter',
		sub_type: 'enter',
	},
	STEP_USER_DATA_ENTER_FIRSTNAMEENTER: {
		code: 'step_user_data_enter',
		sub_type: 'firstNameEnter',
	},
	STEP_USER_DATA_ENTER_LASTNAMEENTER: {
		code: 'step_user_data_enter',
		sub_type: 'lastNameEnter',
	},
	STEP_USER_DATA_ENTER_COMPANYENTER: {
		code: 'step_user_data_enter',
		sub_type: 'companyEnter',
	},
	STEP_USER_DATA_ENTER_PHONEENTER: {
		code: 'step_user_data_enter',
		sub_type: 'phoneEnter',
	},
	STEP_COMPANY_DATA_ENTER_ENTER: {
		code: 'step_company_data_enter',
		sub_type: 'enter',
	},
	STEP_COMPANY_DATA_ENTER_STREETENTER: {
		code: 'step_company_data_enter',
		sub_type: 'streetEnter',
	},
	STEP_COMPANY_DATA_ENTER_ZIPCODEENTER: {
		code: 'step_company_data_enter',
		sub_type: 'zipCodeEnter',
	},
	STEP_COMPANY_DATA_ENTER_CITYENTER: {
		code: 'step_company_data_enter',
		sub_type: 'cityEnter',
	},
	STEP_PASSWORD_ENTER_ENTER: {
		code: 'step_password_enter',
		sub_type: 'enter',
	},
	STEP_PAYMENT_ENTER_ENTER: {
		code: 'step_payment_enter',
		sub_type: 'enter',
	},
	STEP_PAYMENT_CONFIRM_ENTER: {
		code: 'step_payment_confirm',
		sub_type: 'enter',
	},
	STEP_PAYMENT_CONFIRM_AGBVISIT: {
		code: 'step_payment_confirm',
		sub_type: 'agbVisit',
	},
	STEP_PAYMENT_CONFIRM_AGBENTER: {
		code: 'step_payment_confirm',
		sub_type: 'agbEnter',
	},
	STEP_PAYMENT_SUCCESS_ENTER: {
		code: 'step_payment_success',
		sub_type: 'enter',
	},
	PING_BACKGROUND: {
		code: 'ping',
		sub_type: 'background',
		value: process.env.REACT_APP_ENV as ENVIRONMENTS,
	},
	PING_FOREGROUND: {
		code: 'ping',
		sub_type: 'foreground',
		value: process.env.REACT_APP_ENV as ENVIRONMENTS,
	},
}
