import { ApolloError } from 'apollo-client'
import { get } from 'lodash'
import { GraphQLError } from 'graphql'
import { RestError } from 'app/types/RestError'
import { StripeError } from '@stripe/stripe-js'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const CODE_MESSAGE_MAPPING = require('./codes.json')

export const ERROR_UNKNOWN = 200

export const ERRORS_WITH_DIALOG = [204006, 204001, 101504]

/**
 * Get an error message by a predefined code
 * @param code
 */
const getErrorMessageByCode = (code: number): string => {
	const message = CODE_MESSAGE_MAPPING[code.toString()]
	return message || CODE_MESSAGE_MAPPING[ERROR_UNKNOWN]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isApolloError = (error: any): boolean => {
	const gqlErrors = get(error, 'graphQLErrors', null)

	return !!gqlErrors
}

/**
 * Validate if the passed error object is from the type GraphQLError
 * @param error
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isGqlError = (error: any): boolean => {
	return (
		!!(error as GraphQLError).extensions && !!(error as GraphQLError).message
	)
}

/**
 * Validate if the passed error object is from the type RestError
 * @param error
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isRestError = (error: any): boolean => {
	return (
		!!(error as RestError).uuid &&
		!!(error as RestError).code &&
		!!(error as RestError).message
	)
}

/**
 * Converts the GraphQl Error to a user friendly string
 * @param error
 */
const convertGraphQlErrorToMessage = (error: GraphQLError): string => {
	const apiErrorCode: number = get(error, ['extensions', 'category'], -1)
	return getErrorMessageByCode(apiErrorCode)
}

/**
 * @param error
 */
const convertApolloErrorToMessage = (error: ApolloError): string => {
	const firstGqlError = error.graphQLErrors[0]

	return convertGraphQlErrorToMessage(firstGqlError)
}

/**
 * Converts the Rest Error to a user friendly string
 * @param error
 */
const convertRestErrorToMessage = (error: RestError): string => {
	const apiErrorCode = get(error, ['code'], -1)
	return getErrorMessageByCode(apiErrorCode)
}

/**
 * Function to handle the error object to string convertion. Is the global
 * entry point for error translations
 * @param error
 */
export const convertApiErrorToErrorMessage = (
	error: GraphQLError | RestError | ApolloError | StripeError | undefined
): string => {
	let message = getErrorMessageByCode(-1)

	if (!error) {
		return message
	}

	if (isApolloError(error)) {
		message = convertApolloErrorToMessage(error as ApolloError)
	}

	if (isGqlError(error)) {
		message = convertGraphQlErrorToMessage(error as GraphQLError)
	}

	if (isRestError(error)) {
		message = convertRestErrorToMessage(error as RestError)
	}

	return message
}
