import {
	getRoleFromProject,
	getTotalCountContactsFromResponse,
} from 'app/utils/apollo'
import { useParams } from 'react-router-dom'
import { UrlParams } from 'app/types/UrlParams'
import { useTranslation } from 'react-i18next'
import queries from 'domain/tender/bidders/apollo/queries'
import filterByTags from 'app/services/tabulator/filters/filterByTags'
import PageContentBody from 'component/PageContentBody/PageContentBody'
import TagFilter from 'domain/tender/bidders/components/body/filtering/TagFilter/TagFilter'
import { TenderPageContentBodyProps } from 'domain/tender/bidders/components/base/TenderPageContentBody/TenderPageContentBodyProps'
import { CollaborationTable } from 'domain/tender/bidders/index'
import { ContactTag } from 'domain/tender/bidders/types/ContactTag'
import { pullAllBy } from 'lodash'
import React, { FC, useMemo, useState, useEffect, ReactElement } from 'react'

import EmptyTable from 'component/EmptyTable/EmptyTable'
import Loading from 'component/Loading/Loading'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import useStyles from './TenderPageContentBodyStyles'

const TenderPageContentBody: FC<TenderPageContentBodyProps> = ({ project }) => {
	const classes = useStyles()

	const { t } = useTranslation()

	const [selectedTags, setSelectedTags] = useState<ContactTag[]>([])

	const [fetch, { data, loading }] = useGraphQLLazyQuery(queries.ContactsUuid)

	const { projectUuid, lotUuid } = useParams<UrlParams>()

	const totalCount = getTotalCountContactsFromResponse(data)

	useEffect(() => {
		fetch()
	}, [projectUuid, lotUuid])

	const addTagToSelection = (newTag: ContactTag | null): void => {
		if (newTag) {
			const isAlreadyUsed =
				selectedTags.findIndex(tag => tag.uuid === newTag.uuid) > -1
			if (!isAlreadyUsed) {
				setSelectedTags([...selectedTags, newTag])
			}
		}
	}

	const removeTagFromSelection = (tag: ContactTag): void => {
		setSelectedTags(pullAllBy([...selectedTags], [{ uuid: tag.uuid }], 'uuid'))
	}

	const tagUuids = useMemo(() => {
		return selectedTags.reduce((prev, tag) => {
			return [...prev, tag.uuid]
		}, [] as string[])
	}, [selectedTags])

	const renderNormalState = (): ReactElement => {
		return (
			<>
				<TagFilter
					onSelect={addTagToSelection}
					selectedTags={selectedTags}
					onDelete={removeTagFromSelection}
				/>
				<CollaborationTable
					filters={[{ function: filterByTags, data: tagUuids }]}
					role={getRoleFromProject(project)}
					className={classes.table}
					onTagClick={addTagToSelection}
				/>
			</>
		)
	}

	const renderEmptyState = (): ReactElement => {
		return <EmptyTable title={t('domain:tender.viewTable.empty')} />
	}

	return (
		<PageContentBody>
			<Loading loading={loading}>
				{totalCount !== 0 ? renderNormalState() : renderEmptyState()}
			</Loading>
		</PageContentBody>
	)
}

export default TenderPageContentBody
