/* eslint-disable @typescript-eslint/no-explicit-any */

import UpBaseEditor from 'app/services/tabulator/editors/up/UpBaseEditor'
import TabulatorHelper from 'app/services/tabulator/TabulatorHelper'

class AlternativeEditor extends UpBaseEditor {
	onChange(): void {
		const baseRow = TabulatorHelper.findBaseRow(this.cell.getRow())
		const alternativeRows = TabulatorHelper.findAlternativeRows(baseRow)

		if (this.isValidInputValue()) {
			this.success(this.getFormattedInputValue())
			alternativeRows.forEach((row: any) => {
				row.reformat()
			})
		} else {
			// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
			// @ts-ignore
			this.editorParams.sendErrorMessage(
				'Ein ungültiger Wert wurde eingetragen.'
			)
			this.cancel()
		}
	}

	// eslint-disable-next-line class-methods-use-this,@typescript-eslint/no-empty-function
	onPressEnter(): void {} // @TODO it is just a temporary workaround, because the move to the next cell make the app crash
}

export default AlternativeEditor
