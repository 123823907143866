import ListItemIcon from '@material-ui/core/ListItemIcon'
import { ProfileMenuItemDatabaseResetProps } from 'domain/header/components/profileMenu/ProfileMenuItemDatabaseReset/ProfileMenuItemDatabaseResetProps'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import { ENVIRONMENTS } from 'app/constants'
import { useAuthentication, useEnvironmentVariables } from 'app/hooks/index'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

// custom Styles MUST be imported last to ensure style heredity
import useStyles from 'domain/header/components/profileMenu/ProfileMenuItemDatabaseReset/ProfileMenutemDatabaseResetStyles'

const ProfileMenuItemDatabaseReset: FC<ProfileMenuItemDatabaseResetProps> = ({
	onDbReset,
}) => {
	const { t } = useTranslation()

	const { isAdmin } = useAuthentication()
	const { REACT_APP_ENV } = useEnvironmentVariables()
	const classes = useStyles()

	if (!isAdmin || REACT_APP_ENV === ENVIRONMENTS.PROD) {
		return null
	}

	return (
		<MenuItem onClick={onDbReset} className={classes.root}>
			<ListItemIcon>
				<RotateLeftIcon />
			</ListItemIcon>
			<ListItemText primary={t('domain:header.profileMenu.resetDb.title')} />
		</MenuItem>
	)
}

export default ProfileMenuItemDatabaseReset
