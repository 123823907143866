import MuiButton from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ButtonProps } from 'component/Button/ButtonProps'
import React, { FC, useEffect, useState } from 'react'

import useStyles from 'component/Button/ButtonStyles'
import clsx from 'clsx'

const Button: FC<ButtonProps> = ({
	color,
	title,
	onClick,
	disabled = false,
	children,
	fullWidth,
	type,
	loading = false,
	variant,
	classes: parentClasses = {},
	className,
}) => {
	const [ref, setRef] = useState<null | HTMLButtonElement>(null)
	const [minWidth, setMinWidth] = useState(0)
	const classes = useStyles({ minWidth })

	useEffect(() => {
		if (ref) {
			setMinWidth(ref.getBoundingClientRect().width)
		}
	}, [ref])

	return (
		<MuiButton
			ref={buttonRef => setRef(buttonRef)}
			title={title}
			onClick={onClick}
			disabled={loading || disabled}
			type={type}
			variant={variant}
			color={color}
			fullWidth={fullWidth}
			className={clsx(classes.root, className)}
			classes={parentClasses}
		>
			{loading ? <CircularProgress size={24} color="inherit" /> : children}
		</MuiButton>
	)
}

export default Button
