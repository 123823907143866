import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import Tag from 'component/Tag/Tag'
import mutations from 'domain/tender/bidders/apollo/mutations'
import queries from 'domain/tender/bidders/apollo/queries'
import { ContactTagProps } from 'domain/tender/bidders/components/sidesheet/contacts/ContactTag/ContactTagProps'
import React, { FC } from 'react'

const ContactTag: FC<ContactTagProps> = ({ tag, contactUuid, ...rest }) => {
	const [deleteContactTag] = useGraphQLMutation(mutations.deleteContactTag, {
		update: proxy => {
			const data = proxy.readQuery({
				query: queries.ContactWithTags,
				variables: { contactUuid },
			})

			proxy.writeQuery({
				query: queries.ContactWithTags,
				variables: { contactUuid },
				data: {
					contact: {
						uuid: contactUuid,
						// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
						// @ts-ignore
						tags: data.contact.tags.filter(tagCache => {
							return tag.uuid !== tagCache.uuid
						}),
						__typename: 'Contact',
					},
				},
			})
		},
	})

	const onDelete = (): void => {
		deleteContactTag({ variables: { contactUuid, tagUuid: tag.uuid } })
	}

	return <Tag color="primary" label={tag.label} onDelete={onDelete} {...rest} />
}

export default ContactTag
