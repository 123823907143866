import { resolveRoute } from 'app/utils/route'
import React, { FC } from 'react'
import routes from 'app/routing/routes/routesApp'
import { SECTIONS } from 'app/types/SECTIONS'
import { UrlParams } from 'app/types/UrlParams'
import { useTranslation, Trans } from 'react-i18next'
import Link from 'component/Link/Link'
import { useParams } from 'react-router-dom'
import PageContentParagraph from 'component/PageContentParagraph/PageContentParagraph'

const CloseTenderingNotice: FC = () => {
	const { t } = useTranslation()
	const { projectUuid, lotUuid } = useParams<UrlParams>()

	const generateUrl = (): string =>
		resolveRoute(routes.subSection, {
			projectUuid,
			lotUuid,
			sectionId: SECTIONS.TENDER,
			subSectionId: SECTIONS.PERIOD,
		})

	return (
		<PageContentParagraph>
			<Trans
				defaults="domain:award.notAllowed.content"
				values={{
					linkTitle: t(`domain:award.notAllowed.linkTitle`),
				}}
				components={[<Link to={generateUrl()}>bidder</Link>]}
			/>
		</PageContentParagraph>
	)
}

export default CloseTenderingNotice
