import BreadCrumb from 'component/BreadCrumb/BreadCrumb'
import BreadCrumbButton from 'component/BreadCrumbButton/BreadCrumbButton'
import BreadCrumbs from 'component/BreadCrumbs/BreadCrumbs'
import { AttachmentBreadCrumbsProps } from 'domain/general/attachments/components/body/navigation/AttachmentBreadCrumbs/AttachmentBreadCrumbsProps'
import React, { FC, ReactNode, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import ValidateDomainEvent from 'component/ValidateDomainEvent/ValidateDomainEvent'
import { DOMAIN_EVENTS } from 'domain/project/enums/DOMAIN_EVENTS'
import { DOMAIN_EVENT_TRIGGER } from 'domain/project/enums/DOMAIN_EVENT_TRIGGER'
import useStyles from './AttachmentBreadCrumpsStyle'

const AttachmentBreadCrumbs: FC<AttachmentBreadCrumbsProps> = ({
	onNewFolder,
	onBreadCrumbClick,
	parents,
	author,
}) => {
	const classes = useStyles()
	const { t } = useTranslation()
	const { length } = parents

	const renderBreadCrumbs = (): ReactNode | null => {
		if (length > 0 && !author) {
			return parents.map(({ uuid, name }, index) => {
				return (
					<BreadCrumb
						active={index === parents.length - 1}
						onClick={() => onBreadCrumbClick(uuid)}
						key={uuid}
						label={name}
					/>
				)
			})
		}

		return null
	}

	const renderRoot = (): ReactElement => {
		if (author) {
			return <BreadCrumb active label={author.name} />
		}

		return (
			<BreadCrumb
				active={length < 1}
				label={t('domain:attachment.breadcrumb.root.title')}
				onClick={() => {
					onBreadCrumbClick(null)
				}}
			/>
		)
	}

	const renderCreateButton = (): ReactElement | null => {
		if (author) {
			return null
		}

		return (
			<ValidateDomainEvent
				event={DOMAIN_EVENTS.CREATE_ATTACHMENT_FOLDER}
				trigger={DOMAIN_EVENT_TRIGGER.ON_CLICK}
			>
				<BreadCrumbButton
					title={t('domain:attachment.breadcrumb.create.title')}
					onClick={() => onNewFolder(true)}
				/>
			</ValidateDomainEvent>
		)
	}

	return (
		<div className={classes.root}>
			<BreadCrumbs>
				{renderRoot()}
				{renderBreadCrumbs()}
			</BreadCrumbs>
			{renderCreateButton()}
		</div>
	)
}

export default AttachmentBreadCrumbs
