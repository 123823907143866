import { makeStyles } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'

export default makeStyles({
	track: {},
	checked: {
		'& + $track': {
			backgroundColor: themeValues().colors.success,
			background: themeValues().colors.success,
			opacity: 1,
		},
	},
})
