import { makeStyles, Theme } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'

export default makeStyles((theme: Theme) => ({
	root: (inEditMode: boolean) => ({
		...themeValues().components.Tab,
		background: inEditMode ? 'white' : themeValues().colors.success,
		minWidth: inEditMode ? 380 : 0,
		paddingLeft: inEditMode ? theme.spacing(3) : 0,
		paddingRight: inEditMode ? theme.spacing(3) : 0,
		paddingTop: inEditMode ? theme.spacing(2) : 0,
		paddingBottom: inEditMode ? theme.spacing(2) : 0,
		borderRadius: 0,
		borderTopLeftRadius: themeValues().sizes.LotTab.borderRadius,
		borderTopRightRadius: themeValues().sizes.LotTab.borderRadius,
		opacity: 1,
		height: themeValues().sizes.LotTab.height,
		'& .MuiTab-wrapper': {
			display: 'flex',
			alignSelf: 'stretch',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-start',
		},
	}),
	iconButton: (inEditMode: boolean) => ({
		height: themeValues().sizes.LotTab.height,
		padding: inEditMode ? 0 : `0 ${theme.spacing(1)}px`,
		borderRadius: 0,
	}),
	addIcon: {
		fontSize: themeValues().sizes.TabCreateLotIcon.fontSize,
		color: 'white',
	},
}))
