import { Paper } from '@material-ui/core'
import { LAST_VISITED_URL_PARAMS } from 'app/constants'
import { useLocalStorage, useResetDb } from 'app/hooks/index'
import { PrivateRoute } from 'app/routing/index'
import AvailableRouteParams from 'app/routing/routes/AvailableRouteParams'
import routes from 'app/routing/routes/routesApp'
import { resolveRoute } from 'app/utils/route'
import { isSectionItemNavigatable, getSectionItemById } from 'app/utils/section'
import { SideSheetProvider } from 'component/index'
import PageContentError from 'component/PageContentError/PageContentError'
import Validate from 'component/Validate/Validate'
import Drawer from 'domain/drawer/components/Drawer/Drawer'
import Header from 'domain/header/components/Header/Header'
import ProjectPageContent from 'domain/project/components/base/ProjectPageContent/ProjectPageContent'
import React, { FC, ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteProps, Redirect } from 'react-router-dom'
import Loading from 'component/Loading/Loading'
import { get } from 'lodash'

import useStyles from 'template/TemplateDefault/TemplateDefaultStyles'
import { themeValues } from 'app/themes/themeValues'

const TemplateDefault: FC<RouteProps> = props => {
	const { t } = useTranslation()
	const classes = useStyles()
	const { loading, sendRequest } = useResetDb()

	const [
		,
		setNextLastVisitedUrlParams,
	] = useLocalStorage<AvailableRouteParams | null>(
		LAST_VISITED_URL_PARAMS,
		null
	)
	return (
		<PrivateRoute
			{...props}
			render={({ match: { params } }): ReactNode => {
				setNextLastVisitedUrlParams(params)
				const { projectUuid, lotUuid, sectionId, subSectionId } = params
				const sectionItem = getSectionItemById(sectionId, subSectionId)

				if (projectUuid && lotUuid) {
					if (!subSectionId) {
						const sectionObject = getSectionItemById(sectionId)
						if (sectionId && sectionObject) {
							const firstChildKey = get(
								sectionObject,
								['children', 0, 'key'],
								''
							)

							const generateUrl = (): string =>
								resolveRoute(routes.subSection, {
									projectUuid,
									lotUuid,
									sectionId,
									subSectionId: firstChildKey,
								})

							return <Redirect to={generateUrl()} />
						}
						const generateUrl = (): string =>
							resolveRoute(routes.subSection, {
								projectUuid,
								lotUuid,
								sectionId: 'general',
								subSectionId: 'boq',
							})

						return <Redirect to={generateUrl()} />
					}

					if (!sectionItem || !isSectionItemNavigatable(sectionItem)) {
						return <Redirect to={routes['404']} />
					}
				}

				const renderComponent = (): ReactElement | null => {
					const PageComponent = sectionItem && sectionItem.pageContent

					if (PageComponent) {
						return (
							<Validate
								{...sectionItem}
								fallback={() => (
									<PageContentError
										message={t(
											'common:validatorViolations.pageContentNoAccess'
										)}
									/>
								)}
							>
								<PageComponent key={lotUuid} />
							</Validate>
						)
					}

					return <ProjectPageContent />
				}

				return (
					<SideSheetProvider>
						<Loading
							loading={loading}
							loadingText={t('domain:header.profileMenu.resetDb.inProgress')}
						>
							<div className={classes.root}>
								<div className={classes.pageContentTopContainer}>
									<Header onDbReset={sendRequest} />
								</div>

								<div className={classes.pageContentBottomContainer}>
									<Drawer />

									<Paper
										elevation={themeValues().boxShadows.elevation}
										className={classes.pageContent}
									>
										<div className={classes.pageContent}>
											{renderComponent()}
										</div>
									</Paper>
								</div>
							</div>
						</Loading>
					</SideSheetProvider>
				)
			}}
		/>
	)
}

export default TemplateDefault
