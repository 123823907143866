import { Tab as MuiTab } from '@material-ui/core'
import { TabProps } from 'component/Tab/TabProps'
import useStyles from 'component/Tab/TabStyles'
import React, { FC, ReactElement } from 'react'
import clsx from 'clsx'

const Tab: FC<TabProps> = ({
	selected = false,
	classes: parentClasses = {},
	...rest
}): ReactElement | null => {
	const classes = useStyles({ selected })

	return (
		<MuiTab
			{...rest}
			component="div"
			disableRipple
			classes={{
				...parentClasses,
				root: clsx(classes.root, parentClasses.root),
			}}
		/>
	)
}

export default Tab
