import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
	root: {
		marginTop: theme.spacing(1),
	},
	unit: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	action: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: theme.spacing(2),
	},
}))
