import {
	getStatusFromLot,
	getStatusFromRevision,
	getFirstRevisionFromLot,
} from 'app/utils/apollo'
import { hasAnnotationValidStatus } from 'app/utils/tabulator'
import React, { ReactElement, FC, MouseEvent } from 'react'
import TabulatorButton from 'component/TabulatorButton/TabulatorButton'
import { useTranslation } from 'react-i18next'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'
import AnnotationFormatterProps from './AnnotationFormatterProps'
import useStyles from './AnnotationFormatterStyles'

const AnnotationFormatter: FC<AnnotationFormatterProps> = ({
	cell,
	label,
	onClick,
	onDeleteClick,
	lot = null,
	onClickDetails,
	role,
}): ReactElement | null => {
	const { t } = useTranslation()
	const classes = useStyles()
	const lotStatus = getStatusFromLot(lot)
	const revision = getFirstRevisionFromLot(lot)
	const revisionStatus = getStatusFromRevision(revision)

	const isValidAnnotation = hasAnnotationValidStatus(lotStatus, revisionStatus)

	const renderButton = (
		buttonLabel: string,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onButtonClick?: (e: MouseEvent, cell: any) => void,
		className?: string
	): ReactElement | null => (
		<TabulatorButton
			onClick={
				onButtonClick ? (e: MouseEvent) => onButtonClick(e, cell) : undefined
			}
			disabled={!onButtonClick}
			className={className}
		>
			{buttonLabel}
		</TabulatorButton>
	)

	const renderSideSheetButtons = (): ReactElement | null => {
		if (ROLES_PROJECT.LEAD === role) {
			return renderButton(
				t('domain:award.annotations.annotationDetails.button.label'),
				onClickDetails
			)
		}

		if (isValidAnnotation) {
			return renderButton(label, onClick)
		}

		if (onClickDetails) {
			return renderButton(
				t('domain:award.annotations.annotationDetails.button.label'),
				onClickDetails
			)
		}
		return renderButton(label)
	}

	const renderDeleteButton = (): ReactElement | null => {
		if (onDeleteClick && isValidAnnotation) {
			return renderButton(
				t('domain:award.annotations.deleteAnnotation.button.label'),
				onDeleteClick,
				classes.button
			)
		}
		return null
	}
	return (
		<>
			{renderSideSheetButtons()}
			{renderDeleteButton()}
		</>
	)
}
export default AnnotationFormatter
