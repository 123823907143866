import { ById } from 'app/types/ById'
import { POSITION_OPTIONAL_TYPES } from 'app/types/POSITION_OPTIONAL_TYPES'
import { POSITION_SURCHARGE_TYPES } from 'app/types/POSITION_SURCHARGE_TYPES'
import { POSITION_TYPES } from 'app/types/POSITION_TYPES'

export const BOQ_SIDESHEET_ATTRIBUTES_RENDERED = [
	'type',
	'shortText.text',
	'longText.text',
	'subQuantities',
]

export const BOQ_UP_FORMAT_API = '0.[000]'

export const BOQ_UP_FORMAT_INPUT = '0[.][000]'

export const BOQ_UP_FORMAT_CELL = '0.000'

export const BOQ_QTY_FORMAT_CELL = '0.00'

export const MAP_POSITION_TYPE_TO_SHORTHAND: ById<string> = {
	[POSITION_TYPES.WAGE]: 'S',
	[POSITION_TYPES.BASE]: 'G',
	[POSITION_TYPES.SURCHARGE]: 'Z',
	[POSITION_TYPES.BLANKET]: 'P',
	[POSITION_SURCHARGE_TYPES.SURCHARGE_A]: 'Z',
	[POSITION_SURCHARGE_TYPES.SURCHARGE_P]: 'Z',
	[POSITION_SURCHARGE_TYPES.SURCHARGE_Z]: 'Z',
	[POSITION_TYPES.NORMAL]: 'N',
	[POSITION_TYPES.REMARK]: 'H',
	[POSITION_TYPES.ALTERNATIVE]: 'A',
	[POSITION_TYPES.FULFILLMENT]: 'AB',
	[POSITION_TYPES.BLOCK]: 'B',
	[POSITION_OPTIONAL_TYPES.OPTIONAL_E]: 'E',
	[POSITION_OPTIONAL_TYPES.OPTIONAL_M]: 'M',
	[POSITION_TYPES.QTY_DETERM]: '',
	[POSITION_TYPES.SUGGESTION]: '',
}

export const SUFFIX_SURCHARGED = '+'
