import { FORM_PROJECT_UPDATE_PROJECTNAME_LENGTH_MIN } from 'app/constants'
import useForm from 'app/hooks/useForm'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import useCustomSnackbar from 'app/services/snackbar/useCustomSnackbar'
import { UrlParams } from 'app/types/UrlParams'
import { getProjectFromResponse } from 'app/utils/apollo'
import Loading from 'component/Loading/Loading'
import PrimaryButton from 'component/PrimaryButton/PrimaryButton'
import ValidatorForm from 'component/ValidatorForm/ValidatorForm'
import mutations from 'domain/project/apollo/mutations'
import queries from 'domain/project/apollo/queries'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TextValidator } from 'react-material-ui-form-validator'
import { useParams } from 'react-router-dom'

import UpdateProjectFormStyles from 'domain/project/components/body/update/UpdateProjectForm/UpdateProjectFormStyles'

const FORM_KEY_PROJECT_NAME = 'projectName'

const UpdateProjectForm: FC = () => {
	const classes = UpdateProjectFormStyles()

	const { t } = useTranslation()
	const { projectUuid } = useParams<UrlParams>()
	const { sendSuccessMessage } = useCustomSnackbar()
	const { formData, handleInputChange, createFormDataFromObject } = useForm()

	const [updateProject, { loading }] = useGraphQLMutation(
		mutations.updateProject,
		{
			onCompleted: () => sendSuccessMessage(t('domain:project.update.success')),
		}
	)
	const [
		fetchProjectData,
		{ data, loading: fetchLoading },
	] = useGraphQLLazyQuery(queries.ProjectWithUpdateInformation)

	const project = getProjectFromResponse(data)
	const projectName = (project && project.name) || ''

	useEffect(() => {
		createFormDataFromObject({
			projectName,
		})
	}, [project])

	useEffect(() => {
		if (projectUuid) {
			fetchProjectData({
				variables: {
					projectUuid,
				},
			})
		}
	}, [projectUuid])

	const onSubmit = (): void => {
		updateProject({
			variables: {
				projectUuid,
				name: formData.projectName,
			},
		})
	}

	return (
		<Loading loading={fetchLoading}>
			<ValidatorForm onSubmit={onSubmit}>
				<TextValidator
					validators={[
						'required',
						`minStringLength:${FORM_PROJECT_UPDATE_PROJECTNAME_LENGTH_MIN}`,
					]}
					errorMessages={[
						t(
							'domain:project.update.form.projectName.validatorViolations.required'
						),
						t(
							'domain:project.update.form.projectName.validatorViolations.minStringLength',
							{
								minStringLength: FORM_PROJECT_UPDATE_PROJECTNAME_LENGTH_MIN,
							}
						),
					]}
					label={t('domain:project.update.form.projectName.label')}
					name={FORM_KEY_PROJECT_NAME}
					value={formData[FORM_KEY_PROJECT_NAME]}
					fullWidth
					onChange={handleInputChange}
				/>
				<PrimaryButton
					classes={{
						root: classes.buttonRoot,
					}}
					type="submit"
					disabled={projectName === formData[FORM_KEY_PROJECT_NAME]}
					loading={loading}
				>
					{t('domain:project.update.form.submit')}
				</PrimaryButton>
			</ValidatorForm>
		</Loading>
	)
}

export default UpdateProjectForm
