import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { ConfirmationDialogProps } from 'component/ConfirmationDialog/ConfirmationDialogProps'
import Button from 'component/Button/Button'
import SecondaryButton from 'component/SecondaryButton/SecondaryButton'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
	contentText,
	title,
	open,
	onAccept,
	onAcceptLabel,
	onDecline,
	onDeclineLabel,
	children,
	loading,
}) => {
	const { t } = useTranslation()

	return (
		<Dialog open={open} onClose={onDecline}>
			{title && <DialogTitle>{title}</DialogTitle>}
			{contentText && (
				<DialogContent>
					<DialogContentText>{contentText}</DialogContentText>
				</DialogContent>
			)}
			{children && <DialogContent>{children}</DialogContent>}
			<DialogActions>
				{onDecline && (
					<Button onClick={onDecline} color="primary">
						{onDeclineLabel || t('common:dialog.buttons.abort')}
					</Button>
				)}
				{onAccept && (
					<SecondaryButton onClick={onAccept} loading={loading}>
						{onAcceptLabel || t('common:dialog.buttons.confirm')}
					</SecondaryButton>
				)}
			</DialogActions>
		</Dialog>
	)
}

export default ConfirmationDialog
