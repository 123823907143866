import { BOQ_UP_FORMAT_API } from 'app/config/boq'
import numeral from 'numeral'

numeral.register('locale', 'de', {
	delimiters: {
		thousands: '',
		decimal: ',',
	},
	abbreviations: {
		thousand: 'k',
		million: 'm',
		billion: 'b',
		trillion: 't',
	},
	ordinal(number) {
		return number.toString()
	},
	currency: {
		symbol: '€',
	},
})

numeral.locale('de')

export const convertToCurrentLanguage = (value: string): Numeral => {
	const currentLocale = numeral.locale()

	numeral.locale('en')
	const tmpValue = numeral(value)

	numeral.locale(currentLocale)
	return tmpValue
}

export const convertToDataFormat = (value: string): string | null => {
	const currentLocale = numeral.locale()

	const dataInCurrentFormat = numeral(value)
	numeral.locale('en')
	const tmpValue = dataInCurrentFormat

	if (!tmpValue.value()) {
		numeral.locale(currentLocale)
		return null
	}

	const tmpValueResult = tmpValue.format(BOQ_UP_FORMAT_API)
	numeral.locale(currentLocale)

	return tmpValueResult
}

export default numeral
