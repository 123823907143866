import Chip from '@material-ui/core/Chip/Chip'
import InputBase from '@material-ui/core/InputBase'
import AddIcon from '@material-ui/icons/Add'
import useAutocomplete from '@material-ui/lab/useAutocomplete'
import { KEY_ESC } from 'app/constants'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import useDebounce from 'app/hooks/useDebounce'
import { getTagsFromContacts } from 'app/utils/apollo'
import { filterArrayByArray } from 'app/utils/array'
import clsx from 'clsx'
import { InputTagProps } from 'component/InputTag/InputTagProps'
import Loading from 'component/Loading/Loading'
import queries from 'domain/tender/bidders/apollo/queries'
import { ContactTag } from 'domain/tender/bidders/types/ContactTag'
import { isString } from 'lodash'
import React, { FC, ReactElement, useEffect, useState } from 'react'

import useStyles from 'component/InputTag/InputTagStyles'

const InputTag: FC<InputTagProps> = ({
	onAccept,
	loading,
	filteredTags,
	classes: parentClasses,
}) => {
	const classes = useStyles()

	const [searchTerm, setSearchTerm] = useState('')
	const debouncedSearchTerm = useDebounce(searchTerm, 300)
	const [open, setOpen] = useState(false)
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [inputRef, setInputRef] = useState<any | null>(null)
	const [active, setActive] = useState(false)
	const [fetchTags, { data }] = useGraphQLLazyQuery(queries.TagsByFilter)
	const tags = getTagsFromContacts(data) || []

	const {
		focused,
		getRootProps,
		getInputProps,
		getListboxProps,
		getOptionProps,
		groupedOptions,
	} = useAutocomplete({
		open,
		inputValue: searchTerm,
		onInputChange: (event, value) => {
			if (event) {
				setSearchTerm(value)
			}
		},
		id: 'contactTags',
		options: filterArrayByArray<ContactTag>(tags, filteredTags),
		filterSelectedOptions: true,
		multiple: false,
		freeSolo: true,
		blurOnSelect: true,
		onChange: (event, selectedValue: string | ContactTag | null) => {
			if (selectedValue) {
				if (isString(selectedValue)) {
					onAccept(selectedValue)
				} else {
					onAccept(selectedValue.label)
				}

				setSearchTerm('')
			}
		},
	})

	useEffect(() => {
		fetchTags({
			variables: {
				pattern: searchTerm || '',
			},
		})
	}, [debouncedSearchTerm])

	useEffect(() => {
		if (inputRef) {
			inputRef.focus()
			inputRef.select()
		}
	}, [inputRef])

	useEffect(() => {
		if (!focused) {
			setActive(false)
		}
	}, [focused])

	useEffect(() => {
		setOpen(isString(searchTerm) && searchTerm.length > 0)
	}, [searchTerm])

	const renderChipLabel = (): ReactElement => {
		if (active) {
			return (
				<Loading loading={!!loading}>
					<div>
						<div {...getRootProps()}>
							<InputBase
								{...getInputProps()}
								onKeyDown={event => {
									if (event.keyCode === KEY_ESC) {
										setActive(false)
									}
								}}
								inputRef={ref => {
									setInputRef(ref)
								}}
							/>
						</div>
						{groupedOptions.length > 0 ? (
							<div
								className={clsx(
									'MuiPaper-root MuiPaper-elevation1 MuiAutocomplete-paper MuiPaper-rounded',
									classes.listWrapper
								)}
							>
								<ul className="MuiAutocomplete-listbox" {...getListboxProps()}>
									{groupedOptions.map((option, index) => (
										<li
											className="MuiAutocomplete-option"
											{...getOptionProps({ option, index })}
										>
											{isString(option) ? option : option.label}
										</li>
									))}
								</ul>
							</div>
						) : null}
					</div>
				</Loading>
			)
		}

		return <AddIcon />
	}

	return (
		<Chip
			className={clsx(classes.root, parentClasses.root)}
			label={renderChipLabel()}
			onClick={() => setActive(true)}
		/>
	)
}

export default InputTag
