/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography } from '@material-ui/core'
import { useSideSheet } from 'app/hooks'
import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import { useCustomSnackbar } from 'app/services/snackbar'
import {
	addSupTag,
	getPathForIdFromCell,
	getRowFromCell,
	isLineItem,
} from 'app/services/tabulator'
import {
	DataRevision,
	UpdateLineItemData,
	UpdateLineItemVars,
	BoqNode,
} from 'app/types'
import { UrlParams } from 'app/types/UrlParams'
import mutations from 'domain/general/boq/apollo/mutations'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { get } from 'lodash'

interface UseEvents {
	onClickCellLabel: (e: any, cell: any) => void
	handleUpCellEdited: (e: any, cell: any) => void
}

export default (revision: DataRevision | null): UseEvents => {
	const { t } = useTranslation()
	const { openSideSheet } = useSideSheet()
	const { sendSuccessMessage } = useCustomSnackbar()
	const { projectUuid, lotUuid } = useParams<UrlParams>()

	const [updateLineItem] = useGraphQLMutation<
		UpdateLineItemData,
		UpdateLineItemVars
	>(mutations.updateLineItem, {
		onCompleted: () =>
			sendSuccessMessage(t('domain:award.updateLineItem.success')),
	})

	const onClickCellLabel = (e: any, cell: any): void => {
		const data = getRowFromCell(cell).getData() as BoqNode

		if (isLineItem(cell)) {
			openSideSheet({
				title: data.label,
				content: (
					<Typography /* eslint-disable-next-line react/no-danger */
						dangerouslySetInnerHTML={{
							__html: addSupTag(get(data, 'longText.text', '')),
						}}
					/>
				),
			})
		}
	}

	const handleUpCellEdited = (cell: any): void => {
		if (revision && projectUuid && lotUuid) {
			updateLineItem({
				variables: {
					projectUuid,
					lotUuid,
					path: getPathForIdFromCell(cell),
					value: cell.getValue(),
					revisionUuid: revision.uuid,
				},
			})
		}
	}

	return { onClickCellLabel, handleUpCellEdited }
}
