import { makeStyles, Theme } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'

export default makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		alignSelf: 'normal',
		alignItems: 'center',

		'& .MuiButtonBase-root': {
			padding: theme.spacing(0.25),
			marginLeft: theme.spacing(1),
		},
	},
	wrapper: {
		background: '#e3e3e3',
		display: 'flex',
		alignItems: 'center',
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	input: {
		...themeValues().components.Tab,
		color: theme.palette.grey.A700,
		minWidth: 200,
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	lotIcon: {
		...themeValues().sizes.LotTabIcon,
		color: theme.palette.grey.A700,
		marginRight: theme.spacing(1),
	},
	cancelIcon: {
		...themeValues().sizes.InputActionIcons,
		background: themeValues().colors.error,
		'& .MuiSvgIcon-root': {
			color: 'white',
		},

		'&:hover': {
			background: themeValues().colors.error,
		},
	},
	acceptIcon: {
		...themeValues().sizes.InputActionIcons,
		background: themeValues().colors.success,

		'& .MuiSvgIcon-root': {
			color: 'white',
		},

		'&:hover': {
			background: themeValues().colors.success,
		},
	},
}))
