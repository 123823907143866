import { gql } from 'apollo-boost'
import { createNestedQuery } from 'app/utils/apollo'
import { boqNodes } from 'domain/general/boq/apollo/fragments'

const updateLineItem = gql`
	mutation updateLineItem(
		$projectUuid: String!
		$lotUuid: String!
		$revisionUuid: String!
		$path: String!
		$value: String!
	) {
		project(uuid: $projectUuid) {
			lot(uuid: $lotUuid) {
				revision(uuid: $revisionUuid) {
					updateLineItem(path: $path, value: $value) {
						success
					}
				}
			}
		}
	}
`

const createTender = gql`
	mutation createTender(
		$projectUuid: String!
		$lotUuid: String!
		$revisionUuid: String!
	) {
		project(uuid: $projectUuid) {
			lot(uuid: $lotUuid) {
				revision(uuid: $revisionUuid) {
					createTender {
						uuid
						accesses
						status
              ${createNestedQuery(boqNodes, 5)}
					}
				}
			}
		}
	}
`

export default {
	updateLineItem,
	createTender,
}
