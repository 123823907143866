import Button from 'component/Button/Button'
import { SecondaryButtonProps } from 'component/SecondaryButton/SecondaryButtonProps'
import React, { FC } from 'react'

const SecondaryButton: FC<SecondaryButtonProps> = ({ children, ...rest }) => {
	return (
		<Button {...rest} color="secondary" variant="contained">
			{children}
		</Button>
	)
}

export default SecondaryButton
