import React, { FC } from 'react'
import useGraphQLQuery from 'app/services/apollo/hooks/useGraphQLQuery'
import { TextField, MenuItem } from '@material-ui/core'
import { get } from 'lodash'
import { Unit } from 'app/config/annotations'
import { useTranslation } from 'react-i18next'
import useStyles from './SelectUnitDropdownStyles'
import SelectUnitDropdownProps from './SelectUnitDropdownProps'
import queries from '../../apollo/queries'

const SelectUnitDropdown: FC<SelectUnitDropdownProps> = ({
	formData,
	name,
	handleInputChange,
}) => {
	const value = formData[name]

	const { data } = useGraphQLQuery(queries.Units)

	const classes = useStyles()

	const units = get(data, ['common', 'units'], null)

	const { t } = useTranslation()

	return (
		<TextField
			className={classes.root}
			select
			name={name}
			label={t('domain:award.annotations.sideSheet.form.unit.label')}
			value={value}
			onChange={handleInputChange}
		>
			{units &&
				units.map((unitItem: Unit) => (
					<MenuItem key={unitItem.code} value={unitItem.code}>
						{unitItem.label} ({unitItem.code})
					</MenuItem>
				))}
		</TextField>
	)
}

export default SelectUnitDropdown
