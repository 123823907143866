import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { UnitInfoItemProps } from 'domain/general/boq/components/sidesheet/attributeInfos/UnitInfoItem/UnitInfoItemProps'
import DefaultInfoItem from 'domain/general/boq/components/sidesheet/attributeInfos/DefaultInfoItem/DefaultInfoItem'

const UnitInfoItem: FC<UnitInfoItemProps> = ({
	unit,
	classes: parentClasses = {},
}) => {
	const { t } = useTranslation()

	return (
		<DefaultInfoItem
			headline={t(`domain:award.annotations.unit`)}
			value={unit.label}
			classes={parentClasses}
		/>
	)
}

export default UnitInfoItem
