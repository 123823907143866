import { EMAIL_SUPPORT } from 'app/constants'
import { getProjectFromResponse } from 'app/utils/apollo'
import Link from 'component/Link/Link'
import Notice from 'component/Notice/Notice'
import PageContentBody from 'component/PageContentBody/PageContentBody'
import React, { FC, useEffect, ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import queries from 'domain/award/comparison/apollo/queries'

import { PageContent } from 'component'

import AwardPageContentBody from 'domain/award/comparison/components/base/ComparisonPageContentBody/ComparisonPageContentBody'
import ComparisonPageContentHeader from 'domain/award/comparison/components/base/ComparisonPageContentHeader/ComparisonPageContentHeader'
import { UrlParams } from 'app/types/UrlParams'
import Loading from 'component/Loading/Loading'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'

const ComparisonPageContent: FC = () => {
	const { t } = useTranslation()
	const { projectUuid, lotUuid, sectionId } = useParams<UrlParams>()
	const [fetchData, { loading, data, error }] = useGraphQLLazyQuery(
		queries.ProjectWithAwardInformation,
		{
			fetchPolicy: 'cache-and-network',
		}
	)

	useEffect(() => {
		if (projectUuid && lotUuid && sectionId) {
			fetchData({
				variables: {
					projectUuid,
					lotUuid,
				},
			})
		}
	}, [projectUuid, lotUuid, sectionId])

	const project = getProjectFromResponse(data)

	const getBodyComponent = (): ReactElement => {
		if (error) {
			return (
				<PageContentBody
					notice={
						<Notice
							message={
								<Trans
									defaults="domain:lot.validatorViolations.brokenUploadFile.message"
									values={{
										linkTitle: t(
											`domain:lot.validatorViolations.brokenUploadFile.linkTitle`
										),
									}}
									components={[
										<Link color="white" to={EMAIL_SUPPORT}>
											placeholder
										</Link>,
									]}
								/>
							}
							type="error"
						/>
					}
				/>
			)
		}

		return <AwardPageContentBody project={project} />
	}

	return (
		<Loading loading={loading}>
			<PageContent
				key={lotUuid}
				header={<ComparisonPageContentHeader />}
				body={getBodyComponent()}
			/>
		</Loading>
	)
}

export default ComparisonPageContent
