/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	LazyQueryHookOptions,
	QueryTuple,
	useLazyQuery,
} from '@apollo/react-hooks'
import { DocumentNode } from 'graphql'
import { OperationVariables } from '@apollo/react-common'
import useApolloErrorHandling from 'app/hooks/useApolloErrorHandling'
import { ApolloError } from 'apollo-client'

export interface UseLazyQueryOptions<TVariables> extends LazyQueryHookOptions {
	onError?: (error: ApolloError) => void
	variables?: TVariables
}

export default <TData = any, TVariables = OperationVariables>(
	query: DocumentNode,
	apolloOptions?: UseLazyQueryOptions<TVariables>
): QueryTuple<TData, TVariables> => {
	const [onErrorDefault] = useApolloErrorHandling()
	const { onError, ...rest } = apolloOptions || {}

	return useLazyQuery<TData, TVariables>(query, {
		...rest,
		onError: (error: ApolloError) => {
			onErrorDefault(error)
			if (onError) {
				onError(error)
			}
		},
	})
}
