import React, { ReactElement, FC } from 'react'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'
import { TextLinkProps } from './TextLinkProps'

import useStyles from './TextLinkStyles'

const TextLink: FC<TextLinkProps> = ({
	children,
	onClick,
	className,
}): ReactElement => {
	const classes = useStyles()

	return (
		<Typography
			variant="button"
			onClick={onClick}
			className={clsx(className, classes.root)}
		>
			{children}
		</Typography>
	)
}

export default TextLink
