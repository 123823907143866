import { Typography } from '@material-ui/core'
import { getGraphqlErrorCodeFromErrorLinkResponse } from 'app/utils/apollo'
import mutations from 'domain/checkout/apollo/mutations'
import CheckoutCard from 'domain/checkout/components/CheckoutCard/CheckoutCard'
import { CheckoutStepProps } from 'domain/checkout/interfaces/CheckoutStepProps'
import React, { FC, ReactElement, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TextValidator } from 'react-material-ui-form-validator'
import { get } from 'lodash'
import { convertApiErrorToErrorMessage } from 'app/errors/codes'
import {
	EMAIL_ALREADY_REGISTERED_CODE,
	CHECKOUTDATA_KEYS,
} from 'app/config/checkout'

import { ApolloError } from 'apollo-boost'
import TextLink from 'component/TextLink/TextLink'
import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'

const CardEmailEnter: FC<CheckoutStepProps> = ({
	checkoutData,
	handleInputChange,
	goToNextStep,
	createFormDataFromObject,
	classes = {},
	stepId,
}) => {
	const email = get(checkoutData, CHECKOUTDATA_KEYS.EMAIL, '')

	const [error, setError] = useState<ApolloError | undefined>()

	const { t } = useTranslation()

	const onCompleted = (): void => {
		if (createFormDataFromObject) {
			createFormDataFromObject({
				...checkoutData,
				[CHECKOUTDATA_KEYS.STEP_EMAIL_ENTER_COMPLETED]: true,
			})
		}
	}

	const [createAccountToken] = useGraphQLMutation(
		mutations.createAccountToken,
		{
			onCompleted,
			onError: setError,
		}
	)

	const errorCode = getGraphqlErrorCodeFromErrorLinkResponse(error)

	const isEmailAlreadyRegistered = errorCode === EMAIL_ALREADY_REGISTERED_CODE

	const handleClick = (): void => {
		createAccountToken({ variables: { email } })
	}

	const createAnotherActionOnError = (): ReactElement => {
		return (
			<TextLink onClick={handleClick}>
				{t('domain:checkout.createBlankAccount.createTokenLink.linkTitle')}
			</TextLink>
		)
	}
	const [createBlankAccount, { loading }] = useGraphQLMutation(
		mutations.createBlankAccount,
		{
			onCompleted,
			onError: setError,
		}
	)

	useEffect(() => {
		const isStepCompleted = get(
			checkoutData,
			CHECKOUTDATA_KEYS.STEP_EMAIL_ENTER_COMPLETED,
			false
		)
		if (isStepCompleted && goToNextStep) {
			goToNextStep()
		}
	}, [checkoutData])

	const onSubmit = (): void => {
		createBlankAccount({ variables: { email } })
	}

	return (
		<CheckoutCard
			headline={t('domain:checkout.createBlankAccount.card.title')}
			onSubmit={onSubmit}
			stepId={stepId}
			classes={classes}
			disabled={!email}
			loading={loading}
			anotherAction={isEmailAlreadyRegistered && createAnotherActionOnError}
		>
			<Typography paragraph>
				{t('domain:checkout.createBlankAccount.card.description')}
			</Typography>
			<TextValidator
				error={!!error}
				helperText={error ? convertApiErrorToErrorMessage(error) : undefined}
				placeholder={t(
					'domain:checkout.createBlankAccount.form.email.placeholder'
				)}
				validators={['required', 'isEmail']}
				errorMessages={[
					t(
						'domain:checkout.createBlankAccount.form.email.validatorViolations.required'
					),
					t(
						'domain:checkout.createBlankAccount.form.email.validatorViolations.isEmail'
					),
				]}
				name="email"
				value={email}
				onChange={handleInputChange}
				fullWidth
			/>
		</CheckoutCard>
	)
}

export default CardEmailEnter
