import { makeStyles } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'

export default makeStyles({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: '100vh',
		backgroundImage: `URL(${themeValues().images.network}), ${
			themeValues().gradients.background
		}`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'left bottom',
	},
})
