export enum POSITION_TYPES {
	NORMAL = 'normal',
	OPTIONAL = 'optional',
	BASE = 'base',
	ALTERNATIVE = 'alternative',
	REMARK = 'remark',
	SURCHARGE = 'surcharge',
	WAGE = 'wage',
	BLANKET = 'blanket',
	FULFILLMENT = 'fulfillment',
	BLOCK = 'block',
	QTY_DETERM = 'qtyDeterm',
	SUGGESTION = 'suggestion',
	CATEGORY = 'category',
}
