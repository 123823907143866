import { ApolloProvider } from '@apollo/react-hooks'
import PingProvider from 'app/services/tracking/PingProvider'
import CssBaseline from '@material-ui/core/CssBaseline'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import ThemeProvider from '@material-ui/styles/ThemeProvider'
import AppRouter from 'app/routing/AppRouter'
import ApolloClient from 'app/services/apollo/Apollo'
import theme from 'app/themes/baseMuiTheme'
import moment from 'moment'
import { SnackbarProvider, WithSnackbarProps } from 'notistack'
import React from 'react'
import ReactDOM from 'react-dom'
import 'react-tabulator/lib/css/tabulator.min.css'
import 'react-tabulator/lib/styles.css'
import 'app/themes/style.scss'
import 'moment/locale/de'
import './app/i18n/configuration'
import * as serviceWorker from './serviceWorker'

require('typeface-open-sans')

moment.locale('de')

const notistackRef = React.createRef<WithSnackbarProps>()
const onClickDismiss = (key: string | number | undefined) => () => {
	if (notistackRef.current) {
		notistackRef.current.closeSnackbar(key)
	}
}

ReactDOM.render(
	<ApolloProvider client={ApolloClient}>
		<PingProvider>
			<ThemeProvider theme={theme}>
				<SnackbarProvider
					ref={notistackRef}
					maxSnack={3}
					action={key => (
						<IconButton
							key="close"
							aria-label="close"
							style={{ color: 'white' }}
							onClick={onClickDismiss(key)}
						>
							<CloseIcon />
						</IconButton>
					)}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
				>
					<CssBaseline />
					<AppRouter />
				</SnackbarProvider>
			</ThemeProvider>
		</PingProvider>
	</ApolloProvider>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
