import {
	getStatusFromLot,
	getLotFromProject,
	getFirstRevisionFromLot,
	getRoleFromProject,
	isProjectBidder,
} from 'app/utils/apollo'
import React, { FC } from 'react'
import { useParams } from 'react-router-dom'

import PageContentBody from 'component/PageContentBody/PageContentBody'
import TenderNotice from 'domain/general/boq/components/body/status/TenderNotice/TenderNotice'
import AwardTable from 'domain/award/comparison/components/body/AwardTable/AwardTable'

import { useSideSheet } from 'app/hooks'
import { useTranslation } from 'react-i18next'
import { LOT_STATUS } from 'domain/lot/interfaces/LOT_STATUS'
import { UrlParams } from 'app/types/UrlParams'
import { ComparisonPageContentBodyProps } from 'domain/award/comparison/components/base/ComparisonPageContentBody/ComparisonPageContentBodyProps'

import useStyles from 'domain/award/comparison/components/base/ComparisonPageContentBody/ComparisonPageContentBodyStyles'
import CloseTenderingNotice from 'domain/award/comparison/components/body/status/CloseTenderingNotice/CloseTenderingNotice'
import PageContentHeading1 from 'component/PageContentHeading1/PageContentHeading1'
import PageContentParagraph from 'component/PageContentParagraph/PageContentParagraph'

const ComparisonPageContentBody: FC<ComparisonPageContentBodyProps> = ({
	project,
}) => {
	const { lotUuid } = useParams<UrlParams>()
	const { t } = useTranslation()
	const { open } = useSideSheet()

	const lot = getLotFromProject(project)
	const revision = getFirstRevisionFromLot(lot)
	const role = getRoleFromProject(project)
	const lotStatus = getStatusFromLot(lot)
	const classes = useStyles(open)

	const notice = <TenderNotice />

	if (!revision) {
		return (
			<PageContentBody>
				<PageContentParagraph>
					{t(`common:validatorViolations.missingRevision`)}
				</PageContentParagraph>
			</PageContentBody>
		)
	}

	return (
		<PageContentBody notice={notice} key={lotUuid}>
			<AwardTable
				className={classes.table}
				revision={revision}
				isUpVisible={isProjectBidder(role)}
				isAwarded={lotStatus === LOT_STATUS.AWARDED}
			/>

			{lotStatus === LOT_STATUS.TENDERING && (
				<>
					<PageContentHeading1>
						{t(`domain:award.notAllowed.linkTitle`)}
					</PageContentHeading1>
					<CloseTenderingNotice />
				</>
			)}
		</PageContentBody>
	)
}

export default ComparisonPageContentBody
