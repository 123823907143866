import { Theme } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles((theme: Theme) => ({
	header: {
		alignSelf: 'start',
	},
	card: {
		width: '100%',
		maxWidth: 340,
		height: '100%',
		maxHeight: 286,
	},
	toolbar: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	buttonFormGroup: {
		marginTop: theme.spacing(4),
	},
	cardContent: {
		paddingTop: 0,
	},
}))
