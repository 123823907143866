import { makeStyles, Theme } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'

export default makeStyles((theme: Theme) => ({
	root: (isSideSheetOpen: boolean) => ({
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		display: 'flex',
		overflow: isSideSheetOpen ? 'visible' : 'hidden',
		minHeight: `calc(100vh - ${themeValues().sizes.TabHeader.height}px)`,
	}),
	sideSheetContainer: (isSideSheetOpen: boolean) => ({
		width: isSideSheetOpen ? themeValues().sizes.SideSheet.width : 0,
		position: 'relative',
	}),
	innerContainer: (isSideSheetOpen: boolean) => ({
		paddingBottom: theme.spacing(2),
		transition: 'width .2s',
		width: isSideSheetOpen
			? `calc(100% - ${themeValues().sizes.SideSheet.width}px)`
			: '100%',
	}),
}))
