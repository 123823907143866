import { makeStyles, Theme } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'

export default makeStyles((theme: Theme) => ({
	card: {
		borderRadius: 0,
		position: 'sticky',
		top: 0,
		left: '100%',
		zIndex: themeValues().zindex.sidesheet,
		maxHeight: '100vh',
		height: `calc(100% + ${theme.spacing(2)}px)`,
		marginRight: theme.spacing(-2),
		marginTop: theme.spacing(-2),
		marginLeft: theme.spacing(2),
		overflowY: 'auto',
	},
	cardHeader: {
		padding: theme.spacing(2),
		'& .MuiCardHeader-content': {
			display: 'flex',
			justifyContent: 'space-between',
			padding: theme.spacing(2),
		},
	},
	cardContent: {
		paddingTop: 0,
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	iconButton: {
		padding: 0,
		width: 34,
		height: 34,
	},
}))
