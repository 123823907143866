const routes = {
	home: '/',
	checkout: '/checkout',
	checkoutFirstStep: '/checkout/pricing-plan',
	checkoutSteps: '/checkout/:stepId',
	projects: '/projects',
	projectCreate: '/projects/create',
	project: '/projects/:projectUuid',
	lot: '/projects/:projectUuid/lots/:lotUuid/',
	section: '/projects/:projectUuid/lots/:lotUuid/:sectionId',
	subSection: '/projects/:projectUuid/lots/:lotUuid/:sectionId/:subSectionId',
	login: '/login',
	register: '/register/complete',
	'404': '/404',
}

export default routes
