import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
	table: {
		marginBottom: theme.spacing(4),
	},
	cellCondensed: {
		paddingTop: theme.spacing(0.5),
		paddingLeft: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	cellNoBorder: {
		border: 0,
	},
	cellBold: {
		fontWeight: 'bold',
	},
}))
