/* eslint-disable @typescript-eslint/no-explicit-any */

import { BOQ_UP_FORMAT_INPUT } from 'app/config/boq'
import { KEY_ENTER, KEY_ESC } from 'app/constants'
import i18next from 'app/i18n/configuration'
import numeral, {
	convertToDataFormat,
	convertToCurrentLanguage,
} from 'app/services/numeral/index'
import BaseEditor from 'app/services/tabulator/editors/base/BaseEditor'
import { isNumber, toNumber } from 'lodash'
import { Listeners } from '../../types/Listeners'

class UpBaseEditor extends BaseEditor {
	constructor(
		cell: any,
		onRendered: (fn: () => void) => void,
		success: () => void,
		cancel: () => void,
		editorParams: any
	) {
		super(cell, onRendered, success, cancel, editorParams)

		this.onPressEnter = this.onPressEnter.bind(this)
		this.onPressEscape = this.onPressEscape.bind(this)
		this.onChange = this.onChange.bind(this)
		this.onBlur = this.onBlur.bind(this)

		this.formatInputValue()
		this.focusCell()

		this.registerEventListener('keydown', this.onPressEnter)
		this.registerEventListener('keydown', this.onPressEscape)
		this.registerEventListener('change', this.onChange)
		this.registerEventListener('blur', this.onBlur)
	}

	isValidInputValue(): boolean {
		const currentLangKey = i18next.language
		const decimalDelimiter = numeral.localeData(currentLangKey).delimiters
			.decimal
		const valueInDataFormat = convertToDataFormat(this.input.value)

		if (!valueInDataFormat) {
			return false
		}

		if (!isNumber(toNumber(valueInDataFormat))) {
			return false
		}

		const numberSegments = this.input.value.split(decimalDelimiter)

		if (numberSegments.length > 1) {
			if (numberSegments[1].length > 3) {
				return false
			}
		}

		return true
	}

	getInputValue(): string {
		return this.input.value
	}

	getFormattedInputValue(): string {
		return convertToDataFormat(this.getInputValue()) || ''
	}

	public formatInputValue(): void {
		const value = this.cell.getValue()
		this.input.value = convertToCurrentLanguage(value).format(
			BOQ_UP_FORMAT_INPUT
		)
	}

	focusCell(): void {
		this.onRendered(() => {
			this.input.focus()
			this.input.select()
		})
	}

	onChange(): void {
		if (this.isValidInputValue()) {
			this.success(this.getFormattedInputValue())
		} else {
			// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
			// @ts-ignore
			this.editorParams.sendErrorMessage(
				'Ein ungültiger Wert wurde eingetragen.'
			)
			this.cancel()
		}
	}

	onBlur(): void {
		this.cancel()
	}

	registerEventListener(type: Listeners, callback: (e: Event) => void): void {
		this.input.addEventListener(type, callback)
	}

	onPressEnter(e: Event | KeyboardEvent): void {
		if (
			(e as KeyboardEvent).keyCode === KEY_ENTER ||
			(e as KeyboardEvent).key === KEY_ENTER.toString()
		) {
			this.cell.nav().down()
		}
	}

	onPressEscape(e: Event | KeyboardEvent): void {
		if (
			(e as KeyboardEvent).keyCode === KEY_ESC ||
			(e as KeyboardEvent).key === KEY_ESC.toString()
		) {
			this.cancel()
		}
	}
}

export default UpBaseEditor
