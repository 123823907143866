import PageContentHeader from 'component/PageContentHeader/PageContentHeader'
import { BoqPageContentHeaderProps } from 'domain/general/boq/components/base/BoqPageContentHeader/BoqPageContentHeaderProps'
import CreateTenderButton from 'domain/general/boq/components/header/tendering/CreateTenderButton/CreateTenderButton'
import DownloadX83 from 'domain/general/boq/components/header/download/DownloadX83/DownloadX83'
import UploadX84 from 'domain/general/boq/components/header/upload/UploadX84/UploadX84'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

const BoqPageContentHeader: FC<BoqPageContentHeaderProps> = () => {
	const { t } = useTranslation()

	const availableButtons = [
		<CreateTenderButton key="createTender">
			{t(`domain:boq.createTender.button.title`)}
		</CreateTenderButton>,
	]

	const iconButtons = [
		<DownloadX83 key="DownloadX83" />,
		<UploadX84 key="UploadX83" />,
	]

	return (
		<PageContentHeader buttons={availableButtons} iconButtons={iconButtons} />
	)
}

export default BoqPageContentHeader
