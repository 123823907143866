// import RenderErrorMessage from 'component/RenderErrorMessage/RenderErrorMessage'
import { CHECKOUTDATA_KEYS } from 'app/config/checkout'
import { PAYMENT_TYPES } from 'domain/checkout/enums/PAYMENT_TYPES'
import { CheckoutStepProps } from 'domain/checkout/interfaces/CheckoutStepProps'
import React, { forwardRef, useImperativeHandle, useEffect } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { useTranslation } from 'react-i18next'
import { TextValidator } from 'react-material-ui-form-validator'
import { themeValues } from 'app/themes/themeValues'
import { get } from 'lodash'
// import { StripeError } from '@stripe/stripe-js'

import useStyles from './FormCardPaymentStyles'

export interface FormCardPaymentProps
	extends Omit<CheckoutStepProps, 'stepId'> {
	checkourData?: {}
}

export default forwardRef((props, ref) => {
	const { t } = useTranslation()
	const classes = useStyles()
	// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
	// @ts-ignore
	const { checkoutData, handleInputChange, onLoadingChange, onSuccess } = props
	// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
	// @ts-ignore
	const { createFormDataFromObject } = props
	// @TODO check linter errors and move payment to previous props
	const { payment } = checkoutData
	const checkRadioValue = payment === 'newPayment' || payment === undefined

	const stripe = useStripe()
	const elememts = useElements()

	const firstName = get(checkoutData, CHECKOUTDATA_KEYS.FIRSTNAME, '')
	const lastName = get(checkoutData, CHECKOUTDATA_KEYS.LASTNAME, '')
	const fullName = firstName && lastName ? `${firstName} ${lastName}` : ''
	const cardName = get(checkoutData, 'cardName', fullName)

	useEffect(() => {
		createFormDataFromObject({
			...checkoutData,
			payment: undefined,
			termsAndConditions: false,
			cardName,
		})
	}, [])

	const onSubmit = (): void => {
		if (!stripe || !elememts) {
			return
		}

		const cardElement = elememts.getElement(CardElement)
		onLoadingChange(true)
		if (cardElement && checkRadioValue) {
			stripe
				.createPaymentMethod({
					type: PAYMENT_TYPES.CARD,
					card: cardElement,
					// eslint-disable-next-line @typescript-eslint/camelcase
					billing_details: {
						name: cardName,
					},
				})
				.then(res => {
					const { paymentMethod } = res
					if (paymentMethod && !res.error) {
						createFormDataFromObject({
							...checkoutData,
							payment: paymentMethod.id,
						})
						onSuccess()
					} else if (res.error) {
						onLoadingChange(false)
						// @TODO implement stripeError
					}
				})
		} else if (!checkRadioValue) {
			onLoadingChange(true)
			onSuccess()
		}
	}

	useImperativeHandle(ref, () => ({
		onSubmit,
	}))
	return (
		<>
			<TextValidator
				className={classes.rootInput}
				xs={12}
				name="cardName"
				placeholder={t(
					'domain:checkout.createSubscription.form.cardName.placeholder'
				)}
				validators={checkRadioValue ? ['required'] : []}
				value={cardName}
				onChange={handleInputChange}
				errorMessages={[
					t('domain:checkout.paymentEnter.form.validatorViolations.required'),
				]}
				fullWidth
				disabled={!checkRadioValue}
				checkoutdata={checkoutData}
			/>
			<CardElement
				options={{
					disabled: !checkRadioValue,
					style: {
						base: {
							fontSize: `${themeValues().spacing(4)}px`,
							'::placeholder': {
								color: themeValues().colors.PlaceholderCardPayment.color,
							},
						},
						invalid: {
							color: themeValues().colors.error,
						},
					},
				}}
			/>
		</>
	)
})
