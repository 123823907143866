import IconButton from '@material-ui/core/IconButton'
import GetAppIcon from '@material-ui/icons/GetApp'
import { formatSize } from 'app/utils/format'
import clsx from 'clsx'
import MediaCard from 'component/MediaCard/MediaCard'
import { AttachmentCardProps } from 'domain/general/attachments/components/body/attachments/AttachmentCard/AttachmentCardProps'

import useStyles from 'domain/general/attachments/components/body/attachments/AttachmentCard/AttachmentCardStyles'
import React, { FC, ReactElement } from 'react'

const AttachmentCard: FC<AttachmentCardProps> = ({
	onImageClick,
	onDownload,
	attachment,
	classes: parentClasses = {},
}) => {
	const classes = useStyles()
	const { uuid, name, author, previewUrl, size } = attachment

	const renderMetaInformations = (): ReactElement => {
		return (
			<>
				{size > 0 && formatSize(size)}
				{onDownload && (
					<IconButton onClick={onDownload}>
						<GetAppIcon />
					</IconButton>
				)}
			</>
		)
	}

	return (
		<MediaCard
			classes={{
				root: clsx(classes.root, parentClasses.root),
				media: clsx(classes.media, parentClasses.media),
				description: clsx(classes.description, parentClasses.description),
				footerActionsContainer: clsx(
					classes.footerActionsContainer,
					parentClasses.footerActionsContainer
				),
			}}
			hedlineSingleLine
			onClick={onImageClick ? () => onImageClick(uuid) : undefined}
			title={name}
			src={previewUrl}
			headline={name}
			subLine={`von ${author.name}`}
			description={renderMetaInformations()}
		/>
	)
}

export default AttachmentCard
