import useCustomSnackbar from 'app/services/snackbar/useCustomSnackbar'
import { UseDownload } from 'app/types/UseDownload'
import axios from 'axios'
import { saveAs } from 'file-saver'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default (): UseDownload => {
	const { t } = useTranslation()
	const { sendErrorMessage } = useCustomSnackbar()
	const [apiUrl, setApiUrl] = useState<string | null>(null)

	const initializeDownload = (url: string): void => {
		setApiUrl(url)
	}

	const download = (): void => {
		if (apiUrl) {
			axios
				.get(apiUrl)
				.then(res => {
					saveAs(new Blob([res.data]), 'Gaeb-Download.x83')
				})
				.catch(() =>
					sendErrorMessage(t('domain:boq.download.validatorViolation'))
				)
		}
	}

	return {
		initializeDownload,
		download,
	}
}
