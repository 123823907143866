import {
	getLotFromProject,
	getQuantityDeterminationFromLot,
	getProjectFromResponse,
} from 'app/utils/apollo'
import { resolveRoute } from 'app/utils/route'
import BreadCrumb from 'component/BreadCrumb/BreadCrumb'
import BreadCrumbs from 'component/BreadCrumbs/BreadCrumbs'
import React, { FC, ReactNode, ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom'
import { UrlParams } from 'app/types/UrlParams'
import useUrlSearchParams from 'app/hooks/useUrlSearchParams'
import { get } from 'lodash'
import queries from 'domain/billing/quantity/apollo/queries'
import routes from 'app/routing/routes/routesApp'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import useStyles from './QuantityDetailBreadCrumbStyle'

const QuantityDetailBreadCrumb: FC = () => {
	const classes = useStyles()
	const { t } = useTranslation()
	const { projectUuid, lotUuid, sectionId, subSectionId } = useParams<
		UrlParams
	>()
	const { push } = useHistory()

	const query = useUrlSearchParams()
	const queryUuid = query.get('qduuid')
	const [fetchData, { data }] = useGraphQLLazyQuery(
		queries.LotWithQuantityDetermination
	)

	const project = getProjectFromResponse(data)
	const lot = getLotFromProject(project)
	const quantityDetermination = getQuantityDeterminationFromLot(lot)
	const qdName = get(quantityDetermination, 'name', '')

	useEffect(() => {
		if (projectUuid && lotUuid && queryUuid) {
			fetchData({
				variables: {
					projectUuid,
					lotUuid,
					qdUuid: queryUuid,
				},
			})
		}
	}, [projectUuid, lotUuid, queryUuid])

	const onBreadCrumbClick = (): void => {
		return push(
			resolveRoute(routes.subSection, {
				projectUuid,
				lotUuid,
				sectionId,
				subSectionId,
			})
		)
	}

	const renderRoot = (): ReactElement => {
		return (
			<BreadCrumb
				label={t('domain:billing.breadcrumb.root.title')}
				onClick={onBreadCrumbClick}
			/>
		)
	}

	const renderBreadCrumbs = (): ReactNode | null => {
		return <BreadCrumb active label={qdName} />
	}

	return (
		<div className={classes.root}>
			<BreadCrumbs>
				{renderRoot()}
				{renderBreadCrumbs()}
			</BreadCrumbs>
		</div>
	)
}

export default QuantityDetailBreadCrumb
