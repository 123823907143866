import { gql } from 'apollo-boost'

const createAward = gql`
	mutation CreateAward(
		$projectUuid: String!
		$lotUuid: String!
		$bidderUuid: String!
	) {
		project(uuid: $projectUuid) {
			lot(uuid: $lotUuid) {
				uuid
				createAward(bidderUuid: $bidderUuid) {
					uuid
					status
					__typename
					dataRevisions {
						data {
							uuid
							bidders {
								uuid
								isAwarded
							}
						}
					}
				}
			}
		}
	}
`
export default {
	createAward,
}
