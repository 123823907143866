import { getProjectFromResponse, getLotsFromProject } from 'app/utils/apollo'
import React, { FC, useEffect } from 'react'
import PageContent from 'component/PageContent/PageContent'
import ProjectPageContentHeader from 'domain/project/components/base/ProjectPageContentHeader/ProjectPageContentHeader'
import ProjectPageContentBody from 'domain/project/components/base/ProjectPageContentBody/ProjectPageContentBody'
import { UrlParams } from 'app/types/UrlParams'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import queries from 'domain/lot/apollo/queries'
import { useParams } from 'react-router-dom'

const ProjectPageContent: FC = () => {
	const { projectUuid } = useParams<UrlParams>()

	const [fetchData, { data }] = useGraphQLLazyQuery(queries.ProjectWithLots)

	useEffect(() => {
		if (projectUuid) {
			fetchData({
				variables: {
					projectUuid,
				},
			})
		}
	}, [projectUuid])

	const project = getProjectFromResponse(data)
	const lots = getLotsFromProject(project)

	return (
		<PageContent
			ignoreNotAllowed
			header={<ProjectPageContentHeader lots={lots} />}
			body={<ProjectPageContentBody lots={lots} />}
		/>
	)
}

export default ProjectPageContent
