/* eslint-disable @typescript-eslint/no-explicit-any */
import { TABLE_FIELDS_BOQ } from 'app/constants'
import {
	toggleTree,
	MeterUnitFormatter,
	handleCellQDFormulaEditable,
	handleCellQDQtyEditable,
} from 'app/services/tabulator'
import LinFormatter from 'app/services/tabulator/formatters/lin/LinFormatter'
import { getStatusFromQuantityDetermination } from 'app/utils/apollo'
import { reactFormatter } from 'react-tabulator'
import { useTranslation } from 'react-i18next'
import React, { useMemo } from 'react'
import { QuantityDetermination } from 'app/config/quantityDetermination'
import QuantityDeterminationQtyFormatter from 'app/services/tabulator/formatters/QuantityDeterminationQtyFormatter'
import { useCustomSnackbar } from 'app/services/snackbar'
import QuantityDeterminationFormulaFormatter from 'app/services/tabulator/formatters/QuantityDeterminationFormulaFormatter'
import QuantityDeterminationNodesStatusFormatter from 'app/services/tabulator/formatters/QuantityDeterminationNodesStatusFormatter/QuantityDeterminationNodesStatusFormatter'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'
import QuantityDeterminationLeadEditFormatter from 'app/services/tabulator/formatters/QuantityDeterminationLeadEditFormatter/QuantityDeterminationLeadEditFormatter'
import TabulatorEditor from 'app/services/tabulator/TabulatorEditor'
import IconTypesFormatter from 'app/services/tabulator/formatters/type/IconTypesFormatter'
import QuantityDeterminationLabelFormatter from 'app/services/tabulator/formatters/QuantityDeterminationLabelFormatter'

export default (
	modifyRow: (cell: any) => void,
	handleClickAction: (cell: any, action: string) => void,
	quantityDetermination: QuantityDetermination | null,
	handleEdited: (cell: any) => void,
	role: ROLES_PROJECT | null
): any => {
	const { t } = useTranslation()
	const { sendErrorMessage } = useCustomSnackbar()

	const quantityDeterminationStatus = getStatusFromQuantityDetermination(
		quantityDetermination
	)

	const columnsData: any = [
		{
			field: TABLE_FIELDS_BOQ.LIN,
			title: t('common:tabulator.columns.lin'),
			width: 120,
			cellClick: toggleTree,
			formatter: LinFormatter,
		},
		{
			field: TABLE_FIELDS_BOQ.TYPE,
			width: 50,
			title: '',
			formatter: reactFormatter(<IconTypesFormatter />),
		},
		{
			field: TABLE_FIELDS_BOQ.LABEL,
			title: t('common:tabulator.columns.label'),
			widthGrow: 3,
			formatter: (cell: any) =>
				QuantityDeterminationLabelFormatter(
					role,
					cell,
					quantityDeterminationStatus
				),
			// regex to avoid start and finish strings with whitespaces
			validator: ['required', 'regex:^[a-zA-Z0-9_].*[a-zA-Z0-9_]$'],
		},
		{
			field: TABLE_FIELDS_BOQ.QU,
			title: t('common:tabulator.columns.qu'),
			formatter: MeterUnitFormatter,
		},
		{
			field: 'formula',
			title: t(`domain:billing.leadQuantityDeterminationTable.formula.title`),
			editor: TabulatorEditor,
			editorParams: { sendErrorMessage },
			cellEdited: handleEdited,
			editable: (cell: any) =>
				handleCellQDFormulaEditable(role, cell, quantityDeterminationStatus),
			formatter: (cell: any) =>
				QuantityDeterminationFormulaFormatter(
					role,
					cell,
					quantityDeterminationStatus
				),
		},
		{
			field: TABLE_FIELDS_BOQ.QTY,
			title: t('common:tabulator.columns.qty'),
			align: 'right',
			editorParams: { sendErrorMessage },
			editable: (cell: any) =>
				handleCellQDQtyEditable(role, cell, quantityDeterminationStatus),
			cellEdited: handleEdited,
			editor: TabulatorEditor,
			formatter: (cell: any) =>
				QuantityDeterminationQtyFormatter(
					role,
					cell,
					quantityDeterminationStatus
				),
		},
		{
			field: 'status',
			title: t(`domain:billing.leadQuantityDeterminationTable.status.title`),
			formatter: reactFormatter(<QuantityDeterminationNodesStatusFormatter />),
			minWidth: 130,
		},
		{
			field: 'edit',
			title: t(`domain:billing.leadQuantityDeterminationTable.edit.title`),
			formatter: reactFormatter(
				<QuantityDeterminationLeadEditFormatter
					onClickStatus={handleClickAction}
					onClickHandleSuggestion={modifyRow}
					quantityDetermination={quantityDetermination}
				/>
			),
			width: 250,
		},
	]

	return useMemo(() => columnsData, [modifyRow, quantityDetermination])
}
