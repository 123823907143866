import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme: Theme) => ({
	title: {
		marginBottom: theme.spacing(2),
	},
	table: {
		width: 'fit-content',
	},
	keyCell: {
		width: '200px',
		paddingLeft: 0,
		borderBottom: 'none',
	},
	valueCell: {
		borderBottom: 'none',
	},
}))
