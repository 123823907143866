import { gql } from 'apollo-boost'
import { createNestedQuery } from 'app/utils/apollo'
import { boqNodes } from 'domain/general/boq/apollo/fragments'

const ProjectWithAwardInformation = gql`
	query ProjectWithAwardInformation($projectUuid: String!, $lotUuid: String!) {
		project(uuid: $projectUuid) {
			uuid
			name
			role
            lot(uuid: $lotUuid) {
                uuid
                status
                dataRevisions {
                    data {
                        uuid
                        accesses
                        bidders {
                            uuid
                            name
                            isAwarded
                        }
                        version
                        ${createNestedQuery(boqNodes, 5)}
                    }
                }
            }
			__typename
		}
	}
`

export default {
	ProjectWithAwardInformation,
}
