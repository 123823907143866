/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, ReactElement } from 'react'
import TooltipIconButton from 'component/TooltipIconButton/TooltipIconButton'
import { BoqNode } from 'app/types'
import {
	SUFFIX_SURCHARGED,
	MAP_POSITION_TYPE_TO_SHORTHAND,
} from 'app/config/boq'
import { POSITION_TYPES } from 'app/types/POSITION_TYPES'
import { useTranslation } from 'react-i18next'
import IconTypesFormatterProps from './IconTypesFormatterProps'

const IconTypesFormatter: FC<IconTypesFormatterProps> = ({
	cell,
}): ReactElement | null => {
	const { t } = useTranslation()

	const generateSuffix = (): string => {
		const boqNode = cell.getData() as BoqNode

		if (boqNode.isSurcharged) {
			return SUFFIX_SURCHARGED
		}

		return ''
	}

	const getShorthandForTypeOrSubtype = (): string => {
		const boqNode = cell.getData() as BoqNode
		const { type, subType } = boqNode

		if (subType) {
			return MAP_POSITION_TYPE_TO_SHORTHAND[subType]
		}

		return MAP_POSITION_TYPE_TO_SHORTHAND[type]
	}

	const getFullNameForTypeOrSubtype = (): string => {
		const boqNode = cell.getData() as BoqNode
		const { type, subType } = boqNode

		if (subType) {
			return subType
		}

		return type
	}

	const formatCellValue = (): string | number => {
		const shortHand = getShorthandForTypeOrSubtype()
		return shortHand ? `${shortHand}${generateSuffix()}` : ''
	}

	const format = (): ReactElement | null => {
		const value = formatCellValue()
		const fullName = getFullNameForTypeOrSubtype()

		if (fullName !== POSITION_TYPES.CATEGORY) {
			return value ? (
				<TooltipIconButton title={t(`domain:boq.lineItem.types.${fullName}`)}>
					<div className="line-item-type-indicator">{value}</div>
				</TooltipIconButton>
			) : null
		}
		return null
	}

	return format()
}

export default IconTypesFormatter
