import { KeyboardDatePicker } from '@material-ui/pickers'
import { DATE_FORMAT_PICKER } from 'app/config/locales'
import { DatePickerProps } from 'component/DatePicker/DatePickerProps'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

const DatePicker: FC<DatePickerProps> = ({ value, onChange, disabled }) => {
	const { t } = useTranslation()
	return (
		<KeyboardDatePicker
			emptyLabel="__.__.____"
			disabled={disabled}
			value={value}
			onChange={onChange}
			format={DATE_FORMAT_PICKER}
			allowKeyboardControl={false}
			disableToolbar
			fullWidth
			invalidDateMessage={t(
				'domain:tender.createTenderingPeriod.validatorViolations.invalidData'
			)}
			disablePast
			minDateMessage={!disabled && ' '}
		/>
	)
}

export default DatePicker
