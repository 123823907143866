import { postTrackingEvent } from 'app/utils/tracking'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import TrackingEvents from 'app/services/datawarehouse/TrackingEvents'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const de = require('./de.json')

i18n.use(initReactI18next).init({
	resources: {
		de,
	},
	lng: 'de',
	saveMissing: true,
	missingKeyHandler: (ng, ns, key) => {
		return postTrackingEvent({
			code: TrackingEvents.TRANSLATION_NOT_FOUND.code,
			value: key,
			// eslint-disable-next-line @typescript-eslint/camelcase
			user_hash: null,
		})
	},
})

export default i18n
