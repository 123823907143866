import React, { FC } from 'react'
import { Typography } from '@material-ui/core'
import { PageContentParagraphProps } from './PageContentParagraphProps'

const PageContentParagraph: FC<PageContentParagraphProps> = ({
	children,
	className,
}) => {
	return (
		<Typography className={className} gutterBottom component="p">
			{children}
		</Typography>
	)
}

export default PageContentParagraph
