import { addClasses } from 'app/utils/tabulator'
import React, { FC, ReactElement } from 'react'
import { Contact } from 'app/types'
import Tag from 'component/Tag/Tag'
import { ContactTag } from 'domain/tender/bidders/types/ContactTag'

export interface ContactTagsFormatterProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	cell?: any
	onClick?: (tag: ContactTag) => void
}

const ContactTagsFormatter: FC<ContactTagsFormatterProps> = ({
	cell,
	onClick,
}): ReactElement => {
	const cellElement = cell.getElement()
	const row = cell.getRow()
	const rowElement = row.getElement()
	addClasses(rowElement, ['row-growable'])
	addClasses(cellElement, ['cell-tags'])

	const contactData = cell.getData() as Contact
	const tags = contactData.tags as []

	return (
		<>
			{tags.map((tag: ContactTag) => (
				<Tag
					onClick={onClick ? () => onClick(tag) : undefined}
					key={tag.uuid}
					label={tag.label}
				/>
			))}
		</>
	)
}

export default ContactTagsFormatter
