import NotAllowedRulesConfiguration from 'app/types/NotAllowedRulesConfiguration'
import { LOT_STATUS } from 'domain/lot/interfaces/LOT_STATUS'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'

export default {
	bidders: [
		{
			role: ROLES_PROJECT.LEAD,
			status: [
				LOT_STATUS.CREATED,
				LOT_STATUS.TENDERING,
				LOT_STATUS.AWARDING,
				LOT_STATUS.AWARDED,
				LOT_STATUS.CONFIRMED,
			],
		},
	],
	period: [
		{
			role: ROLES_PROJECT.LEAD,
			status: [
				LOT_STATUS.CREATED,
				LOT_STATUS.TENDERING,
				LOT_STATUS.AWARDING,
				LOT_STATUS.AWARDED,
				LOT_STATUS.CONFIRMED,
			],
		},
	],
	comparison: [
		{
			role: ROLES_PROJECT.LEAD,
			status: [
				LOT_STATUS.TENDERING,
				LOT_STATUS.AWARDING,
				LOT_STATUS.AWARDED,
				LOT_STATUS.CONFIRMED,
			],
		},
	],
	annotations: [
		{
			role: ROLES_PROJECT.LEAD,
			status: [
				LOT_STATUS.TENDERING,
				LOT_STATUS.AWARDING,
				LOT_STATUS.AWARDED,
				LOT_STATUS.CONFIRMED,
			],
		},
	],
	awarding: [
		{
			role: ROLES_PROJECT.LEAD,
			status: [LOT_STATUS.AWARDING, LOT_STATUS.AWARDED, LOT_STATUS.CONFIRMED],
		},
	],
	quantity: [
		{
			role: ROLES_PROJECT.LEAD,
			status: [LOT_STATUS.CONFIRMED],
		},
		{
			role: ROLES_PROJECT.BIDDER,
			status: [LOT_STATUS.AWARDED, LOT_STATUS.CONFIRMED],
		},
	],
} as NotAllowedRulesConfiguration
