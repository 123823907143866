import React, { FC } from 'react'
import TabPanelProps from './TabPanelProps'

const TabPanel: FC<TabPanelProps> = ({ children, value, index }) => {
	if (value === index) {
		return <>{children}</>
	}
	return null
}

export default TabPanel
