import React, { ReactElement, FC } from 'react'

import clsx from 'clsx'
import useStyles from './TabulatorButtonStyles'
import { TabulatorButtonProps } from './TabulatorButtonProps'

const TabulatorButton: FC<TabulatorButtonProps> = ({
	children,
	onClick,
	disabled,
	variant = 'primary',
	className,
}): ReactElement => {
	const classes = useStyles({ disabled })
	return (
		<button
			type="button"
			disabled={disabled}
			className={clsx(classes.root, classes[variant], className)}
			onClick={onClick}
		>
			<span>{children}</span>
		</button>
	)
}

export default TabulatorButton
