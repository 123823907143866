import { addSupTag } from 'app/utils/tabulator'
import { isLineItem } from '../Callbacks'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (cell: any): string => {
	const originalCellValue = cell.getValue()

	if (originalCellValue) {
		return isLineItem(cell) ? addSupTag(originalCellValue) : originalCellValue
	}

	return ''
}
