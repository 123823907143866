import makeStyles from '@material-ui/core/styles/makeStyles'
import { themeValues } from 'app/themes/themeValues'

export default makeStyles({
	root: {
		background: themeValues().palette.blue.dark,
		backgroundImage: `URL(${themeValues().images.network}), ${
			themeValues().gradients.background
		}`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'left bottom',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100vw',
		height: '100vh',
		flexWrap: 'wrap',
	},
	container: {},
	title: {
		color: 'white',
		fontSize: 80,
	},
	subTitle: {
		color: 'white',
		fontSize: 50,
	},
	content: {
		margin: '16px 0 16px 0',
		color: 'white',
	},
})
