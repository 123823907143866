import React, { FC, useEffect } from 'react'
import { useAuthentication, useEnvironmentVariables } from 'app/hooks'
import { ENVIRONMENTS } from 'app/constants'
import { MenuItem, ListItemText, ListItemIcon } from '@material-ui/core'
import CachedIcon from '@material-ui/icons/Cached'
import { useTranslation } from 'react-i18next'
import usePrevious from 'app/hooks/usePrevious'

const ProfileMenuRefreshToken: FC = () => {
	const { isAdmin, refreshStoredToken, refreshToken } = useAuthentication()
	const { REACT_APP_ENV } = useEnvironmentVariables()
	const { t } = useTranslation()

	const previousToken = usePrevious<string | null>(refreshToken)

	useEffect(() => {
		if (refreshToken && previousToken) {
			if (previousToken !== refreshToken) {
				window.location.reload()
			}
		}
	}, [refreshToken])

	if (!isAdmin || REACT_APP_ENV === ENVIRONMENTS.PROD) {
		return null
	}

	return (
		<MenuItem onClick={() => refreshStoredToken(refreshToken)}>
			<ListItemIcon>
				<CachedIcon />
			</ListItemIcon>
			<ListItemText
				primary={t('domain:header.profileMenu.refreshToken.title')}
			/>
		</MenuItem>
	)
}

export default ProfileMenuRefreshToken
