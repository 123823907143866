/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	calculateDeviationFromLowest,
	getNumberStringWithSign,
	addClasses,
	getBiddersFromCell,
} from 'app/utils/tabulator'
import React, { ReactElement } from 'react'
import { isNaN, toNumber } from 'lodash'
import { Chip } from '@material-ui/core'
import { renderToString } from 'react-dom/server'
import { Bidder } from 'app/types'

export default (cell: any, formatterParams: any): ReactElement | string => {
	let cellValue = cell.getValue()
	const cellElement = cell.getElement()
	const customClasses = ['up']

	if (!formatterParams.isBidderAwarded && formatterParams.isAwardProcessOver) {
		customClasses.push('declined-column')
	}

	const bidders: Bidder[] | null = getBiddersFromCell(cell)

	if (!bidders) {
		return ''
	}

	if (isNaN(cellValue)) {
		const cellValueAsNumber = toNumber(cellValue)
		cellValue = cellValueAsNumber.toFixed(3).replace(/0{0,1}$/, '')
		// @TODO handle by translation lib
		cellValue = cellValue.toString().replace('.', ',')
	}

	const prices = bidders.map((bidder: Bidder) => {
		return Number(bidder.up)
	})
	const lowestLinePrice = Math.min(...prices)
	// const averageLinePrice = roundToTwoDecimals(calculateAverage(prices))

	const deviationFromLowest = calculateDeviationFromLowest(
		lowestLinePrice,
		Number(cellValue)
	)

	// const deviationFromAverage = Math.round(
	// 	calculateDeviation(Number(cellValue), averageLinePrice)
	// )

	if (
		!formatterParams.isAwardProcessOver &&
		Number(cellValue) === lowestLinePrice
	) {
		customClasses.push('lowest-price-cell')
	}

	addClasses(cellElement, customClasses)

	// @TODO reactivate - just modified for presentation
	// return `${cellValue} ${CURRENCY_SYMBOL_EURO} | ${getNumberStringWithSign(
	// 	deviationFromLowest
	// )} | ${getNumberStringWithSign(deviationFromAverage)}`

	return renderToString(
		<div
			className={`chip-wrapper${deviationFromLowest <= 0 ? ' chip-empty' : ''}`}
		>
			{deviationFromLowest > 0 && (
				<Chip
					className="chip-negative"
					label={getNumberStringWithSign(deviationFromLowest)}
				/>
			)}
			{Number(cellValue).toFixed(3)}
		</div>
	)

	// return `${cellValue} ${CURRENCY_SYMBOL_EURO} | ${getNumberStringWithSign(
	// 	deviationFromLowest
	// )}%`
}
