/* eslint-disable @typescript-eslint/no-explicit-any */

import UpBaseFormatter from 'app/services/tabulator/formatters/up/UpBaseFormatter'
import { POSITION_TYPES } from 'app/types/POSITION_TYPES'
import { toNumber } from 'lodash'

class BaseCellFormatter extends UpBaseFormatter {
	constructor(cell: any) {
		super(cell, POSITION_TYPES.BASE)
	}

	format(): string | number {
		const cellValue = this.cell.getValue()

		if (!cellValue || toNumber(cellValue) <= 0) {
			this.markAsInvalid()
		} else {
			this.markAsValid()
		}

		return super.format()
	}
}

export default BaseCellFormatter
