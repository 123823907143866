import { makeStyles, Theme } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'
import { ENVIRONMENTS } from 'app/constants'

export default makeStyles((theme: Theme) => ({
	root: {
		'& .MuiFormHelperText-root.Mui-error': {
			color: themeValues().colors.text.color,
			fontSize: '0.95em',
			margin: '0 14px 0 0',
		},
		color: themeValues().colors.text.color,
	},
	header: {
		margin: `${theme.spacing(2.5)}px 0 0`,
		color: themeValues().colors.text.color,
	},
	input: {
		marginTop: theme.spacing(2),
		'& .MuiFilledInput-root': {
			backgroundColor: 'white',
		},
		'& .MuiFormHelperText-root.Mui-error': {
			color: themeValues().colors.text.color,
		},
	},
	actions: {
		margin: themeValues().sizes.Actions.margin,
		display: 'flex',
		justifyContent:
			process.env.REACT_APP_ENV === ENVIRONMENTS.DEMO
				? 'flex-end'
				: 'space-between',
		alignItems: 'center',
	},
	link: {
		fontSize: theme.typography.body1.fontSize,
		textDecoration: 'none',
		color: themeValues().colors.text.color,
	},
}))
