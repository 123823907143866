import { PasswordCheckValidatorProps } from 'component/PasswordCheckValidator/PasswordCheckValidatorProps'
import PasswordValidator from 'component/PasswordValidator/PasswordValidator'
import React, { FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { get } from 'lodash'

const PasswordCheckValidator: FC<PasswordCheckValidatorProps> = ({
	formData,
	onInputChange,
}) => {
	const { t } = useTranslation()
	const [passwordVisible, setPasswordVisible] = useState(false)

	useEffect(() => {
		ValidatorForm.addValidationRule('isEqual', (value: string) => {
			return formData.password === value
		})

		return () => {
			ValidatorForm.removeValidationRule('isEqual')
		}
	})

	return (
		<>
			<PasswordValidator
				value={get(formData, 'password', '') as string}
				onChange={onInputChange}
				onEndAdornmentChange={newValue => {
					setPasswordVisible(newValue)
				}}
			/>

			<PasswordValidator
				name="passwordCheck"
				validators={['required', 'isEqual']}
				errorMessages={[
					t('common:inputs.password.validatorViolations.required'),
					t('common:inputs.password.validatorViolations.isEqual'),
				]}
				type={passwordVisible ? 'text' : 'password'}
				value={get(formData, 'passwordCheck', '') as string}
				label={t('common:inputs.password.confirmationLabel')}
				onChange={onInputChange}
				endAdornment={false}
			/>
		</>
	)
}

export default PasswordCheckValidator
