import logout from 'app/hooks/useLogout'
import ProfileMenuItem from 'domain/header/components/profileMenu/ProfileMenuItem/ProfileMenuItem'
import { ProfileMenuItemLogoutProps } from 'domain/header/components/profileMenu/ProfileMenuItemLogout/ProfileMenuItemLogoutProps'
import React, { FC } from 'react'

const ProfileMenuItemLogout: FC<ProfileMenuItemLogoutProps> = ({ item }) => {
	return <ProfileMenuItem item={item} onClick={logout} />
}

export default ProfileMenuItemLogout
