import AppBar from '@material-ui/core/AppBar'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import AccountCircle from '@material-ui/icons/AccountCircle'
import useForm from 'app/hooks/useForm'
import PrivateRoute from 'app/routing/PrivateRoute'
import routes from 'app/routing/routes/routesApp'
import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import { resolveRoute } from 'app/utils/route'
import PrimaryButton from 'component/PrimaryButton/PrimaryButton'
import TemplateBackground from 'component/TemplateBackground/TemplateBackground'
import queries from 'domain/drawer/apollo/queries'
import ProfileMenu from 'domain/header/components/profileMenu/ProfileMenu/ProfileMenu'
import mutations from 'domain/project/apollo/mutations'
import React, { FC, MouseEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteProps, useHistory } from 'react-router-dom'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { get } from 'lodash'

import useStyles from 'template/TemplateFirstUsage/TemplateFirstUsageStyles'
import ProfileName from 'domain/header/components/profileMenu/ProfileName/ProfileName'

const TemplateFirstUsage: FC<RouteProps> = ({ ...rest }) => {
	const classes = useStyles()

	const { push } = useHistory()
	const { t } = useTranslation()
	const { handleInputChange, formData } = useForm()
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

	const [createProject, { data, error }] = useGraphQLMutation(
		mutations.createProject
	)

	const onSubmit = (): void => {
		createProject({
			variables: { name: formData.projectName },
			refetchQueries: [
				{
					query: queries.ProjectListForRedirect,
				},
			],
		})
	}

	useEffect(() => {
		const projectUuid = get(data, ['createProject', 'uuid'], null)
		if (projectUuid) {
			push(
				resolveRoute(routes.project, {
					projectUuid,
				})
			)
		}
	}, [data, error])

	return (
		<PrivateRoute
			{...rest}
			render={() => {
				return (
					<TemplateBackground>
						<AppBar
							position="absolute"
							classes={{
								root: classes.header,
							}}
						>
							<Toolbar
								classes={{
									root: classes.toolbar,
								}}
							>
								<ProfileName />
								<IconButton
									color="inherit"
									onClick={(event: MouseEvent<HTMLElement>) =>
										setAnchorEl(event.currentTarget)
									}
								>
									<AccountCircle />
								</IconButton>

								<ProfileMenu
									open={Boolean(anchorEl)}
									anchorEl={anchorEl}
									onClose={() => setAnchorEl(null)}
								/>
							</Toolbar>
						</AppBar>
						<Card
							classes={{
								root: classes.card,
							}}
						>
							<CardHeader title={t('domain:project.create.form.title')} />
							<CardContent
								classes={{
									root: classes.cardContent,
								}}
							>
								<ValidatorForm instantValidate={false} onSubmit={onSubmit}>
									<FormGroup>
										<TextValidator
											label={t('domain:project.create.form.projectName.label')}
											onChange={handleInputChange}
											name="projectName"
											fullWidth
											value={formData.projectName}
											validators={['required', 'minStringLength:3']}
											errorMessages={[
												t(
													'domain:project.create.form.validatorViolations.required'
												),
												t(
													'domain:project.create.form.validatorViolations.minLength',
													{
														minLength: 3,
													}
												),
											]}
										/>
									</FormGroup>
									<FormGroup
										classes={{
											root: classes.buttonFormGroup,
										}}
									>
										<PrimaryButton type="submit">
											{t('domain:project.create.form.submit')}
										</PrimaryButton>
									</FormGroup>
								</ValidatorForm>
							</CardContent>
						</Card>
					</TemplateBackground>
				)
			}}
		/>
	)
}

export default TemplateFirstUsage
