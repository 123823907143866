import useLocalStorage from 'app/hooks/useLocalStorage'
import TrackingEvents from 'app/services/datawarehouse/TrackingEvents'
import { TrackingEvent } from 'app/services/datawarehouse/interfaces/TrackingEvent'
import { TrackEventMeta } from 'app/services/datawarehouse/interfaces/TrackingEventMeta'
import { STORED_TOKEN } from 'app/constants'
import { postTrackingEvent } from 'app/utils/tracking'
import { v4 as uuidv4 } from 'uuid'

interface UseTracking {
	track: (event: keyof typeof TrackingEvents, meta?: TrackEventMeta) => void
}

export default (): UseTracking => {
	const [localStorageToken, setLocalStorageToken] = useLocalStorage<
		string | null
	>(STORED_TOKEN, null)

	if (!localStorageToken) {
		const generatedToken = uuidv4()
		setLocalStorageToken(generatedToken)
	}

	const track = (
		event: keyof typeof TrackingEvents,
		meta?: TrackEventMeta
	): void => {
		if (localStorageToken) {
			postTrackingEvent({
				...TrackingEvents[event],
				// eslint-disable-next-line @typescript-eslint/camelcase
				user_hash: localStorageToken,
				meta: meta || {},
			} as TrackingEvent)
		}
	}

	return {
		track,
	}
}
