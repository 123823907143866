/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component, ReactNode } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'
import { LogoutButton } from 'domain/logout/index'

export interface ErrorBoundaryState {
	error: Error | null
}

const styles = {
	root: {
		background: themeValues().palette.blue.dark,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100vw',
		height: '100vh',
		flexWrap: 'wrap',
	},
	container: {},
	title: {
		color: 'white',
		fontSize: 80,
	},
	subTitle: {
		color: 'white',
		fontSize: 50,
	},
	content: {
		margin: '16px 0 16px 0',
		color: 'white',
	},
}

class ErrorBoundary extends Component<any, ErrorBoundaryState> {
	constructor(props: any) {
		super(props)

		this.state = {
			error: null,
		} as ErrorBoundaryState
	}

	componentDidCatch(error: Error): void {
		this.setState({ error })
	}

	render(): ReactNode {
		const { error } = this.state
		const { children, classes } = this.props

		if (error) {
			return (
				<div className={classes.root}>
					<div>
						<Typography component="h1" className={classes.title}>
							:( Oh Nein!
						</Typography>
						<Typography
							color="textPrimary"
							component="h3"
							className={classes.subTitle}
						>
							Ein Fehler ist aufgetreten!
						</Typography>
						<Typography component="p" className={classes.content}>
							{error.name}
						</Typography>
						<Typography component="p" className={classes.content}>
							{error.message}
						</Typography>
						<LogoutButton />
					</div>
				</div>
			)
		}

		return children
	}
}

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export default withStyles(styles)(ErrorBoundary)
