import { LoadingProps } from 'component/Loading/LoadingProps'
import React, { FC } from 'react'

import useLoading from 'app/hooks/useLoading'

const Loading: FC<LoadingProps> = ({
	children,
	loading,
	size,
	disableLoader = false,
	loadingText,
}) => {
	return useLoading(
		() => {
			return <>{children}</>
		},
		loading,
		undefined,
		size,
		disableLoader,
		loadingText
	)
}

export default Loading
