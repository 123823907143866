/* eslint-disable @typescript-eslint/no-explicit-any */
import { getBoqNodesFromRevision } from 'app/utils/apollo'
import React, { FC, useMemo } from 'react'
import { get, isArray } from 'lodash'
import { Bidder, BoqNode } from 'app/types/index'
import useColumns from 'domain/award/comparison/tabulator/useColumns'
import useOptions from 'domain/award/comparison/tabulator/useOptions'
import { resolveTree } from 'app/services/utils/index'
import Table from 'component/Table/Table'
import { TableProps } from 'domain/award/comparison/components/body/AwardTable/AwardTableProps'

const AwardTable: FC<TableProps> = ({
	className,
	revision,
	isEditable = false,
	isUpVisible = false,
	isAwarded = false,
}) => {
	const { options } = useOptions()
	const columns = useColumns(isEditable, isUpVisible, revision, isAwarded)
	const boqNodes = getBoqNodesFromRevision(revision)

	// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
	// @ts-ignore
	const prepareTableData: BoqNode[] = useMemo(
		() =>
			boqNodes &&
			resolveTree<BoqNode>(boqNodes, {
				onNode: (data, resolver: any) => {
					const bidders: Bidder[] | null = get(data, ['bidders'], null)
					if (isArray(bidders)) {
						const resolvedBidderData = bidders.reduce(
							(prev: object, value: Bidder) => {
								return {
									...prev,
									[value.uuid]: value.up,
								}
							},
							{}
						)
						return resolver({ ...data, ...resolvedBidderData })
					}
					return resolver(data)
				},
			}),
		[boqNodes, isAwarded]
	)

	return (
		<Table
			className={className}
			options={options}
			data={prepareTableData}
			columns={columns}
		/>
	)
}

export default AwardTable
