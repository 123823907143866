import React, { FC, ReactElement, useState, ChangeEvent } from 'react'
import {
	Card,
	CardActions,
	CardHeader,
	CardContent,
	Typography,
	ListItemText,
	List,
} from '@material-ui/core'
import PrimaryButton from 'component/PrimaryButton/PrimaryButton'
import { useTranslation } from 'react-i18next'
import { CURRENCY_SYMBOL_EURO } from 'app/config/locales'
import clsx from 'clsx'
import QuantityDropdown from 'component/QuantityDropdown/QuantityDropdown'
import { get, invert } from 'lodash'
import { STEPS_CHECKOUT } from 'app/config/checkout'
import TrackingEvents from 'app/services/datawarehouse/TrackingEvents'
import useTracking from 'app/services/datawarehouse/useTracking'
import { PricingPlanProps } from './PricingPlanProps'
import useStyles from './PricingPlanStyles'

const PricingPlan: FC<PricingPlanProps> = ({
	onSelectPricingPlan,
	pricingPlan,
	classes: parentClasses = {},
	goToNextStep,
	checkoutData,
}) => {
	const { title, subtitle, price, info, code, prepaymentMonths } = pricingPlan
	const classes = useStyles()
	const [quantity, setQuantity] = useState(get(checkoutData, 'quantity', '1'))
	const { track } = useTracking()

	const { t } = useTranslation()

	const handleChange = (event: ChangeEvent<{ value: unknown }>): void => {
		if (setQuantity) {
			setQuantity(event.target.value as string)
		}
		const stepKey = invert(STEPS_CHECKOUT)[STEPS_CHECKOUT.STEP_PRICING_PLAN]
		track(`${stepKey}_SELECTQUANTITY` as keyof typeof TrackingEvents, {
			chosenPlan: code,
			quantity: event.target.value as string,
		})
	}

	const getListItems = (): ReactElement[] | null =>
		info.map(details => {
			return (
				<ListItemText
					key={details}
					className={clsx(classes.listItem, parentClasses.listItem)}
				>
					<Typography variant="subtitle1" align="center">
						{details}
					</Typography>
				</ListItemText>
			)
		})

	const getPaymentFrequencyText = (): string => {
		if (prepaymentMonths && prepaymentMonths > 1) {
			return t('domain:checkout.pricingPlan.frequencyUpfront')
		}

		return t('domain:checkout.pricingPlan.frequency')
	}

	const onClick = (): void => {
		if (onSelectPricingPlan && goToNextStep) {
			onSelectPricingPlan(code, quantity)
			goToNextStep()
		}
	}

	return (
		<Card className={clsx(classes.cardRoot, parentClasses.cardRoot)}>
			<CardHeader
				className={clsx(classes.cardHeader, parentClasses.cardHeader)}
				title={title}
				subheader={subtitle}
			/>
			<CardContent
				className={clsx(classes.cardContent, parentClasses.cardContent)}
			>
				<div className={clsx(classes.cardPricing, parentClasses.cardPricing)}>
					<Typography component="h2" variant="h3" color="textPrimary">
						{CURRENCY_SYMBOL_EURO}
						{price}
					</Typography>
					<Typography variant="h6" color="textSecondary">
						{getPaymentFrequencyText()}
					</Typography>
				</div>
			</CardContent>
			<div className={classes.infoAndAction}>
				<List>{getListItems()}</List>
				<div className={classes.quantityAndButton}>
					<QuantityDropdown quantity={quantity} onChange={handleChange} />

					<CardActions
						className={clsx(classes.submitButton, parentClasses.submitButton)}
					>
						<PrimaryButton onClick={onClick}>
							{t('domain:checkout.pricingPlan.button.title')}
						</PrimaryButton>
					</CardActions>
				</div>
			</div>
		</Card>
	)
}

export default PricingPlan
