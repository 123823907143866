import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
	root: {},
	headline: {
		marginBottom: theme.spacing(1),
	},
	content: {
		marginBottom: theme.spacing(3),
		whiteSpace: 'pre-line',
		wordBreak: 'break-word',
	},
}))
