import React, { FC } from 'react'
import { Notice } from 'component'
import { useTranslation } from 'react-i18next'
import AnnotationSideSheetProps from './AnnotationSideSheetProps'
import CreateAnnotationForm from '../CreateAnnotationForm/CreateAnnotationForm'

const AnnotationSideSheet: FC<AnnotationSideSheetProps> = ({
	boqNode,
	boqAnnotation,
	revision,
}) => {
	const { t } = useTranslation()
	return (
		<>
			<Notice message={t('domain:award.annotations.notice.message')} />
			<CreateAnnotationForm
				boqNode={boqNode}
				boqAnnotation={boqAnnotation}
				revision={revision}
			/>
		</>
	)
}

export default AnnotationSideSheet
