import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import InputTag from 'component/InputTag/InputTag'
import mutations from 'domain/tender/bidders/apollo/mutations'
import queries from 'domain/tender/bidders/apollo/queries'
import ContactTag from 'domain/tender/bidders/components/sidesheet/contacts/ContactTag/ContactTag'
import { ContactTagsProps } from 'domain/tender/bidders/components/sidesheet/contacts/ContactTags/ContactTagsProps'
import React, { FC } from 'react'

import useStyles from 'domain/tender/bidders/components/sidesheet/contacts/ContactTags/ContactTagsStyles'

const ContactTags: FC<ContactTagsProps> = ({ contact }) => {
	const classes = useStyles()
	const { uuid: contactUuid, tags } = contact

	const [createContactTag] = useGraphQLMutation(mutations.createContactTag, {
		refetchQueries: [
			{
				query: queries.Contacts, // to update the contacts in the table
			},
		],
	})

	const onAcceptChipInput = (label: string): void => {
		createContactTag({
			variables: { contactUuid, label },
		})
	}

	return (
		<div className={classes.root}>
			{Array.isArray(tags) &&
				tags.map(tag => {
					return (
						<ContactTag
							key={tag.uuid}
							className={classes.tag}
							contactUuid={contactUuid}
							tag={tag}
						/>
					)
				})}
			<InputTag
				classes={{
					root: classes.tag,
				}}
				filteredTags={tags || []}
				onAccept={onAcceptChipInput}
			/>
		</div>
	)
}

export default ContactTags
