import GetAppIcon from '@material-ui/icons/GetApp'
import useDownload from 'app/hooks/useDownload'
import routesApi from 'app/routing/routes/routesApi'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import { UrlParams } from 'app/types/UrlParams'
import {
	getLotFromProject,
	getProjectFromResponse,
	getFirstRevisionFromLot,
} from 'app/utils/apollo'
import { resolveRoute } from 'app/utils/route'
import Validate from 'component/Validate/Validate'

import useStyles from 'domain/general/boq/components/header/download/DownloadX83/DownloadX83Styles'
import { LOT_STATUS } from 'domain/lot/interfaces/LOT_STATUS'
import queries from 'domain/tender/bidders/apollo/queries'
import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import TooltipIconButton from 'component/TooltipIconButton/TooltipIconButton'

const DownloadX83 = (): ReactElement => {
	const { t } = useTranslation()
	const { projectUuid, lotUuid } = useParams<UrlParams>()
	const { initializeDownload, download } = useDownload()
	const [fetchData, { data }] = useGraphQLLazyQuery(queries.LotWithRevisions)
	const classes = useStyles()

	const project = getProjectFromResponse(data)
	const lot = getLotFromProject(project)
	const revision = getFirstRevisionFromLot(lot)

	useEffect(() => {
		if (projectUuid && lotUuid) {
			fetchData({ variables: { projectUuid, lotUuid } })
		}
	}, [projectUuid, lotUuid])

	useEffect(() => {
		if (projectUuid && revision) {
			initializeDownload(
				`${process.env.REACT_APP_API_URL}/${resolveRoute(
					routesApi.downloadGaeb,
					{
						projectUuid,
						revisionUuid: revision.uuid,
					}
				)}`
			)
		}
	}, [projectUuid, revision])

	return (
		<Validate invalidCombinations={[{ lotStatus: LOT_STATUS.DRAFT }]}>
			<div className={classes.root}>
				<TooltipIconButton
					title={t('domain:boq.download.button.title')}
					onClick={download}
				>
					<GetAppIcon />
				</TooltipIconButton>
			</div>
		</Validate>
	)
}

export default DownloadX83
