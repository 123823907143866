import { STEPS_CHECKOUT, CHECKOUTDATA_KEYS } from 'app/config/checkout'
import { getStripePaymentMethodsFromResponse } from 'app/utils/apollo'
import CheckoutCard from 'domain/checkout/components/CheckoutCard/CheckoutCard'
import FormCardPayment from 'domain/checkout/components/FormCardPayment/FormCardPayment'
import { PAYMENT_TYPES } from 'domain/checkout/enums/PAYMENT_TYPES'
import { CheckoutStepProps } from 'domain/checkout/interfaces/CheckoutStepProps'
import React, { FC, useRef, useState, ChangeEvent, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import {
	Tabs,
	Tab,
	RadioGroup,
	FormControlLabel,
	Radio,
} from '@material-ui/core'
import TabPanel from 'component/TabPanel/TabPanel'
import clsx from 'clsx'
import queries from 'domain/account/apollo/queries'
import { get } from 'lodash'
import useGraphQLQuery from 'app/services/apollo/hooks/useGraphQLQuery'
import useStyles from './CardPaymentEnterStyles'

const CardPaymentEnter: FC<CheckoutStepProps> = props => {
	const classes = useStyles()
	const cardFormRef = useRef()

	const [paymentType] = useState('card')
	const [loading, setLoading] = useState(false)
	const [tabIndex, setTabindex] = useState(0)

	const { t } = useTranslation()
	const { data } = useGraphQLQuery(queries.AccountConnectedPayments)

	const stripePaymentMethods = getStripePaymentMethodsFromResponse(data)
	const {
		checkoutData,
		handleInputChange,
		createFormDataFromObject,
		classes: parentClasses = {},
		goToNextStep,
	} = props

	const handleTabChange = (event: ChangeEvent<{}>, newValue: number): void => {
		setTabindex(newValue)
	}

	const renderPaymentRadios = (): ReactNode => {
		if (Array.isArray(stripePaymentMethods) && stripePaymentMethods.length) {
			return stripePaymentMethods
				.map(
					({
						stripePaymentMethodId,
						cardBrand,
						cardLast4,
						cardExpMonth,
						cardExpYear,
					}) => (
						<FormControlLabel
							key={stripePaymentMethodId}
							name="payment"
							value={stripePaymentMethodId}
							control={<Radio />}
							label={`${cardBrand}  ***${cardLast4}  ${cardExpMonth}/${cardExpYear}`}
						/>
					)
				)
				.concat(
					<FormControlLabel
						key="payment"
						name="payment"
						value="newPayment"
						control={<Radio />}
						label={t(`domain:checkout.paymentEnter.labels.radioLabel`)}
					/>
				)
		}
		return null
	}

	const onSubmit = (): void => {
		if (cardFormRef && paymentType === PAYMENT_TYPES.CARD) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
			// @ts-ignore
			cardFormRef.current.onSubmit()
		}
	}

	return (
		<CheckoutCard
			{...props}
			classes={{
				...parentClasses,
				cardRoot: clsx(classes.cardRoot, parentClasses.cardRoot),
			}}
			headline={t('domain:checkout.createSubscription.card.title')}
			stepId={STEPS_CHECKOUT.STEP_PAYMENT_ENTER}
			onSubmit={onSubmit}
			loading={loading}
		>
			<Tabs
				value={tabIndex}
				onChange={handleTabChange}
				indicatorColor="primary"
				textColor="primary"
				centered
				classes={{
					fixed: classes.fixed,
				}}
			>
				<Tab
					label={t(`domain:checkout.paymentEnter.labels.${PAYMENT_TYPES.CARD}`)}
				/>
			</Tabs>
			<TabPanel value={tabIndex} index={0}>
				<div className={classes.cardLogosRoot}>
					<img
						className={classes.cardLogo}
						src="https://cdn.tenderframe.de/assets/logos-payment/mc_symbol_opt_73_3x.png"
						alt="mastercard"
					/>
					<img
						className={classes.cardLogo}
						src="https://cdn.tenderframe.de/assets/logos-payment/Visa-POS-Graphic_visa_pos_fc.png"
						alt="mastercard"
					/>
				</div>
				<RadioGroup
					name="previousPaymentSelection"
					onChange={handleInputChange}
					value={get(checkoutData, CHECKOUTDATA_KEYS.PAYMENT, 'newPayment')}
					classes={{ root: classes.fixed }}
				>
					{renderPaymentRadios()}
				</RadioGroup>
				<FormCardPayment
					ref={cardFormRef}
					/* eslint-disable-next-line @typescript-eslint/ban-ts-ignore */
					// @ts-ignore*/
					checkoutData={checkoutData}
					handleInputChange={handleInputChange}
					onSuccess={goToNextStep}
					createFormDataFromObject={createFormDataFromObject}
					onLoadingChange={(isLoading: boolean) => {
						setLoading(isLoading)
					}}
				/>
			</TabPanel>
		</CheckoutCard>
	)
}

export default CardPaymentEnter
