import { Theme, makeStyles } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
	root: {
		color: theme.palette.primary.main,
		'&:hover': {
			textDecoration: 'underline',
			cursor: 'pointer',
		},
	},
}))
