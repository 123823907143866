import { gql } from 'apollo-boost'

const ProjectWithLots = gql`
	query ProjectWithLots($projectUuid: String!) {
		project(uuid: $projectUuid) {
			uuid
			lots {
				data {
					uuid
					name
				}
			}
		}
	}
`

const ProjectWithLotInformation = gql`
	query ProjectWithLotInformation($projectUuid: String!) {
		project(uuid: $projectUuid) {
			uuid
			role
			__typename
			lots {
				data {
					uuid
					name
					status
				}
			}
		}
	}
`

const ProjectWithCurrentLotStatus = gql`
	query ProjectWithCurrentLotStatus($projectUuid: String!, $lotUuid: String!) {
		project(uuid: $projectUuid) {
			uuid
			lot(uuid: $lotUuid) {
				uuid
				status
				__typename
			}
		}
	}
`

const ProjectWithLotName = gql`
	query ProjectWithLotName($projectUuid: String!, $lotUuid: String!) {
		project(uuid: $projectUuid) {
			uuid
			lot(uuid: $lotUuid) {
				uuid
				name
			}
		}
	}
`

export default {
	ProjectWithLotInformation,
	ProjectWithCurrentLotStatus,
	ProjectWithLotName,
	ProjectWithLots,
}
