import { Typography, IconButton } from '@material-ui/core'
import useStyles from 'component/Tab/TabLabel/TabLabelStyles'
import React, { FC, ReactElement, MouseEvent } from 'react'
import clsx from 'clsx'
import LotInput from 'domain/lot/components/LotInput/LotInput'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'
import Validate from 'component/Validate/Validate'
import { TabLabelProps } from './TabLabelProps'

const TabLabel: FC<TabLabelProps> = ({
	title = '',
	icon: Icon,
	menuIcon: MenuIcon,
	onClickMenu,
	selected = false,
	className,
	inEditMode,
	onAcceptLot,
	onDeclineLot,
}): ReactElement | null => {
	const classes = useStyles({ selected })

	if (inEditMode) {
		return (
			<LotInput
				className={classes.lotInput}
				onAccept={onAcceptLot}
				onDecline={onDeclineLot}
				loading={false}
				defaultValue={title}
			/>
		)
	}
	return (
		<div className={classes.root}>
			<div className={classes.labelContainer}>
				{Icon && <Icon classes={{ root: classes.icon }} />}
				{title && (
					<Typography
						classes={{ root: classes.label }}
						component="h1"
						noWrap
						variant="h6"
					>
						{title}
					</Typography>
				)}
			</div>
			<Validate
				validCombinations={[
					{
						projectRole: ROLES_PROJECT.LEAD,
					},
				]}
			>
				<IconButton
					className={clsx(className, classes.menuIcon)}
					onClick={(e: MouseEvent<HTMLButtonElement>) => {
						onClickMenu(e)
					}}
				>
					{MenuIcon && <MenuIcon />}
				</IconButton>
			</Validate>
		</div>
	)
}

export default TabLabel
