import IconButton from '@material-ui/core/IconButton'
import PublishIcon from '@material-ui/icons/Publish'
import { convertApiErrorToErrorMessage } from 'app/errors/codes'
import { useEnvironmentVariables } from 'app/hooks/index'
import useUpload from 'app/hooks/useUpload'
import routesApi from 'app/routing/routes/routesApi'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import useCustomSnackbar from 'app/services/snackbar/useCustomSnackbar'
import { REVISION_STATUS } from 'app/types/REVISION_STATUS'
import { UrlParams } from 'app/types/UrlParams'
import {
	getLotFromProject,
	getProjectFromResponse,
	getFirstRevisionFromLot,
} from 'app/utils/apollo'
import { resolveRoute } from 'app/utils/route'
import Loading from 'component/Loading/Loading'
import Validate from 'component/Validate/Validate'
// import { LOT_STATUS } from 'domain/lot/interfaces/LOT_STATUS'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'
import queries from 'domain/tender/bidders/apollo/queries'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import useStyles from 'domain/general/boq/components/header/upload/UploadX84/UploadX84Styles'

const UploadX84: FC = () => {
	const classes = useStyles()

	const { t } = useTranslation()
	const ENV = useEnvironmentVariables()
	const { sendErrorMessage } = useCustomSnackbar()
	const { projectUuid, lotUuid } = useParams<UrlParams>()
	const [fetchData, { data, loading }] = useGraphQLLazyQuery(
		queries.LotWithRevisions
	)

	const project = getProjectFromResponse(data)
	const lot = getLotFromProject(project)
	const revision = getFirstRevisionFromLot(lot)

	const {
		renderFileInput,
		openUploadDialog,
		error,
		initializeUpload,
	} = useUpload()

	useEffect(() => {
		if (revision && project && lot) {
			initializeUpload(
				`${ENV.REACT_APP_API_URL}/${resolveRoute(
					routesApi.uploadGaebUpdateRevision,
					{
						projectUuid,
						lotUuid,
						revisionUuid: (revision && revision.uuid) || 'asdasd',
					}
				)}`
			)
		}
	}, [revision, project, lot])

	useEffect(() => {
		if (error) {
			sendErrorMessage(convertApiErrorToErrorMessage(error))
		}
	}, [error])

	useEffect(() => {
		if (projectUuid && lotUuid) {
			fetchData({ variables: { projectUuid, lotUuid } })
		}
	}, [projectUuid, lotUuid])

	return (
		<Validate
			validCombinations={[
				{
					projectRole: ROLES_PROJECT.BIDDER,
					lotStatus: null, // Todo: enable button: LOT_STATUS.TENDERING,
					revisionStatus: REVISION_STATUS.BIDDING,
				},
			]}
		>
			<Loading loading={loading}>
				<div className={classes.root}>
					<IconButton
						title={t('domain:boq.upload.button.title')}
						onClick={() => openUploadDialog()}
					>
						<PublishIcon />
					</IconButton>
					{renderFileInput()}
				</div>
			</Loading>
		</Validate>
	)
}

export default UploadX84
