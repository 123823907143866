import Grid from '@material-ui/core/Grid'
import { TOKEN_DIGIT_LENGTH, CHECKOUTDATA_KEYS } from 'app/config/checkout'
import CheckoutCard from 'domain/checkout/components/CheckoutCard/CheckoutCard'
import TokenDigitValidator from 'domain/checkout/components/TokenDigitValidator/TokenDigitValidator'
import { CheckoutStepProps } from 'domain/checkout/interfaces/CheckoutStepProps'
import queries from 'domain/register/apollo/queries'
import React, {
	FC,
	ChangeEvent,
	useCallback,
	createRef,
	ClipboardEvent,
	useEffect,
	useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { get, isEmpty } from 'lodash'
import RenderErrorMessage from 'component/RenderErrorMessage/RenderErrorMessage'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'

const references = Array.from(Array(TOKEN_DIGIT_LENGTH).keys()).reduce(
	(prev, key) => {
		return { ...prev, [`digit${key}`]: createRef() }
	},
	{}
)

const CardEmailVerification: FC<CheckoutStepProps> = props => {
	const {
		checkoutData,
		handleInputChange,
		classes: parentClasses,
		goToNextStep,
		setToken,
		createFormDataFromObject,
	} = props
	const { t } = useTranslation()

	const [emailError, setEmailError] = useState<Error | undefined>()

	const lastDigit = get(checkoutData, `digit${TOKEN_DIGIT_LENGTH - 1}`, '')
	const email = get(checkoutData, CHECKOUTDATA_KEYS.EMAIL, '')

	useEffect(() => {
		const isStepCompleted = get(
			checkoutData,
			CHECKOUTDATA_KEYS.STEP_EMAIL_VERIFICATION_COMPLETED,
			false
		)
		if (isStepCompleted && goToNextStep) {
			goToNextStep()
		}
	}, [checkoutData])

	const handleOnComplete = (emailFromQuery: string): void => {
		if (emailFromQuery === email && createFormDataFromObject) {
			createFormDataFromObject({
				...checkoutData,
				[CHECKOUTDATA_KEYS.STEP_EMAIL_VERIFICATION_COMPLETED]: true,
			})
		} else {
			const error = new Error(
				t('domain:checkout.accountToken.validatorViolation')
			)
			setEmailError(error)
		}
	}

	const [fetchAccount, { loading, error }] = useGraphQLLazyQuery(
		queries.AccountToken,
		{
			onCompleted: res => handleOnComplete(res.accountToken.email),
		}
	)

	const focusNextInput = useCallback((currentKey: number) => {
		const nextReference = get(
			references,
			[`digit${currentKey + 1}`, 'current'],
			null
		)
		const nextInputReference = get(nextReference, ['children', 0])

		if (nextInputReference) {
			nextInputReference.focus()
		}
	}, [])

	const onChange = (e: ChangeEvent<HTMLInputElement>, key: number): void => {
		const value = get(e, ['target', 'value'], null)

		if (handleInputChange) {
			handleInputChange(e)
		}

		if (value) {
			focusNextInput(key)
		}
	}

	const onPaste = (e: ClipboardEvent): void => {
		const pastedContent = e.clipboardData.getData('Text')
		const digitsArr = pastedContent.split('')

		const digitsObj = Array.from(Array(TOKEN_DIGIT_LENGTH).keys()).reduce(
			(prev, value, index) => {
				if (Array.isArray(digitsArr) && digitsArr[index]) {
					return { ...prev, [`digit${value}`]: digitsArr[index] }
				}
				return prev
			},
			{}
		)
		if (createFormDataFromObject) {
			createFormDataFromObject({
				...checkoutData,
				...digitsObj,
			})
		}
	}

	const renderDigitInputs = useCallback(() => {
		return Array.from(Array(TOKEN_DIGIT_LENGTH).keys()).map(key => {
			const value = get(checkoutData, `digit${key}`, '')
			return (
				<Grid item xs={2} key={key}>
					<TokenDigitValidator
						/* eslint-disable-next-line @typescript-eslint/ban-ts-ignore */
						// @ts-ignore
						ref={references[`digit${key}`]}
						name={`digit${key}`}
						value={value}
						/* eslint-disable-next-line @typescript-eslint/ban-ts-ignore */
						// @ts-ignore
						onChange={e => onChange(e, key)}
						onPaste={onPaste}
					/>
				</Grid>
			)
		})
	}, [checkoutData])

	const getToken = (): string => {
		return Array.from(Array(TOKEN_DIGIT_LENGTH).keys())
			.map(key => {
				return get(checkoutData, `digit${key}`, '')
			})
			.join('')
	}

	const onSubmit = (): void => {
		const token = getToken()

		fetchAccount({
			variables: {
				token,
			},
		})
		if (setToken) {
			setToken(token)
		}
	}

	useEffect(() => {
		if (lastDigit) {
			onSubmit()
		}
	}, [lastDigit])

	return (
		<CheckoutCard
			instantValidate
			headline={t('domain:checkout.accountToken.card.title')}
			onSubmit={onSubmit}
			loading={loading}
			disabled={isEmpty(getToken())}
			classes={parentClasses}
			{...props}
		>
			<Grid container spacing={2}>
				{renderDigitInputs()}
			</Grid>
			<RenderErrorMessage error={error || emailError} />
		</CheckoutCard>
	)
}

export default CardEmailVerification
