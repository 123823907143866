import { Theme } from 'app/types/Theme'
import { LOT_STATUS } from 'domain/lot/interfaces/LOT_STATUS'

export const themeValues = (): Theme => {
	return {
		logo: {
			default: 'https://cdn.tenderframe.de/assets/logo-colored-transparent.png',
			invert: 'https://cdn.tenderframe.de/assets/logo/logo-wh.svg',
			svg: 'https://cdn.tenderframe.de/assets/logo/logo-wh.svg',
		},
		images: {
			network: 'https://cdn.tenderframe.de/assets/network.png',
			login: 'https://cdn.tenderframe.de/assets/construction-site.jpg',
		},
		components: {
			Tab: {
				fontSize: 16,
				fontFamily: `"Open Sans", sans-serif`,
				fontWeight: 500,
				letterSpacing: '0.0075em',
				backgroundSelected: '#FFF',
				backgroundUnselected: 'rgba(255,255,255,0.2)',
			},
		},
		zindex: {
			sidesheet: 1000,
		},
		sizes: {
			Actions: {
				margin: '24px 0 0 0',
			},
			AttachmentCard: {
				height: 200,
				width: 300,
			},
			AttachmentDropzone: {
				height: 335,
				width: 300,
				borderWidth: 2,
			},
			Button: {
				borderRadius: 4,
				minWidth: 125,
			},
			Drawer: {
				width: 300,
			},
			DrawerMenuItemLink: {
				height: 55,
			},
			EndDateCalendar: {
				maxWidth: 400,
			},
			InputActionIcons: {
				width: 32,
				height: 32,
				borderRadius: 12,
			},
			PageContentHeader: {
				minHeight: 64,
			},
			PricingTable: {
				minWidth: 300,
			},
			ProfileMenu: {
				minWidth: 192,
			},
			ProjectDropdown: {
				height: 40,
			},
			RegisterForm: {
				width: 420,
			},
			SideSheet: {
				width: 500,
			},
			TabHeader: {
				height: 160,
			},
			LotTab: {
				height: 50,
				borderRadius: 4,
				fontSize: 16,
			},
			LotTabIcon: {
				fontSize: 28,
			},
			TabCreateLotIcon: {
				fontSize: 34,
				height: 50,
			},
			TokenDigitValidator: {
				height: 100,
				fontSize: 32,
			},
		},
		animations: {
			SideSheet: {
				duration: 225,
			},
		},
		spacing: factor => factor * 4,
		mainOverlap: 10,
		palette: {
			blue: {
				main: '#30a7c9',
				dark: '#007ea7',
				light: '#71c4dd',
			},
			gray: {
				main: '#f4f4f4',
				dark: '#3b3b3b',
				light: '#f4f4f4',
			},
		},
		lotStatusColors: {
			[LOT_STATUS.DRAFT]: {
				main: '#fff',
				dark: '#f48fb1',
				light: '#f48fb1',
			},
			[LOT_STATUS.CREATED]: {
				main: '#fff',
				dark: '#22cfcb',
				light: '#22cfcb',
			},
			[LOT_STATUS.TENDERING]: {
				main: '#fff',
				dark: '#f54dae',
				light: '#f54dae',
			},
			[LOT_STATUS.AWARDING]: {
				main: '#555',
				dark: '#fff176',
				light: '#fff9c4',
			},
			[LOT_STATUS.AWARDED]: {
				main: '#fff',
				dark: '#4dd0e1',
				light: '#b2ebf2',
			},
			[LOT_STATUS.CONFIRMED]: {
				main: '#fff',
				dark: '#81c784',
				light: '#c8e6c9',
			},
		},
		colors: {
			AttachmentDropzone: {
				background: '#fff',
				border: '#e3e3e3',
				borderActive: '#30a7c9',
			},
			ProjectDropdown: {
				background: '#fefefe',
				border: '#9e9e9e',
			},
			Drawer: {
				background: '#f9f9f9',
				color: '#7f7f7f',
			},
			DrawerMenuItem: {
				background: '#fff',
			},
			DrawerMenuItemHeadline: {
				color: '#b8b8b8',
			},
			DrawerMenuItemLink: {
				fontWeight: 300,
				borderTop: '1px solid #fcfcfc',
				borderBottom: '1px solid #ececec',
			},
			PlaceholderCardPayment: {
				color: 'rgba(0, 0, 0, 0.35)',
			},
			SideBoxLogin: {
				color: 'rgba(0, 0, 0, 0.5)',
			},
			background: { color: '#fff' },
			primary: '#278eb1',
			success: '#4cce5c',
			error: '#D83679',
			warning: '#ffe95e',
			default: '#f1f1f1',
			border: { color: 'fff' },
			icon: { color: 'fff' },
			intent: { color: 'fff' },
			text: { color: '#fff' },
		},
		gradients: {
			background: 'linear-gradient(178deg, #1e4f5f, #358ca9)',
			button: 'linear-gradient(#296b81, #3fa6c8)',
		},
		boxShadows: {
			button: '4px 4px 6px #d0d0d0',
			sectionCard: '1px 1px 2px #d0d0d0',
			elevation: 4,
		},
		selectedDate: {
			color: 'white',
			backgroundColor: '#278eb1',
			textColor: 'black',
		},
	}
}
