import { gql } from 'apollo-boost'

const completeRegistration = gql`
	mutation completeRegistration($token: String!, $password: String!) {
		completeRegistration(token: $token, password: $password) {
			email
			__typename
		}
	}
`

const createDemoAccount = gql`
	mutation createDemoAccount($email: String!) {
		createDemoAccount(email: $email) {
			refreshToken
		}
	}
`

export default {
	completeRegistration,
	createDemoAccount,
}
