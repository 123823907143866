import React, { FC } from 'react'
import {
	ANNOTATION_SIDESHEET_ATTRIBUTES_RENDERED,
	BoqAnnotation,
} from 'app/config/annotations'
import { get } from 'lodash'
import { AnnotationDetailSideSheetProps } from 'domain/award/annotations/sidesheet/AnnotationDetailSideSheet/AnnotationDetailSideSheetProps'
import AttributeInfoItem from 'domain/general/boq/components/sidesheet/attributeInfos/AttributeInfoItem/AttributeInfoItem'

const AnnotationDetailSideSheet: FC<AnnotationDetailSideSheetProps> = ({
	boqAnnotation,
}) => {
	return (
		<div>
			{ANNOTATION_SIDESHEET_ATTRIBUTES_RENDERED.map((key: string) => {
				const element = get(boqAnnotation, key, null)
				if (element) {
					return (
						<AttributeInfoItem
							boqAnnotation={boqAnnotation}
							attributeKey={key as keyof BoqAnnotation}
						/>
					)
				}
				return null
			})}
		</div>
	)
}

export default AnnotationDetailSideSheet
