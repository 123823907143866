import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import TableChartIcon from '@material-ui/icons/TableChart'
import { KEY_ENTER, KEY_ESC } from 'app/constants'
import { useLoading } from 'app/hooks/index'
import { LotInputProps } from 'domain/lot/components/LotInput/LotInputProps'
import React, {
	ChangeEvent,
	FC,
	KeyboardEvent,
	useEffect,
	useState,
} from 'react'

import useStyles from 'domain/lot/components/LotInput/LotInputStyles'
import clsx from 'clsx'

const LotInput: FC<LotInputProps> = ({
	onAccept,
	onDecline,
	loading = true,
	className,
	defaultValue,
}) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [inputRef, setInputRef] = useState<any | null>(null)
	const [value, setValue] = useState<string | null>(null)
	const classes = useStyles()

	useEffect(() => {
		if (inputRef) {
			inputRef.focus()
			inputRef.select()
		}
	}, [inputRef])

	const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
		setValue(e.target.value)
	}

	const onKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
		if (e.keyCode === KEY_ENTER) onAccept(value)

		if (e.keyCode === KEY_ESC) onDecline(value)
	}

	return useLoading(
		() => (
			<div className={clsx(classes.root, className)}>
				<TableChartIcon className={classes.lotIcon} />
				<div className={classes.wrapper}>
					<InputBase
						className={`${classes.input} MuiTypography-button`}
						value={value}
						onChange={onChange}
						onKeyDown={onKeyDown}
						inputRef={ref => {
							setInputRef(ref)
						}}
						defaultValue={defaultValue}
					/>
					<IconButton
						className={classes.cancelIcon}
						onClick={() => onDecline(value)}
						disableRipple
					>
						<CloseIcon />
					</IconButton>
					<IconButton
						className={classes.acceptIcon}
						onClick={() => onAccept(value)}
						disableRipple
					>
						<DoneIcon />
					</IconButton>
				</div>
			</div>
		),
		loading
	)
}

export default LotInput
