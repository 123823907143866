import SideSheet from 'component/SideSheet/SideSheet'
import { initialState } from 'component/SideSheetProvider/initialState'
import reducer from 'component/SideSheetProvider/reducer'
import SideSheetContext from 'component/SideSheetProvider/SideSheetContext'
import { SideSheetCreateContextInterface } from 'component/SideSheetProvider/SideSheetCreateContextInterface'
import React, { FunctionComponent, useReducer } from 'react'

const SideSheetProvider: FunctionComponent = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState)
	const value: SideSheetCreateContextInterface = { state, dispatch }

	const { open } = state

	return (
		<SideSheetContext.Provider value={value}>
			<SideSheet open={open} />
			{children}
		</SideSheetContext.Provider>
	)
}

export default SideSheetProvider
