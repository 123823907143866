import { gql } from 'apollo-boost'

const createProject = gql`
	mutation createProject($name: String!) {
		createProject(name: $name) {
			uuid
			__typename
		}
	}
`

const updateProject = gql`
	mutation updateProject($projectUuid: String!, $name: String!) {
		updateProject(uuid: $projectUuid, name: $name) {
			uuid
			name
		}
	}
`

export default {
	createProject,
	updateProject,
}
