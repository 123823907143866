import { STRIPE_PLAN, subscriptionPlans } from 'app/config/subscriptionPlans'
import { SubscriptionPlan } from 'domain/checkout/interfaces/SubscriptionPlan'

export const getSubscriptionPlanByCode = (
	code: STRIPE_PLAN
): SubscriptionPlan | null => {
	return (
		subscriptionPlans.find(subscriptionPlan => {
			return subscriptionPlan.code === code
		}) || null
	)
}
