import { POSITION_TYPES } from 'app/types/POSITION_TYPES'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'

export enum QUANTITY_DETERMINATION_STATUS {
	INITIAL = 'initial',
	EDITED = 'edited',
	SUBMITTED = 'submitted',
	REVIEWED = 'reviewed',
	ACCEPTED = 'accepted',
	COMPLETED = 'completed',
}

export enum QUANTITY_DETERMINATION_NODE_STATUS {
	ACCEPTED = 'accepted',
	DECLINED = 'declined',
	PENDING = 'pending',
	TEMPORARY = 'temporary',
}

export interface QuantityDeterminationNode {
	uuid: string
	status: QUANTITY_DETERMINATION_NODE_STATUS | null
	lin: string | null
	type: POSITION_TYPES
	shortText: string | null
	unit: string | null
	formula: string | null
	qty: number | null
	quantityDeterminationNodes?: QuantityDeterminationNode[]
}

export interface QuantityDetermination {
	uuid: string
	status: QUANTITY_DETERMINATION_STATUS
	name: string
	date: Date
}

export interface QuantityDeterminationPreflight {
	success: boolean
	failures: PreflightFailure[]
}

export interface PreflightFailure {
	lin: string
	name: string
}

export const NOTICE_CONDITIONS = {
	[ROLES_PROJECT.BIDDER]: {
		validQuantityDeterminationStatus: [
			QUANTITY_DETERMINATION_STATUS.SUBMITTED,
			QUANTITY_DETERMINATION_STATUS.ACCEPTED,
			QUANTITY_DETERMINATION_STATUS.COMPLETED,
		],
	},
	[ROLES_PROJECT.LEAD]: {
		validQuantityDeterminationStatus: [
			QUANTITY_DETERMINATION_STATUS.ACCEPTED,
			QUANTITY_DETERMINATION_STATUS.REVIEWED,
			QUANTITY_DETERMINATION_STATUS.COMPLETED,
		],
	},
}
