import React, { FC } from 'react'
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { MAX_QUANTITY } from 'app/config/checkout'
import { useTranslation } from 'react-i18next'
import { QuantityDropdownProps } from './QuantityDropdownProps'
import useStyles from './QuantityDropdownStyles'

const QuantityDropdown: FC<QuantityDropdownProps> = ({
	quantity,
	onChange,
}) => {
	const classes = useStyles()
	const { t } = useTranslation()

	return (
		<FormControl className={classes.formControl}>
			<InputLabel id="quantityInputLabel">
				{t('domain:checkout.selectQuantity.inputLabel')}
			</InputLabel>
			<Select
				labelId="quantityInputLabel"
				id="quantity"
				name={t('domain:checkout.selectQuantity.name')}
				value={quantity}
				onChange={onChange}
			>
				{[...Array(MAX_QUANTITY)].map((element, index) => {
					const value = `${index + 1}`
					return (
						<MenuItem key={value} value={value}>
							{value}
						</MenuItem>
					)
				})}
			</Select>
		</FormControl>
	)
}

export default QuantityDropdown
