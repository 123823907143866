/* eslint-disable @typescript-eslint/no-explicit-any */

import UpBaseFormatter from 'app/services/tabulator/formatters/up/UpBaseFormatter'
import TabulatorHelper from 'app/services/tabulator/TabulatorHelper'
import { BoqNode } from 'app/types/graphql/BoqNode'
import { POSITION_TYPES } from 'app/types/POSITION_TYPES'
import { isNumber, toNumber } from 'lodash'

interface CellFormatterInterface {
	isValid: () => boolean
}

class AlternativeFormatter extends UpBaseFormatter
	implements CellFormatterInterface {
	private _baseRow: any

	private _siblings: any[] = []

	get baseRow(): any {
		return this._baseRow
	}

	set baseRow(value: any) {
		this._baseRow = value
	}

	get siblings(): any[] {
		return this._siblings
	}

	set siblings(value: any[]) {
		this._siblings = value
	}

	addSibling(row: any): void {
		this.siblings.push(row)
	}

	findCorrelatedRows(): void {
		this.baseRow = TabulatorHelper.findBaseRow(this.cell.getRow())
		this.siblings = TabulatorHelper.findAlternativeRows(this.baseRow)
	}

	constructor(cell: any) {
		super(cell, POSITION_TYPES.ALTERNATIVE)

		this.findCorrelatedRows()
	}

	isValid(): boolean {
		const value = this.cell.getValue()

		const hasAtLeastOneValue = this.siblings.reduce((prev, row) => {
			const { up } = row.getData() as BoqNode

			if (toNumber(up) > 0) {
				return true
			}

			return prev
		}, false)

		if (hasAtLeastOneValue) {
			return true
		}

		if (!value || !this.baseRow || this.siblings.length < 1) {
			return false
		}

		if (!isNumber(value)) {
			return false
		}

		return !(isNumber(value) && toNumber(value) >= 0)
	}

	format(): string | number {
		if (!this.isValid()) {
			this.markAsInvalid()
		} else {
			this.markAsValid()
		}

		return super.format()
	}
}

export default AlternativeFormatter
