import TextField from '@material-ui/core/TextField'
import AutoComplete from '@material-ui/lab/Autocomplete'
import { RenderInputParams } from '@material-ui/lab/Autocomplete/Autocomplete'
import useDebounce from 'app/hooks/useDebounce'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import { getTagsFromContacts } from 'app/utils/apollo'
import { filterArrayByArray } from 'app/utils/array'
import Tag from 'component/Tag/Tag'
import queries from 'domain/tender/bidders/apollo/queries'
import { TagFilterProps } from 'domain/tender/bidders/components/body/filtering/TagFilter/TagFilterProps'
import { ContactTag } from 'domain/tender/bidders/types/ContactTag'
import React, { ChangeEvent, FC, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// custom Styles MUST be imported last to ensure style heredity
import useStyles from 'domain/tender/bidders/components/body/filtering/TagFilter/TagFilterStyles'

const TagFilter: FC<TagFilterProps> = ({
	onSelect,
	onDelete,
	selectedTags,
}) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const [searchTerm, setSearchTerm] = useState<string>('')
	const debouncedSearchTerm = useDebounce(searchTerm, 300)

	const [fetchTags, { data = [], loading }] = useGraphQLLazyQuery(
		queries.TagsByFilter,
		{
			fetchPolicy: 'cache-and-network',
		}
	)

	useEffect(() => {
		fetchTags({
			variables: {
				findAppliedOnly: true,
				pattern: searchTerm || '',
			},
		})
	}, [selectedTags, debouncedSearchTerm])

	const tags = getTagsFromContacts(data)

	const renderSelectedTags = (): ReactNode => {
		return selectedTags.map(tag => {
			return (
				<Tag
					color="primary"
					classes={{
						root: classes.tagRoot,
					}}
					key={tag.uuid}
					label={tag.label}
					onDelete={() => onDelete(tag)}
				/>
			)
		})
	}

	return (
		<div className={classes.root}>
			<AutoComplete
				autoSelect
				autoHighlight
				loading={loading}
				id="filter-contact-tags"
				options={filterArrayByArray(tags, selectedTags)}
				onInputChange={(event, value) => {
					setSearchTerm(value)
				}}
				onOpen={() => fetchTags()}
				onChange={(e: ChangeEvent<{}>, tag: ContactTag | null) => onSelect(tag)}
				getOptionLabel={(tag: ContactTag) => tag.label}
				className={classes.autocomplete}
				clearOnEscape
				disableClearable
				renderInput={(params: RenderInputParams) => (
					<TextField
						{...params}
						label={t('domain:tender.filter.tag.autocomplete.placeholder')}
						variant="outlined"
						fullWidth
					/>
				)}
			/>
			{renderSelectedTags()}
		</div>
	)
}

export default TagFilter
