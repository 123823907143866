import React, { FC } from 'react'
import { DrawerMenuItemProps } from 'app/config/drawerMenu'
import { ListItem, ListItemText } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import useStyles from './DrawerMenuItemHeadlineStyles'

const DrawerMenuItemHeadline: FC<DrawerMenuItemProps> = ({ configuration }) => {
	const { t } = useTranslation()
	const classes = useStyles()

	return (
		<ListItem classes={{ root: classes.root }} disabled>
			<ListItemText classes={{ primary: classes.text }}>
				{t(configuration.title)}
			</ListItemText>
		</ListItem>
	)
}

export default DrawerMenuItemHeadline
