import { Typography } from '@material-ui/core'
import { addSupTag } from 'app/utils/tabulator'
import clsx from 'clsx'
import AttributeInfoItemHeadline from 'domain/general/boq/components/sidesheet/attributeInfos/AttributeInfoItemHeadline/AttributeInfoItemHeadline'
import { DefaultInfoItemProps } from 'domain/general/boq/components/sidesheet/attributeInfos/DefaultInfoItem/DefaultInfoItemProps'
import React, { FC, ReactElement } from 'react'

import useStyles from './DefaultInfoItemStyles'

const DefaultInfoItem: FC<DefaultInfoItemProps> = ({
	headline,
	value,
	classes: parentClasses = {},
}) => {
	const classes = useStyles()

	const renderHeadline = (): ReactElement | null => {
		return (
			<AttributeInfoItemHeadline
				classes={{ root: clsx(classes.headline, parentClasses.headline) }}
				text={headline}
			/>
		)
	}

	const renderContent = (): ReactElement | null => {
		return (
			<Typography
				classes={{
					root: clsx(classes.content, parentClasses.content),
				}}
				dangerouslySetInnerHTML={{
					__html:
						// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
						// @ts-ignore
						addSupTag(value),
				}}
			/>
		)
	}

	return (
		<>
			{renderHeadline()}
			{renderContent()}
		</>
	)
}
export default DefaultInfoItem
