import { makeStyles } from '@material-ui/core'

export default makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: 20,
	},
	bidderName: {
		margin: '0 0 0 20px',
		alignSelf: 'center',
	},
})
