const routesApi = {
	uploadGaebCreateRevision: 'api/v1/projects/:projectUuid/lots/:lotUuid',
	uploadGaebUpdateRevision:
		'api/v1/projects/:projectUuid/lots/:lotUuid/revision/:revisionUuid',
	uploadLotAttachment: 'api/v1/projects/:projectUuid/lots/:lotUuid/attachments',
	uploadLotAttachmentForParent:
		'api/v1/projects/:projectUuid/lots/:lotUuid/attachments/:parentUuid',
	downloadGaeb: 'api/v1/projects/:projectUuid/revisions/:revisionUuid/download',
	tracking: 'trk/evt',
}

export default routesApi
