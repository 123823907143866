import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
	root: {},
	active: {
		fontWeight: 'bold',
		marginRight: theme.spacing(1),
	},
	clickable: {
		cursor: 'pointer',
		textDecoration: 'underline',
		fontWeight: 'bold',
	},
}))
