/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	getLotFromProject,
	getQuantityDeterminationsFromLot,
	getProjectFromResponse,
	getRoleFromProject,
} from 'app/utils/apollo'
import { resolveRoute } from 'app/utils/route'
import React, { FC, ReactElement } from 'react'
import { UrlParams } from 'app/types/UrlParams'
import Table from 'component/Table/Table'
import { useTranslation } from 'react-i18next'
import { reactFormatter } from 'react-tabulator'
import { useParams, useHistory } from 'react-router-dom'
import routes from 'app/routing/routes/routesApp'
import QuantityDeterminationQuantityFormatter from 'app/services/tabulator/formatters/QuantityDeterminationQuantityFormatter'
import MomentUtils from '@date-io/moment'
import { DATE_TIME_FORMAT } from 'app/config/locales'
import QuantityDeterminationStatusFormatter from 'app/services/tabulator/formatters/QuantityDeterminationStatusFormatter/QuantityDeterminationStatusFormatter'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'
import PageContentParagraph from 'component/PageContentParagraph/PageContentParagraph'
import TableQuantityDeterminationsProps from './TableQuantityDeterminationsProps'

const TableQuantityDetermination: FC<TableQuantityDeterminationsProps> = ({
	data,
	loading,
}) => {
	const { projectUuid, lotUuid, sectionId, subSectionId } = useParams<
		UrlParams
	>()
	const { t } = useTranslation()
	const { push } = useHistory()

	const project = getProjectFromResponse(data)
	const role = getRoleFromProject(project)
	const lot = getLotFromProject(project)
	const quantityDeterminationsData = getQuantityDeterminationsFromLot(lot)

	const isQuantityDeterminationsTableEmpty =
		quantityDeterminationsData && quantityDeterminationsData.length === 0

	const handleEmptyState = (): ReactElement | null => {
		if (role === ROLES_PROJECT.LEAD) {
			return (
				<PageContentParagraph>
					{t('domain:billing.tableQuantityDeterminations.emptyState')}
				</PageContentParagraph>
			)
		}
		return null
	}

	const handleClick = (e: MouseEvent, uuid: string): void => {
		push(
			resolveRoute(
				routes.subSection,
				{
					projectUuid,
					lotUuid,
					sectionId,
					subSectionId,
				},
				{
					qduuid: uuid,
				}
			)
		)
	}

	const dataColumns: any = [
		{
			field: 'name',
			title: t('domain:billing.tableQuantityDeterminations.name.title'),
			width: 800,
		},
		{
			field: 'createdAt',
			title: t('domain:billing.tableQuantityDeterminations.createdAt.title'),
			formatter: (cell: any) => {
				return `${new MomentUtils()
					.date(cell.getValue())
					.format(DATE_TIME_FORMAT)}`
			},
			minWidth: 150,
		},
		{
			field: 'status',
			title: t('domain:billing.tableQuantityDeterminations.status.title'),
			formatter: reactFormatter(
				<QuantityDeterminationStatusFormatter role={role} />
			),
			minWidth: 130,
		},
		{
			field: 'quantity',
			title: t('domain:billing.tableQuantityDeterminations.quantity.title'),
			formatter: reactFormatter(
				<QuantityDeterminationQuantityFormatter
					onClick={handleClick}
					role={role}
				/>
			),
			minWidth: 120,
		},
	]

	return (
		<>
			{isQuantityDeterminationsTableEmpty ? (
				handleEmptyState()
			) : (
				<Table
					loading={loading}
					columns={dataColumns}
					data={quantityDeterminationsData || []}
					options={{
						...{
							headerSort: false,
						},
					}}
				/>
			)}
		</>
	)
}

export default TableQuantityDetermination
