import { useMutation } from '@apollo/react-hooks'
import { useStripe } from '@stripe/react-stripe-js'
import { CHECKOUTDATA_KEYS, STEPS_CHECKOUT } from 'app/config/checkout'
import { InvoiceItem } from 'app/types/InvoiceItem'
import { resolveRoute } from 'app/utils/route'
import CheckboxValidator from 'component/CheckboxValidator/CheckboxValidator'
import InvoiceTable from 'component/InvoiceTable/InvoiceTable'
import Link from 'component/Link/Link'
import RenderErrorMessage from 'component/RenderErrorMessage/RenderErrorMessage'
import mutations from 'domain/checkout/apollo/mutations'
import CheckoutCard from 'domain/checkout/components/CheckoutCard/CheckoutCard'
import { CheckoutStepProps } from 'domain/checkout/interfaces/CheckoutStepProps'
import { Subscription } from 'domain/checkout/interfaces/Subscription'
import { get, invert } from 'lodash'
import React, {
	FC,
	useState,
	useEffect,
	ReactElement,
	ChangeEvent,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routes from 'app/routing/routes/routesApp'
import useTracking from 'app/services/datawarehouse/useTracking'
import TrackingEvents from 'app/services/datawarehouse/TrackingEvents'
import { StripeError } from '@stripe/stripe-js'
import { ApolloError } from 'apollo-boost'

const CardPaymentConfirm: FC<CheckoutStepProps> = props => {
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<StripeError | ApolloError | null>()

	const stripe = useStripe()
	const { t } = useTranslation()
	const { track } = useTracking()
	const {
		checkoutData,
		handleInputChange,
		goToNextStep,
		createFormDataFromObject,
		classes = {},
		stepId,
	} = props

	const [
		createSubscription,
		{ data },
	] = useMutation(mutations.createSubscription, { onError: setError })

	const paymentMethodId = get(checkoutData, CHECKOUTDATA_KEYS.PAYMENT, null)
	const quantity = get(checkoutData, CHECKOUTDATA_KEYS.QUANTITY, null)
	const pricingPlanCode = get(
		checkoutData,
		CHECKOUTDATA_KEYS.PRICING_PLAN,
		null
	)

	useEffect(() => {
		const isPaymentSuccessful = get(
			checkoutData,
			CHECKOUTDATA_KEYS.STEP_PAYMENT_CONFIRM_COMPLETED,
			false
		)

		if (isPaymentSuccessful === true && goToNextStep) {
			goToNextStep()
		}
	}, [checkoutData])

	useEffect(() => {
		if (error) {
			setLoading(false)
		}
	}, [error])

	useEffect(() => {
		if (data) {
			const subscriptionData = get(
				data,
				'createSubscription',
				null
			) as Subscription | null

			if (subscriptionData) {
				const {
					paymentIntentStatus,
					clientSecret,
					setupIntentStatus,
				} = subscriptionData
				if (
					paymentIntentStatus === 'requires_action' ||
					paymentIntentStatus === 'requires_payment_method'
				) {
					if (stripe && clientSecret) {
						stripe
							.confirmCardPayment(clientSecret)
							.then(result => {
								if (result.error) {
									setError(result.error)
								} else if (createFormDataFromObject) {
									createFormDataFromObject({
										...checkoutData,
										[CHECKOUTDATA_KEYS.STEP_PAYMENT_CONFIRM_COMPLETED]: true,
									})
								}
							})
							.catch(e => {
								setError(e)
							})
					}
				}

				if (setupIntentStatus === 'requires_action') {
					if (stripe && clientSecret) {
						stripe
							.confirmCardSetup(clientSecret)
							.then(result => {
								if (result.error) {
									setError(result.error)
								} else if (createFormDataFromObject) {
									createFormDataFromObject({
										...checkoutData,
										[CHECKOUTDATA_KEYS.STEP_PAYMENT_CONFIRM_COMPLETED]: true,
									})
								}
							})
							.catch(e => {
								setError(e)
							})
					}
				} else if (createFormDataFromObject) {
					createFormDataFromObject({
						...checkoutData,
						[CHECKOUTDATA_KEYS.STEP_PAYMENT_CONFIRM_COMPLETED]: true,
					})
					setLoading(false)
				}
			} else {
				setLoading(false)
			}
		}
	}, [data])

	const onSubmit = (): void => {
		setLoading(true)

		createSubscription({
			variables: {
				paymentMethodId,
				pricingPlanCode,
				quantity,
			},
		})
	}

	const handleTrackOnClick = (): void => {
		const stepKey = stepId && invert(STEPS_CHECKOUT)[stepId]
		track(`${stepKey}_AGBVISIT` as keyof typeof TrackingEvents, checkoutData)
	}

	const createInvoiceItems = (): InvoiceItem[] => {
		if (!quantity || !pricingPlanCode) {
			return []
		}
		return [{ quantity, pricingPlanCode }]
	}

	const createAnotherAction = (): ReactElement => {
		return (
			<Link
				to={resolveRoute(routes.checkoutSteps, {
					stepId: STEPS_CHECKOUT.STEP_PAYMENT_ENTER,
				})}
			>
				{t('domain:checkout.paymentConfirmation.button.goBack.label')}
			</Link>
		)
	}

	const handleTrackAndInputChange = (event: ChangeEvent): void => {
		if (handleInputChange) {
			handleInputChange(event)
			const stepKey = stepId && invert(STEPS_CHECKOUT)[stepId]
			track(`${stepKey}_AGBENTER` as keyof typeof TrackingEvents, checkoutData)
		}
	}

	return (
		<CheckoutCard
			{...props}
			classes={classes}
			buttonLabel={t('domain:checkout.paymentConfirmation.form.submit.label')}
			headline={t('domain:checkout.paymentConfirmation.card.title')}
			onSubmit={onSubmit}
			loading={loading}
			disabled={!pricingPlanCode}
			anotherAction={createAnotherAction}
		>
			<InvoiceTable items={createInvoiceItems()} />
			<CheckboxValidator
				label={
					<Trans
						defaults="domain:checkout.paymentConfirmation.form.termsAndConditions.label"
						values={{
							linkTitle: t(
								`domain:checkout.paymentConfirmation.form.termsAndConditions.linkTitle`
							),
						}}
						components={[
							<Link
								onClick={handleTrackOnClick}
								target="_blank"
								to={t('domain:checkout.legal.termsAndConditions.url')}
							>
								linkTitle
							</Link>,
						]}
					/>
				}
				name="termsAndConditions"
				onChange={handleTrackAndInputChange}
				value={get(checkoutData, 'termsAndConditions', false)}
				validators={['isTruthy']}
				errorMessages={[
					t(
						'domain:checkout.paymentConfirmation.form.termsAndConditions.validatorViolations.isChecked'
					),
				]}
			/>
			<RenderErrorMessage error={error} />
		</CheckoutCard>
	)
}

export default CardPaymentConfirm
