import Menu from '@material-ui/core/Menu'
import { headerMenu } from 'app/config/menus'
import MenuItem from 'component/MenuItem/MenuItem'
import { ProfileMenuProps } from 'domain/header/components/profileMenu/ProfileMenu/ProfileMenuProps'
import ProfileMenuItem from 'domain/header/components/profileMenu/ProfileMenuItem/ProfileMenuItem'
import React, { FC } from 'react'

import useStyles from 'domain/header/components/profileMenu/ProfileMenu/ProfileMenuStyles'

const ProfileMenu: FC<ProfileMenuProps> = ({
	open,
	anchorEl,
	onClose,
	onDbReset,
}) => {
	const classes = useStyles()

	return (
		<Menu
			id="menu-appbar"
			classes={{
				list: classes.root,
			}}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={open}
			onClose={onClose}
		>
			{headerMenu.map(item => (
				<MenuItem
					key={item.id}
					item={item}
					component={ProfileMenuItem}
					onDbReset={onDbReset}
				/>
			))}
		</Menu>
	)
}

export default ProfileMenu
