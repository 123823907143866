import { UrlParams } from 'app/types/UrlParams'
import { MenuItemProps } from 'component/MenuItem/MenuItemProps'
import Validate from 'component/Validate/Validate'
import React, { FC, ReactElement, forwardRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'

const MenuItem: FC<MenuItemProps> = (
	{ component, item, selected, onClick, onCompleted, onDbReset },
	// @TODO this removes the console error, needed for future implementation of ref (see below)
	// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
	ref
): ReactElement | null => {
	const { lotUuid } = useParams<UrlParams>()
	const { push } = useHistory()

	let Component = component

	if (item.component) {
		Component = item.component
	}

	const customOnClick = (): void => {
		if (item.url) {
			push(item.url)
		}
	}

	const renderFallback = (): ReactElement | null => {
		if (item.disabled) {
			return <Component item={item} disabled />
		}

		return null
	}

	if (item.hideOnMissingLotUuid && lotUuid === undefined) {
		return null
	}

	return (
		<Validate
			fallback={renderFallback}
			combinationOperator={item.combinationOperator}
			validCombinations={item.validCombinations}
			onCompleted={onCompleted}
			disableLoader
		>
			<Component
				// @TODO implement if we need the ref for the profile menu
				// ref={ref}
				item={item}
				selected={selected}
				onClick={onClick || customOnClick}
				onDbReset={onDbReset}
			/>
		</Validate>
	)
}
const forwardMenuItem = forwardRef(MenuItem)
export default forwardMenuItem
