/* eslint-disable @typescript-eslint/no-explicit-any */
import { getBiddersFromRevision } from 'app/utils/apollo'
import { useMemo } from 'react'
import { toNumber } from 'lodash'

import { TABLE_FIELDS_BOQ } from 'app/constants'
import {
	BottomCalcFormatter,
	CellFormatterAwardPrice,
	MeterUnitFormatter,
	toggleTree,
} from 'app/services/tabulator'
import { DataRevision } from 'app/types'
import { useTranslation } from 'react-i18next'
import useEvents from './useEvents'

const calculateSumForBidderFromBoqNode = (
	node: any,
	bidderKey: string,
	total: number
): number => {
	if (Array.isArray(node)) {
		return node.reduce((prev, value) => {
			if (value[bidderKey]) {
				return prev + toNumber(value[bidderKey]) * toNumber(value.qty)
			}

			return calculateSumForBidderFromBoqNode(value.boqNodes, bidderKey, prev)
		}, total)
	}

	return total
}

const calulator = (
	values: any,
	data: any,
	calcParams: any,
	bidderUuid: string
): number => {
	return calculateSumForBidderFromBoqNode(data, bidderUuid, 0)
}

export default (
	isEditable: boolean,
	isVisible: boolean,
	revision: DataRevision | null,
	isAwarded: boolean
): any => {
	const { t } = useTranslation()
	const { onClickCellLabel } = useEvents(revision)
	const allBidders = getBiddersFromRevision(revision)

	return useMemo(
		() => [
			{
				field: TABLE_FIELDS_BOQ.LIN,
				title: t('common:tabulator.columns.lin'),
				width: 120,
				cellClick: toggleTree,
			},
			{
				field: TABLE_FIELDS_BOQ.LABEL,
				title: t('common:tabulator.columns.label'),
				widthGrow: 3,
				cellClick: onClickCellLabel,
				formatter: MeterUnitFormatter,
			},
			{
				field: TABLE_FIELDS_BOQ.QU,
				title: t('common:tabulator.columns.qu'),
				formatter: MeterUnitFormatter,
			},
			{
				field: TABLE_FIELDS_BOQ.QTY,
				title: t('common:tabulator.columns.qty'),
				align: 'right',
			},
			// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
			// @ts-ignore
			...allBidders.map(bidder => {
				const awardInfoParams = {
					isBidderAwarded: bidder.isAwarded,
					isAwardProcessOver: isAwarded,
				}
				return {
					field: bidder.uuid,
					title: bidder.name,

					formatterParams: awardInfoParams,
					bottomCalcFormatterParams: awardInfoParams,
					bottomCalc: (values: any, data: any, calcParams: any) =>
						calulator(values, data, calcParams, bidder.uuid),
					bottomCalcFormatter: BottomCalcFormatter,
					formatter: CellFormatterAwardPrice,
					align: 'right',
				}
			}),
		],
		[isVisible, isAwarded]
	)
}
