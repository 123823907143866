import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import { KEY_ESC, KEY_ENTER } from 'app/constants'
import { ActionInputProps } from 'component/ActionInput/ActionInputProps'
import Loading from 'component/Loading/Loading'
import React, { KeyboardEvent, useState, FC, useEffect } from 'react'
import { isString } from 'lodash'

import useStyles from 'component/ActionInput/ActionInputStyles'

const ActionInput: FC<ActionInputProps> = ({
	value,
	name,
	onChange,
	onAccept,
	onAbort,
	acceptOnEnter,
	abortOnEscape,
	focusAndSelect,
	defaultValue,
	Icon,
	loading = false,
}) => {
	const classes = useStyles()

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [inputRef, setInputRef] = useState<any | null>(null)

	useEffect(() => {
		if (focusAndSelect && inputRef) {
			inputRef.focus()
			inputRef.select()
		}
	}, [inputRef, focusAndSelect])

	const onKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
		if (isString(value)) {
			if (acceptOnEnter && e.keyCode === KEY_ENTER) {
				onAccept(value || '')
			}

			if (abortOnEscape && e.keyCode === KEY_ESC) {
				onAbort(value || '')
			}
		}
	}

	return (
		<Loading loading={loading}>
			<div className={classes.root}>
				{Icon && <Icon className={classes.lotIcon} />}
				<div className={classes.wrapper}>
					<InputBase
						name={name}
						className={`${classes.input} MuiTypography-button`}
						value={value}
						onChange={onChange}
						onKeyDown={onKeyDown}
						inputRef={ref => {
							setInputRef(ref)
						}}
						defaultValue={defaultValue}
					/>
					<IconButton
						className={classes.cancelIcon}
						onClick={() => onAbort(value || '')}
						disableRipple
					>
						<CloseIcon />
					</IconButton>
					<IconButton
						className={classes.acceptIcon}
						onClick={() => onAccept(value || '')}
						disableRipple
					>
						<DoneIcon />
					</IconButton>
				</div>
			</div>
		</Loading>
	)
}

export default ActionInput
