import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(1),
	},
	icon: {
		fontSize: '120%',
	},
}))
