export enum STEPS_CHECKOUT {
	STEP_PRICING_PLAN = 'pricing-plan',
	STEP_EMAIL_ENTER = 'email',
	STEP_EMAIL_VERIFICATION = 'email-verification',
	STEP_USER_DATA_ENTER = 'user-data',
	STEP_COMPANY_DATA_ENTER = 'company-data',
	STEP_PASSWORD_ENTER = 'password',
	STEP_PAYMENT_ENTER = 'payment',
	STEP_PAYMENT_CONFIRM = 'payment-confirmation',
	STEP_PAYMENT_SUCCESS = 'payment-successful',
}

export const FORMAT_PRICE = '0,0.00 $'

export const CHECKOUT_DEFAULT_STEP = STEPS_CHECKOUT.STEP_PRICING_PLAN

export const TOKEN_DIGIT_LENGTH = 6

export const PAYMENT_SUCCESSFULL_REDIRECT_INTERVAL_IN_SECONDS = 6

export const MAX_QUANTITY = 10

export const EMAIL_ALREADY_REGISTERED_CODE = 101004

type StepValidator = {
	[key in STEPS_CHECKOUT]: string[]
}

export interface CheckoutValidationRule {
	stepId: STEPS_CHECKOUT
	requiredDataKeys: string[]
	skipIfAuthenticated: boolean
	requiresAuthentication?: boolean
}

export enum CHECKOUTDATA_KEYS {
	PRICING_PLAN = 'chosenPlan',
	QUANTITY = 'quantity',
	EMAIL = 'email',
	TOKEN = 'token',
	FIRSTNAME = 'firstName',
	LASTNAME = 'lastName',
	COMPANY = 'company',
	PHONE = 'phone',
	STREET = 'street',
	ZIP_CODE = 'zipCode',
	CITY = 'city',
	PAYMENT = 'payment',
	STEP_EMAIL_ENTER_COMPLETED = 'emailEnterCompleted',
	STEP_EMAIL_VERIFICATION_COMPLETED = 'emailVerificationCompleted',
	STEP_USER_DATA_ENTER_COMPLETED = 'userDataEnterCompleted',
	STEP_COMPANY_DATA_ENTER_COMPLETED = 'companyDataEnterCompleted',
	STEP_PAYMENT_CONFIRM_COMPLETED = 'paymentConfirmCompleted',
}

const REQUIRED_CHECKOUTDATA_KEYS_FOR_STEP_PRICING_PLAN: string[] = []
const REQUIRED_CHECKOUTDATA_KEYS_FOR_STEP_EMAIL_ENTER = [
	CHECKOUTDATA_KEYS.PRICING_PLAN,
	CHECKOUTDATA_KEYS.QUANTITY,
]
const REQUIRED_CHECKOUTDATA_KEYS_FOR_STEP_EMAIL_VERIFICATION = [
	...REQUIRED_CHECKOUTDATA_KEYS_FOR_STEP_EMAIL_ENTER,
	CHECKOUTDATA_KEYS.EMAIL,
	CHECKOUTDATA_KEYS.STEP_EMAIL_ENTER_COMPLETED,
]
const REQUIRED_CHECKOUTDATA_KEYS_FOR_STEP_USER_DATA_ENTER = [
	...REQUIRED_CHECKOUTDATA_KEYS_FOR_STEP_EMAIL_VERIFICATION,
	CHECKOUTDATA_KEYS.STEP_EMAIL_VERIFICATION_COMPLETED,
	CHECKOUTDATA_KEYS.TOKEN,
]
const REQUIRED_CHECKOUTDATA_KEYS_FOR_STEP_COMPANY_DATA_ENTER = [
	...REQUIRED_CHECKOUTDATA_KEYS_FOR_STEP_USER_DATA_ENTER,
	CHECKOUTDATA_KEYS.STEP_USER_DATA_ENTER_COMPLETED,
	CHECKOUTDATA_KEYS.FIRSTNAME,
	CHECKOUTDATA_KEYS.LASTNAME,
	CHECKOUTDATA_KEYS.COMPANY,
	CHECKOUTDATA_KEYS.PHONE,
]
const REQUIRED_CHECKOUTDATA_KEYS_FOR_STEP_PASSWORD_ENTER = [
	...REQUIRED_CHECKOUTDATA_KEYS_FOR_STEP_COMPANY_DATA_ENTER,
	CHECKOUTDATA_KEYS.STEP_COMPANY_DATA_ENTER_COMPLETED,
	CHECKOUTDATA_KEYS.STREET,
	CHECKOUTDATA_KEYS.ZIP_CODE,
	CHECKOUTDATA_KEYS.CITY,
]

const REQUIRED_CHECKOUTDATA_KEYS_FOR_STEP_PAYMENT_ENTER = [
	CHECKOUTDATA_KEYS.PRICING_PLAN,
	CHECKOUTDATA_KEYS.QUANTITY,
]
const REQUIRED_CHECKOUTDATA_KEYS_FOR_STEP_PAYMENT_CONFIRM = [
	CHECKOUTDATA_KEYS.PRICING_PLAN,
	CHECKOUTDATA_KEYS.PAYMENT,
]
const REQUIRED_CHECKOUTDATA_KEYS_FOR_STEP_PAYMENT_SUCCESSFUL = [
	CHECKOUTDATA_KEYS.PRICING_PLAN,
	CHECKOUTDATA_KEYS.STEP_PAYMENT_CONFIRM_COMPLETED,
]

export const CHECKOUT_VALIDATION_RULES: CheckoutValidationRule[] = [
	{
		stepId: STEPS_CHECKOUT.STEP_PRICING_PLAN,
		requiredDataKeys: REQUIRED_CHECKOUTDATA_KEYS_FOR_STEP_PRICING_PLAN,
		skipIfAuthenticated: false,
	},
	{
		stepId: STEPS_CHECKOUT.STEP_EMAIL_ENTER,
		requiredDataKeys: REQUIRED_CHECKOUTDATA_KEYS_FOR_STEP_EMAIL_ENTER,
		skipIfAuthenticated: true,
	},
	{
		stepId: STEPS_CHECKOUT.STEP_EMAIL_VERIFICATION,
		requiredDataKeys: REQUIRED_CHECKOUTDATA_KEYS_FOR_STEP_EMAIL_VERIFICATION,
		skipIfAuthenticated: true,
	},
	{
		stepId: STEPS_CHECKOUT.STEP_USER_DATA_ENTER,
		requiredDataKeys: REQUIRED_CHECKOUTDATA_KEYS_FOR_STEP_USER_DATA_ENTER,
		skipIfAuthenticated: true,
	},
	{
		stepId: STEPS_CHECKOUT.STEP_COMPANY_DATA_ENTER,
		requiredDataKeys: REQUIRED_CHECKOUTDATA_KEYS_FOR_STEP_COMPANY_DATA_ENTER,
		skipIfAuthenticated: true,
	},
	{
		stepId: STEPS_CHECKOUT.STEP_PASSWORD_ENTER,
		requiredDataKeys: REQUIRED_CHECKOUTDATA_KEYS_FOR_STEP_PASSWORD_ENTER,
		skipIfAuthenticated: true,
	},
	{
		stepId: STEPS_CHECKOUT.STEP_PAYMENT_ENTER,
		requiredDataKeys: REQUIRED_CHECKOUTDATA_KEYS_FOR_STEP_PAYMENT_ENTER,
		skipIfAuthenticated: false,
		requiresAuthentication: true,
	},
	{
		stepId: STEPS_CHECKOUT.STEP_PAYMENT_CONFIRM,
		requiredDataKeys: REQUIRED_CHECKOUTDATA_KEYS_FOR_STEP_PAYMENT_CONFIRM,
		skipIfAuthenticated: false,
		requiresAuthentication: true,
	},
	{
		stepId: STEPS_CHECKOUT.STEP_PAYMENT_SUCCESS,
		requiredDataKeys: REQUIRED_CHECKOUTDATA_KEYS_FOR_STEP_PAYMENT_SUCCESSFUL,
		skipIfAuthenticated: false,
		requiresAuthentication: true,
	},
]
