import Button from 'component/Button/Button'
import { PrimaryButtonProps } from 'component/PrimaryButton/PrimaryButtonProps'
import React, { FC } from 'react'

const PrimaryButton: FC<PrimaryButtonProps> = ({
	children,
	className,
	...rest
}) => {
	return (
		<Button {...rest} className={className} color="primary" variant="contained">
			{children}
		</Button>
	)
}

export default PrimaryButton
