import { gql } from 'apollo-boost'

const LotWithLeadInfo = gql`
	query LotWithLeadInfo($projectUuid: String!, $lotUuid: String!) {
		project(uuid: $projectUuid) {
			uuid
			role
			lot(uuid: $lotUuid) {
				uuid
				status
				dataRevisions {
					data {
						accesses
						uuid
						status
						leadInfo {
							address {
								name1
								name2
								name3
								name4
								street
								zipCode
								city
								country
								ILN
								contact
								phone
								fax
								email
								VatId
								bank {
									name
									BLZ
									accountNo
									IBAN
								}
							}
							vendorNumber
							awardNumber
							accountRecNumber
							country
						}
					}
				}
			}
		}
	}
`

export default { LotWithLeadInfo }
