import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'
import { ById } from 'app/types'
import {
	QUANTITY_DETERMINATION_STATUS,
	QUANTITY_DETERMINATION_NODE_STATUS,
} from './quantityDetermination'

export enum TABULATOR_CHIP_VARIANTS {
	SUCCESS = 'success',
	ERROR = 'error',
	DEFAULT = 'default',
	WARNING = 'warning',
	PRIMARY = 'primary',
}

interface QuantityDeterminationStatusConfigProps {
	[key: string]: { [key: string]: TABULATOR_CHIP_VARIANTS }
}

export const QUANTITY_DETERMINATION_STATUS_CONFIG: QuantityDeterminationStatusConfigProps = {
	[ROLES_PROJECT.BIDDER]: {
		[QUANTITY_DETERMINATION_STATUS.INITIAL]: TABULATOR_CHIP_VARIANTS.WARNING,
		[QUANTITY_DETERMINATION_STATUS.EDITED]: TABULATOR_CHIP_VARIANTS.WARNING,
		[QUANTITY_DETERMINATION_STATUS.SUBMITTED]: TABULATOR_CHIP_VARIANTS.DEFAULT,
		[QUANTITY_DETERMINATION_STATUS.REVIEWED]: TABULATOR_CHIP_VARIANTS.WARNING,
		[QUANTITY_DETERMINATION_STATUS.ACCEPTED]: TABULATOR_CHIP_VARIANTS.WARNING,
		[QUANTITY_DETERMINATION_STATUS.COMPLETED]: TABULATOR_CHIP_VARIANTS.SUCCESS,
	},
	[ROLES_PROJECT.LEAD]: {
		[QUANTITY_DETERMINATION_STATUS.SUBMITTED]: TABULATOR_CHIP_VARIANTS.WARNING,
		[QUANTITY_DETERMINATION_STATUS.REVIEWED]: TABULATOR_CHIP_VARIANTS.DEFAULT,
		[QUANTITY_DETERMINATION_STATUS.ACCEPTED]: TABULATOR_CHIP_VARIANTS.DEFAULT,
		[QUANTITY_DETERMINATION_STATUS.COMPLETED]: TABULATOR_CHIP_VARIANTS.SUCCESS,
	},
}

export const QUANTITY_DETERMINATION_NODE_STATUS_CONFIG: ById<TABULATOR_CHIP_VARIANTS> = {
	[QUANTITY_DETERMINATION_NODE_STATUS.PENDING]: TABULATOR_CHIP_VARIANTS.DEFAULT,
	[QUANTITY_DETERMINATION_NODE_STATUS.ACCEPTED]:
		TABULATOR_CHIP_VARIANTS.SUCCESS,
	[QUANTITY_DETERMINATION_NODE_STATUS.DECLINED]: TABULATOR_CHIP_VARIANTS.ERROR,
}
