import { Contact } from 'app/types'
import { TableFilterFunction } from 'app/types/TableFilterFunction'

const filterByTag: TableFilterFunction<Contact, string[]> = (
	data,
	selectedTagIds
) => {
	const { tags: contactTags } = data

	if (!Array.isArray(selectedTagIds) || selectedTagIds.length < 1) {
		return true
	}

	if (contactTags === null) {
		return false
	}

	const contactTagsById = contactTags.reduce((prev, tag) => {
		return [...prev, tag.uuid]
	}, [] as string[])

	return selectedTagIds.reduce((prev, id) => {
		if (prev) {
			return contactTagsById.includes(id)
		}

		return prev
	}, true as boolean)
}

export default filterByTag
