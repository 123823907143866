/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	KEY_ESC,
	KEY_TAB,
	TABLE_FIELDS_QUANTITY_DETERMINATON,
} from 'app/constants'
import { get } from 'lodash'
import i18next from 'app/i18n/configuration'
import BaseEditor from './BaseEditor'
import { Listeners } from '../../types/Listeners'

class QuantityDeterminationEditor extends BaseEditor {
	constructor(
		cell: any,
		onRendered: (fn: () => void) => void,
		success: () => void,
		cancel: () => void,
		editorParams: any
	) {
		super(cell, onRendered, success, cancel, editorParams)

		this.onPressEscape = this.onPressEscape.bind(this)
		this.onChange = this.onChange.bind(this)
		this.onBlur = this.onBlur.bind(this)
		this.onTab = this.onTab.bind(this)

		this.input.value = cell.getValue() ? cell.getValue() : ''
		this.focusCell()
		this.getColumnField()

		this.registerEventListener('change', this.onChange)
		this.registerEventListener('blur', this.onBlur)
		this.registerEventListener('keydown', this.onTab)
		this.registerEventListener('keydown', this.onPressEscape)
	}

	getColumnField(): string {
		return get(this.cell, ['_cell', 'column', 'field'], null)
	}

	isValidInputValue(): any {
		let regex = null
		let valueInDataFormat = null

		switch (this.getColumnField()) {
			case TABLE_FIELDS_QUANTITY_DETERMINATON.FORMULA:
				regex = new RegExp('(^[0-9.]+|^[0-9.]+[*][0-9.]+)$')
				valueInDataFormat = regex.exec(this.input.value)
				return !!valueInDataFormat
			case TABLE_FIELDS_QUANTITY_DETERMINATON.QTY:
				regex = new RegExp('^[0-9.]+$')
				valueInDataFormat = regex.exec(this.input.value)
				return !!valueInDataFormat
			case TABLE_FIELDS_QUANTITY_DETERMINATON.LABEL:
				return true
			default:
				return false
		}
	}

	getInputValue(): string {
		return this.input.value
	}

	focusCell(): any {
		this.onRendered(() => {
			this.input.focus()
			this.input.select()
		})
	}

	onChange(): void {
		if (this.isValidInputValue()) {
			this.success(this.getInputValue())
		} else {
			this.cancel()
		}
	}

	onBlur(): void {
		if (this.isValidInputValue()) {
			this.success(this.getInputValue())
		} else {
			// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
			// @ts-ignore
			this.editorParams.sendErrorMessage(
				i18next.t('common:tabulator.editor.params.errorMessage')
			)
			this.cancel()
		}
	}

	registerEventListener(type: Listeners, callback: (e: Event) => void): void {
		this.input.addEventListener(type, callback)
	}

	onTab(e: Event | KeyboardEvent): any {
		if (
			(e as KeyboardEvent).keyCode === KEY_TAB ||
			(e as KeyboardEvent).key === KEY_TAB.toString()
		) {
			if (this.isValidInputValue()) {
				this.success(this.getInputValue())
			} else {
				this.cancel()
			}
		}
	}

	onPressEscape(e: Event | KeyboardEvent): void {
		if (
			(e as KeyboardEvent).keyCode === KEY_ESC ||
			(e as KeyboardEvent).key === KEY_ESC.toString()
		) {
			this.cancel()
		}
	}
}

export default QuantityDeterminationEditor
