import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import useCustomSnackbar from 'app/services/snackbar/useCustomSnackbar'
import {
	getTenderingEndDateFromLot,
	getStatusFromLot,
	getLotFromProject,
	getProjectFromResponse,
} from 'app/utils/apollo'
import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import mutations from 'domain/tender/period/apollo/mutations'
import queries from 'domain/tender/period/apollo/queries'
import SecondaryButton from 'component/SecondaryButton/SecondaryButton'
import { LOT_STATUS } from 'domain/lot/interfaces/LOT_STATUS'
import ConfirmationDialog from 'component/ConfirmationDialog/ConfirmationDialog'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'

const CreateAwarding = (): ReactElement => {
	const { projectUuid, lotUuid } = useParams<{
		projectUuid: string
		lotUuid: string
	}>()
	const { t } = useTranslation()

	const [open, setOpen] = useState(false)

	const { sendSuccessMessage } = useCustomSnackbar()
	const [createAwarding] = useGraphQLMutation(mutations.createAwarding, {
		onCompleted: () =>
			sendSuccessMessage(t('domain:tender.createAwarding.success')),
	})

	const [fetchTenderEndDate, { data }] = useGraphQLLazyQuery(
		queries.TenderEndDateForLot
	)

	const project = getProjectFromResponse(data)
	const lot = getLotFromProject(project)
	const status = getStatusFromLot(lot)
	const endDate = getTenderingEndDateFromLot(lot)
	const now = new Date()

	const isDateInThePast = endDate ? new Date(endDate) < now : false
	const isStatusNotTendering = status !== LOT_STATUS.TENDERING

	const isDisabled = isDateInThePast || isStatusNotTendering

	useEffect(() => {
		if (projectUuid && lotUuid) {
			fetchTenderEndDate({
				variables: {
					projectUuid,
					lotUuid,
				},
			})
		}
	}, [projectUuid, lotUuid])

	const onClick = (): void => {
		createAwarding({
			variables: {
				projectUuid,
				lotUuid,
			},
		})
		setOpen(!open)
	}

	return (
		<>
			<SecondaryButton disabled={isDisabled} onClick={() => setOpen(!open)}>
				{t('domain:tender.createAwarding.button.title')}
			</SecondaryButton>
			<ConfirmationDialog
				open={open}
				contentText={t(
					'domain:tender.createAwarding.confirmationDialog.contentText'
				)}
				onAcceptLabel={t(
					'domain:tender.createAwarding.confirmationDialog.onAcceptLabel'
				)}
				onAccept={onClick}
				onDecline={() => setOpen(!open)}
			/>
		</>
	)
}

export default CreateAwarding
