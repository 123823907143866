/* eslint-disable @typescript-eslint/no-explicit-any */

import { POSITION_TYPES } from 'app/types/POSITION_TYPES'
import { QUANTITY_DETERMINATION_STATUS } from 'app/config/quantityDetermination'
import {
	removeClass,
	addClasses,
	getFirstChildFromCell,
	getParentFromRow,
	getParentFromCell,
	shouldClassBeRemoved,
} from 'app/utils/tabulator'
import { hasEditableCellValidCombination } from 'app/utils/validator'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'
import { get } from 'lodash'

export default (
	role: ROLES_PROJECT | null,
	cell: any,
	quantityDeterminationStatus: QUANTITY_DETERMINATION_STATUS | null
): string | number => {
	const parent = getParentFromCell(cell)
	const { type: parentType } = parent && parent.getData()

	const cellElement = cell.getElement()
	const {
		type,
		status: quantityDeterminationNodeStatus,
	} = cell.getRow().getData()
	const value = cell.getValue()

	const validParentTypes = [POSITION_TYPES.WAGE, POSITION_TYPES.BLANKET]

	const childType = get(getFirstChildFromCell(cell), ['data', 'type'])
	const isGrandParentTypeBlanketOrWage = (): boolean => {
		if (parentType === POSITION_TYPES.QTY_DETERM) {
			const grandParent = getParentFromRow(parent)
			const { type: grandParentType } = grandParent.getData()
			return validParentTypes.includes(grandParentType)
		}
		return false
	}

	if (
		hasEditableCellValidCombination({
			quantityDeterminationStatus,
			quantityDeterminationNodeStatus,
			type,
			role,
			childType,
		})
	) {
		if (
			!validParentTypes.includes(parentType) &&
			!isGrandParentTypeBlanketOrWage()
		) {
			if (value) {
				addClasses(cellElement, ['table-cell-valid'])
				removeClass(cellElement, 'table-cell-invalid')
			} else {
				addClasses(cellElement, ['table-cell-invalid'])
				removeClass(cellElement, 'table-cell-valid')
			}
		}
	}

	if (shouldClassBeRemoved(role, quantityDeterminationStatus)) {
		removeClass(cellElement, 'table-cell-valid')
	}
	return value
}
