/* eslint-disable @typescript-eslint/no-explicit-any */

import { BoqNode } from 'app/types/graphql/BoqNode'

const LinFormatter = (cell: any): string => {
	const boqNode = cell.getData() as BoqNode

	return `
		<div class="lin-wrapper">
			${boqNode.lin || ''}
			<span class="zz">${boqNode.lic || ''}</span>
		</div>
	`
}

export default LinFormatter
