import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { themeValues } from 'app/themes/themeValues'

interface StyleProps {
	selected: boolean
}

export default makeStyles<Theme, StyleProps>(theme => ({
	root: ({ selected }) => ({
		minWidth: 'fit-content',
		textAlign: 'center',
		'&:hover': {
			'& $menuIcon': {
				display: 'flex',
				borderRadius: '50%',
				visibility: selected ? 'visible' : 'hidden',
				'&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
			},
		},
		'& :focus': {
			outline: 'none',
		},
	}),
	menuIcon: () => ({
		visibility: 'hidden',
		padding: theme.spacing(1),
		fontSize: themeValues().sizes.LotTabIcon.fontSize,
		position: 'absolute',
		top: -8,
		right: 5,
	}),
	list: {
		minWidth: themeValues().sizes.ProfileMenu.minWidth,
		'& .MuiMenuItem-root': {
			display: 'flex',
			justifyContent: 'space-between',
		},
	},
	tooltip: {
		cursor: 'pointer',
		pointerEvents: 'auto',
	},
}))
