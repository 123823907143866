import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import { ProjectData, ProjectVars } from 'app/types/index'
import { getLotFromProject, getProjectFromResponse } from 'app/utils/apollo'
import { PageContentHeaderProps } from 'component/PageContentHeader/PageContentHeaderProps'
import queries from 'domain/lot/apollo/queries'
import React, { FC, ReactElement, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import useStyles from 'component/PageContentHeader/PageContentHeaderStyles'
import drawerMenu from 'app/config/drawerMenu'
import { useTranslation } from 'react-i18next'
import PageContentHeading1 from 'component/PageContentHeading1/PageContentHeading1'

const PageContentHeader: FC<PageContentHeaderProps> = ({
	title,
	buttons = [],
	iconButtons = [],
}) => {
	const classes = useStyles()
	const { t } = useTranslation()
	const { projectUuid, lotUuid, sectionId, subSectionId } = useParams<{
		projectUuid: string
		lotUuid: string
		sectionId: string
		subSectionId: string
	}>()

	const [loadData, { data }] = useGraphQLLazyQuery<ProjectData, ProjectVars>(
		queries.ProjectWithLotName,
		{
			variables: { projectUuid, lotUuid },
		}
	)

	useEffect(() => {
		if (lotUuid) {
			loadData()
		}
	}, [lotUuid])

	const project = getProjectFromResponse(data)
	const lot = getLotFromProject(project)

	const generateAlternativeTitle = (): string | null => {
		const sectionMenuItem = drawerMenu.find(({ key }) => key === sectionId)

		if (lot && sectionMenuItem && sectionMenuItem.children) {
			const subSectionMenuItem = sectionMenuItem.children.find(
				({ key }) => key === subSectionId
			)
			if (subSectionMenuItem) {
				const subSectionTitle = `${t(subSectionMenuItem.title)}`
				return `${subSectionTitle} ${lot.name}`
			}
			return null
		}
		return null
	}

	const renderTitle = (): ReactElement => {
		const alternativeTitle = generateAlternativeTitle()

		return (
			<PageContentHeading1 className={classes.title}>
				{title || alternativeTitle}
			</PageContentHeading1>
		)
	}

	return (
		<div className={classes.root}>
			<div className={classes.topRow}>
				<div className={classes.titleContainer}>
					{renderTitle()}
					{iconButtons}
				</div>
				<div>{buttons}</div>
			</div>
		</div>
	)
}

export default PageContentHeader
