import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
	'@global': {
		body: {
			minWidth: 0,
		},
	},
	cardWrapper: {
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(8),
	},
	linkWrapper: {
		display: 'flex',
		justifyContent: 'center',
	},
	link: {
		padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
	},
}))
