import { makeStyles } from '@material-ui/core'

export type InputTagStyleKeys = 'root' | 'listWrapper'

export default makeStyles(() => ({
	root: {},
	listWrapper: {
		position: 'absolute',
		top: '100%',
		left: 0,
		right: 0,
	},
}))
