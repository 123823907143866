/* eslint-disable @typescript-eslint/no-explicit-any */

class BaseEditor {
	private _cell: any

	private _newValue = ''

	private _input: HTMLInputElement

	private _onRendered: (fn: () => void) => void

	private _success: (value: string) => void

	private _cancel: () => void

	private _editorParams: object

	get cell(): any {
		return this._cell
	}

	set cell(value: any) {
		this._cell = value
	}

	get newValue(): string {
		return this._newValue
	}

	set newValue(value: string) {
		this._newValue = value
	}

	get success(): (value: string) => void {
		return this._success
	}

	set success(value: (value: string) => void) {
		this._success = value
	}

	get input(): HTMLInputElement {
		return this._input
	}

	set input(value: HTMLInputElement) {
		this._input = value
	}

	get cancel(): () => void {
		return this._cancel
	}

	set cancel(value: () => void) {
		this._cancel = value
	}

	get onRendered(): (fn: () => void) => void {
		return this._onRendered
	}

	set onRendered(value: (fn: () => void) => void) {
		this._onRendered = value
	}

	get editorParams(): object {
		return this._editorParams
	}

	set editorParams(value: object) {
		this._editorParams = value
	}

	constructor(
		cell: any,
		onRendered: (fn: () => void) => void,
		success: () => void,
		cancel: () => void,
		editorParams: any
	) {
		this._cell = cell
		this._input = document.createElement('input')
		this._success = success
		this._cancel = cancel
		this._onRendered = onRendered
		this._editorParams = editorParams

		this._input.style.width = '100%'
	}
}

export default BaseEditor
