import { useSideSheet } from 'app/hooks/index'
import { REVISION_STATUS } from 'app/types/REVISION_STATUS'
import {
	getLotFromProject,
	getStatusFromRevision,
	getDataFromBoqAnnotations,
	getBoqAnnotationsFromRevision,
	getFirstRevisionFromLot,
	getRoleFromProject,
	isProjectBidder,
} from 'app/utils/apollo'
import { hasValidCombinations } from 'app/utils/validator'
import PageContentBody from 'component/PageContentBody/PageContentBody'
import { BoqPageContentBodyProps } from 'domain/general/boq/components/base/BoqPageContentBody/BoqPageContentBodyProps'
import BoqTable from 'domain/general/boq/components/body/listing/BoqTable/BoqTable'
import TenderNotice from 'domain/general/boq/components/body/status/TenderNotice/TenderNotice'
import UploadButton from 'domain/general/boq/components/body/upload/UploadButton/UploadButton'
import { LOT_STATUS } from 'domain/lot/interfaces/LOT_STATUS'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'
import React, { FC, ReactElement } from 'react'

import useStyles from 'domain/general/boq/components/base/BoqPageContentBody/BoqPageContentBodyStyles'
import AnnotationsTable from 'domain/award/annotations/components/body/AnnotationsTable/AnnotationsTable'
import Validate from 'component/Validate/Validate'
import PageContentHeading2 from 'component/PageContentHeading2/PageContentHeading2'
import { useTranslation } from 'react-i18next'

const BoqPageContentBody: FC<BoqPageContentBodyProps> = ({ project }) => {
	const lot = getLotFromProject(project)
	const revision = getFirstRevisionFromLot(lot || null)
	const revisionStatus = getStatusFromRevision(revision)
	const boqAnnotations = getBoqAnnotationsFromRevision(revision)
	const boqAnnotationsData = getDataFromBoqAnnotations(boqAnnotations)
	const role = getRoleFromProject(project)

	const { uuid, status } = lot || {}

	const { open } = useSideSheet()
	const classes = useStyles(open)
	const { t } = useTranslation()

	const notice = <TenderNotice />

	if (!revision) {
		return (
			<PageContentBody>
				<UploadButton />
			</PageContentBody>
		)
	}

	const renderContent = (): ReactElement | null => {
		if (boqAnnotationsData && boqAnnotationsData.length > 0) {
			return (
				<div className={classes.annotationContainer}>
					<PageContentHeading2>
						{t('domain:award.annotations.title')}
					</PageContentHeading2>
					<AnnotationsTable
						boqAnnotationsData={boqAnnotationsData}
						className={classes.annotationsTable}
						lot={lot}
						role={role}
					/>
				</div>
			)
		}
		return null
	}

	return (
		<PageContentBody notice={notice} key={uuid}>
			<BoqTable
				className={classes.table}
				lot={lot}
				isEditable={hasValidCombinations(
					[
						{
							projectRole: ROLES_PROJECT.BIDDER,
							lotStatus: LOT_STATUS.TENDERING,
							revisionStatus: REVISION_STATUS.BIDDING,
						},
					],
					{ lotStatus: status || null, revisionStatus, role }
				)}
				isUpVisible={isProjectBidder(role)}
				role={role}
			/>
			<Validate
				validCombinations={[
					{
						projectRole: ROLES_PROJECT.BIDDER,
					},
				]}
			>
				{renderContent()}
			</Validate>
		</PageContentBody>
	)
}

export default BoqPageContentBody
