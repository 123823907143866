import { themeValues } from 'app/themes/themeValues'
import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
	pricingPlanCard: {
		margin: `0 ${theme.spacing(2)}px`,
		minWidth: themeValues().sizes.PricingTable.minWidth,
		[theme.breakpoints.down('sm')]: {
			marginBottom: `${theme.spacing(2)}px`,
		},
		[theme.breakpoints.down('md')]: {
			marginBottom: `${theme.spacing(2)}px`,
		},
	},
}))
