import { makeStyles, Theme } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'

export default makeStyles((theme: Theme) => ({
	root: {},
	cardRoot: {
		minWidth: themeValues().sizes.RegisterForm.width,
	},
	cardHeaderRoot: {
		marginTop: 15,
		paddingBottom: 0,
	},
	form: {
		width: themeValues().sizes.RegisterForm.width,
		marginTop: theme.spacing(4),
	},
	formTitle: {
		paddingLeft: theme.spacing(2),
	},
	formGroup: {
		margin: '0 30px 30px 30px',
	},
	buttonRoot: {
		marginTop: theme.spacing(2),
	},
}))
