import { useCustomSnackbar } from 'app/services/snackbar'
import { convertApiErrorToErrorMessage } from 'app/errors/codes'
import { ApolloError } from 'apollo-client'

export default (): [(error: ApolloError) => void] => {
	const { sendErrorMessage } = useCustomSnackbar()

	const onError = (apolloError: ApolloError): void => {
		const { graphQLErrors, networkError } = apolloError
		if (Array.isArray(graphQLErrors)) {
			// just display the first error
			sendErrorMessage(convertApiErrorToErrorMessage(graphQLErrors[0]))
		}

		if (networkError) {
			sendErrorMessage(networkError.message)
		}
	}

	return [onError]
}
