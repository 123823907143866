export enum UNITS {
	M = 'Meter',
	M2 = 'Quadratmeter',
	M3 = 'Kubikmeter',
	LFM = 'laufende Meter',
	PSCH = 'Pauschal',
	PACK = 'Packungen',
	KG = 'Kilogramm',
	T = 'Tonnen',
	STD = 'Stunden',
	MIN = 'Minuten',
}

export interface BoqAnnotation {
	uuid: string
	shortText: string
	longText?: string
	unit?: Unit
	unitPrice?: number | null
	quantity?: number | null
	comment?: string
	lin?: string
	referenceLabel?: string
}

export interface BoqAnnotations {
	uuid: string
	data: BoqAnnotation[]
	totalCount: number
	name?: string
}

export interface Unit {
	label: string
	code: string
}

export const ANNOTATION_SIDESHEET_ATTRIBUTES_RENDERED = [
	'shortText',
	'longText',
	'unit',
	'quantity',
	'unitPrice',
	'comment',
]
