/* eslint-disable @typescript-eslint/no-explicit-any */

export const containsObject = (needle: any, hayStack: any[]): boolean =>
	hayStack.findIndex(val => val.uuid === needle.uuid) > -1

export const filterArrayByArray = <T>(
	source?: T[] | null,
	filter?: T[] | null
): T[] => {
	if (!source || !filter) {
		return []
	}

	return source.filter(val => {
		return !containsObject(val, filter)
	})
}

export const reduceToUuidArray = <T extends { uuid: string }>(
	arrayOfObjects: T[]
): string[] => {
	if (Array.isArray(arrayOfObjects) && arrayOfObjects.length < 1) {
		return []
	}

	return arrayOfObjects.reduce((prev, value) => {
		return [...prev, value.uuid]
	}, [] as string[])
}
