import { makeStyles } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'

export default makeStyles({
	button: {
		...themeValues().sizes.InputActionIcons,
		padding: '6px 0',
		margin: '3px',
		'& .MuiSvgIcon-root': {
			color: 'white',
		},
	},
})
