import routes from 'app/routing/routes/routesApp'
import clsx from 'clsx'
import { LogoProps } from 'domain/header/components/Logo/LogoProps'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { themeValues } from 'app/themes/themeValues'

import useStyles from 'domain/header/components/Logo/LogoStyles'

const Logo: FC<LogoProps> = ({
	vector = true,
	invert = false,
	classes: parentClasses = {},
}) => {
	const classes = useStyles()

	const getLogoImage = (): string => {
		const theme = themeValues()
		const pixel = invert ? theme.logo.invert : theme.logo.default
		return vector ? theme.logo.svg : pixel
	}

	return (
		<Link to={routes.home} className={clsx(classes.root, parentClasses.root)}>
			<img
				src={getLogoImage()}
				className={clsx(classes.image, parentClasses.image)}
				alt="Logo"
			/>
		</Link>
	)
}

export default Logo
