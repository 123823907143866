import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import { Typography } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import {
	ACTION_SIDESHEET_ANIMATION_END,
	ACTION_SIDESHEET_ANIMATION_START,
} from 'app/constants'
import useSideSheet from 'app/hooks/useSideSheet'
import { SideSheetProps } from 'component/SideSheet/SideSheetProps'
import SideSheetContext from 'component/SideSheetProvider/SideSheetContext'
import React, { FC, ReactElement, useContext } from 'react'
import { createPortal } from 'react-dom'

import useStyles from 'component/SideSheet/SideSheetStyles'
import { themeValues } from 'app/themes/themeValues'

const SideSheet: FC<SideSheetProps> = ({ open = false }) => {
	const node = document.getElementById('sidesheet-container')
	const { dispatch } = useContext(SideSheetContext)
	const { getContent, closeSideSheet, getTitle, isClosable } = useSideSheet()

	const classes = useStyles()
	const title = getTitle()

	const onTransitionEnd = (): void => {
		if (dispatch) {
			dispatch({ type: ACTION_SIDESHEET_ANIMATION_END })
		}
	}

	const onTransitionStart = (): void => {
		if (dispatch) {
			dispatch({ type: ACTION_SIDESHEET_ANIMATION_START })
		}
	}

	const renderSubHeader = (): ReactElement => (
		<>
			{title && (
				<Typography variant="h5" component="h2">
					{title}
				</Typography>
			)}
			{isClosable && (
				<IconButton
					onClick={() => closeSideSheet()}
					className={classes.iconButton}
				>
					<Close />
				</IconButton>
			)}
		</>
	)

	if (node) {
		return createPortal(
			<Slide
				direction="left"
				in={open}
				onEnter={onTransitionStart}
				onEntered={onTransitionEnd}
				onExit={onTransitionStart}
				onExited={onTransitionEnd}
			>
				<Card
					elevation={themeValues().boxShadows.elevation}
					className={classes.card}
				>
					<CardHeader
						className={classes.cardHeader}
						disableTypography
						subheader={renderSubHeader()}
					/>
					<CardContent className={classes.cardContent}>
						{getContent()}
					</CardContent>
				</Card>
			</Slide>,
			node
		)
	}

	return null
}

export default SideSheet
