/* eslint-disable @typescript-eslint/no-explicit-any */

import { BOQ_QTY_FORMAT_CELL } from 'app/config/boq'
import QtyBaseFormatter from 'app/services/tabulator/formatters/qty/QtyBaseFormatter'
import { convertToCurrentLanguage } from 'app/services/numeral'
import { POSITION_TYPES } from 'app/types/POSITION_TYPES'
import { isEmpty, toNumber } from 'lodash'

class SurchargeFormatter extends QtyBaseFormatter {
	constructor(cell: any) {
		super(cell, POSITION_TYPES.SURCHARGE)
	}

	verifyCellValue(): boolean {
		const cellValue = this.cell.getValue()
		if (isEmpty(cellValue)) {
			this.markAsInvalid()
			return false
		}

		if (toNumber(cellValue) <= 0) {
			this.markAsInvalid()
			return false
		}

		this.markAsValid()
		return true
	}

	formatCellValue(): string | number {
		this.verifyCellValue()
		return convertToCurrentLanguage(this.cell.getValue()).format(
			BOQ_QTY_FORMAT_CELL
		)
	}
}

export default SurchargeFormatter
