/* eslint-disable @typescript-eslint/no-explicit-any */
// @TODO we currently dont have interfaces for apollo, but there is the possibility to generate the by cli. I made a research note on that
const createOrDeleteResponse = (
	{ lotUuid, projectMemberUuid, contactUuid }: any,
	isDelete: boolean
): any => {
	let mutationName = 'createCollaboration'

	if (isDelete) {
		mutationName = 'deleteCollaboration'
	}

	return {
		project: {
			__typename: 'MutateProject',
			lot: {
				uuid: lotUuid,
				__typename: 'MutateLot',
				[mutationName]: {
					uuid: contactUuid,
					hasAccess: null,
					projectMemberUuid,
					__typename: 'Collaboration',
				},
			},
		},
	}
}

const createCollaborationResponse = (vars: any): any => {
	return createOrDeleteResponse(vars, false)
}

const deleteCollaborationResponse = (vars: any): any => {
	return createOrDeleteResponse(vars, true)
}

export default {
	createCollaborationResponse,
	deleteCollaborationResponse,
}
