import { gql } from 'apollo-boost'

const LotWithContractInfo = gql`
	query LotWithContractInfo($projectUuid: String!, $lotUuid: String!) {
		project(uuid: $projectUuid) {
			uuid
			lot(uuid: $lotUuid) {
				uuid
				dataRevisions {
					data {
						uuid
						contractInfo {
							longText {
								text
							}
							shortText {
								text
							}
						}
					}
				}
			}
		}
	}
`

export default { LotWithContractInfo }
