/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	QUANTITY_DETERMINATION_STATUS,
	QUANTITY_DETERMINATION_NODE_STATUS,
} from 'app/config/quantityDetermination'
import { CombinationOperator } from 'app/types/CombinationOperator'
import { HasEditableCellValidCombinationsConfig } from 'app/types/hasEditableCellValidCombinationsConfig'
import { HasValidCombinationsConfig } from 'app/types/HasValidCombinationsConfig'
import { POSITION_TYPES } from 'app/types/POSITION_TYPES'
import { ValidCombination } from 'app/types/ValidCombinations'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'

export const hasInvalidCombinations = (
	validData: ValidCombination[],
	configuration: HasValidCombinationsConfig
): boolean => {
	const {
		combinationOperator,
		lotStatus,
		revisionStatus,
		role,
		quantityDeterminationStatus,
		environment,
	} = configuration

	if (!Array.isArray(validData) || validData.length < 1) {
		return false
	}

	// check if combinations are valid if OR operator
	// just one combination has to be valid
	if (combinationOperator === CombinationOperator.OR) {
		return validData.reduce(
			(prev, value) =>
				prev ||
				((value.projectRole === undefined || value.projectRole !== role) &&
					(value.lotStatus === undefined || value.lotStatus !== lotStatus) &&
					(value.revisionStatus === undefined ||
						value.revisionStatus !== revisionStatus) &&
					(value.quantityDeterminationStatus === undefined ||
						value.quantityDeterminationStatus !==
							quantityDeterminationStatus) &&
					(value.environment === undefined ||
						value.environment !== environment)),
			false as boolean
		)
	}

	// check if combinations are valid if AND operator
	// all combinations have to be valid
	return validData.reduce(
		(prev, value) =>
			prev
				? (value.projectRole === undefined || value.projectRole !== role) &&
				  (value.lotStatus === undefined || value.lotStatus !== lotStatus) &&
				  (value.revisionStatus === undefined ||
						value.revisionStatus !== revisionStatus) &&
				  (value.quantityDeterminationStatus === undefined ||
						value.quantityDeterminationStatus !==
							quantityDeterminationStatus) &&
				  (value.environment === undefined || value.environment !== environment)
				: false,
		true as boolean
	)
}

export const hasValidCombinations = (
	validData: ValidCombination[],
	configuration: HasValidCombinationsConfig
): boolean => {
	const {
		combinationOperator,
		lotStatus,
		revisionStatus,
		role,
		quantityDeterminationStatus,
		environment,
	} = configuration

	if (!Array.isArray(validData) || validData.length < 1) {
		return false
	}

	// check if combinations are valid if OR operator
	// just one combination has to be valid
	if (combinationOperator === CombinationOperator.OR) {
		return validData.reduce(
			(prev, value) =>
				prev ||
				((value.projectRole === undefined || value.projectRole === role) &&
					(value.lotStatus === undefined || value.lotStatus === lotStatus) &&
					(value.revisionStatus === undefined ||
						value.revisionStatus === revisionStatus) &&
					(value.quantityDeterminationStatus === undefined ||
						value.quantityDeterminationStatus ===
							quantityDeterminationStatus) &&
					(value.environment === undefined ||
						value.environment === environment)),
			false as boolean
		)
	}

	// check if combinations are valid if AND operator
	// all combinations have to be valid
	return validData.reduce(
		(prev, value) =>
			prev
				? (value.projectRole === undefined || value.projectRole === role) &&
				  (value.lotStatus === undefined || value.lotStatus === lotStatus) &&
				  (value.revisionStatus === undefined ||
						value.revisionStatus === revisionStatus) &&
				  (value.quantityDeterminationStatus === undefined ||
						value.quantityDeterminationStatus ===
							quantityDeterminationStatus) &&
				  (value.environment === undefined || value.environment === environment)
				: false,
		true as boolean
	)
}
// Tabulator editable validation
/** ************************* */

export const hasEditableCellValidCombination = (
	configuration: HasEditableCellValidCombinationsConfig
): boolean => {
	const {
		type,
		quantityDeterminationStatus,
		quantityDeterminationNodeStatus,
		role,
		childType,
	} = configuration
	const validStatus: any = {
		[ROLES_PROJECT.BIDDER]: {
			validQuantityDeterminationStatus: [
				QUANTITY_DETERMINATION_STATUS.INITIAL,
				QUANTITY_DETERMINATION_STATUS.EDITED,
				QUANTITY_DETERMINATION_STATUS.REVIEWED,
				QUANTITY_DETERMINATION_STATUS.ACCEPTED,
			],
			validQuantityDeterminationNodeStatus: [
				QUANTITY_DETERMINATION_NODE_STATUS.TEMPORARY,
				QUANTITY_DETERMINATION_NODE_STATUS.PENDING,
				QUANTITY_DETERMINATION_NODE_STATUS.DECLINED,
			],
			validType: POSITION_TYPES.QTY_DETERM,
			invalidChildType: POSITION_TYPES.SUGGESTION,
		},
		[ROLES_PROJECT.LEAD]: {
			validQuantityDeterminationStatus: [
				QUANTITY_DETERMINATION_STATUS.SUBMITTED,
			],
			validQuantityDeterminationNodeStatus: [
				QUANTITY_DETERMINATION_NODE_STATUS.TEMPORARY,
				QUANTITY_DETERMINATION_NODE_STATUS.PENDING,
			],
			validType: POSITION_TYPES.SUGGESTION,
		},
	}

	if (!role) {
		return false
	}

	const {
		validQuantityDeterminationStatus,
		validQuantityDeterminationNodeStatus,
		validType,
		invalidChildType,
	} = validStatus[role]
	if (validQuantityDeterminationStatus.includes(quantityDeterminationStatus)) {
		if (
			validQuantityDeterminationNodeStatus.includes(
				quantityDeterminationNodeStatus
			)
		) {
			if (childType && invalidChildType === childType) {
				return false
			}

			if (validType === type) {
				return true
			}
		}
	}

	return false
}
