import { gql } from 'apollo-boost'

const createQuantityDetermination = gql`
	mutation createQuantityDetermination(
		$projectUuid: String!
		$lotUuid: String!
		$name: String!
	) {
		project(uuid: $projectUuid) {
			lot(uuid: $lotUuid) {
				createQuantityDetermination(name: $name) {
					uuid
					status
					name
					__typename
				}
			}
		}
	}
`
export default { createQuantityDetermination }
