import { resolveRoute } from 'app/utils/route'
import React, { FC } from 'react'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import PrimaryButton from 'component/PrimaryButton/PrimaryButton'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom'
import { UrlParams } from 'app/types/UrlParams'
import useForm from 'app/hooks/useForm'
import mutations from 'domain/billing/apollo/mutations'
import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import routes from 'app/routing/routes/routesApp'
import { get } from 'lodash'
import PageContentHeading2 from 'component/PageContentHeading2/PageContentHeading2'

import useStyles from 'domain/billing/quantity/components/body/CreateQuantityDeterminationForm/CreateQuantityDeterminationStyles'
import CreateQuantityDeterminationFormProps from './CreateQuantityDeterminationFormProps'

const CreateQuantityDeterminationForm: FC<CreateQuantityDeterminationFormProps> = ({
	isEmpty,
}) => {
	const { t } = useTranslation()
	const classes = useStyles({ isEmpty })
	const { subSectionId, sectionId, lotUuid, projectUuid } = useParams<
		UrlParams
	>()
	const { formData, handleInputChange } = useForm()
	const { name } = formData
	const { push } = useHistory()

	const [createQuantityDetermination] = useGraphQLMutation(
		mutations.createQuantityDetermination,
		{
			onCompleted: response => {
				const qduuid = get(
					response,
					['project', 'lot', 'createQuantityDetermination', 'uuid'],
					null
				)
				if (qduuid) {
					push(
						resolveRoute(
							routes.subSection,
							{
								projectUuid,
								lotUuid,
								sectionId,
								subSectionId,
							},
							{
								qduuid,
							}
						)
					)
				}
			},
		}
	)

	const onSubmit = (): void => {
		if (name) {
			createQuantityDetermination({
				variables: {
					projectUuid,
					lotUuid,
					name,
				},
			})
		}
	}

	return (
		<ValidatorForm onSubmit={onSubmit}>
			{!isEmpty && (
				<PageContentHeading2>
					{t(`domain:billing.createQuantityDetermination.form.title`)}
				</PageContentHeading2>
			)}

			<TextValidator
				classes={{ root: classes.root }}
				validators={['required', 'minStringLength:1', 'maxStringLength:50']}
				name="name"
				placeholder={t(
					'domain:billing.createQuantityDetermination.form.placeholder'
				)}
				value={formData.name || ''}
				fullWidth
				onChange={handleInputChange}
				errorMessages={[
					t(
						'domain:billing.createQuantityDetermination.form.validatorViolations.required'
					),
					t(
						'domain:billing.createQuantityDetermination.form.validatorViolations.minLength',
						{
							minLength: 1,
						}
					),
					t(
						'domain:billing.createQuantityDetermination.form.validatorViolations.maxLength',
						{
							maxLength: 50,
						}
					),
				]}
			/>
			<PrimaryButton type="submit" classes={{ root: classes.buttonSubmit }}>
				{t('domain:billing.createQuantityDetermination.form.button.label')}
			</PrimaryButton>
		</ValidatorForm>
	)
}

export default CreateQuantityDeterminationForm
