/* eslint-disable @typescript-eslint/no-explicit-any */

import { BoqNode } from 'app/types/graphql/BoqNode'
import { POSITION_TYPES } from 'app/types/POSITION_TYPES'
import { getBoqNodesFromCell } from 'app/utils/tabulator'

class TabulatorHelper {
	/**
	 * @param alternativeRow
	 */
	public static findBaseRow(alternativeRow: any): any {
		let baseRow = null
		let currentRowInLoop = alternativeRow
		const { type } = currentRowInLoop.getData()

		if (type !== POSITION_TYPES.ALTERNATIVE) {
			return null
		}

		do {
			const prevRow = currentRowInLoop.getPrevRow() as any
			const { type: prevRowType } = prevRow.getData() as BoqNode

			if (prevRowType === POSITION_TYPES.BASE) {
				baseRow = prevRow
			}

			currentRowInLoop = prevRow
		} while (!baseRow)

		return currentRowInLoop
	}

	/**
	 * @param baseRow
	 */
	public static findAlternativeRows(baseRow: any): any[] {
		const result = []
		let currentRowInLoop = baseRow
		let end = false

		do {
			const nextRow = currentRowInLoop.getNextRow() as any
			const { type: nextRowType } = nextRow.getData() as BoqNode

			if (nextRowType !== POSITION_TYPES.ALTERNATIVE) {
				end = true
			} else {
				result.push(nextRow)
			}

			currentRowInLoop = nextRow
		} while (!end)

		return result
	}

	/**
	 * @param cell
	 */
	public static isLineItem(cell: any): boolean {
		return !Array.isArray(getBoqNodesFromCell(cell))
	}
}

export default TabulatorHelper
