/* eslint-disable @typescript-eslint/no-explicit-any */
export interface UseOptions {
	options: any
}

export default (): UseOptions => {
	const getOptions = (): any => ({
		headerSort: false,
		layout: 'fitColumns',
	})

	return { options: getOptions() }
}
