import Chip from '@material-ui/core/Chip/Chip'
import clsx from 'clsx'
import Loading from 'component/Loading/Loading'
import { TagProps } from 'component/Tag/TagProps'
import React, { FC } from 'react'

import useStyles from 'component/Tag/TagStyles'

const Tag: FC<TagProps> = ({
	label,
	loading,
	classes: parentClasses = {},
	...rest
}) => {
	const classes = useStyles()

	return (
		<Chip
			className={clsx(classes.root, parentClasses.root)}
			label={<Loading loading={!!loading}>{label}</Loading>}
			{...rest}
		/>
	)
}

export default Tag
