import useSideSheet from 'app/hooks/useSideSheet'
import PageContentHeader from 'component/PageContentHeader/PageContentHeader'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import ContactCreateSideSheet from 'domain/tender/bidders/components/sidesheet/contacts/ContactCreateSideSheet/ContactCreateSideSheet'
import { TenderPageContentHeaderProps } from 'domain/tender/bidders/components/base/TenderPageContentHeader/TenderPageContentHeaderProps'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import TooltipIconButton from 'component/TooltipIconButton/TooltipIconButton'
import ValidateDomainEvent from 'component/ValidateDomainEvent/ValidateDomainEvent'
import { DOMAIN_EVENTS } from 'domain/project/enums/DOMAIN_EVENTS'
import { DOMAIN_EVENT_TRIGGER } from 'domain/project/enums/DOMAIN_EVENT_TRIGGER'

const TenderPageContentHeader: FC<TenderPageContentHeaderProps> = () => {
	const { openSideSheet } = useSideSheet()
	const { t } = useTranslation()

	const openCreateContactSidesheet = (): void => {
		openSideSheet({
			title: t('domain:tender.createContact.sideSheet.title'),
			content: <ContactCreateSideSheet />,
		})
	}

	const availableButtons = [
		<ValidateDomainEvent
			key="openCreateContactSidesheet"
			event={DOMAIN_EVENTS.CREATE_CONTACT}
			trigger={DOMAIN_EVENT_TRIGGER.ON_CLICK}
		>
			<TooltipIconButton
				onClick={openCreateContactSidesheet}
				title={t('domain:tender.createContact.sideSheet.trigger.title')}
			>
				<PersonAddIcon />
			</TooltipIconButton>
		</ValidateDomainEvent>,
	]

	return <PageContentHeader buttons={availableButtons} />
}

export default TenderPageContentHeader
