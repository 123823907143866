import { TooltipIconButtonProps } from 'component/TooltipIconButton/TooltipIconButtonProps'
import React, { FC } from 'react'
import { Tooltip, IconButton } from '@material-ui/core'

const TooltipIconButton: FC<TooltipIconButtonProps> = ({
	title,
	onClick,
	children,
}) => {
	return (
		<Tooltip title={title}>
			<IconButton onClick={onClick}>{children}</IconButton>
		</Tooltip>
	)
}

export default TooltipIconButton
