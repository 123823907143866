/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSideSheet } from 'app/hooks'
import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import useCustomSnackbar from 'app/services/snackbar/useCustomSnackbar'
import { getFirstRevisionFromLot } from 'app/utils/apollo'
import LineItemDetailSideSheet from 'domain/general/boq/components/sidesheet/LineItemDetailSideSheet/LineItemDetailSideSheet'
import {
	getPathForIdFromCell,
	getRowFromCell,
	isLineItem,
} from 'app/services/tabulator'
import { UpdateLineItemData, UpdateLineItemVars, BoqNode, Lot } from 'app/types'
import mutations from 'domain/general/boq/apollo/mutations'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { POSITION_TYPES } from 'app/types/POSITION_TYPES'
import AnnotationSideSheet from 'domain/award/annotations/sidesheet/AnnotationSideSheet/AnnotationSideSheet'

interface UseEvents {
	onClickCellLabel: (e: any, cell: any) => void
	handleUpCellEdited: (e: any, cell: any) => void
	onClickCellType: (e: any, cell: any) => void
	openAnnotationSideSheet: (e: any, cell: any) => void
}

export default (lot: Lot | null): UseEvents => {
	const revision = getFirstRevisionFromLot(lot)
	const { t } = useTranslation()
	const { openSideSheet } = useSideSheet()
	const { sendSuccessMessage } = useCustomSnackbar()
	const { projectUuid, lotUuid } = useParams<{
		projectUuid: string
		lotUuid: string
	}>()

	const [updateLineItem] = useGraphQLMutation<
		UpdateLineItemData,
		UpdateLineItemVars
	>(mutations.updateLineItem, {
		onCompleted: () =>
			sendSuccessMessage(t('domain:boq.updateLineItem.success')),
	})

	const onClickCellLabel = (e: any, cell: any): void => {
		const table = cell.getTable()
		table.deselectRow()
		const boqNode = getRowFromCell(cell).getData() as BoqNode
		if (isLineItem(cell)) {
			const { type } = boqNode
			const row = cell.getRow()
			row.toggleSelect()
			if (type !== POSITION_TYPES.CATEGORY) {
				openSideSheet({
					title: t('domain:boq.viewLineItem.sideSheet.title'),
					content: <LineItemDetailSideSheet boqNode={boqNode} />,
				})
			}
		}
	}

	const onClickCellType = (e: any, cell: any): void => {
		const table = cell.getTable()
		table.deselectRow()
		if (isLineItem(cell)) {
			const row = cell.getRow()
			row.toggleSelect()
			const boqNode = cell.getData() as BoqNode
			const { type } = boqNode
			if (type !== POSITION_TYPES.CATEGORY) {
				openSideSheet({
					title: t('domain:boq.viewLineItem.sideSheet.title'),
					content: <LineItemDetailSideSheet boqNode={boqNode} />,
				})
			}
		}
	}

	const openAnnotationSideSheet = (e: any, cell: any): void => {
		const table = cell.getTable()
		const row = cell.getRow()
		const boqNode = cell.getData() as BoqNode

		table.deselectRow()
		row.toggleSelect()

		const { lin, label } = boqNode
		openSideSheet({
			title: t('domain:award.annotations.sideSheet.title', { lin, label }),
			content: <AnnotationSideSheet revision={revision} boqNode={boqNode} />,
		})
	}

	const handleUpCellEdited = (cell: any): void => {
		if (revision) {
			updateLineItem({
				variables: {
					projectUuid,
					lotUuid,
					path: getPathForIdFromCell(cell),
					value: cell.getValue(),
					revisionUuid: revision.uuid,
				},
			})
		}
	}

	return {
		onClickCellLabel,
		handleUpCellEdited,
		onClickCellType,
		openAnnotationSideSheet,
	}
}
