import React, { FC } from 'react'
import PrimaryButton from 'component/PrimaryButton/PrimaryButton'
import { AwardBidderButtonProps } from 'domain/award/awarding/components/body/AwardBidderButton/AwardBidderButtonProps'

import useStyles from 'domain/award/awarding/components/body/AwardBidderButton/AwardBidderButtonStyles'
import PageContentParagraph from 'component/PageContentParagraph/PageContentParagraph'

const AwardBidderButton: FC<AwardBidderButtonProps> = ({
	bidder,
	onClickAwardButton,
}) => {
	const classes = useStyles()
	return (
		<div className={classes.root}>
			<PrimaryButton
				onClick={() => {
					onClickAwardButton(bidder)
				}}
			>
				Auswählen
			</PrimaryButton>{' '}
			<PageContentParagraph className={classes.bidderName}>
				{bidder.name}
			</PageContentParagraph>
		</div>
	)
}

export default AwardBidderButton
