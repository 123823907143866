import React, { FC } from 'react'
import { FormHelperText } from '@material-ui/core'
import {
	convertApiErrorToErrorMessage,
	isRestError,
	isGqlError,
	isApolloError,
} from 'app/errors/codes'
import { GraphQLError } from 'graphql'
import { RestError } from 'app/types'
import { ApolloError } from 'apollo-boost'
import { StripeError } from '@stripe/stripe-js'
import { RenderErrorMessageProps } from './RenderErrorMessageProps'

const RenderErrorMessage: FC<RenderErrorMessageProps> = ({ error }) => {
	if (!error) {
		return null
	}

	const getErrorMessage = (
		errorObj: GraphQLError | RestError | ApolloError | StripeError
	): string | null => {
		if (
			isRestError(errorObj) ||
			isGqlError(errorObj) ||
			isApolloError(errorObj)
		) {
			return convertApiErrorToErrorMessage(errorObj)
		}

		if (errorObj && errorObj.message) {
			return errorObj.message
		}
		return null
	}

	return <FormHelperText error>{getErrorMessage(error)}</FormHelperText>
}

export default RenderErrorMessage
