export const AUTH_TOKEN_IDENTIFIER = 'authenticationTokens'

export const LAST_VISITED_URL_PARAMS = 'lastVisitedURLParams'

export const CHECKOUT_DATA = 'checkoutData'

export const STORED_TOKEN = 'token'

export const EMAIL_SUPPORT = 'support@tenderframe.de'

export const SNACKBAR_TIMEOUT = 3000

export const FORM_PROJECT_UPDATE_PROJECTNAME_LENGTH_MIN = 3

export const INPUT_PASSWORD_LENGTH_MIN = 8

export const ERROR_CODE_NO_PERMISSION_TO_ACCESS_PROJECT = 101201

export const ERROR_CODE_INVALID_PROJECT_UUID = 101200

export const ERROR_CODE_INVALID_LOT_UUID = 101550

export const REDIRECT_URL = '/projects'

export const REGEX_URL_PARAMS = /:([a-zA-Z]*)/g

export enum USER_ROLES {
	ADMIN = 'admin',
	USER = 'user',
}

export enum ENVIRONMENTS {
	DEV = 'dev',
	DEMO = 'demo',
	PROD = 'prod',
}

export enum REVISION_ACCESS_TYPES {
	READ = 'read',
	WRITE = 'write',
}

export enum TABLE_FIELDS_BOQ {
	ANNOTATION = 'annotation',
	UP = 'up',
	LIN = 'lin',
	TYPE = 'type',
	LABEL = 'label',
	QTY = 'qty',
	QU = 'qu',
}

export enum TABLE_FIELDS_BOQ_ANNOTATIONS {
	LIN = 'lin',
	SHORT_TEXT = 'shortText',
	UNIT = 'unit',
	QUANTITY = 'quantity',
	UNIT_PRICE = 'unitPrice',
	ANNOTATION = 'annotation',
}

export enum TABLE_FIELDS_QUANTITY_DETERMINATON {
	FORMULA = 'formula',
	QTY = 'qty',
	LABEL = 'label',
}

export const KEY_ENTER = 13

export const KEY_ESC = 27

export const KEY_TAB = 9

export const ACTION_SIDESHEET_OPEN = 'SIDESHEET/OPEN'

export const ACTION_SIDESHEET_CLOSE = 'SIDESHEET/CLOSE'

export const ACTION_SIDESHEET_ANIMATION_START = 'SIDESHEET/ANIMATION/START'

export const ACTION_SIDESHEET_ANIMATION_END = 'SIDESHEET/ANIMATION/END'
