import { LAST_VISITED_URL_PARAMS } from 'app/constants'
import { useLocalStorage } from 'app/hooks/index'
import AvailableRouteParams from 'app/routing/routes/AvailableRouteParams'
import routes from 'app/routing/routes/routesApp'

import useQueryWithException from 'app/services/apollo/hooks/useQueryWithException'
import { ProjectListData, ProjectListVars } from 'app/types/index'
import { SECTIONS } from 'app/types/SECTIONS'
import { getProjectsFromResponse, getLotsFromProject } from 'app/utils/apollo'
import { isNewProjectRoute, resolveRoute } from 'app/utils/route'
import Loading from 'component/Loading/Loading'
import queries from 'domain/drawer/apollo/queries'
import { isEmpty } from 'lodash'
import React, { ReactElement } from 'react'
import { Redirect } from 'react-router-dom'

const ProjectRedirection = (): ReactElement => {
	const { data, loading } = useQueryWithException<
		ProjectListData,
		ProjectListVars
	>(queries.ProjectListForRedirect)
	const projects = getProjectsFromResponse(data)
	const firstProject = Array.isArray(projects) && projects[0]
	const [lastVisitedUrlParams] = useLocalStorage<AvailableRouteParams | null>(
		LAST_VISITED_URL_PARAMS,
		null
	)

	const getFirstRoute = (): string => {
		if (firstProject) {
			const firstSectionId = SECTIONS.BOQ
			const lots = getLotsFromProject(firstProject)
			const firstLot = Array.isArray(lots) && lots[0]
			const firstLotId = firstLot && firstLot.uuid
			const route = firstLotId ? routes.section : routes.project

			return resolveRoute(route, {
				projectUuid: firstProject.uuid,
				lotUuid: firstLotId || undefined,
				sectionId: firstSectionId,
			})
		}
		return ''
	}

	// @TODO for performance optimization ... we are currently fetching all lots, maybe we can add in future a parameter first
	const getRedirectUrl = (): string => {
		if (lastVisitedUrlParams && !isEmpty(lastVisitedUrlParams)) {
			if (isNewProjectRoute(lastVisitedUrlParams)) {
				return resolveRoute(routes.project, lastVisitedUrlParams)
			}
			return resolveRoute(routes.section, lastVisitedUrlParams)
		}
		return getFirstRoute()
	}
	return (
		<Loading loading={loading}>
			{firstProject ? (
				<Redirect to={getRedirectUrl()} />
			) : (
				<Redirect to={routes.projectCreate} />
			)}
		</Loading>
	)
}

export default ProjectRedirection
