import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import PageContentParagraph from 'component/PageContentParagraph/PageContentParagraph'

const EmptyAnnotationsNotice: FC = () => {
	const { t } = useTranslation()

	return (
		<PageContentParagraph>
			{t('domain:award.annotations.emptyState')}
		</PageContentParagraph>
	)
}

export default EmptyAnnotationsNotice
