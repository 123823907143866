import React, { FC } from 'react'
import Table from 'component/Table/Table'
import useColumns from '../../../tabulator/useColumns'
import useOptions from '../../../tabulator/useOptions'
import AnnotationsTableProps from './AnnotationsTableProps'

const AnnotationsTable: FC<AnnotationsTableProps> = ({
	className,
	boqAnnotationsData,
	lot,
	role,
}) => {
	const columns = useColumns(lot, role)
	const { options } = useOptions()

	return (
		<Table
			className={className}
			options={options}
			data={boqAnnotationsData || []}
			columns={columns}
		/>
	)
}

export default AnnotationsTable
