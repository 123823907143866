import { TABLE_FIELDS_BOQ } from 'app/constants'
import { RowFormatterDefault } from 'app/services/tabulator'

export interface UseOptions {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	options: any
}

export default (): UseOptions => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const getOptions = (): any => ({
		dataTree: true,
		dataTreeChildField: 'boqNodes',
		dataTreeStartExpanded: true,
		headerSort: false,
		layout: 'fitColumns',
		rowFormatter: RowFormatterDefault,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		tooltips: (cell: any) => {
			const { field } = cell.getColumn().getDefinition()
			return field === TABLE_FIELDS_BOQ.LABEL ? cell.getValue() : ''
		},
	})

	return { options: getOptions() }
}
