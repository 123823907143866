import { EMAIL_SUPPORT } from 'app/constants'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import { UrlParams } from 'app/types/UrlParams'
import { getProjectFromResponse } from 'app/utils/apollo'
import Link from 'component/Link/Link'
import Loading from 'component/Loading/Loading'
import Notice from 'component/Notice/Notice'
import PageContent from 'component/PageContent/PageContent'
import PageContentBody from 'component/PageContentBody/PageContentBody'
import queries from 'domain/general/boq/apollo/queries'
import BoqPageContentBody from 'domain/general/boq/components/base/BoqPageContentBody/BoqPageContentBody'
import BoqPageContentHeader from 'domain/general/boq/components/base/BoqPageContentHeader/BoqPageContentHeader'
import React, { FC, useEffect, ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

const BoqPageContent: FC = () => {
	const { projectUuid, lotUuid, sectionId } = useParams<UrlParams>()
	const { t } = useTranslation()

	const [fetchData, { data, loading, error }] = useGraphQLLazyQuery(
		queries.ProjectWithBoqInformation,
		{
			fetchPolicy: 'cache-and-network',
		}
	)

	useEffect(() => {
		if (projectUuid && lotUuid && sectionId) {
			fetchData({
				variables: {
					projectUuid,
					lotUuid,
				},
			})
		}
	}, [projectUuid, lotUuid, sectionId])

	const project = getProjectFromResponse(data)

	const getBodyComponent = (): ReactElement => {
		if (error) {
			return (
				<PageContentBody
					notice={
						<Notice
							message={
								<Trans
									defaults="domain:lot.validatorViolations.brokenUploadFile.message"
									values={{
										linkTitle: t(
											`domain:lot.validatorViolations.brokenUploadFile.linkTitle`
										),
									}}
									components={[
										<Link color="white" to={EMAIL_SUPPORT}>
											title
										</Link>,
									]}
								/>
							}
							type="error"
						/>
					}
				/>
			)
		}

		return <BoqPageContentBody project={project} />
	}

	return (
		<Loading loading={loading}>
			<PageContent
				key={lotUuid}
				header={<BoqPageContentHeader project={project} />}
				body={getBodyComponent()}
			/>
		</Loading>
	)
}

export default BoqPageContent
