import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import FormGroup from '@material-ui/core/FormGroup'
import Typography from '@material-ui/core/Typography'
import useAuthentication from 'app/hooks/useAuthentication'
import useForm from 'app/hooks/useForm'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import PasswordCheckValidator from 'component/PasswordCheckValidator/PasswordCheckValidator'
import PrimaryButton from 'component/PrimaryButton/PrimaryButton'
import ValidatorForm from 'component/ValidatorForm/ValidatorForm'
import mutations from 'domain/register/apollo/mutations'
import queries from 'domain/register/apollo/queries'
import InvalidLinkCardContent from 'domain/register/components/InvalidLinkCardContent/InvalidLinkCardContent'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TextValidator } from 'react-material-ui-form-validator'
import { useLocation } from 'react-router-dom'
import { get } from 'lodash'

import useStyles from 'domain/register/components/RegisterForm/RegisterFormStyles'

const RegisterForm: FC = () => {
	const classes = useStyles()

	const { formData, handleInputChange } = useForm({
		defaultValues: {
			password: '',
			passwordCheck: '',
		},
	})

	const { t } = useTranslation()
	const location = useLocation()
	const { login } = useAuthentication()

	const [
		fetchToken,
		{ loading: fetchEmailLoading, data },
	] = useGraphQLLazyQuery(queries.AccountToken)

	const email = data ? data.accountToken.email : null

	const [
		completeRegistration,
		{ loading: registrationLoading },
	] = useGraphQLMutation(mutations.completeRegistration, {
		onCompleted: () => {
			if (email && formData.password) {
				login(email, get(formData, 'password', '') as string)
			}
		},
	})

	const urlParams = new URLSearchParams(location.search)
	const token = urlParams.get('token')

	useEffect(() => {
		if (token) {
			fetchToken({
				variables: {
					token,
				},
			})
		}
	}, [token])

	const onSubmit = (): void => {
		completeRegistration({
			variables: {
				token,
				password: formData.password,
			},
		})
	}

	return (
		<div className={classes.root}>
			<Card
				classes={{
					root: classes.cardRoot,
				}}
			>
				{email && (
					<>
						<CardHeader
							title={
								<Typography
									variant="h5"
									component="h1"
									className={classes.formTitle}
								>
									{t('domain:register.complete.card.headline')}
								</Typography>
							}
							classes={{
								root: classes.cardHeaderRoot,
							}}
						/>
						<ValidatorForm
							onSubmit={onSubmit}
							instantValidate={false}
							className={classes.form}
						>
							<FormGroup className={classes.formGroup}>
								<TextValidator
									label={t('domain:register.complete.form.email.label')}
									type="email"
									name="username"
									value={email}
									disabled
									fullWidth
								/>

								<PasswordCheckValidator
									formData={formData}
									onInputChange={handleInputChange}
								/>

								<PrimaryButton
									classes={{ root: classes.buttonRoot }}
									type="submit"
									loading={registrationLoading}
								>
									{t('domain:register.complete.form.submit')}
								</PrimaryButton>
							</FormGroup>
						</ValidatorForm>
					</>
				)}
				{!fetchEmailLoading && !email && <InvalidLinkCardContent />}
			</Card>
		</div>
	)
}

export default RegisterForm
