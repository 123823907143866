import { gql } from 'apollo-boost'

const TenderEndDateForLot = gql`
	query TenderEndDateForLot($projectUuid: String!, $lotUuid: String!) {
		project(uuid: $projectUuid) {
			uuid
			lot(uuid: $lotUuid) {
				uuid
				tenderingEndDate
				status
			}
		}
	}
`

export default { TenderEndDateForLot }
