/* eslint-disable @typescript-eslint/no-explicit-any */
import SurchargeEditor from 'app/services/tabulator/editors/qty/SurchargeEditor'
import { POSITION_TYPES } from 'app/types/POSITION_TYPES'
import SuggestionEditor from './editors/suggestion/SuggestionEditor'
import QtyDetermEditor from './editors/qtyDeterm/QtyDetermEditor'

export default (
	cell: any,
	onRendered: any,
	success: any,
	cancel: any,
	editorParams: any
): any => {
	const tabulatorNode = cell.getData()
	const { type } = tabulatorNode
	switch (type) {
		case POSITION_TYPES.SURCHARGE:
			return new SurchargeEditor(
				cell,
				onRendered,
				success,
				cancel,
				editorParams
			).input
		case POSITION_TYPES.QTY_DETERM:
			return new QtyDetermEditor(
				cell,
				onRendered,
				success,
				cancel,
				editorParams
			).input
		case POSITION_TYPES.SUGGESTION:
			return new SuggestionEditor(
				cell,
				onRendered,
				success,
				cancel,
				editorParams
			).input
		default:
			return null
	}
}
