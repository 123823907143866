import { Theme, makeStyles } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
	root: {
		marginRight: theme.spacing(-0.5),
		marginLeft: theme.spacing(-0.5),
	},
	tag: {
		margin: theme.spacing(0.5),
	},
}))
