import React, { FC } from 'react'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

const EmptyContractInfoNotice: FC = () => {
	const { t } = useTranslation()

	return <Typography>{t('domain:general.regulations.empty')}</Typography>
}

export default EmptyContractInfoNotice
