import { BOQ_SIDESHEET_ATTRIBUTES_RENDERED } from 'app/config/boq'
import { BoqNode } from 'app/types/graphql/BoqNode'
import AttributeInfoItem from 'domain/general/boq/components/sidesheet/attributeInfos/AttributeInfoItem/AttributeInfoItem'
import { LineItemDetailSideSheetProps } from 'domain/general/boq/components/sidesheet/LineItemDetailSideSheet/LineItemDetailSideSheetProps'
import React, { FC } from 'react'

const LineItemDetailSideSheet: FC<LineItemDetailSideSheetProps> = ({
	boqNode,
}) => {
	return (
		<div>
			{BOQ_SIDESHEET_ATTRIBUTES_RENDERED.map(key => {
				return (
					<AttributeInfoItem
						boqNode={boqNode}
						attributeKey={key as keyof BoqNode}
					/>
				)
			})}
		</div>
	)
}

export default LineItemDetailSideSheet
