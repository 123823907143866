import { variantFromNodeStatus } from 'app/utils/tabulator'
import React, { ReactElement, FC } from 'react'
import { useTranslation } from 'react-i18next'
import TabulatorChip from 'component/TabulatorChip/TabulatorChip'
import {
	QUANTITY_DETERMINATION_NODE_STATUS,
	QuantityDeterminationNode,
} from 'app/config/quantityDetermination'
import QuantityDeterminationNodesStatusFormatterProps from './QuantityDeterminationNodesStatusFormatterProps'

const QuantityDeterminationNodesStatusFormatter: FC<QuantityDeterminationNodesStatusFormatterProps> = ({
	cell,
}): ReactElement | null => {
	const { t } = useTranslation()

	const row = cell.getRow()
	const quantityDeterminationNode = row.getData() as QuantityDeterminationNode
	const { status: quantityDeterminationNodeStatus } = quantityDeterminationNode

	if (
		quantityDeterminationNodeStatus &&
		quantityDeterminationNodeStatus !==
			QUANTITY_DETERMINATION_NODE_STATUS.TEMPORARY
	) {
		return (
			<TabulatorChip
				variant={variantFromNodeStatus(quantityDeterminationNodeStatus)}
			>
				{t(
					`domain:billing.quantityDeterminationItem.status.${quantityDeterminationNodeStatus}`
				)}
			</TabulatorChip>
		)
	}

	return null
}

export default QuantityDeterminationNodesStatusFormatter
