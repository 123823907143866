import React, { ReactElement, FC } from 'react'
import useStyles from './TabulatorIconButtonStyles'
import { TabulatorIconButtonProps } from './TabulatorIconButtonProps'

const TabulatorIconButton: FC<TabulatorIconButtonProps> = ({
	children,
	onClick,
	disabled,
}): ReactElement => {
	const classes = useStyles({ disabled })

	return (
		<button
			type="button"
			className={classes.root}
			onClick={onClick}
			disabled={disabled}
		>
			<span className={classes.svgContainer}>{children}</span>
		</button>
	)
}

export default TabulatorIconButton
