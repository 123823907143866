/* eslint-disable @typescript-eslint/camelcase */
import { AUTH_TOKEN_IDENTIFIER, REDIRECT_URL, USER_ROLES } from 'app/constants'
import useEnvironmentVariables from 'app/hooks/useEnvironmentVariables'
import useLocalStorage from 'app/hooks/useLocalStorage'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import { AuthenticationTokens, RestError } from 'app/types'
import axios from 'axios'
import queries from 'domain/account/apollo/queries'
import { camelCase, get } from 'lodash'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { GRANT_TYPES } from 'app/config/authentication'

export interface UseAuthentication {
	scope: string | null
	scopeAsArray: string[]
	accessToken: string | null
	tokenType: string | null
	refreshToken: string | null
	expiresIn: string | null
	isAdmin: boolean
	loading: boolean
	error: RestError | null
	resetError: () => void
	refreshStoredToken: (accountRefreshToken: string | null) => void
	login: (username: string, password: string) => void
}

export interface UseAuthenticationState {
	scope: string | null
	accessToken: string | null
	tokenType: string | null
	refreshToken: string | null
	expiresIn: string | null
}

export default (): UseAuthentication => {
	const ENV = useEnvironmentVariables()
	const history = useHistory()
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)
	const [tokens, setTokens] = useState<UseAuthenticationState>({
		scope: null,
		accessToken: null,
		tokenType: null,
		refreshToken: null,
		expiresIn: null,
	})

	const [fetchAccountInformations] = useGraphQLLazyQuery(
		queries.AccountInformations,
		{
			fetchPolicy: 'network-only',
			onCompleted: () => {
				history.push(REDIRECT_URL)
			},
		}
	)

	const [
		authenticationTokens,
		setAuthenticationTokens,
	] = useLocalStorage<AuthenticationTokens | null>(AUTH_TOKEN_IDENTIFIER, null)

	const keys = [
		'scope',
		'access_token',
		'token_type',
		'refresh_token',
		'expires_in',
	]

	const getByKey = <T>(key: string): T | null =>
		get(authenticationTokens, [key], null) as T | null

	useEffect(() => {
		setTokens(
			keys.reduce(
				(prev, key) => ({ ...prev, [camelCase(key)]: getByKey(key) }),
				tokens
			)
		)
	}, [authenticationTokens])

	const convertScopeToArray = (): string[] => {
		const { scope } = tokens
		return scope ? scope.split(' ') : []
	}

	const isAdmin = !!convertScopeToArray().find(
		role => role === USER_ROLES.ADMIN
	)

	const refreshStoredToken = (currentRefreshToken: string | null): void => {
		if (currentRefreshToken) {
			setLoading(true)
			axios
				.post(ENV.REACT_APP_API_OAUTH_URL, {
					grant_type: GRANT_TYPES.REFRESH_TOKEN,
					client_id: ENV.REACT_APP_API_CLIENT_ID,
					client_secret: ENV.REACT_APP_API_CLIENT_SECRET,
					refresh_token: currentRefreshToken,
				})
				.then(res => {
					setAuthenticationTokens(res.data)
				})
				.catch(err => {
					const { data } = err.response
					setError(data)
				})
				.finally(() => setLoading(false))
		}
	}

	const login = (username: string, password: string): void => {
		setLoading(true)
		axios
			.post(ENV.REACT_APP_API_OAUTH_URL, {
				username,
				password,
				grant_type: GRANT_TYPES.PASSWORD,
				client_id: ENV.REACT_APP_API_CLIENT_ID,
				client_secret: ENV.REACT_APP_API_CLIENT_SECRET,
			})
			.then(res => {
				setAuthenticationTokens(res.data)
				fetchAccountInformations()
			})
			.catch(errorResponse => {
				const { data } = errorResponse.response
				setError(data)
			})
			.finally(() => setLoading(false))
	}

	const resetError = (): void => {
		setError(null)
	}

	return {
		...tokens,
		scopeAsArray: convertScopeToArray(),
		isAdmin,
		login,
		error,
		resetError,
		loading,
		refreshStoredToken,
	}
}
