import MomentUtils from '@date-io/moment'
import { DATE_FORMAT_PICKER, TIME_FORMAT_PICKER } from 'app/config/locales'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import { REVISION_STATUS } from 'app/types/REVISION_STATUS'
import { UrlParams } from 'app/types/UrlParams'
import {
	getTenderingEndDateFromLot,
	getLotFromProject,
	getProjectFromResponse,
} from 'app/utils/apollo'
import { Notice } from 'component/index'
import Validate from 'component/Validate/Validate'
import { LOT_STATUS } from 'domain/lot/interfaces/LOT_STATUS'
import { ROLES_PROJECT } from 'domain/project/enums/ROLES_PROJECT'
import queries from 'domain/tender/bidders/apollo/queries'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

// see possible combination in confluence https://tenderframe.atlassian.net/wiki/spaces/IT/pages/30605313/UI+-+Status+combinations
const TenderNotice: FC = () => {
	const { t } = useTranslation()
	const { projectUuid, lotUuid } = useParams<UrlParams>()
	const [fetchTenderEndDate, { data }] = useGraphQLLazyQuery(
		queries.TenderEndDateForLot
	)

	const project = getProjectFromResponse(data)
	const lot = getLotFromProject(project)
	const endDate = getTenderingEndDateFromLot(lot)
	const momentEndDate = new MomentUtils().date(endDate)

	useEffect(() => {
		if (projectUuid && lotUuid) {
			fetchTenderEndDate({
				variables: {
					projectUuid,
					lotUuid,
				},
			})
		}
	}, [projectUuid, lotUuid])

	return (
		// notice is just for bidders visible
		<Validate projectRoles={[ROLES_PROJECT.BIDDER]}>
			{endDate && (
				<Validate
					validCombinations={[
						{
							projectRole: ROLES_PROJECT.BIDDER,
							lotStatus: LOT_STATUS.TENDERING,
							revisionStatus: REVISION_STATUS.BIDDING,
						},
					]}
				>
					<Notice
						type="info"
						message={t('domain:boq.notices.tenderingEndDate', {
							date: momentEndDate.format(DATE_FORMAT_PICKER),
							time: momentEndDate.format(TIME_FORMAT_PICKER),
						})}
					/>
				</Validate>
			)}

			<Validate
				validCombinations={[
					{
						lotStatus: LOT_STATUS.TENDERING,
						revisionStatus: REVISION_STATUS.SUBMITTED,
					},
				]}
			>
				<Notice
					type="success"
					message={t('domain:boq.notices.tenderingAndSubmitted')}
				/>
			</Validate>

			<Validate
				validCombinations={[
					{
						lotStatus: LOT_STATUS.AWARDING,
						revisionStatus: REVISION_STATUS.BIDDING,
					},
				]}
			>
				<Notice
					type="info"
					message={t('domain:boq.notices.awardingAndBidding')}
				/>
			</Validate>

			<Validate
				validCombinations={[
					{
						lotStatus: LOT_STATUS.AWARDING,
						revisionStatus: REVISION_STATUS.SUBMITTED,
					},
				]}
			>
				<Notice
					type="info"
					message={t('domain:boq.notices.awardingAndSubmitted')}
				/>
			</Validate>

			<Validate
				validCombinations={[
					{
						lotStatus: LOT_STATUS.AWARDED,
						revisionStatus: REVISION_STATUS.ACCEPTED,
					},
				]}
			>
				<Notice
					type="success"
					message={t('domain:boq.notices.awardedAndAccepted')}
				/>
			</Validate>

			<Validate
				validCombinations={[
					{
						lotStatus: LOT_STATUS.AWARDED,
						revisionStatus: REVISION_STATUS.DECLINED,
					},
				]}
			>
				<Notice
					type="error"
					message={t('domain:boq.notices.awardedAndDeclined')}
				/>
			</Validate>
		</Validate>
	)
}

export default TenderNotice
