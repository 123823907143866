import { makeStyles } from '@material-ui/core'

export default makeStyles({
	root: {
		width: '100%',
		position: 'relative',
	},
	spinnerContainer: {
		position: 'absolute',
		left: '50%',
		top: '50%',
		transform: 'translate(-50%, -50%)',
	},
})
