import { makeStyles, Theme } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'

export default makeStyles((theme: Theme) => ({
	ButtonRoot: {
		background: themeValues().colors.success,
		backgroundColor: themeValues().colors.success,
		padding: theme.spacing(1),
		'& .MuiOutlinedInput-root:hover': {
			border: 0,
		},
		'& .MuiButton-label': {
			minWidth: 'auto',
		},
	},
}))
