import { PrivateRoute } from 'app/routing'
import routes from 'app/routing/routes/routesApp'
import TrackingProvider from 'app/services/tracking/TrackingProvider'
import { ErrorBoundary } from 'component'
import RegisterForm from 'domain/register/components/RegisterForm/RegisterForm'
import ProjectRedirection from 'domain/project/components/base/ProjectRedirection/ProjectRedirection'
import React, { ReactElement } from 'react'
import { BrowserRouter, Redirect, Switch } from 'react-router-dom'
import TemplateAuthentication from 'template/TemplateAuthentication/TemplateAuthentication'
import TemplateCheckout from 'template/TemplateCheckout/TemplateCheckout'
import TemplateDefault from 'template/TemplateDefault/TemplateDefault'
import TemplatePageNotFound from 'template/TemplatePageNotFound/TemplatePageNotFound'
import TemplateFirstUsage from 'template/TemplateFirstUsage/TemplateFirstUsage'
import TemplateLogin from 'template/TemplateLogin/TemplateLogin'
import { ENVIRONMENTS } from 'app/constants'
import CreateDemoAccountForm from 'domain/register/components/CreateDemoAccountForm/CreateDemoAccountForm'
import LoginForm from 'domain/login/components/LoginForm/LoginForm'

const AppRouter = (): React.ReactElement => {
	const renderConditionalRoutes = (): ReactElement[] | null => {
		const currentEnvironment = process.env.REACT_APP_ENV as ENVIRONMENTS
		if (currentEnvironment === ENVIRONMENTS.DEMO) {
			return [
				<TemplateLogin
					exact
					path={routes.home}
					component={CreateDemoAccountForm}
				/>,
			]
		}
		return [
			<TemplateLogin
				key={routes.home}
				exact
				path={routes.home}
				component={LoginForm}
			/>,
			<TemplateCheckout
				key={routes.checkoutSteps}
				exact
				path={routes.checkoutSteps}
			/>,
			<TemplateCheckout key={routes.checkout} exact path={routes.checkout} />,
			<TemplateAuthentication
				key={routes.register}
				path={routes.register}
				component={RegisterForm}
			/>,
		]
	}

	return (
		<BrowserRouter>
			<TrackingProvider>
				<ErrorBoundary>
					<Switch>
						{renderConditionalRoutes()}

						<TemplateLogin exact path={routes.login} component={LoginForm} />

						<TemplateFirstUsage exact path={routes.projectCreate} />

						<TemplateDefault exact path={routes.project} />
						<TemplateDefault exact path={routes.lot} />
						<TemplateDefault exact path={routes.section} />
						<TemplateDefault exact path={routes.subSection} />

						<PrivateRoute
							exact
							path={routes.projects}
							component={ProjectRedirection}
						/>

						<TemplatePageNotFound exact path={routes['404']} />

						<Redirect to={routes['404']} />
					</Switch>
				</ErrorBoundary>
			</TrackingProvider>
		</BrowserRouter>
	)
}

export default AppRouter
