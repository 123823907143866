import rules from 'app/config/notAllowed'
import routes from 'app/routing/routes/routesApp'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import NotAllowedRule from 'app/types/NotAllowedRule'
import { UrlParams } from 'app/types/UrlParams'
import {
	getStatusFromLot,
	getLotFromProject,
	getProjectFromResponse,
	getRoleFromProject,
} from 'app/utils/apollo'
import { resolveRoute } from 'app/utils/route'
import Link from 'component/Link/Link'
import Loading from 'component/Loading/Loading'
import PageContentBody from 'component/PageContentBody/PageContentBody'
import PageContentHeader from 'component/PageContentHeader/PageContentHeader'
import PageContentParagraph from 'component/PageContentParagraph/PageContentParagraph'
import queries from 'domain/project/apollo/queries'
import NotAllowedProps from 'domain/project/components/base/NotAllowed/NotAllowedProps'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import React, { FC, useEffect, ReactNode, ReactElement } from 'react'
import { isEmpty } from 'lodash'

const NotAllowed: FC<NotAllowedProps> = ({ children, fallback, disabled }) => {
	const { t } = useTranslation()
	const { projectUuid, lotUuid, subSectionId } = useParams<UrlParams>()
	const [fetchData, { data, loading, called }] = useGraphQLLazyQuery(
		queries.NotAllowedCheck
	)

	const project = getProjectFromResponse(data)
	const role = getRoleFromProject(project)
	const lot = getLotFromProject(project)
	const status = getStatusFromLot(lot)

	const baseKey = `common:notAllowed.${role}.${subSectionId}.${status}`

	useEffect(() => {
		if (projectUuid && lotUuid) {
			fetchData({
				variables: {
					projectUuid,
					lotUuid,
				},
			})
		}
	}, [projectUuid, lotUuid])

	const findRuleByUrl = (): NotAllowedRule[] => {
		if (subSectionId) {
			const rule = rules[subSectionId]

			if (rule) {
				return rule || []
			}
		}

		return []
	}

	const validateRules = (): boolean => {
		const foundRule = findRuleByUrl()
		if (foundRule.length < 1) {
			return true
		}

		if (status && role) {
			return !!foundRule.find(rule => {
				const { role: ruleRole, status: ruleStatus } = rule
				return ruleRole === role && ruleStatus.includes(status)
			})
		}

		return false
	}

	const createTargetUrl = (): string => {
		const targetSectionId = t(`${baseKey}.to.section`)
		const targetSubSectionId = t(`${baseKey}.to.subSection`)

		if (isEmpty(targetSectionId) || isEmpty(targetSubSectionId)) {
			return ''
		}

		return resolveRoute(routes.subSection, {
			projectUuid,
			lotUuid,
			sectionId: targetSectionId,
			subSectionId: targetSubSectionId,
		})
	}

	const renderNotAllowedMessage = (): ReactElement | null => {
		if (role && status) {
			return (
				<PageContentParagraph>
					<Trans
						defaults={`${baseKey}.message`}
						values={{
							linkTitle: t(`${baseKey}.linkTitle`),
						}}
						components={[<Link to={createTargetUrl()}>empty</Link>]}
					/>
				</PageContentParagraph>
			)
		}
		return null
	}

	const renderChildren = (): ReactNode => {
		if (validateRules() || disabled) {
			return children
		}

		if (fallback) {
			return fallback(
				<>
					<PageContentHeader />
					<PageContentBody>{renderNotAllowedMessage()}</PageContentBody>
				</>
			)
		}

		return null
	}

	if (disabled) {
		return <>{renderChildren()}</>
	}

	return <Loading loading={!called || loading}>{renderChildren()}</Loading>
}

export default NotAllowed
