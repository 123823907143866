import { UrlParams } from 'app/types/UrlParams'
import {
	getStatusFromLot,
	getLotFromProject,
	getProjectFromResponse,
} from 'app/utils/apollo'
import { useParams } from 'react-router-dom'
import queries from 'domain/lot/apollo/queries'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import { useEffect } from 'react'
import { LOT_STATUS } from 'domain/lot/interfaces/LOT_STATUS'

interface UseLotStatus {
	hasValidStatus: boolean
	status: LOT_STATUS
	loading: boolean
}

export default (): UseLotStatus => {
	const { lotUuid, projectUuid } = useParams<UrlParams>()

	const [loadData, { data, loading }] = useGraphQLLazyQuery(
		queries.ProjectWithCurrentLotStatus,
		{
			variables: {
				projectUuid,
				lotUuid,
			},
		}
	)

	useEffect(() => {
		if (lotUuid) {
			loadData()
		}
	}, [lotUuid])

	const project = getProjectFromResponse(data)
	const lot = getLotFromProject(project)

	const currentStatus = getStatusFromLot(lot) || LOT_STATUS.DRAFT

	const hasValidStatus = (): boolean => {
		return !!currentStatus
	}

	return {
		hasValidStatus: hasValidStatus(),
		status: currentStatus,
		loading,
	}
}
