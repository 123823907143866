import { Theme, makeStyles } from '@material-ui/core'

export default makeStyles((theme: Theme) => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	noticeContainer: {
		width: '100%',
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
	},
	container: {
		marginTop: theme.spacing(4),
		width: '100%',
	},
}))
