import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { themeValues } from 'app/themes/themeValues'

export default makeStyles((theme: Theme) => ({
	root: {
		'& .MuiOutlinedInput-root': {
			position: 'relative',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
			'&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
			'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
				borderWidth: 1,
			},
		},
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		width: '100%',
		height: 'fit-content',
		borderRadius: themeValues().sizes.Button.borderRadius,
		'& button': {
			height: themeValues().sizes.ProjectDropdown.height,
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0,
			borderTopRightRadius: themeValues().sizes.Button.borderRadius,
			borderBottomRightRadius: themeValues().sizes.Button.borderRadius,
			boxShadow: 'none',
		},
	},
	createButton: {
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		borderTopRightRadius: themeValues().sizes.Button.borderRadius,
		borderBottomRightRadius: themeValues().sizes.Button.borderRadius,
		boxShadow: 'none',
	},
	label: {
		color: 'white',
	},
	select: {
		background: themeValues().colors.ProjectDropdown.background,
		overflow: 'hidden',
		flex: 1,
		padding: `0 ${theme.spacing(1)}px 0 ${theme.spacing(1)}px`,
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
	},
	menuItem: {
		width: '100%',
	},
}))
