/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	MutationHookOptions,
	MutationTuple,
	useMutation,
} from '@apollo/react-hooks'
import { OperationVariables } from '@apollo/react-common'
import { DocumentNode } from 'graphql'
import useApolloErrorHandling from 'app/hooks/useApolloErrorHandling'
import { ApolloError } from 'apollo-client'

export interface CustomMutationHookOptions<TData, TVariables>
	extends Omit<MutationHookOptions, 'onError'> {
	onError?: (
		error: ApolloError,
		handleError: (error: ApolloError) => void
	) => void
}

export default <TData = any, TVariables = OperationVariables>(
	mutation: DocumentNode,
	options?: CustomMutationHookOptions<TData, TVariables>
): MutationTuple<TData, TVariables> => {
	const [handleError] = useApolloErrorHandling()
	return useMutation(mutation, {
		...options,
		onError: (error: ApolloError) => {
			if (options && options.onError) {
				options.onError(error, handleError)
			} else {
				handleError(error)
			}
		},
	})
}
