import { gql } from 'apollo-boost'

const ProjectWithAwardBidders = gql`
	query ProjectWithAwardBidders($projectUuid: String!, $lotUuid: String!) {
		project(uuid: $projectUuid) {
			uuid
			name
			role
			lot(uuid: $lotUuid) {
				uuid
				status
				dataRevisions {
					data {
						uuid
						accesses
						bidders {
							uuid
							name
							isAwarded
						}
					}
				}
				__typename
			}
		}
	}
`

export default {
	ProjectWithAwardBidders,
}
