import {
	getStatusFromLot,
	getLotFromProject,
	getProjectFromResponse,
} from 'app/utils/apollo'
import { resolveRoute } from 'app/utils/route'
import Tab from 'component/Tab/Tab'
import { TabProps } from 'domain/lot/components/LotTab/LotTabProps'
import React, { FC, useState, useEffect, MouseEvent } from 'react'
import TabLabel from 'component/Tab/TabLabel/TabLabel'
import { Menu, MenuItem, Tooltip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ProjectData, ProjectVars } from 'app/types/index'
import useStyles from 'domain/lot/components/LotTab/LotTabStyles'
import { UrlParams } from 'app/types/UrlParams'
import mutations from 'domain/lot/apollo/mutations'
import { useParams } from 'react-router-dom'
import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import useCustomSnackbar from 'app/services/snackbar/useCustomSnackbar'
import { LOT_STATUS } from 'domain/lot/interfaces/LOT_STATUS'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import queries from 'domain/lot/apollo/queries'
import routes from 'app/routing/routes/routesApp'
import HelpIcon from '@material-ui/icons/Help'

const LotTab: FC<TabProps> = ({ item, onClick, selected = false }) => {
	const { id, title, icon, menuIcon } = item
	const classes = useStyles({ selected })
	const { sendErrorMessage } = useCustomSnackbar()
	const { t } = useTranslation()

	const { projectUuid, lotUuid, sectionId } = useParams<UrlParams>()
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const [inEditMode, setEditMode] = useState(false)

	const [fetchData, { data }] = useGraphQLLazyQuery<ProjectData, ProjectVars>(
		queries.ProjectWithCurrentLotStatus
	)

	const onCompleteReset = (): void => {
		window.location.href = resolveRoute(routes.section, {
			projectUuid,
			lotUuid,
			sectionId,
		})
	}

	const [resetLot] = useGraphQLMutation<ProjectData, ProjectVars>(
		mutations.resetLot,
		{ onCompleted: onCompleteReset }
	)

	useEffect(() => {
		if (projectUuid) {
			fetchData({
				variables: {
					projectUuid,
					lotUuid,
				},
			})
		}
	}, [projectUuid, lotUuid])

	const project = getProjectFromResponse(data)
	const lot = getLotFromProject(project)
	const lotStatus = getStatusFromLot(lot)

	const handleClick = (e: MouseEvent<HTMLButtonElement>): void => {
		setAnchorEl(e.currentTarget)
	}

	useEffect(() => {
		if (lotUuid !== id) {
			setEditMode(false)
		}
	}, [lotUuid])

	const handleClose = (): void => {
		setAnchorEl(null)
	}

	const onCompleted = (): void => {
		setEditMode(false)
	}

	const [updateLot] = useGraphQLMutation(mutations.updateLot, {
		onCompleted,
	})

	const isValidInput = (value: string | null): boolean => {
		return !!value
	}

	const modifyTabLabel = (): void => {
		setEditMode(true)
		handleClose()
	}

	const onAcceptLot = (lotName: string | null): void => {
		const wsRegex = /\S.*\S/
		const result = lotName?.match(wsRegex) || ''
		if (isValidInput(lotName) && lotName && result.length) {
			updateLot({
				variables: {
					projectUuid,
					lotUuid: id,
					lotName: lotName.trim(),
				},
			})
		} else {
			sendErrorMessage(t('domain:lot.lotMenu.updateLot.validatorViolation'))
		}
	}

	const onResetLot = (): void => {
		if (lotStatus === LOT_STATUS.CREATED) {
			resetLot({
				variables: {
					projectUuid,
					lotUuid,
				},
			})
		}
	}

	const onDeclineLot = (): void => {
		setEditMode(false)
	}

	return (
		<>
			<Tab
				classes={{ root: classes.root }}
				selected={selected}
				value={id}
				label={
					<TabLabel
						className={classes.menuIcon}
						icon={icon}
						menuIcon={menuIcon}
						title={title}
						selected={selected}
						onClickMenu={handleClick}
						inEditMode={inEditMode}
						onAcceptLot={onAcceptLot}
						onDeclineLot={onDeclineLot}
					/>
				}
				onClick={() => onClick(id)}
			/>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				classes={{ list: classes.list }}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<MenuItem onClick={modifyTabLabel} key="Update" value="Update">
					{t('domain:lot.lotMenu.updateLot.title')}
				</MenuItem>
				<MenuItem
					onClick={onResetLot}
					key="Reset"
					value="Reset"
					disabled={lotStatus !== LOT_STATUS.CREATED}
				>
					{t('domain:lot.lotMenu.resetLot.title')}
					{lotStatus !== LOT_STATUS.CREATED ? (
						<Tooltip
							title={t('domain:lot.lotMenu.resetLot.disabled.tooltip')}
							className={classes.tooltip}
						>
							<HelpIcon />
						</Tooltip>
					) : null}
				</MenuItem>
			</Menu>
		</>
	)
}

export default LotTab
