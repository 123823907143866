import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import { getProjectFromResponse } from 'app/utils/apollo'
import Loading from 'component/Loading/Loading'
import React, { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { PageContent } from 'component/index'
import queries from 'domain/general/boq/apollo/queries'
import AttachmentPageContentHeader from 'domain/general/attachments/components/base/AttachmentPageContentHeader/AttachmentPageContentHeader'
import AttachmentPageContentBody from 'domain/general/attachments/components/base/AttachmentPageContentBody/AttachmentPageContentBody'
import { UrlParams } from 'app/types/UrlParams'

const AttachmentPageContent: FC = () => {
	const { projectUuid, lotUuid, sectionId } = useParams<UrlParams>()

	const [fetchData, { loading, data }] = useGraphQLLazyQuery(
		queries.ProjectWithBoqInformation,
		{
			variables: {
				projectUuid,
				lotUuid,
			},
		}
	)

	useEffect(() => {
		if (projectUuid && lotUuid && sectionId) {
			fetchData({
				variables: {
					projectUuid,
					lotUuid,
				},
			})
		}
	}, [projectUuid, lotUuid, sectionId])

	const project = getProjectFromResponse(data)

	return (
		<Loading loading={loading}>
			<PageContent
				key={lotUuid}
				header={<AttachmentPageContentHeader project={project} />}
				body={<AttachmentPageContentBody project={project} />}
			/>
		</Loading>
	)
}

export default AttachmentPageContent
