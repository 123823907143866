/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	PRICING_PLANS_SCOPE_ORDER,
	MAP_PRICING_PLAN_TO_SCOPE,
} from 'app/config/pricingPlans'
import getDomainEvents from 'app/domainEvents/events'
import useAuthentication from 'app/hooks/useAuthentication'
import { getDomainEventByEventKey } from 'app/utils/domainEvents'
import ConfirmationDialog from 'component/ConfirmationDialog/ConfirmationDialog'
import { DOMAIN_EVENT_TRIGGER } from 'domain/project/enums/DOMAIN_EVENT_TRIGGER'
import { DOMAIN_EVENTS } from 'domain/project/enums/DOMAIN_EVENTS'
import { DomainEvent } from 'domain/project/interfaces/DomainEvent'
import { useDropzone } from 'react-dropzone'
import React, {
	ReactElement,
	useState,
	useCallback,
	ReactNode,
	Children,
	useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'

interface UseValidateDomainEventOptions {
	event: DOMAIN_EVENTS
	trigger: DOMAIN_EVENT_TRIGGER
	currentCount?: number
}

interface UseValidateDomainEvent {
	generateDialogMarkup: () => ReactElement | null
	render: (children: ReactNode) => ReactElement | null
	domainEventObject: DomainEvent | null
	maxCount: number | null
	openDialog: () => void
	closeDialog: () => void
	hasAccess: () => boolean
}

export default (
	options: UseValidateDomainEventOptions
): UseValidateDomainEvent => {
	const [isDialogOpen, setIsDialogOpen] = useState(false)

	const { t } = useTranslation()
	const { event, trigger, currentCount } = options
	const { scopeAsArray } = useAuthentication()

	const domainEvents = getDomainEvents()
	const domainEventObject = getDomainEventByEventKey(domainEvents, event)
	const maxCount = domainEventObject?.maxCount || null

	useEffect(() => {
		if (DOMAIN_EVENT_TRIGGER.ALWAYS === trigger) {
			setIsDialogOpen(true)
		}
	}, [trigger])

	const { getRootProps } = useDropzone({
		noDrag: true,
	})

	const getDialogMessage = (): string => {
		return PRICING_PLANS_SCOPE_ORDER.reduce((prev, value) => {
			if (
				domainEventObject &&
				domainEventObject.upgradeMessage[value] &&
				scopeAsArray.includes(MAP_PRICING_PLAN_TO_SCOPE[value]) &&
				!prev
			) {
				return domainEventObject.upgradeMessage[value]
			}
			return prev
		}, '')
	}

	const openDialog = (): void => {
		setIsDialogOpen(true)
	}

	const closeDialog = (): void => {
		setIsDialogOpen(false)
	}

	const generateDialogMarkup = useCallback(() => {
		if (domainEventObject) {
			const { name } = domainEventObject
			return (
				<ConfirmationDialog
					onAcceptLabel={t('common:validateDomainEvent.dialog.buttons.confirm')}
					open={isDialogOpen}
					title={name}
					contentText={getDialogMessage()}
					onAccept={() => setIsDialogOpen(false)}
				/>
			)
		}

		return null
	}, [isDialogOpen, domainEventObject])

	const hasAccess = (): boolean => {
		let userHasValidPlan = false
		if (domainEventObject) {
			const { plans } = domainEventObject
			userHasValidPlan = plans.reduce((prev, value) => {
				return scopeAsArray.includes(MAP_PRICING_PLAN_TO_SCOPE[value]) || prev
			}, false as boolean)
		}

		if (maxCount !== null) {
			userHasValidPlan = maxCount > (currentCount || 0)
		}

		return userHasValidPlan
	}

	const handleEvent = (): void => {
		setIsDialogOpen(true)
	}

	const modifyEventsOfChild = (child: ReactNode): ReactNode => {
		let newProps = {}

		if (hasAccess()) {
			return child
		}

		switch (trigger) {
			case DOMAIN_EVENT_TRIGGER.ON_CLICK:
			case DOMAIN_EVENT_TRIGGER.ON_CHANGE:
				newProps = {
					[trigger]: handleEvent,
				}
				break
			case DOMAIN_EVENT_TRIGGER.ON_DROP:
				newProps = {
					...getRootProps(),
					onClick: handleEvent,
					[trigger]: handleEvent,
				}
				break
			default:
				break
		}

		// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
		// @ts-ignore
		return React.cloneElement(child, newProps)
	}

	const render = (children: ReactNode): ReactElement | null => {
		if (hasAccess()) {
			return <>{children}</>
		}

		const eventsToHandle = [
			DOMAIN_EVENT_TRIGGER.ON_CLICK,
			DOMAIN_EVENT_TRIGGER.ON_CHANGE,
			DOMAIN_EVENT_TRIGGER.ON_DROP,
		]

		if (eventsToHandle.includes(trigger)) {
			return (
				<>
					{Children.map(children, (child: any) => modifyEventsOfChild(child))}
					{generateDialogMarkup()}
				</>
			)
		}

		return <>{children}</>
	}

	return {
		generateDialogMarkup,
		render,
		domainEventObject,
		maxCount,
		openDialog,
		closeDialog,
		hasAccess,
	}
}
