import useForm from 'app/hooks/useForm'
import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import useCustomSnackbar from 'app/services/snackbar/useCustomSnackbar'
import { UrlParams } from 'app/types/UrlParams'
import PrimaryButton from 'component/PrimaryButton/PrimaryButton'
import mutations from 'domain/tender/bidders/apollo/mutations'
import queries from 'domain/tender/bidders/apollo/queries'
import { CreateContactFormProps } from 'domain/tender/bidders/components/sidesheet/contacts/CreateContactForm/CreateContactFormProps'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { useParams } from 'react-router-dom'

import useStyles from 'domain/tender/bidders/components/sidesheet/contacts/CreateContactForm/CreateContactFormStyles'

const CreateContactForm: FC<CreateContactFormProps> = ({
	contact,
	disabled = false,
}) => {
	const classes = useStyles()

	const { projectUuid, lotUuid } = useParams<UrlParams>()
	const { t } = useTranslation()
	const { sendSuccessMessage } = useCustomSnackbar()
	const {
		handleInputChange,
		formData,
		reset,
		createFormDataFromObject,
	} = useForm({
		defaultValues: { firstName: '', lastName: '', email: '', company: '' },
	})

	const [createContact, { loading }] = useGraphQLMutation(
		mutations.createContact,
		{
			onCompleted: () => {
				sendSuccessMessage(t('domain:tender.createContact.success'))
				reset()
			},
		}
	)

	useEffect(() => {
		if (contact) {
			const { firstName, lastName, email, company } = contact
			createFormDataFromObject({
				firstName,
				lastName,
				email,
				company,
			})
		}
	}, [contact])

	const onSubmit = (): void => {
		createContact({
			variables: { ...formData },
			refetchQueries: [
				{
					query: queries.ProjectWithTenderInformationAndContacts,
					variables: { projectUuid, lotUuid },
				},
			],
		})
	}

	const handleRequiredFieldLabel = (label: string): string => {
		return disabled ? label : `${label} *`
	}

	return (
		<ValidatorForm onSubmit={onSubmit}>
			<TextValidator
				name="firstName"
				value={formData.firstName}
				label={t('domain:tender.createContact.form.firstName.label')}
				fullWidth
				placeholder={t(
					'domain:tender.createContact.form.firstName.placeholder'
				)}
				margin="normal"
				disabled={disabled}
				onChange={handleInputChange}
			/>
			<TextValidator
				validators={['required']}
				errorMessages={[
					t(
						'domain:tender.createContact.form.lastName.validatorViolations.required'
					),
				]}
				name="lastName"
				value={formData.lastName}
				label={handleRequiredFieldLabel(
					t('domain:tender.createContact.form.lastName.label')
				)}
				fullWidth
				placeholder={t('domain:tender.createContact.form.lastName.placeholder')}
				margin="normal"
				disabled={disabled}
				onChange={handleInputChange}
			/>
			<TextValidator
				validators={['required']}
				errorMessages={[
					t(
						'domain:tender.createContact.form.email.validatorViolations.required'
					),
				]}
				name="email"
				value={formData.email}
				type="email"
				label={handleRequiredFieldLabel(
					t('domain:tender.createContact.form.email.label')
				)}
				fullWidth
				placeholder={t('domain:tender.createContact.form.email.placeholder')}
				margin="normal"
				disabled={disabled}
				onChange={handleInputChange}
			/>
			<TextValidator
				name="company"
				validators={['required']}
				errorMessages={[
					t(
						'domain:tender.createContact.form.company.validatorViolations.required'
					),
				]}
				value={formData.company}
				label={handleRequiredFieldLabel(
					t('domain:tender.createContact.form.company.label')
				)}
				fullWidth
				placeholder={t('domain:tender.createContact.form.company.placeholder')}
				margin="normal"
				disabled={disabled}
				onChange={handleInputChange}
			/>
			{!contact && (
				<PrimaryButton
					classes={{ root: classes.buttonRoot }}
					type="submit"
					fullWidth={false}
					loading={loading}
				>
					{t('domain:tender.createContact.form.submit')}
				</PrimaryButton>
			)}
		</ValidatorForm>
	)
}

export default CreateContactForm
