import { getStatusFromQuantityDetermination } from 'app/utils/apollo'
import React, { ReactElement, FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import {
	QUANTITY_DETERMINATION_NODE_STATUS,
	QUANTITY_DETERMINATION_STATUS,
	QuantityDeterminationNode,
} from 'app/config/quantityDetermination'
import TabulatorButton from 'component/TabulatorButton/TabulatorButton'
import { POSITION_TYPES } from 'app/types/POSITION_TYPES'
import TabulatorTextLink from 'component/TabulatorTextLink/TabulatorTextLink'
import QuantityDeterminationLeadEditFormatterProps from './QuantityDeterminationLeadEditFormatterProps'
import useStyles from './QuantityDeterminationLeadEditFormatterStyles'

const QuantityDeterminationLeadEditFormatter: FC<QuantityDeterminationLeadEditFormatterProps> = ({
	cell,
	onClickStatus,
	onClickHandleSuggestion,
	quantityDetermination,
}): ReactElement | null => {
	const { t } = useTranslation()

	const quantityDeterminationStatus =
		quantityDetermination &&
		getStatusFromQuantityDetermination(quantityDetermination)

	const row = cell.getRow()
	const quantityDeterminationNode = row.getData() as QuantityDeterminationNode
	const {
		type,
		status: quantityDeterminationNodeStatus,
		quantityDeterminationNodes,
	} = quantityDeterminationNode

	const acceptDisabled =
		quantityDeterminationNodeStatus ===
			QUANTITY_DETERMINATION_NODE_STATUS.ACCEPTED ||
		quantityDeterminationStatus === QUANTITY_DETERMINATION_STATUS.REVIEWED ||
		quantityDeterminationStatus === QUANTITY_DETERMINATION_STATUS.ACCEPTED ||
		quantityDeterminationStatus === QUANTITY_DETERMINATION_STATUS.COMPLETED

	const declineDisabled =
		quantityDeterminationNodeStatus ===
			QUANTITY_DETERMINATION_NODE_STATUS.DECLINED ||
		quantityDeterminationStatus === QUANTITY_DETERMINATION_STATUS.REVIEWED ||
		quantityDeterminationStatus === QUANTITY_DETERMINATION_STATUS.ACCEPTED ||
		quantityDeterminationStatus === QUANTITY_DETERMINATION_STATUS.COMPLETED

	const shouldTextLinkCreateSuggestionRender =
		((Array.isArray(quantityDeterminationNodes) &&
			quantityDeterminationNodes.length < 1) ||
			!quantityDeterminationNodes) &&
		type === POSITION_TYPES.QTY_DETERM &&
		quantityDeterminationNodeStatus ===
			QUANTITY_DETERMINATION_NODE_STATUS.DECLINED &&
		quantityDeterminationStatus === QUANTITY_DETERMINATION_STATUS.SUBMITTED

	const shouldTextLinkDeleteSuggestionRender =
		type === POSITION_TYPES.SUGGESTION &&
		(quantityDeterminationNodeStatus ===
			QUANTITY_DETERMINATION_NODE_STATUS.TEMPORARY ||
			quantityDeterminationNodeStatus ===
				QUANTITY_DETERMINATION_NODE_STATUS.PENDING) &&
		quantityDeterminationStatus === QUANTITY_DETERMINATION_STATUS.SUBMITTED

	const classes = useStyles({
		declineDisabled,
		acceptDisabled,
		quantityDeterminationNodeStatus,
		quantityDeterminationStatus,
	})

	const renderCreateSuggestionLink = (): ReactNode => {
		if (shouldTextLinkCreateSuggestionRender && onClickHandleSuggestion) {
			return (
				<TabulatorTextLink
					className={classes.suggestionLink}
					onClick={() => onClickHandleSuggestion(cell)}
				>
					<p>{t(`domain:billing.suggestion.link.title.createSuggestion`)}</p>
				</TabulatorTextLink>
			)
		}
		return null
	}

	if (quantityDeterminationNodeStatus && onClickHandleSuggestion) {
		if (
			onClickStatus &&
			quantityDeterminationNodeStatus !==
				QUANTITY_DETERMINATION_NODE_STATUS.TEMPORARY &&
			type === POSITION_TYPES.QTY_DETERM
		) {
			return (
				<div>
					<TabulatorButton
						variant="success"
						className={classes.acceptIcon}
						onClick={() => onClickStatus(cell, 'accept')}
						disabled={acceptDisabled}
					>
						<svg
							className="MuiSvgIcon-root"
							focusable="false"
							viewBox="0 0 24 24"
							aria-hidden="true"
						>
							<path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
						</svg>
					</TabulatorButton>
					<TabulatorButton
						variant="error"
						className={classes.cancelIcon}
						onClick={() => onClickStatus(cell, 'decline')}
						disabled={declineDisabled}
					>
						<svg
							className="MuiSvgIcon-root"
							focusable="false"
							viewBox="0 0 24 24"
							aria-hidden="true"
							name="reject"
						>
							<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
						</svg>
					</TabulatorButton>
					{renderCreateSuggestionLink()}
				</div>
			)
		}

		if (onClickHandleSuggestion && shouldTextLinkDeleteSuggestionRender) {
			return (
				<TabulatorTextLink
					className={classes.suggestionLink}
					onClick={() => onClickHandleSuggestion(cell)}
				>
					<p>{t(`domain:billing.suggestion.link.title.deleteSuggestion`)}</p>
				</TabulatorTextLink>
			)
		}
	}
	return null
}

export default QuantityDeterminationLeadEditFormatter
