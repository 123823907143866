import { useRef, useEffect } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const usePrevious = <T>(value: T): T => {
	const ref = useRef<T>()

	// store current value in ref
	useEffect(() => {
		ref.current = value
	}, [value]) // Only re-run if value changes

	// Return previous value (happens before update in useEffect above)
	return ref.current as T
}

export default usePrevious
