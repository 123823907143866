import { Theme, createMuiTheme } from '@material-ui/core/styles'
import { themeValues } from './themeValues'

const theme = createMuiTheme({
	palette: {
		primary: {
			light: '#71c4dd',
			main: '#278eb1',
			dark: '#367CA3',
			contrastText: '#fff',
		},
		secondary: {
			main: '#4cce5c',
			contrastText: '#fff',
		},
	},
	typography: {
		fontFamily: `"Open Sans", sans-serif`,
		h5: {
			fontWeight: 500,
			fontSize: 26,
			letterSpacing: 0.5,
		},
	},
	props: {},
	mixins: {
		toolbar: {
			minHeight: 60,
			backgroundColor: 'transparent',
			color: 'white',
		},
	},
})

const themeCustom: Theme = {
	...theme,
	overrides: {
		MuiAppBar: {
			root: {
				boxShadow: 'none',
				backgroundColor: 'transparent !important',
			},
		},
		MuiDrawer: {
			paper: {
				backgroundColor: '#F4F4F4',
				borderRight: 'none !important',
				paddingRight: '10px',
			},
		},
		MuiButton: {
			label: {
				textTransform: 'none',
				minWidth: '100px',
			},
			contained: {
				color: '#fff',
			},
			containedPrimary: {
				'&.Mui-disabled': {
					color: 'white',
					opacity: 0.5,
					backgroundColor: theme.palette.primary.main,
				},
			},
			containedSecondary: {
				'&.Mui-disabled': {
					color: 'white',
					opacity: 0.5,
					backgroundColor: theme.palette.secondary.main,
				},
			},
		},
		MuiTabs: {
			root: {
				marginLeft: 0,
			},
		},
		MuiTab: {
			root: {
				fontSize: 15,
				// minWidth: 'none',
				// width: 1000,
			},
			labelIcon: {
				minHeight: themeValues().sizes.LotTab.height,
			},
			wrapper: {
				position: 'relative',
			},
		},
		MuiSelect: {
			select: {
				'&:focus': {
					backgroundColor: '#fff',
				},
			},
		},
		MuiTypography: {
			h4: {
				color: themeValues().palette.gray.dark,
			},
			root: {
				textTransform: 'none',
			},
			button: {
				textTransform: 'none',
			},
		},
		MuiTooltip: {
			tooltip: {
				borderRadius: 4,
			},
		},
		MuiDivider: {
			root: {
				backgroundColor: '#bbb',
			},
		},
		MuiListItemText: {
			primary: {
				fontWeight: theme.typography.fontWeightRegular,
			},
		},
		MuiListItemIcon: {
			root: {
				color: 'inherit',
				marginRight: 0,
				'& svg': {
					fontSize: 20,
				},
			},
		},
		MuiMenuItem: {
			root: {
				'&$selected': {
					backgroundColor: 'transparent',
				},
			},
		},
		MuiAvatar: {
			root: {
				width: 32,
				height: 32,
			},
		},
	},
}

export default themeCustom
