import routes from 'app/routing/routes/routesApp'
import useGraphQLMutation from 'app/services/apollo/hooks/useGraphQLMutation'
import { CreateLotVars, ProjectData } from 'app/types/index'
import { SECTIONS } from 'app/types/SECTIONS'
import { UrlParams } from 'app/types/UrlParams'
import { resolveRoute } from 'app/utils/route'
import mutations from 'domain/lot/apollo/mutations'
import { get, isString } from 'lodash'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import PrimaryButton from 'component/PrimaryButton/PrimaryButton'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import useStyles from 'domain/lot/components/CreateLotForm/CreateLotFormStyles'
import useForm from 'app/hooks/useForm'

const CreateLotForm: FC = () => {
	const { t } = useTranslation()
	const classes = useStyles()

	const { projectUuid } = useParams<UrlParams>()

	const { formData, handleInputChange } = useForm()

	const { lotName } = formData

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onCompleted = (lots: any): void => {
		const lotUuid: string = get(lots, ['project', 'createLot', 'uuid'], null)

		window.location.href = resolveRoute(routes.section, {
			projectUuid,
			lotUuid,
			sectionId: SECTIONS.BOQ,
		})
	}

	const [createLot, { loading }] = useGraphQLMutation<
		ProjectData,
		CreateLotVars
	>(mutations.createLot, { onCompleted })

	const onSubmit = (): void => {
		if (lotName && isString(lotName)) {
			createLot({
				variables: {
					projectUuid,
					lotName,
				},
			})
		}
	}

	return (
		<ValidatorForm onSubmit={onSubmit}>
			<TextValidator
				name="lotName"
				fullWidth
				validation={['required']}
				value={lotName}
				onChange={handleInputChange}
				type="text"
				placeholder={t('domain:lot.createLot.placeholder.inputForm')}
			/>
			<PrimaryButton
				loading={loading}
				type="submit"
				classes={{ root: classes.submitButton }}
			>
				{t('domain:lot.createLot.label.button')}
			</PrimaryButton>
		</ValidatorForm>
	)
}

export default CreateLotForm
