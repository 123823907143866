import { gql } from 'apollo-boost'

const AccountInformations = gql`
	query AccountInformations {
		account {
			uuid
			ip
			firstName
			lastName
		}
	}
`

const AccountConnectedPayments = gql`
	query AccountConnectedPayments {
		account {
			uuid
			stripePaymentMethods {
				stripePaymentMethodId
				name
				cardBrand
				cardCountry
				cardExpMonth
				cardExpYear
				cardLast4
				cardAddressLineCheck
			}
		}
	}
`

export default {
	AccountInformations,
	AccountConnectedPayments,
}
