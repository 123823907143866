import PageContentHeader from 'component/PageContentHeader/PageContentHeader'
import React, { FC } from 'react'
import CreateAwarding from '../../body/CreateAwarding/CreateAwarding'

const PeriodPageContentHeader: FC = () => {
	const createAwarding = [<CreateAwarding key="createAwarding" />]

	return <PageContentHeader buttons={createAwarding} />
}

export default PeriodPageContentHeader
