import { gql } from 'apollo-boost'

const ProjectWithInformations = gql`
	query ProjectWithInformations($projectUuid: String!, $lotUuid: String!) {
		project(uuid: $projectUuid) {
			uuid
			name
			lot(uuid: $lotUuid) {
				uuid
				dataRevisions {
					data {
						gaebInfo {
							version
							date
							progSystem
							progName
						}
						projectInfo {
							name
							label
							description
							currency
							currencyLabel
						}
						awardInfo {
							currency
							currencyLabel
							city
						}
						boqInfo {
							name
							label
							date
						}
					}
				}
			}
		}
	}
`
export default {
	ProjectWithInformations,
}
