import { getAccountFromResponse } from 'app/utils/apollo'
import React, { FC } from 'react'
import Typography from '@material-ui/core/Typography'
import useGraphQLQuery from 'app/services/apollo/hooks/useGraphQLQuery'
import queries from 'domain/account/apollo/queries'

// custom Styles MUST be imported last to ensure style heredity
import useStyles from 'domain/header/components/profileMenu/ProfileName/ProfileNameStyles'

const ProfileName: FC = () => {
	const classes = useStyles()
	const { data } = useGraphQLQuery(queries.AccountInformations, {
		fetchPolicy: 'network-only',
	})

	const { firstName, lastName } = getAccountFromResponse(data) || {}
	return (
		<Typography className={classes.root} variant="h6" noWrap>
			{firstName} {lastName}
		</Typography>
	)
}

export default ProfileName
