import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import routes from 'app/routing/routes/routesApp'
import useGraphQLQuery from 'app/services/apollo/hooks/useGraphQLQuery'
import { ProjectListData, ProjectListVars } from 'app/types/index'
import { UrlParams } from 'app/types/UrlParams'
import {
	getProjectsFromResponse,
	getFirstLotFromProject,
} from 'app/utils/apollo'
import { resolveRoute } from 'app/utils/route'
import clsx from 'clsx'
import Loading from 'component/Loading/Loading'
import queries from 'domain/drawer/apollo/queries'
import CreateProjectButton from 'domain/project/components/header/CreateProjectButton/CreateProjectButton'
import { ProjectDropdownProps } from 'domain/project/components/header/ProjectDropdown/ProjectDropdownProps'
import { isString } from 'lodash'
import React, { ChangeEvent, FC, ReactNode } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import useStyles from 'domain/project/components/header/ProjectDropdown/ProjectDropdownStyles'

const ProjectDropdown: FC<ProjectDropdownProps> = ({
	create,
	classes: parentClasses = {},
	label,
}) => {
	const { projectUuid: projectUuidFromUrl } = useParams<UrlParams>()
	const classes = useStyles()

	const { push } = useHistory()
	const match = useRouteMatch()
	const { path: currentRoute } = match || {}

	const { data, loading } = useGraphQLQuery<ProjectListData, ProjectListVars>(
		queries.ProjectListWithSelectInformation
	)

	const projects = getProjectsFromResponse(data)

	const handleChangeProject = (
		event: ChangeEvent<{
			name?: string
			value: unknown
		}>
	): void => {
		const projectUuid = event.target.value

		if (currentRoute && isString(projectUuid) && Array.isArray(projects)) {
			const project = projects.find(value => value.uuid === projectUuid) || null
			const firstLot = getFirstLotFromProject(project)

			if (project && firstLot) {
				push(
					resolveRoute(routes.section, {
						projectUuid,
						lotUuid: firstLot.uuid,
						sectionId: 'boq',
					})
				)
			} else {
				push(
					resolveRoute(routes.project, {
						projectUuid,
					})
				)
			}
		}
	}

	const renderMenuItems = (): ReactNode | null => {
		if (!projects || (Array.isArray(projects) && projects.length < 1)) {
			return null
		}

		return projects.map(project => {
			return (
				<MenuItem
					className={classes.menuItem}
					value={project.uuid}
					key={project.uuid}
				>
					{project.name}
				</MenuItem>
			)
		})
	}

	return (
		<FormControl
			variant="outlined"
			className={clsx(classes.root, parentClasses.root)}
		>
			<Loading loading={loading} size={30}>
				<InputLabel
					focused={false}
					classes={{
						root: classes.label,
						focused: classes.label,
						animated: classes.label,
						shrink: classes.label,
					}}
					disableAnimation
					id="projectDropdown"
				>
					{label}
				</InputLabel>
				<Select
					labelId="projectDropdown"
					classes={{
						select: classes.select,
					}}
					autoWidth
					fullWidth
					className={classes.select}
					value={projectUuidFromUrl || undefined}
					onChange={handleChangeProject}
				>
					{renderMenuItems()}
				</Select>
				{create && <CreateProjectButton />}
			</Loading>
		</FormControl>
	)
}

export default ProjectDropdown
