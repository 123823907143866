import { UrlParams } from 'app/types/UrlParams'
import {
	getLotFromProject,
	getProjectFromResponse,
	getFirstRevisionFromLot,
} from 'app/utils/apollo'
import PageContent from 'component/PageContent/PageContent'
import React, { ReactElement, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import queries from 'domain/general/regulations/apollo/queries'
import { List, ListItem } from '@material-ui/core'
import { get } from 'lodash'
import PageContentBody from 'component/PageContentBody/PageContentBody'
import { ContractInfo } from 'app/types/ContractInfo'
import PageContentHeading2 from 'component/PageContentHeading2/PageContentHeading2'
import PageContentParagraph from 'component/PageContentParagraph/PageContentParagraph'
import useGraphQLLazyQuery from 'app/services/apollo/hooks/useGraphQLLazyQuery'
import RegulationsPageContentHeader from '../RegulationsPageContentHeader/RegulationsPageContentHeader'
import EmptyContractInfoNotice from '../../body/status/EmptyNotice/EmptyContractInfoNotice'

const RegulationsPageContent = (): ReactElement | null => {
	const { projectUuid, lotUuid } = useParams<UrlParams>()

	const [LotWithContractInfo, { data }] = useGraphQLLazyQuery(
		queries.LotWithContractInfo
	)

	const project = getProjectFromResponse(data)
	const lot = getLotFromProject(project)
	const revision = getFirstRevisionFromLot(lot)
	const contractInfo = get(revision, ['contractInfo'])

	useEffect(() => {
		LotWithContractInfo({
			variables: {
				projectUuid,
				lotUuid,
			},
		})
	}, [projectUuid, lotUuid])

	const renderPageContent = (): ReactElement => {
		if (contractInfo && contractInfo.length > 0) {
			return (
				<List>
					{contractInfo.map(
						({ shortText, longText }: ContractInfo, index: number) => {
							return (
								// eslint-disable-next-line react/no-array-index-key
								<ListItem key={`${index}`} disableGutters>
									<div>
										<PageContentHeading2 gutterBottom>
											{shortText.text}
										</PageContentHeading2>
										<PageContentParagraph>{longText.text}</PageContentParagraph>
									</div>
								</ListItem>
							)
						}
					)}
				</List>
			)
		}
		return <EmptyContractInfoNotice />
	}

	return (
		<PageContent
			key={lotUuid}
			header={<RegulationsPageContentHeader />}
			body={<PageContentBody>{renderPageContent()}</PageContentBody>}
		/>
	)
}

export default RegulationsPageContent
