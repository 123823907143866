import { Box } from '@material-ui/core'
import { STEPS_CHECKOUT, CHECKOUT_DEFAULT_STEP } from 'app/config/checkout'
import routes from 'app/routing/routes/routesApp'
import { UrlParams } from 'app/types/UrlParams'
import { resolveRoute } from 'app/utils/route'
import Link from 'component/Link/Link'
import TemplateBackground from 'component/TemplateBackground/TemplateBackground'
import CardCompanyDataEnter from 'domain/checkout/components/CardCompanyDataEnter/CardCompanyDataEnter'
import CardEmailEnter from 'domain/checkout/components/CardEmailEnter/CardEmailEnter'
import CardEmailVerification from 'domain/checkout/components/CardEmailVerification/CardEmailVerification'
import CardPasswordEnter from 'domain/checkout/components/CardPasswordEnter/CardPasswordEnter'
import CardPaymentConfirm from 'domain/checkout/components/CardPaymentConfirm/CardPaymentConfirm'
import CardPaymentEnter from 'domain/checkout/components/CardPaymentEnter/CardPaymentEnter'
import CardPaymentSuccessful from 'domain/checkout/components/CardPaymentSuccessful/CardPaymentSuccessful'
import CardPricingTable from 'domain/checkout/components/CardPricingTable/CardPricingTable'
import CardUserDataEnter from 'domain/checkout/components/CardUserDataEnter/CardUserDataEnter'
import CheckoutFlow from 'domain/checkout/components/CheckoutFlow/CheckoutFlow'
import React, { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, RouteProps } from 'react-router-dom'

import useStyles from './TemplateCheckoutStyles'

const TemplateCheckout: FC<RouteProps> = props => {
	const classes = useStyles()
	const { t } = useTranslation()

	const isValidStepParameter = (
		stepId: STEPS_CHECKOUT | undefined
	): boolean => {
		if (!stepId) {
			return false
		}

		const index = Object.values(STEPS_CHECKOUT).indexOf(stepId)
		return index >= 0
	}

	return (
		<Route
			{...props}
			render={({ history: { push }, match: { params } }): ReactNode => {
				const { stepId } = params as UrlParams
				const isValid = isValidStepParameter(stepId)

				if (!isValid) {
					push(
						resolveRoute(routes.checkoutSteps, {
							stepId: CHECKOUT_DEFAULT_STEP,
						})
					)
				}

				return (
					<TemplateBackground>
						<Box className={classes.cardWrapper}>
							<CheckoutFlow>
								<CardPricingTable stepId={STEPS_CHECKOUT.STEP_PRICING_PLAN} />
								<CardEmailEnter stepId={STEPS_CHECKOUT.STEP_EMAIL_ENTER} />
								<CardEmailVerification
									stepId={STEPS_CHECKOUT.STEP_EMAIL_VERIFICATION}
								/>
								<CardUserDataEnter
									stepId={STEPS_CHECKOUT.STEP_USER_DATA_ENTER}
								/>
								<CardCompanyDataEnter
									stepId={STEPS_CHECKOUT.STEP_COMPANY_DATA_ENTER}
								/>
								<CardPasswordEnter
									stepId={STEPS_CHECKOUT.STEP_PASSWORD_ENTER}
								/>
								<CardPaymentEnter stepId={STEPS_CHECKOUT.STEP_PAYMENT_ENTER} />
								<CardPaymentConfirm
									stepId={STEPS_CHECKOUT.STEP_PAYMENT_CONFIRM}
								/>
								<CardPaymentSuccessful
									stepId={STEPS_CHECKOUT.STEP_PAYMENT_SUCCESS}
								/>
							</CheckoutFlow>
							<Box className={classes.linkWrapper}>
								<Link
									color="white"
									to={t('domain:checkout.legal.termsAndConditions.url')}
									target="_blank"
									className={classes.link}
								>
									{t('domain:checkout.legal.termsAndConditions.title')}
								</Link>
								<Link
									color="white"
									to={t('domain:checkout.legal.privacy.url')}
									target="_blank"
									className={classes.link}
								>
									{t('domain:checkout.legal.privacy.title')}
								</Link>
								<Link
									color="white"
									to={t('domain:checkout.legal.imprint.url')}
									target="_blank"
									className={classes.link}
								>
									{t('domain:checkout.legal.imprint.title')}
								</Link>
							</Box>
						</Box>
					</TemplateBackground>
				)
			}}
		/>
	)
}

export default TemplateCheckout
