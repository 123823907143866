import React, { FC, useCallback } from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import Logo from 'domain/header/components/Logo/Logo'
import { useAuthentication } from 'app/hooks'
import { REDIRECT_URL } from 'app/constants'
import useStyles from './TemplateLoginStyles'
import TemplateLoginProps from './TemplateLoginProps'

const TemplateLogin: FC<TemplateLoginProps> = ({
	component: Component,
	...rest
}) => {
	const classes = useStyles()

	const { accessToken } = useAuthentication()
	const location = useLocation()

	const renderRedirectURL = useCallback(() => {
		return <Redirect to={REDIRECT_URL} />
	}, [])

	const renderContent = useCallback(() => {
		if (accessToken) {
			return renderRedirectURL()
		}

		return (
			<div className={classes.root}>
				<div className={classes.sideBox}>
					<Logo
						classes={{
							root: classes.logoRoot,
							image: classes.logoImage,
						}}
					/>
					<div className={classes.form}>
						<Component />
					</div>
				</div>
			</div>
		)
	}, [accessToken, location])

	return <Route {...rest} render={renderContent} />
}

export default TemplateLogin
