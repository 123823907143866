import { makeStyles } from '@material-ui/core'
import { themeValues } from 'app/themes/themeValues'

export default makeStyles({
	root: {
		width: themeValues().sizes.AttachmentCard.width,
		minWidth: themeValues().sizes.AttachmentCard.width,
	},
	media: {
		height: themeValues().sizes.AttachmentCard.height,
	},
	description: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	footerActionsContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
})
