import { ValidateDomainEventProps } from 'app/types/ValidateDomainEventProps'
import useValidateDomainEvent from 'component/ValidateDomainEvent/useValidateDomainEvent'
import { FC, ReactElement } from 'react'

const ValidateDomainEvent: FC<ValidateDomainEventProps> = ({
	children,
	event: eventKey,
	trigger,
}): ReactElement | null => {
	const { render } = useValidateDomainEvent({
		event: eventKey,
		trigger,
	})

	return render(children) ?? null
}

export default ValidateDomainEvent
