import InputBase from '@material-ui/core/InputBase'
import { TokenDigitValidatorProps } from 'domain/checkout/components/TokenDigitValidator/TokenDigitValidatorProps'
import { isEmpty, isNumber, toNumber, isNaN, get } from 'lodash'
import React, { FC, forwardRef, ChangeEvent } from 'react'
import useStyles from './TokenDigitValidatorStyles'

const TokenDigitValidator: FC<TokenDigitValidatorProps> = forwardRef(
	(props, ref) => {
		const { onChange, value: inputValue } = props
		const classes = useStyles()

		const isValidInput = (value: string): boolean => {
			if (!value || isEmpty(value)) {
				return true
			}

			if (value.length > 1) {
				return false
			}

			if (!isNumber(toNumber(value))) {
				return false
			}

			if (value && value.length > 1) {
				return false
			}

			return !isNaN(toNumber(value))
		}

		const onCustomChange = (e: ChangeEvent<HTMLInputElement>): void => {
			if (onChange && isValidInput(e.target.value)) {
				onChange(e)
			}
		}

		return (
			<InputBase
				{...props}
				ref={ref}
				value={inputValue}
				autoComplete="false"
				autoCapitalize="false"
				autoCorrect="false"
				classes={{ root: classes.root, input: classes.input }}
				onChange={onCustomChange}
				onFocus={() => {
					const input = get(ref, ['current', 'children', 0])
					if (input) {
						// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
						// @ts-ignore
						input.select()
					}
				}}
			/>
		)
	}
)

export default forwardRef<FC, TokenDigitValidatorProps>((props, ref) => (
	<TokenDigitValidator ref={ref} {...props} />
))
